export const SURVEY_RESULTS_CLASSNAME = 'survey-container__results';

export const SURVEY_NODE_CLASSNAME = 'survey-container__node';

export const ACCESSION_ID_SEARCH_PARAM = 'accessionId';

export enum FOOTER_STYLE {
  Alternative = 'alternative',
}

export enum CONTENT_ALLOCATION {
  Top = 'top',
  Bottom = 'bottom',
}

export const defaultClinicName = 'myGeneHistory';

export const queryParamsToInitialize = ['campaign_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'];

export const MINUTE = 60;

export const TIMEOUT_SECONDS = 29 * MINUTE;
export const CHATBOT_TIMEOUT_SECONDS = 57 * MINUTE;

export const DELAY_SECONDS = 60;
export const CHATBOT_DELAY_SECONDS = 180;

export const DEFAULT_SLUG = 'demo';

export const CLINIC_LOGO_CONFIGURATION_KEY = 'clinicLogo';
