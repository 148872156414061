export const isMonthLessThan = (date1: Date, date2: Date): boolean => date2.getMonth() < date1.getMonth();
export const isDateLessThan = (date1: Date, date2: Date): boolean => date2.getMonth() === date1.getMonth() && date2.getDate() < date1.getDate();

export const isLessThan = (date1: Date, date2: Date): boolean => isMonthLessThan(date1, date2) || isDateLessThan(date1, date2);

export const yearDiff = (date1: Date, date2: Date): number => {
  const years = date2.getFullYear() - date1.getFullYear();
  if (isLessThan(date1, date2)) {
    return years - 1;
  }
  return years;
};
export const isValidDate = (val: string): boolean => val.length === 10 && val.lastIndexOf('-') === 7;

export const getAge = (dob: Date): number => yearDiff(dob, new Date());

export const getDateFromInput = (val: string): Date => {
  const dateArr = val.split('-');
  const year = Number.parseInt(dateArr[0], 10);
  const month = Number.parseInt(dateArr[1], 10);
  const day = Number.parseInt(dateArr[2], 10);
  return new Date(year, month - 1, day);
};

export default {
  isMonthLessThan,
  isDateLessThan,
  isLessThan,
  yearDiff,
  isValidDate,
  getAge,
  getDateFromInput,
};
