import { ContinueType, NodeTypes, ChatModalNodeType, ChatModalNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const medicalHistoryModalNode: ChatModalNode = {
  id: NODE_IDS.MEDICAL_HISTORY_NODE,
  type: NodeTypes.ChatModalNode,
  name: 'Chat Medical History Modal Node',
  description: 'Chat Medical History Modal Node',
  data: {
    answerKey: 'medicalHistory',
    continueType: ContinueType.AutoFixed,
    subtitle: 'chatbot.medicalHistory.subtitle',
    flowType: ChatModalNodeType.MedicalHistory,
    pageTitleKey: 'pageTitles.chatbot.medicalHistoryModalNode',
  },
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Assessment Complete Node',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.ASSESSMENT_COMPLETE_NODE,
      },
    },
  ],
};

export default medicalHistoryModalNode;
