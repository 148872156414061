import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionSelectComponent, ComponentSizes, OptionKeyValue, SurveyAccount, ContinueType } from '@myriadgenetics/mgh-types';
import { Text1, Text2 } from '../../elements/typography';
import { OptionSelect } from '../question';
import { useHistory } from '../../helpers/routing';
import aux from '../../helpers/auxiliary-page';
import QuestionText from '../../elements/question-text';
import Continue from '../../elements/continue';

import './index.scss';

type Provider = string | number | null | undefined;
type Clinic = string | null;

interface Props {
  onProviderSelected: (surveyAccount: SurveyAccount, clinicName: string) => void;
  surveyAccounts: SurveyAccount[];
}

interface ClinicAccount {
  name: string | number;
  surveyAccountId: number | string;
}

interface ClinicMap {
  [clinicName: string]: ClinicAccount[];
}

export const getClinicName = (surveyAccount: SurveyAccount): string => surveyAccount.clinicName || 'N/A';

const getProviderName = (surveyAccount: SurveyAccount): string | number => surveyAccount.providerName || surveyAccount.surveyId;

const getSurveyAccountById = (surveyAccountId: number, surveyAccounts: SurveyAccount[]): SurveyAccount | null | undefined =>
  surveyAccounts.find((sa) => sa.id === surveyAccountId);

const getSurveyAccountByProvider = (providerList: OptionKeyValue[], provider: Provider): Provider[] =>
  providerList.filter((p) => p.optionValue === provider).map((p) => p.optionKey);

const parseSurveyAccounts = (surveyAccounts: SurveyAccount[]): ClinicMap => {
  const clinicMap: ClinicMap = {};
  surveyAccounts.forEach((sa) => {
    const clinicName = getClinicName(sa);
    if (!clinicMap[clinicName]) {
      clinicMap[clinicName] = [];
    }

    clinicMap[clinicName].push({
      name: getProviderName(sa),
      surveyAccountId: sa.id,
    });
  });

  // sort by provider name
  Object.keys(clinicMap).forEach((c) => {
    clinicMap[c] = clinicMap[c].sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));
  });
  return clinicMap;
};

function ProviderSelect({ onProviderSelected, surveyAccounts }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [clinic, setClinic] = useState<Clinic | undefined>(null);
  const [provider, setProvider] = useState<Provider | undefined>(null);
  const [surveyAccount, setSurveyAccount] = useState<SurveyAccount | null | undefined>(null);
  const clinicMap = parseSurveyAccounts(surveyAccounts);
  const clinicNames = Object.keys(clinicMap);
  const sortedClinicNames = clinicNames.sort((a, b) => a.localeCompare(b));

  const providers: OptionKeyValue[] = useMemo(
    () =>
      clinic
        ? clinicMap[clinic].map((p) => ({
            optionKey: p.surveyAccountId,
            optionValue: `${p.name}`,
          }))
        : [],
    [clinic, clinicMap],
  );

  const isSingleClinic = clinicNames.length === 1;
  const isSingleProvider = providers.length === 1;
  const isSelectedNeeded = !(isSingleClinic && isSingleProvider);

  useEffect(() => {
    if (!clinic && isSingleClinic) {
      setClinic(sortedClinicNames[0]);
    } else if (!provider && isSingleProvider) {
      const firstProvider = providers[0];
      setProvider(firstProvider.optionValue);
      setSurveyAccount(getSurveyAccountById(Number.parseInt(firstProvider.optionKey.toString(), 10), surveyAccounts));
    }
  }, [clinic, isSingleClinic, provider, isSingleProvider, providers, surveyAccounts, sortedClinicNames]);

  const clinicsComponent: OptionSelectComponent = {
    id: 1,
    type: 'OPTION_SELECT',
    data: {
      answerKey: 'providerSelectClinic',
      isNative: true,
      isSingleSelect: true,
      labelKey: 'provider.clinicName',
      optionSize: ComponentSizes.Large,
      placeholderKey: '',
      questionKey: '',
      required: true,
      selectionOptions: clinicNames.map((name) => ({
        optionKey: name,
        optionValue: name,
      })),
      subtitleKey: '',
    },
  };

  const providersComponent: OptionSelectComponent = {
    id: 2,
    type: 'OPTION_SELECT',
    data: {
      answerKey: 'providerSelectProvider',
      isNative: true,
      isSingleSelect: true,
      labelKey: 'provider.name',
      optionSize: ComponentSizes.Large,
      placeholderKey: providers.length > 0 ? 'provider.name' : ' ', // space so this seems incomplete till clinic is selected
      questionKey: '',
      required: true,
      selectionOptions: providers,
      subtitleKey: '',
    },
  };

  return (
    <div className="provider-select">
      <Text2 className="provider-select__heading">{t('provider.heading')}</Text2>
      <div className="provider-select__question">
        <QuestionText htmlFor="optionSelect" questionKey={!isSelectedNeeded ? 'provider.delivered' : 'provider.question'} />
      </div>
      {clinic !== 'N/A' && isSingleClinic && (
        <div className="provider-select__clinic-container">
          <Text2 className="provider-select__clinic-label" tag="p">
            {t('provider.clinicName')}
          </Text2>
          <Text1 className="provider-select__clinic" tag="p">
            {clinic}
          </Text1>
        </div>
      )}
      {clinic !== 'N/A' && !isSingleClinic && (
        <OptionSelect
          className="provider-select__clinic-option-select"
          compDef={clinicsComponent}
          responses={{
            providerSelectClinic: clinic ? [{ optionKey: clinic, optionValue: clinic }] : undefined,
          }}
          onResponseUpdated={(key: string, val: OptionKeyValue[]) => {
            setClinic(val[0].optionValue);
            setProvider(null);
          }}
        />
      )}
      {provider && isSingleProvider && (
        <div className="provider-select__provider-container">
          <Text2 className="provider-select__provider-label" tag="p">
            {t('provider.name')}
          </Text2>
          <Text1 className="provider-select__provider" tag="p">
            {provider}
          </Text1>
        </div>
      )}
      {clinic && !isSingleProvider && (
        <OptionSelect
          className="provider-select__provider-option-select"
          compDef={providersComponent}
          responses={{
            providerSelectProvider: getSurveyAccountByProvider(providers, provider) || undefined,
          }}
          onResponseUpdated={(key, val) => {
            setProvider(val[0].optionValue);
            setSurveyAccount(getSurveyAccountById(val[0].optionKey, surveyAccounts));
          }}
        />
      )}
      {isSelectedNeeded && <Text2 className="provider-select__help">{t('provider.help')}</Text2>}
      <Continue
        buttonKey="continue"
        disabled={!provider || !clinic}
        type={ContinueType.End}
        onContinue={() => {
          if (surveyAccount) {
            onProviderSelected(surveyAccount, getClinicName(surveyAccount));
          } else {
            aux.routeToAuxPage(history, {
              titleKey: 'errorPages.server.title',
              messageKey: 'errorPages.server.message',
            });
          }
        }}
      />
    </div>
  );
}

export default ProviderSelect;
