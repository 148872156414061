import { NodeTypes, ChatModalNodeOptions, DetailsType } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const breastCancerRiskStep = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.TC_NODE,
  type: NodeTypes.BreastCancerRisk,
  name: 'Tc Node.',
  description: 'Cancer History Node description',
  data: {
    continueType,
    answerKey: baseKey,
    detailsType: DetailsType.Self,
    selfSexKey: 'personalInformation.sex[0].optionValue',
    subtitle,
    pageTitleKey: 'pageTitles.tcNode',
  },
  flows: [],
});
