import { PageNode, ChatbotNode, Component, ContinueType } from '@myriadgenetics/mgh-types';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { ChatbotNodeType } from '../chatbot-container/types';

export * as SurveyNodeConsts from './constants';

export type CompoundNode = (PageNode | ChatbotNodeType) & { id: number };

export const findNodeById = (id: number, nodeList: CompoundNode[]): CompoundNode | undefined =>
  nodeList && nodeList?.find && nodeList?.find((n) => n.id === id);

export const isEndType = (type: ContinueType) => type === ContinueType.AutoEnd || type === ContinueType.End;

export const isAutoType = (type: ContinueType): boolean => type === ContinueType.AutoFixed || type === ContinueType.AutoEnd;

export const triggerContinue = (gotoNextNode: (a: any) => void, { flows }: any, responses: any) => {
  gotoNextNode(getNextNodeId(flows, responses));
};

export const getUniqueListBasedOnId = (list: any) => {
  return [
    ...list
      .reduce((acc: any, curr: any) => {
        acc.set(curr.id, curr);
        return acc;
      }, new Map())
      .values(),
  ];
};

export const areAllValid = (validationMap: any): boolean => {
  for (const key in validationMap) {
    if (!validationMap[key].isValid) return false;
  }
  return true;
};

export const trackGtmData = (node: PageNode | ChatbotNode, gtmDataTracking: any) => {
  gtmDataTracking && gtmDataTracking({ answerKey: node?.data?.continueKey || '' });
};

export const autoAble = (node: any, get: (a: string, b: string) => void, responses: any): boolean => {
  for (let i = 0; i < node.components.length; i++) {
    const c: Component = node.components[i];
    if (c.type === 'TEXT_QUESTION') {
      const { secondaryResponse } = c.data;
      return !!secondaryResponse && get(responses, c.data.answerKey) === secondaryResponse.value;
    }
  }

  return true;
};

export const isContinueInline = (node: ChatbotNode | PageNode) => {
  try {
    return node.components.some((c: Component) => c.type === 'CONTINUE');
  } catch (e) {
    console.error(`Unable to continue due to: ${e}`);
  }
};

const generateUniqueFromName = (name = '') => `${name?.toLowerCase().split('/').join('-').split(' ').join('-').split('.').join('')}`;

export const getUniqueDataId = (id: number, name = '') => `${generateUniqueFromName(name)}-${id}`;

export const getUniqueClassName = (name = '') => generateUniqueFromName(name);

const RESULTS_NODE_KEYWORD_INDICATOR = 'Results';

export const hasResultsNodeName = (nodeName: string) => nodeName.toLowerCase().indexOf(RESULTS_NODE_KEYWORD_INDICATOR.toLowerCase()) > -1;

export default {
  areAllValid,
  autoAble,
  findNodeById,
  getUniqueListBasedOnId,
  isContinueInline,
  isEndType,
  isAutoType,
  trackGtmData,
  triggerContinue,
  getUniqueDataId,
  getUniqueClassName,
  hasResultsNodeName,
};
