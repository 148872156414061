export const NODE_IDS = {
  MRN_NODE: -29,
  START_NODE: -30,
  INTRO_WITH_TERMS_NODE: -31,
  ENTER_PERSONAL_HISTORY_NODE: -32,
  PATIENT_LOCATION_NODE: -33,
  PERSONAL_HISTORY_INTRO_NODE: -34,
  PERSONAL_HISTORY_NODE: -35,
  PERSONAL_CANCER_NODE: -36,
  SOME_NEXT_NODE: -37,
  PREVIOUSLY_TESTED_NODE: -38,
  QUALIFYING_QUESTION_NODE: -39,
  HALFWAY_THROUGH_NODE: -40,
  MEDICAL_HISTORY: -41,
  ASSESSMENT_COMPLETE_NODE: -42,
  MEETS_CRITERIA_TC_OVER_NEXT_STEPS_NODE: -44,
  MEETS_CRITERIA_TC_UNDER_NEXT_STEPS_NODE: -46,
  MEETS_CRITERIA_TC_OVER_THRESHOLD_NODE: -47,
  MEETS_CRITERIA_TC_UNDER_THRESHOLD_NODE: -48,
  MEETS_CRITERIA_GENETIC_COUNSELOR_CONTACT_NODE: -49,
  OUTSIDE_CRITERIA_TC_OVER_THRESHOLD_NODE: -51,
  OUTSIDE_CRITERIA_TC_UNDER_THRESHOLD_NODE: -53,
  BREAST_CANCER_RISK_NODE: -54,
  CRITERIA_ENDPOINT_NODE: -55,
  CANCER_HISTORY_INTRO_NODE: -56,
  MEDICAL_HISTORY_NODE: -57,
  HIDE_RESULTS_NODE: -58,
  THANK_YOU_EXIT: -60,
  POST_SURVEY_ENTRY_NODE: -61,
};

export const LIFETIME_TC_THRESHOLD = 0.15;
