import { useEffect } from 'react';
import { Component, ContinueType, MrnNode, PageNode, SurveyAccount } from '@myriadgenetics/mgh-types';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { areAllValid } from '../helpers';

import { useHistory } from '../../../helpers/routing';
import { Text2 } from '../../../elements/typography';
import { typeToComponent } from '../../../helpers/component-map';
import Validation from '../../../validation';
import Continue from '../../../elements/continue';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash';

import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { getPatientIdentifiersUniquePaths, PatientIdentifierTypes } from '../../../helpers/url';
import type { PatientIdentifierResponse } from '../../survey-container/types';

import './index.scss';

interface Props {
  node: MrnNode;
  className?: string;
  initNode?: any | PageNode;
  responses: any;
  surveyAccount?: SurveyAccount;
  gotoNextNode: (nodeId: number | null | undefined) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
}

function MrnNodeReact({ node, className, responses, gotoNextNode, onResponseUpdated, initNode, surveyAccount }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const paths = getPatientIdentifiersUniquePaths();
  const skipThisPage = paths.some((path) => {
    const identifiers = get(responses, path, []);
    // Use of patientID from the url is secondary
    return identifiers.some((identifier: PatientIdentifierResponse) => identifier.use === PatientIdentifierTypes.SECONDARY && identifier.value);
  });
  useEffect(() => {
    if (history.action === 'REPLACE' && skipThisPage) gotoNextNode(getNextNodeId(node.flows, responses));
  });

  const validationMap = Validation.createValidationMap(node.components, responses);
  const allAreValid = areAllValid(validationMap);
  let hasSpecialCharacter = false;

  if (!allAreValid) {
    const mrn = get(responses, `${node.data.answerKey}.value`, '');
    const lettersAndNumbersOnly = /^[A-Za-z0-9]*$/;
    hasSpecialCharacter = !lettersAndNumbersOnly.test(mrn);
  }

  const onInternalResponseUpdated = (answerKey: string, val: any) => {
    onResponseUpdated(answerKey, val);
    const use = get(responses, `${node.data.answerKey}.use`, '');
    if (use !== PatientIdentifierTypes.OFFICIAL) onResponseUpdated(`${node.data.answerKey}.use`, 'official');
  };

  if (!skipThisPage)
    return (
      <div className={`mrn-info ${className || ''}`}>
        {Array.isArray(node.components) &&
          node.components.map((c: Component, i: number) => {
            const key = Validation.getKey(c, i);
            const Tag = typeToComponent(c);
            return (
              <Tag
                key={key}
                compDef={c}
                responses={responses}
                onResponseUpdated={onInternalResponseUpdated}
                onContinue={() => {}}
                surveyAccount={surveyAccount}
                validationResult={validationMap[key]}
              />
            );
          })}

        {!hasSpecialCharacter ? (
          <>
            <div className={`${!allAreValid ? 'wrapper--disabled' : ''} center`}>
              <Continue
                disabled={!allAreValid}
                onContinue={() => {
                  onResponseUpdated(`${node.data.answerKey}.use`, 'official');
                  gotoNextNode(getNextNodeId(node.flows, responses));
                }}
                type={ContinueType.End}
              />
            </div>
            <Text2>{t('chatbot.mrn.askDesk')}</Text2>
          </>
        ) : (
          <div className="invalid-entry--container">
            <div className="invalid-entry--title">
              <WarningIcon />
              <span>{t('chatbot.mrn.invalidEntry')}</span>
            </div>
            <div className="invalid-entry--subtitle">{t('chatbot.mrn.checkMRN')}</div>
            <div className="invalid-entry--content">{t('chatbot.mrn.notAllowingSpecialCharacters')}</div>
          </div>
        )}
      </div>
    );
  return <></>;
}

export default MrnNodeReact;
