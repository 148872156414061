import { NodeTypes, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5007,
    description: 'Built-in page node',
    name: 'Live birth history',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.liveBirthNode',
    },
    components: [
      {
        id: -8,
        description: 'Built in component',
        name: 'Live birth history',
        type: 'YES_NO',
        data: {
          answerKey: `${baseKey}.liveBirth`,
          questionKey: 'breastCancerRisk.liveBirth',
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.liveBirth',
            definitionKey: 'helpHints.liveBirthHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -7,
        type: 'BINARY',
        name: 'Live birth history flow',
        description: 'Built-in flow',
        data: {
          answerKey: `${baseKey}.liveBirth`,
          affirmativePageId: -5008,
          negativePageId: -5004,
        },
      },
    ],
  };
};
