import { NodeTypes, ChatModalNodeOptions, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const colonPolypCount = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.COLON_POLYP_COUNT_NODE,
  name: 'Colon polyp Count Node',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.colonPolypCountNode',
  },
  components: [
    {
      type: 'OPTION_SELECT',
      id: -3,
      data: {
        answerKey: `${baseKey}.polypRange`,
        questionKey: 'colonPolyp.numberOfPolyps',
        required: true,
        isSingleSelect: true,
        isNative: true,
        subtitleKey: '',
        labelKey: 'colonPolyp.polypRange',
        placeholderKey: '',
        optionSize: ComponentSizes.Large,
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'colonPolyp.polypRanges.1',
          },
          {
            optionKey: 2,
            optionValue: 'colonPolyp.polypRanges.2to5',
          },
          {
            optionKey: 3,
            optionValue: 'colonPolyp.polypRanges.6to9',
          },
          {
            optionKey: 4,
            optionValue: 'colonPolyp.polypRanges.10to19',
          },
          {
            optionKey: 5,
            optionValue: 'colonPolyp.polypRanges.20to99',
          },
          {
            optionKey: 6,
            optionValue: 'colonPolyp.polypRanges.100to999',
          },
          {
            optionKey: 7,
            optionValue: 'colonPolyp.polypRanges.1000+',
          },
        ],
      },
    },
  ],
  flows: [
    {
      id: -3,
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.CRITERIA_ENDPOINT_NODE,
      },
    },
  ],
});
