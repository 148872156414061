import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextLogo from '../../elements/text-logo';
import Button from '../../elements/button';
import Link from '../../elements/link';
import Logger from '../../helpers/logger';
import { Text1, Text3 } from '../../elements/typography';
import DialogFrame, { DialogType } from '../dialog-frame';

import './index.scss';

interface Props {
  onContinue: () => void;
}

function StartPage({ onContinue }: Props) {
  const { t } = useTranslation();
  const [dialogDisplay, setDialogDisplay] = useState<DialogType | null>(null);

  return (
    <div className="start-page">
      <div className="start-page__logo">
        <TextLogo size="large" />
      </div>
      <Text1 className="start-page__intro">{t('introductory.title')}</Text1>
      <div className="start-page__started">
        <Button onClick={onContinue}>
          <Text1>{t('continue')}</Text1>
        </Button>
      </div>
      {dialogDisplay && <DialogFrame type={dialogDisplay} onClose={() => setDialogDisplay(null)} />}
      <div className="start-page__tos">
        <Text3 tag="div">{t('introductory.tosPreface')}</Text3>
        <Link
          className="start-page__tos-link"
          onClick={() => {
            Logger.logEvent('Clicked TOS', null);
            setDialogDisplay(DialogType.TermsOfUse);
          }}
        >
          <Text3 tag="span">{t('introductory.termsOfUse')}</Text3>
        </Link>
        <Text3 tag="span">{t('introductory.and')}</Text3>
        <Link
          className="start-page__pp-link"
          onClick={() => {
            Logger.logEvent('Clicked PP', null);
            setDialogDisplay(DialogType.PrivacyPolicy);
          }}
        >
          <Text3 tag="span">{t('introductory.privacy')}</Text3>
        </Link>
      </div>
      <Text3 className="start-page__subtitle">{t('introductory.subtitle')}</Text3>
    </div>
  );
}

export default StartPage;
