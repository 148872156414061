import { useHistory as baseUseHistory, useLocation as baseUseLocation } from 'react-router-dom';
import * as H from 'history';
import { isChatbotUrl } from '../url';

export class WrappedUseHistory {
  history: H.History;
  action?: H.Action;
  location?: H.Location | any; // I don't love this but it's required for usage of `lengthAtPush`
  length?: number;

  constructor() {
    this.history = baseUseHistory();
    if (this.history) {
      this.action = this.history.action;
      this.location = this.history.location;
      this.length = this.history.length;
    }
  }

  push = (url: string, state: any) => {
    const aChatbotUrl = isChatbotUrl(url);
    this.history.push(url, {
      ...state,
      lengthAtPush: (state.lengthAtPush || this.history.length) + 1,
    });

    if (window && window.scrollTo && !aChatbotUrl) {
      try {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      } catch (e) {
        window.scrollTo(0, 0);
      }
    }
  };

  replace = (url: string, state: any) => {
    this.history.replace(url, state);
  };

  go = (n: number) => {
    this.history.go(n);
  };

  goBack = () => {
    this.history.goBack();
  };

  goForward = () => {
    this.history.goForward();
  };

  listen = (listener: any) => {
    this.history.listen(listener);
  };

  goForwardOnContinue = () => {
    return this.action === 'POP' && this.location && this.location.state && this.length && this.length > this.location.state.lengthAtPush;
  };
}

export const useHistory = () => new WrappedUseHistory();
export const useLocation = () => baseUseLocation();

export const startOver = () => {
  window.onbeforeunload = null;
  window.location.reload();
};
