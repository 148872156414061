import * as Sentry from '@sentry/react';
import Config from '../config';
import { M_DATA_KEYS, KEYS_TO_MEDALLIA } from './types';

// SENTRY
const logError = (err: any) => {
  if (Config.enableSentry()) {
    if (typeof err === 'string') {
      Sentry.captureMessage(err);
    } else {
      Sentry.captureException(err);
    }
  } else {
    console.error(err);
  }
};

const MEDALLIA_SDK_PATH = 'KAMPYLE_ONSITE_SDK';

// global window
declare global {
  interface Window {
    analytics: any;
    mDataLayer: any;
    [MEDALLIA_SDK_PATH]: any;
  }
}

// SEGMENT
const analytics = () =>
  (Config.enableAnalytics() && window.analytics) || {
    page: (page: any) => (page ? console.log(page) : console.log(window.location.pathname)),
    track: (event: string, properties: any) => console.log(`${event}: ${JSON.stringify(properties)}`),
  };

// MEDALLIA
const logMedalliaData = (key: string, value: any) => {
  const mghNameSpace = 'mgh';

  const shouldDisplayMedalliaSurvey = (max: number) => {
    const baseNumber = 0;
    const randomNumber = Math.floor(Math.random() * max);
    return randomNumber === baseNumber;
  };

  const updateMedalliaPageView = () => {
    if (window[MEDALLIA_SDK_PATH] && typeof window[MEDALLIA_SDK_PATH].updatePageView === 'function') {
      if (Config.isDev()) {
        console.log('[MEDALLIA]: updating page view data');
      }
      window[MEDALLIA_SDK_PATH].updatePageView();
    }
  };

  const pushToDataLayer = (key: string, value: any) => {
    window.mDataLayer[mghNameSpace][key] = value;

    updateMedalliaPageView();

    if (Config.isDev()) {
      console.log(`[MEDALLIA]: added to medallia data layer window.mDataLayer.${mghNameSpace}.${key}: ${value}`);
    }
  };

  const initializeDataLayer = (namespace: string) => {
    window.mDataLayer = window.mDataLayer || { [mghNameSpace]: {} };

    const hasNameSpace = Object.prototype.hasOwnProperty.call(window.mDataLayer, namespace);

    if (!hasNameSpace) {
      window.mDataLayer[namespace] = {};
    }

    if (!Object.prototype.hasOwnProperty.call(window.mDataLayer[namespace], KEYS_TO_MEDALLIA.SHOULD_DISPLAY_MEDALLIA_SURVEY)) {
      // always push our should display
      // TODO: put this value in our config so we can adjust without deploying. Include a reasonable default.
      const oneInNumber = 4; // determines factor. 4 = 1/4 = 25%
      pushToDataLayer(KEYS_TO_MEDALLIA.SHOULD_DISPLAY_MEDALLIA_SURVEY, shouldDisplayMedalliaSurvey(oneInNumber));
    }
  };

  initializeDataLayer(mghNameSpace);

  if (window.mghConfig?.MEDALLIA_KEYS.includes(key)) {
    switch (key) {
      case M_DATA_KEYS.CRITERIA_CHECK:
        pushToDataLayer(KEYS_TO_MEDALLIA.MET_CRITERIA, value?.criteriaMetList.length > 0);
        break;
      case M_DATA_KEYS.SURVEY_ENTRY_SAVE:
        pushToDataLayer(KEYS_TO_MEDALLIA.SURVEY_NAME, value?.surveyName);
        pushToDataLayer(KEYS_TO_MEDALLIA.SURVEY_ID, value?.surveyId);
        break;
      default:
        pushToDataLayer(key, value);
        break;
    }
  }
};

// TODO add identity on "start"
const logPageView = (page?: any) => analytics().page(page);
const logEvent = (event: string, properties: any) => analytics().track(event, properties);

export default {
  logError,
  logPageView,
  logEvent,
  logMedalliaData,
};
