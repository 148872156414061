import React from 'react';
import { CriteriaDetailsComponent } from '@myriadgenetics/mgh-types';
import { useTranslation } from 'react-i18next';
import { H3, H4, Text1 } from '../../../elements/typography';

import './index.scss';

interface Props {
  compDef: any | CriteriaDetailsComponent;
  responses: any;
}

export enum CriteriaCheckResult {
  NotMet = 'NOT_MET',
  Meets = 'MEETS',
}

interface CriteriaCoverage {
  key: string;
  reason: string;
}

interface CriteriaGuidelines {
  [key: string]: CriteriaCoverage[];
}

interface Criteria {
  criteria_code: string;
  test_family: string;
  description: string;
}

interface Criterion {
  criteria: Criteria;
  criteria_check_result: CriteriaCheckResult;
  coverage_reasons: string[];
  coverage: CriteriaCoverage[];
}

const NCCN_TOKEN = 'NCCN';
const LYNCH_TOKEN = 'Lynch';
const FAP_TOKEN = 'FAP';
const USPSTF_TOKEN = 'USPSTF';
const ASBS_TOKEN = 'ASBS';
const MISC_TOKEN = 'MISC';
const MISC_FULL_TOKEN = 'Miscellaneous';

const containsText = (str: string, text: string) => str.toLowerCase().indexOf(text.toLowerCase()) !== -1;

const mapGuidelineKey = (description: string): string => {
  if (containsText(description, NCCN_TOKEN) || containsText(description, FAP_TOKEN) || containsText(description, LYNCH_TOKEN)) {
    return NCCN_TOKEN;
  } else if (containsText(description, USPSTF_TOKEN)) {
    return USPSTF_TOKEN;
  } else if (containsText(description, ASBS_TOKEN)) {
    return ASBS_TOKEN;
  } else if (containsText(description, MISC_TOKEN) || containsText(description, MISC_FULL_TOKEN)) {
    return MISC_FULL_TOKEN;
  }
  return description;
};

const criteriaObj = (responses: any) => {
  const { criteriaCheck, surveyEntrySave } = responses;
  // criteria from saved entry
  return surveyEntrySave && surveyEntrySave.rawCriteria
    ? {
        criteriaList: surveyEntrySave.rawCriteria ? JSON.parse(surveyEntrySave.rawCriteria) : [],
        criteriaMetList: surveyEntrySave.criteriaMetList ? JSON.parse(surveyEntrySave.criteriaMetList) : [],
      }
    : criteriaCheck; // criteria from criteria check - no save
};

const criteriaCoverage = (criteriaList: Criterion[]): CriteriaGuidelines => {
  const guidelines: CriteriaGuidelines = {};

  criteriaList.forEach((c) => {
    const key = mapGuidelineKey(c.criteria.description);
    if (c.criteria_check_result === CriteriaCheckResult.Meets) {
      if (!guidelines[key]) {
        guidelines[key] = [];
      }
      guidelines[key] = guidelines[key].concat(c.coverage);
    }
  });
  return guidelines;
};

function CriteriaDetails({ compDef, responses }: Props) {
  const { t } = useTranslation();

  const criteriaResult = criteriaObj(responses);
  const reasons = criteriaResult && criteriaResult.criteriaList ? criteriaCoverage(criteriaResult.criteriaList) : {};

  return (
    <div className="criteria-details">
      {criteriaResult && (
        <React.Fragment>
          <H3 className="criteria-details__title">{t('results.met.criteriaDetailsTitle')}</H3>
          <H4 className="criteria-details__guidelines-title">{t('results.met.guidelinesMet')}</H4>
          <Text1 className="criteria-details__guidelines">
            {Object.keys(reasons)
              .sort((a, b) => a.localeCompare(b))
              .join(', ')}
          </Text1>
          <H4 className="criteria-details__criteria-title">{t('results.met.qualifyingCriteria')}</H4>
          {Object.keys(reasons)
            .sort((a, b) => a.localeCompare(b))
            .map((guideline) => (
              <GuidelineDetails key={guideline} guideline={guideline} criteriaCoverage={reasons[guideline]} />
            ))}
        </React.Fragment>
      )}
    </div>
  );
}

type GDProp = {
  guideline: string;
  criteriaCoverage: CriteriaCoverage[];
};

function GuidelineDetails({ guideline, criteriaCoverage }: GDProp) {
  const { t } = useTranslation();
  const getDedupedCoverageByReason = (coverage: CriteriaCoverage[]) => {
    return [...new Map(coverage.map((c) => [c.reason, c])).values()];
  };
  const dedupCoverage = getDedupedCoverageByReason(criteriaCoverage);
  return (
    <div className="guideline-details">
      <Text1 className="guideline-details__guideline" bold="true">
        {t('results.met.guidelineTitle', { guideline })}:
      </Text1>
      {criteriaCoverage &&
        [...dedupCoverage].map((cc, i) => (
          <Text1 key={i} className="guideline-details__reason">
            {t(`criteriaReasons.${cc.key}`, { defaultValue: cc.reason })}
          </Text1>
        ))}
    </div>
  );
}

export default CriteriaDetails;
