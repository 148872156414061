import { set as _set, isEmpty as _isEmpty } from 'lodash';
import { Node, NodeTypes, SurveyEvents } from '@myriadgenetics/mgh-types';
import { pushGTMData, GTMDataProps } from '../../helpers/googleTagManager';
import { remapAnswer } from '../../helpers/answers';
import { WrappedUseHistory } from '../../helpers/routing';
import { LocationState, SurveyContainerStep } from './types';
import API from '../../api';
import { ACCESSION_ID_SEARCH_PARAM, queryParamsToInitialize } from './constants';
import { SURVEY_ACCOUNT_ID_SEARCH_PARAM } from '../../helpers/surveyAccount';
import Logger from '../../helpers/logger';
import { TFunction } from 'i18next';
import { getPatientIdentifierKeys, cleanUpSecondaryPatientIdentifier } from './../../helpers/url/index';

interface responseUpdate {
  answerKey: string;
  answer: any;
  gtmData?: Partial<GTMDataProps>;
  responses: { [key: string]: any };
  aT404PUrl: boolean;
  history: WrappedUseHistory;
  location: LocationState;
}

interface initSurvey {
  urlSlug: string;
  history: WrappedUseHistory;
  location: LocationState;
  session: string;
  title: string;
}

interface goToNextNodeParams {
  id: number | null | undefined;
  replace?: boolean;
  curNode?: Node | null | undefined;
  survey?: any;
  goForwardOnContinue: boolean;
  history: WrappedUseHistory;
  urlSlug: string;
  location: LocationState;
  t: TFunction;
}

export const updateResponses = ({ answerKey, answer, gtmData, responses, aT404PUrl, history, location }: responseUpdate): any => {
  // here's where we maintain backwards compatibility
  const newMapping = remapAnswer(answerKey, answer);
  if (Array.isArray(newMapping)) {
    for (const newlyMappedAnswer of newMapping) {
      _set(responses, newlyMappedAnswer.key, newlyMappedAnswer.value);
    }
  }

  // this custom event is only for chatbot...may need to filter out the emit
  const event = new CustomEvent(SurveyEvents.onResponseUpdated, { detail: { answerKey, answer } });
  document.dispatchEvent(event);

  // updated in place so downstream components have updated value right away
  _set(responses, answerKey, answer);

  aT404PUrl && pushGTMData({ answerKey, answer, data: gtmData });
  // be careful not to create new responses object here as pointers will get screwy otherwise
  history.replace(location.pathname, {
    ...location.state,
    locResponses: responses,
  });

  // here we will add to the dataLayer
  Logger.logMedalliaData(answerKey, answer);

  return responses;
};

export const getSurveyByIdPath = (id: number): string => `/patient-surveys/${id}`;
export const getSurveyById = async (id: number) => API.get(getSurveyByIdPath(id));

const getPatientIdentifierQueryParams = (location: LocationState): { [key: string]: string } => {
  const patientIdentifierKeys = getPatientIdentifierKeys();
  const search = new URLSearchParams(location.search);
  return patientIdentifierKeys.reduce((acc, key) => {
    if (!key) return acc;
    const patientIdentifierQueryParam = search.get(key);
    if (patientIdentifierQueryParam) {
      const cleanedPatientIdentifier = cleanUpSecondaryPatientIdentifier(patientIdentifierQueryParam);
      acc = { ...acc, [key]: cleanedPatientIdentifier };
    }
    return acc;
  }, {});
};

export const initializeSurvey = ({ urlSlug, history, location, session, title }: initSurvey) => {
  const init = () => {
    const initData: any = {
      ajs_anonymous_id: 'not-provided',
      personalBreastCancerHistory: false, // default value to ensure proper breast cancer risk flow
    };

    // be careful as window.analytics is not guaranteed to have been fully loaded
    // this can happen if segment is blocked for any reason
    try {
      window.analytics.ready(() => {
        // should reset anonymous id on every survey load
        window.analytics.reset();
        initData.ajs_anonymous_id = window.analytics.user().anonymousId();
      });
    } catch {
      console.log('unable to set anonymous id');
      initData.ajs_anonymous_id = `init_${crypto.randomUUID()}`;
    }

    const search = new URLSearchParams(location.search);
    queryParamsToInitialize.forEach((qp) => {
      if (search.get(qp)) initData[qp] = search.get(qp);
    });

    const patientIdentifiersQueryParams = getPatientIdentifierQueryParams(location);

    let historyObj: any = {
      title,
      surveyStep: SurveyContainerStep.Lang,
      mghUrl: urlSlug,
      accessionId: search.get(ACCESSION_ID_SEARCH_PARAM),
      surveyEntryAccountId: search.get(SURVEY_ACCOUNT_ID_SEARCH_PARAM),
      locSession: session,
      initData,
    };

    if (!_isEmpty(patientIdentifiersQueryParams)) {
      historyObj = {
        ...historyObj,
        queryParams: {
          ...patientIdentifiersQueryParams,
        },
      };
    }

    history.push(`/${urlSlug}/lang${location.search || ''}`, historyObj);
  };

  // this is to guarantee that we will have fully loaded window.analytics
  if (window.analytics && window.analytics.ready) {
    window.analytics.ready(init);
  } else {
    init();
  }
};

export const getTagForNode = (node: Node, nodes: any): any | null => {
  switch (node.type) {
    case NodeTypes.ColonPolyp:
      return nodes.ColonPolypNodeReact;
    case NodeTypes.BloodTransfusion:
      return nodes.BloodTransfusionNodeReact;
    case NodeTypes.BreastCancerRisk:
      return nodes.BreastCancerRiskNodeReact;
    case NodeTypes.CancerHistory:
      return nodes.CancerHistoryNodeReact;
    case NodeTypes.ContextBreak:
      return nodes.ContextBreakNodeReact;
    case NodeTypes.EndpointCall:
      return nodes.EndpointCallNode;
    case NodeTypes.Page:
      return nodes.PageNodeReact;
    case NodeTypes.Redirect:
      return nodes.RedirectNodeReact;
    case NodeTypes.Content:
      return nodes.ContentNodeReact;
    case NodeTypes.Chat:
      return nodes.ChatbotWorkflow;
    case NodeTypes.ChatModalNode:
      return nodes.ChatModalNode;
    default:
      return null;
  }
};

const getLangPathRegex = (urlSlug: string) => new RegExp(`[#/](?:${urlSlug}|lang)([/]|/lang)?$`);
export const getIsLangPath = (pathname: string, urlSlug: string) => getLangPathRegex(urlSlug).test(pathname);

export const startState = (history: WrappedUseHistory) => ({
  lengthAtPush: history.length,
  timing: {
    firstPage: new Date().toISOString(),
  },
});

export const isForceStartOver = (atResults: boolean, surveyStep?: SurveyContainerStep) => atResults && surveyStep !== SurveyContainerStep.Results;

export const attachOnBeforeUnloadEvent = (shouldDisplayNotice: boolean | null) => {
  if (shouldDisplayNotice) {
    window.onbeforeunload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = false;
    };
  } else {
    window.onbeforeunload = null;
  }
};

export const findNodeByIdFromSurvey = (id: number | null, survey: any): Node | null | undefined => survey && survey.nodes.find((n: Node) => n.id === id);

export const gotoNextNode = ({ id, replace, curNode, survey, goForwardOnContinue, history, location, urlSlug, t }: goToNextNodeParams) => {
  // TODO figure out how we can make it so CANCER_HISTORY doesn't have to be explicitly ignored
  if (goForwardOnContinue && curNode && curNode.type !== NodeTypes.CancerHistory) {
    history.go(1);
  } else {
    const routeMethod = replace ? history.replace : history.push;
    if (id) {
      const nextNode = findNodeByIdFromSurvey(id, survey);
      if (!nextNode) {
        Logger.logError(`node: ${id} does not exist`);
      } else {
        routeMethod(`/${urlSlug}/survey/node/${id}`, {
          ...location.state,
          title: t(nextNode.name || `myGeneHistory - ${id}`),
          nodeId: id,
        });
      }
    } else {
      routeMethod(`/${urlSlug}/final`, {
        ...location.state,
        title: t('pageTitles.final'),
        surveyStep: SurveyContainerStep.Final,
        nodeId: undefined,
      });
    }
  }
};
