import mrnNode from './mrnNode';
import startNode from './start';
import introWithTermsNode from './introWithTerms';
import enterPersonalHistoryNode from './enterPersonalHistoryNode';
import patientLocationNode from './patientLocation';
import personalHistoryIntroNode from './personalHistoryIntro';
import personalHistoryNode from './personalHistoryNode';
import previouslyTested from './previouslyTested';
import qualifyingQuestionNode from './qualifyingQuestionNode';
import personalCancerNode from './personalCancerNode';
import nextNode from './someNextNodeExample';
import halfwayNode from './halfwayNode';
import assessmentCompleteNode from './assessmentCompleteNode';
import hideResultsNode from './hideResultsNode';
import meetsCriteriaOverNextStepsNode from './meetsCriteriaOverNextStepsNode';
import meetsCriteriaUnderNextStepsNode from './meetsCriteriaUnderNextStepsNode';
import meetsCriteriaTCOverThresholdNode from './meetsCriteriaTCOverThresholdNode';
import meetsCriteriaGeneticCounselorContactNode from './meetsCriteriaGeneticCounselorContactNode';
import outsideCriteriaTCOverThresholdNode from './outsideCriteriaTCOverThresholdNode';
import meetsCriteriaTCUnderThresholdNode from './meetsCriteriaTCUnderThresholdNode';
import outsideCriteriaTCUnderThresholdNode from './outsideCriteriaTCUnderThresholdNode';
import breastCancerRiskNode from './breastCancerRisk';
import cancerHistoryIntroNode from './cancerHistoryIntroNode';
import medicalHistoryModalNode from './medicalHistoryModalNode';
import thankYouExitLink from './thankYouExit';
import postSurveyEntry from './postSurveyEntry';

export default {
  mrnNode,
  startNode,
  introWithTermsNode,
  enterPersonalHistoryNode,
  patientLocationNode,
  personalHistoryIntroNode,
  personalHistoryNode,
  previouslyTested,
  qualifyingQuestionNode,
  personalCancerNode,
  nextNode,
  halfwayNode,
  assessmentCompleteNode,
  hideResultsNode,
  meetsCriteriaOverNextStepsNode,
  meetsCriteriaUnderNextStepsNode,
  meetsCriteriaTCOverThresholdNode,
  meetsCriteriaTCUnderThresholdNode,
  meetsCriteriaGeneticCounselorContactNode,
  outsideCriteriaTCOverThresholdNode,
  outsideCriteriaTCUnderThresholdNode,
  breastCancerRiskNode,
  cancerHistoryIntroNode,
  medicalHistoryModalNode,
  thankYouExitLink,
  postSurveyEntry,
};
