import { ComponentSizes, NodeTypes, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5010,
    description: 'Built-in page node',
    name: 'Hormone Replacement Therapy types',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.hormoneReplacementTherapyTypesNode',
    },
    components: [
      {
        type: 'OPTION_SELECT',
        id: -11,
        name: 'Hormone Replacement Therapy Types',
        description: 'Built in question',
        data: {
          answerKey: `${baseKey}.hormoneTherapyType`,
          questionKey: 'breastCancerRisk.hormoneTherapyType',
          labelKey: '',
          placeholderKey: '',
          subtitleKey: '',
          required: true,
          isSingleSelect: true,
          optionSize: ComponentSizes.Medium,
          selectionOptions: [
            {
              optionKey: 3,
              optionValue: 'breastCancerRisk.hormoneTherapyTypes.combined',
            },
            {
              optionKey: 1,
              optionValue: 'breastCancerRisk.hormoneTherapyTypes.estrogenOnly',
            },
            {
              optionKey: 2,
              optionValue: 'breastCancerRisk.hormoneTherapyTypes.progesteroneOnly',
            },
            {
              optionKey: 4,
              optionValue: 'breastCancerRisk.hormoneTherapyTypes.dontRemember',
            },
          ],
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtTypes',
            definitionKey: '',
          },
        },
      },
      {
        id: -3,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtTypeCombined',
            definitionKey: 'helpHints.hrtTypeCombinedHint',
          },
        },
      },
      {
        id: -4,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtTypeEstrogen',
            definitionKey: 'helpHints.hrtTypeEstrogenHint',
          },
        },
      },
      {
        id: -5,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtTypeProgesterone',
            definitionKey: 'helpHints.hrtTypeProgesteroneHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -10,
        type: 'UNCONDITIONAL',
        name: 'Hormone Replacement Therapy history flow',
        description: 'Built-in flow',
        data: {
          nextPageId: -5011,
        },
      },
    ],
  };
};
