import { useTranslation } from 'react-i18next';

import '../index.scss';

interface FooterItemProps {
  urlKey: string;
  textKey: string;
  subItems?: FooterItemProps[];
}

interface FooterNavProps {
  items: FooterItemProps[];
  isSimpleView?: boolean;
}

function FooterNav(props: FooterNavProps) {
  const { t } = useTranslation();

  return (
    <ul className={`footer__nav ${props.isSimpleView ? 'simpleView' : ''}`}>
      {props.items.map(({ urlKey, textKey, subItems }, index) => (
        <li key={index}>
          <a href={t(urlKey)} title={t(textKey)} target="_blank" rel="noreferrer" className="footer__nav_link">
            {t(textKey)}
          </a>
          {subItems && (
            <ul className="footer__sub_nav">
              {subItems.map(({ urlKey: url, textKey: text }, index) => (
                <li key={index}>
                  <a href={t(url)} target="_blank" rel="noreferrer" className="footer__sub_nav_link">
                    {t(text)}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}

export default FooterNav;
