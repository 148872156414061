import React from 'react';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { BreastPathology } from '@myriadgenetics/mgh-types';
import { Text3 } from '../../../elements/typography';

import './index.scss';

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

const SEPARATOR = ':';

const yesNoMapper = (val: boolean, noKey?: string) => (val ? YesNo.Yes : noKey || YesNo.No);
interface Props {
  pathology: BreastPathology;
}

function BreastCancerDetails({ pathology }: Props) {
  const isDcis = ({ type, breast }: BreastPathology): boolean => !!(type === 'cancerHistory.cancers.dcis' && breast);
  const isBreast = ({ type, breast }: BreastPathology): boolean => !!(breast && type !== 'cancerHistory.cancers.dcis');
  const isLeftBreast = ({ isBilateral, left }: BreastPathology): boolean => !!(!isBilateral && left);
  const isRightBreast = ({ isBilateral, right }: BreastPathology): boolean => !!(!isBilateral && right);
  const isRelativeBilateral = ({ isBilateral, breast }: BreastPathology): boolean => !!(isBilateral && !breast);

  return (
    <div className="breast-cancer-details">
      {isDcis(pathology) && (
        <BreastCancerDetail
          className="breast-cancer-details__triple-negative"
          labelKey="review.tripleNegative"
          displayKey={yesNoMapper(get(pathology, 'breast.tripleNegative', false), 'pathology.noOrUnknown')}
        />
      )}
      {isBreast(pathology) && (
        <React.Fragment>
          <BreastCancerDetail labelKey="review.details" displayKey={get(pathology, 'breast.details[0].optionValue', 'pathology.breast.none')} />
          <BreastCancerDetail
            className="breast-cancer-details__metastatic"
            labelKey="review.metastatic"
            displayKey={yesNoMapper(get(pathology, 'breast.metastatic', false), 'pathology.noOrUnknown')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__triple-negative"
            labelKey="review.tripleNegative"
            displayKey={yesNoMapper(get(pathology, 'breast.tripleNegative', false), 'pathology.noOrUnknown')}
          />
          {pathology.breast?.currentTreatment !== undefined && (
            <BreastCancerDetail
              className="breast-cancer-details__current-treatment"
              labelKey="review.currentlyBeingTreated"
              displayKey={yesNoMapper(get(pathology, 'breast.currentTreatment', false))}
            />
          )}
        </React.Fragment>
      )}
      {isLeftBreast(pathology) && (
        <React.Fragment>
          <BreastCancerDetail
            className="breast-cancer-details__left-details"
            labelKey="review.leftDetails"
            displayKey={get(pathology, 'left.details[0].optionValue', 'pathology.breast.none')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__left-metastatic"
            labelKey="review.leftMetastatic"
            displayKey={yesNoMapper(get(pathology, 'left.metastatic', false), 'pathology.noOrUnknown')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__left-triple-negative"
            labelKey="review.leftTripleNegative"
            displayKey={yesNoMapper(get(pathology, 'left.tripleNegative', false), 'pathology.noOrUnknown')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__left-current-treatment"
            labelKey="review.leftBeingTreated"
            displayKey={yesNoMapper(get(pathology, 'left.currentTreatment', false))}
          />
        </React.Fragment>
      )}
      {isRightBreast(pathology) && (
        <React.Fragment>
          <BreastCancerDetail
            className="breast-cancer-details__right-details"
            labelKey="review.rightDetails"
            displayKey={get(pathology, 'right.details[0].optionValue', 'pathology.breast.none')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__right-metastatic"
            labelKey="review.rightMetastatic"
            displayKey={yesNoMapper(get(pathology, 'right.metastatic', false), 'pathology.noOrUnknown')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__right-triple-negative"
            labelKey="review.rightTripleNegative"
            displayKey={yesNoMapper(get(pathology, 'right.tripleNegative', false), 'pathology.noOrUnknown')}
          />
          <BreastCancerDetail
            className="breast-cancer-details__right-current-treatment"
            labelKey="review.rightBeingTreated"
            displayKey={yesNoMapper(get(pathology, 'right.currentTreatment', false))}
          />
        </React.Fragment>
      )}
      {isRelativeBilateral(pathology) && (
        <React.Fragment>
          <BreastCancerDetail labelKey="review.firstDetails" displayKey={get(pathology, 'first.details[0].optionValue', 'pathology.breast.none')} />
          <BreastCancerDetail
            className="breast-cancer-details__first-triple-negative"
            labelKey="review.firstTripleNegative"
            displayKey={yesNoMapper(get(pathology, 'first.tripleNegative', false), 'pathology.noOrUnknown')}
          />
          <BreastCancerDetail labelKey="review.secondDetails" displayKey={get(pathology, 'second.details[0].optionValue', 'pathology.breast.none')} />
          <BreastCancerDetail
            className="breast-cancer-details__second-triple-negative"
            labelKey="review.secondTripleNegative"
            displayKey={yesNoMapper(get(pathology, 'second.tripleNegative', false), 'pathology.noOrUnknown')}
          />
        </React.Fragment>
      )}
    </div>
  );
}

type DetailProps = {
  className?: string;
  displayKey: string;
  labelKey: string;
};

function BreastCancerDetail({ className, displayKey, labelKey }: DetailProps) {
  const { t } = useTranslation();
  return (
    <p className={`breast-cancer-details__detail ${className || ''}`}>
      <Text3 className="breast-cancer-details__detail-label" tag="span">
        {`${t(labelKey)}${SEPARATOR}`}
      </Text3>
      <Text3 className="breast-cancer-details__detail-value" tag="span" bold="true">
        {t(displayKey)}
      </Text3>
    </p>
  );
}

export default BreastCancerDetails;
