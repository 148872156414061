import React, { useState } from 'react';
import { RecoverTypes, RejectionErrorTypes, SurveyAccount, BaseError } from '@myriadgenetics/mgh-types';
import { useHistory } from '../../../../helpers/routing';
import Logger from '../../../../helpers/logger';
import auxiliaryPage, { errorStateMap } from '../../../../helpers/auxiliary-page';
import { getSurveyAccountsByUrl } from '../../../../helpers/api';
import LangStep from './components/langStep';

interface ConfigState {
  fetching: boolean;
  surveyAccounts?: SurveyAccount[] | null;
}
const DEF_CONFIG_STATE: ConfigState = { fetching: false, surveyAccounts: null };

export interface SharedProps {
  urlSlug: string;
  setProvider: (arg0: SurveyAccount, arg1: string, arg2: boolean) => void;
  onContinue: () => void;
  shouldHide?: boolean;
}

interface ContainerProps extends SharedProps {
  setSurveyAccounts: (arg0: SurveyAccount[]) => void;
}

function LangStepContainer(props: ContainerProps) {
  const history = useHistory();
  const [configState, setConfigState] = useState<ConfigState>(DEF_CONFIG_STATE);
  const { fetching, surveyAccounts } = configState;

  if (!fetching && surveyAccounts === null) {
    setConfigState({ fetching: true, surveyAccounts: null });
    getSurveyAccountsByUrl(props.urlSlug)
      .then((res) => {
        if (res.length === 0) {
          auxiliaryPage.routeToAuxPage(history, (errorStateMap as Record<RejectionErrorTypes, BaseError>)[RejectionErrorTypes.MissingAccount]);
        } else {
          setConfigState({ fetching: false, surveyAccounts: res });
          props.setSurveyAccounts(res);
        }
      })
      .catch((error) => {
        Logger.logError(error);
        auxiliaryPage.routeToAuxPage(history, {
          ...errorStateMap[RejectionErrorTypes.Network],
          recoverTextKey: 'startOver',
          recoverAction: RecoverTypes.GoBack,
        });
      });
  }
  return <LangStep {...props} surveyAccounts={surveyAccounts} />;
}

export { LangStep };
export default LangStepContainer;
