import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import close from '../../assets/close.svg';
import { H3, Text1 } from '../typography';

import './index.scss';
import Button from '../button';

type Props = {
  translatedMessage: string;
  confirmKey: string;
  onConfirm: () => void;
  onCancel: () => void;
  containerClass?: string;
};

function ConfirmationModal({ translatedMessage, confirmKey, onConfirm, onCancel, containerClass }: Props) {
  const { t } = useTranslation();
  const container = containerClass ? document.getElementsByClassName(containerClass)[0] || document.body : document.body;

  const escListener = (event: KeyboardEvent) => {
    event.keyCode === 27 && onCancel();
  };

  useEffect(() => {
    window.addEventListener('keyup', escListener);
    return () => {
      window.removeEventListener('keyup', escListener);
    };
  });

  return (
    container && (
      <React.Fragment>
        {ReactDOM.createPortal(
          <div className="confirmation-modal">
            <div className="confirmation-modal__backdrop" onClick={onCancel}></div>
            <div className="confirmation-modal__content-area">
              <div className="confirmation-modal__header">
                <img className="confirmation-modal__close-icon" alt={t('modal.close')} src={close} onClick={onCancel} />
              </div>
              <div className="confirmation-modal__body">
                <H3>{t('attention')}</H3>
                <Text1>{translatedMessage}</Text1>
              </div>
              <div className="confirmation-modal__footer">
                <Button className="confirmation-modal__cancel-btn" onClick={onCancel}>
                  {t('cancel')}
                </Button>
                <Button className="confirmation-modal__confirm-btn" secondary onClick={onConfirm}>
                  {t(confirmKey)}
                </Button>
              </div>
            </div>
          </div>,
          container,
        )}
      </React.Fragment>
    )
  );
}

export default ConfirmationModal;
