import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContinueType } from '@myriadgenetics/mgh-types';
import Continue from '../../../elements/continue';
import { isEndType } from '../helpers';
import { H4, Text2 } from '../../../elements/typography';
import Button from '../../../elements/button';
import { startOver, useHistory } from '../../../helpers/routing';

interface CWProps {
  continueKey?: string;
  allAreValid: boolean;
  continueType: ContinueType;
  isResultsPage?: boolean;
  triggerContinue: () => void;
  isClosePageButtonExist?: boolean;
  gtmDataTracking: () => void;
  hasBackButton?: boolean;
  isChatbotStyle?: boolean;
  isTriggerModal?: boolean;
  isPrimary?: boolean;
  disabled?: boolean;
  disabledIcon?: any;
}

function ContinueWrapper({
  continueKey,
  isResultsPage,
  continueType,
  allAreValid,
  hasBackButton,
  triggerContinue,
  isClosePageButtonExist,
  gtmDataTracking,
  isChatbotStyle,
  isTriggerModal,
  isPrimary,
  disabled,
  disabledIcon,
}: CWProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const goBack = () => history.goBack();

  const disabledButtonText = useCallback(
    (key: string) => {
      const originalButtonText = t(key);

      if (!isTriggerModal) return originalButtonText;

      /**
       * This `const` strips the word "Enter" from the
       * beginning of the button text after translation
       */
      const finalButtonText = originalButtonText.replace(`${t('chatbot.enter')} `, '');

      return finalButtonText;
    },
    [isTriggerModal, t],
  );

  // TODO: Refactoring: These conditional renderings are difficult to manage and to read.
  const isChatbotAndValidAndDisabled = isChatbotStyle && allAreValid && disabled;
  const isNotChatbotAndValidAndNotResults = !isChatbotStyle && allAreValid && !isResultsPage;
  const isChatbotAndValidAndNotDisabled = isChatbotStyle && allAreValid && !disabled;
  const isNotResultsAndNotValidAndEndType = !isResultsPage && !allAreValid && isEndType(continueType);
  const handleOnContinueWithGtmLog = () => {
    triggerContinue();
    gtmDataTracking && gtmDataTracking();
  };

  return (
    <>
      {hasBackButton && (
        <Button onClick={goBack} className={'page-node__action page-node__action--back'}>
          {t('back')}
        </Button>
      )}

      {isChatbotAndValidAndDisabled && (
        <div className="container-after-modal-closed continue__button">
          {isTriggerModal && !isResultsPage && (
            <Button className="chat-bubble--undo chat-bubble--edit" onClick={handleOnContinueWithGtmLog}>
              {t('edit')}
            </Button>
          )}
          <div className="wrapper">
            {disabledIcon}
            <Text2>{disabledButtonText(continueKey || 'continue')}</Text2>
          </div>
        </div>
      )}
      {(isNotChatbotAndValidAndNotResults || isChatbotAndValidAndNotDisabled) && (
        <div className={`page-node__action page-node__action--continue ${isTriggerModal ? 'page-node__action--modal-trigger' : ''}`}>
          <Continue
            buttonKey={continueKey}
            type={continueType}
            onContinue={handleOnContinueWithGtmLog}
            isChatbotStyle={isChatbotStyle}
            disabled={disabled}
            isPrimary={isPrimary}
          />
        </div>
      )}

      {isNotResultsAndNotValidAndEndType && (
        <div className={`page-node__action page-node__action--continue ${isTriggerModal ? 'page-node__action--modal-trigger' : ''}`}>
          <Continue buttonKey={continueKey} type={continueType} disabled onContinue={handleOnContinueWithGtmLog} isPrimary={isPrimary} />
        </div>
      )}

      {!isChatbotStyle && isResultsPage && (
        <React.Fragment>
          <div className="page-node__start-over-break">
            <hr className="page-node__results-break" />
            <H4>{t('startOverBreakText')}</H4>
          </div>
          {/* TODO Temporary decision. When we have buttons in related nodes - remove this button */}
          {!isClosePageButtonExist && (
            <Button className="page-node__action page-node__action--start-over" onClick={startOver}>
              {t('startOver')}
            </Button>
          )}
        </React.Fragment>
      )}
    </>
  );
}

export default ContinueWrapper;
