import { BreastCancerRiskType, NodeTypes, PageNode, TextQuestionType, ComponentSizes } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type, includesBreastDensity }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5014,
    description: 'Built-in page node',
    name: 'Hormone Therapy Stopped input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.hormoneTherapyStoppedInputNode',
    },
    components: [
      {
        id: -15,
        description: 'Built in component',
        name: 'Stopped Hormone Therapy Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.hormoneTherapyStoppedInput`,
          questionKey: 'breastCancerRisk.hormoneTherapyStoppedInput',
          labelKey: 'breastCancerRisk.yearsLabel',
          placeholderKey: '',
          inputType: TextQuestionType.Number,
          size: ComponentSizes.Large,
          required: type === BreastCancerRiskType.TcScore,
          secondaryResponse: {
            textKey: 'breastCancerRisk.dontKnow',
            value: type === BreastCancerRiskType.TcScore ? ' ' : null,
          },
        },
      },
    ],
    flows: [
      {
        id: -14,
        name: 'Stopped Hormone Therapy Flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: includesBreastDensity ? -5019 : -5015,
        },
      },
    ],
  };
};
