export const CLINIC_LOGO_CONFIGURATION_KEY = 'clinicLogo';

export const footerNav = [
  {
    urlKey: 'https://myriad.com/terms-of-use/',
    textKey: 'footer.nav.terms',
  },
  {
    urlKey: 'https://myriad.com/privacy-policy/',
    textKey: 'footer.nav.privacy',
  },
  {
    urlKey: 'https://myriad.com/patients-families/patient-resources/language-assistance/',
    textKey: 'footer.nav.lang',
  },
];

export const footerMenuNav = [
  {
    urlKey: '#',
    textKey: 'footer.menu.payers',
    subItems: [
      {
        urlKey: 'https://myriad.com/managed-care/',
        textKey: 'footer.menu.overview',
      },
    ],
  },
  {
    urlKey: '#',
    textKey: 'footer.menu.investors',
    subItems: [
      {
        urlKey: 'https://investor.myriad.com/financial-information/',
        textKey: 'footer.menu.relations',
      },
      {
        urlKey: 'https://investor.myriad.com/events-and-presentations',
        textKey: 'footer.menu.events',
      },
    ],
  },
  {
    urlKey: '#',
    textKey: 'footer.menu.news',
    subItems: [
      {
        urlKey: 'https://myriad.com/investors/news-releases/',
        textKey: 'footer.menu.press',
      },
      {
        urlKey: 'https://myriad.com/myriad-genetics-blog/',
        textKey: 'footer.menu.blog',
      },
    ],
  },
  {
    urlKey: '#',
    textKey: 'footer.menu.careers',
    subItems: [
      {
        urlKey: 'https://myriad.com/working-at-myriad/joining-the-myriad-team/',
        textKey: 'footer.menu.team',
      },
    ],
  },
];

export const socialMenu = [
  {
    urlKey: 'https://www.facebook.com/MyriadGenetics',
    iconKey: 'facebook',
  },
  {
    urlKey: 'https://www.linkedin.com/company/myriad-genetics',
    iconKey: 'linkedin',
  },
  {
    urlKey: 'https://twitter.com/myriadgenetics',
    iconKey: 'twitter',
  },
  {
    urlKey: 'https://www.instagram.com/myriadgenetics/',
    iconKey: 'instagram',
  },
  {
    urlKey: 'https://www.youtube.com/user/myriadgenetics/videos',
    iconKey: 'youtube',
  },
];
