import get from 'lodash.get';
import MghTypes, { Relative } from '@myriadgenetics/mgh-types';
import { flatten, unflatten } from '../../../helpers/relative';

const { relatives, uniqueRelatives } = MghTypes;

export const isSelfFemale = (responses: Record<string, any>, selfSexKey: string): boolean => get(responses, selfSexKey) === 'personDemographic.female';
export const isSelfMale = (responses: Record<string, any>, selfSexKey: string): boolean => get(responses, selfSexKey) === 'personDemographic.male';

export const relativeInRelativeList = (list: Relative[], relative: Relative): boolean => list.some((i) => relative === i);

export const isUniqueRelative = (relative: Relative): boolean => relativeInRelativeList(uniqueRelatives, relative);

export const getNextRelativeEntry = (responses: any, answerKey: string, relative: Relative): string => {
  const base = `${answerKey}.${flatten(relative)}`;
  if (isUniqueRelative(relative)) {
    return `${base}.0`;
  } else {
    return `${base}.${get(responses, base, []).length}`;
  }
};

export const getRelativeFromRelativeEntry = (answerKey: string, relativeEntry: string): Relative => {
  const split = relativeEntry.split('.');
  split.pop();
  const relativeStr = unflatten(split.join('.').replace(`${answerKey}.`, ''));
  return relatives.find((rel: any) => rel === relativeStr) || 'relatives.other';
};

export const getNextPathologyEntry = (responses: any, relativeEntry: string): string => {
  const base = `${relativeEntry}.pathology`;
  return `${base}.${get(responses, base, []).length}`;
};

export default {
  isSelfFemale,
  isSelfMale,
  relativeInRelativeList,
  isUniqueRelative,
  getNextRelativeEntry,
  getRelativeFromRelativeEntry,
  getNextPathologyEntry,
};
