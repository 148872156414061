import { OptionSelectComponent, Pathology, Relative, ComponentSizes, OptionKeyValue } from '@myriadgenetics/mgh-types';

const defaultPathologies: Pathology[] = [
  {
    displayKey: 'cancerHistory.cancers.breastFemale',
    optionKey: 1,
    femaleApplicable: true,
    maleApplicable: false,
    answerKey: 'breast',
  },
  {
    displayKey: 'cancerHistory.cancers.breastMale',
    optionKey: 14,
    femaleApplicable: false,
    maleApplicable: true,
    answerKey: 'breast',
  },
  {
    displayKey: 'cancerHistory.cancers.dcis',
    optionKey: 2,
    femaleApplicable: true,
    maleApplicable: false,
    answerKey: 'dcis',
  },
  {
    displayKey: 'cancerHistory.cancers.colon',
    optionKey: 3,
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'colon',
  },
  {
    displayKey: 'cancerHistory.cancers.uterine',
    optionKey: 4,
    femaleApplicable: true,
    maleApplicable: false,
    answerKey: 'uterine',
  },
  {
    displayKey: 'cancerHistory.cancers.fallopian',
    optionKey: 5,
    femaleApplicable: true,
    maleApplicable: false,
    answerKey: 'fallopian',
  },
  {
    displayKey: 'cancerHistory.cancers.gastric',
    optionKey: 6,
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'gastric',
  },
  {
    displayKey: 'cancerHistory.cancers.melanoma',
    optionKey: 7,
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'melanoma',
  },
  {
    displayKey: 'cancerHistory.cancers.ovarian',
    optionKey: 8,
    femaleApplicable: true,
    maleApplicable: false,
    answerKey: 'ovarian',
  },
  {
    displayKey: 'cancerHistory.cancers.pancreatic',
    optionKey: 9,
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'pancreatic',
  },
  {
    displayKey: 'cancerHistory.cancers.peritoneal',
    optionKey: 10,
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'peritoneal',
  },
  {
    displayKey: 'cancerHistory.cancers.prostate',
    optionKey: 12,
    femaleApplicable: false,
    maleApplicable: true,
    answerKey: 'prostate',
  },
  {
    optionKey: 16,
    displayKey: 'cancerHistory.cancers.rectum',
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'rectum',
  },
  {
    displayKey: 'cancerHistory.cancers.other',
    optionKey: 13,
    femaleApplicable: true,
    maleApplicable: true,
    answerKey: 'other',
  },
];

export const otherPathologies: OptionKeyValue[] = [
  {
    optionKey: 29,
    optionValue: 'cancerHistory.cancers.adrenalCarcinoma',
  },
  {
    optionKey: 24,
    optionValue: 'cancerHistory.cancers.ampulla',
  },
  {
    optionKey: 18,
    optionValue: 'cancerHistory.cancers.bileDuct',
  },
  {
    optionKey: 19,
    optionValue: 'cancerHistory.cancers.biliaryTract',
  },
  {
    optionKey: 8,
    optionValue: 'cancerHistory.cancers.bladder',
  },
  {
    optionKey: 17,
    optionValue: 'cancerHistory.cancers.brainTumor',
  },
  {
    optionKey: 3,
    optionValue: 'cancerHistory.cancers.breastHyperplasia',
  },
  {
    optionKey: 7,
    optionValue: 'cancerHistory.cancers.breastLcis',
  },
  {
    optionKey: 2,
    optionValue: 'cancerHistory.cancers.cervix',
  },
  {
    optionKey: 35,
    optionValue: 'cancerHistory.cancers.chrpe',
  },
  {
    optionKey: 27,
    optionValue: 'cancerHistory.cancers.desmoidTumors',
  },
  {
    optionKey: 34,
    optionValue: 'cancerHistory.cancers.duodenalPolyps',
  },
  {
    optionKey: 22,
    optionValue: 'cancerHistory.cancers.duodenum',
  },
  {
    optionKey: 23,
    optionValue: 'cancerHistory.cancers.fibromas',
  },
  {
    optionKey: 25,
    optionValue: 'cancerHistory.cancers.stomachPolyps',
  },
  {
    optionKey: 26,
    optionValue: 'cancerHistory.cancers.glioblastoma',
  },
  {
    optionKey: 13,
    optionValue: 'cancerHistory.cancers.headNeck',
  },
  {
    optionKey: 11,
    optionValue: 'cancerHistory.cancers.hematological',
  },
  {
    optionKey: 14,
    optionValue: 'cancerHistory.cancers.hepatobiliary',
  },
  {
    optionKey: 41,
    optionValue: 'cancerHistory.cancers.hepatoblastoma',
  },
  {
    optionKey: 5,
    optionValue: 'cancerHistory.cancers.hodgkins',
  },
  {
    optionKey: 6,
    optionValue: 'cancerHistory.cancers.kidney',
  },
  {
    optionKey: 42,
    optionValue: 'cancerHistory.cancers.jejunalPolyps',
  },
  {
    optionKey: 38,
    optionValue: 'cancerHistory.cancers.jejunum',
  },
  {
    optionKey: 10,
    optionValue: 'cancerHistory.cancers.leukemia',
  },
  {
    optionKey: 4,
    optionValue: 'cancerHistory.cancers.lung',
  },
  {
    optionKey: 40,
    optionValue: 'cancerHistory.cancers.medulloblastoma',
  },
  {
    optionKey: 31,
    optionValue: 'cancerHistory.cancers.nevus',
  },
  {
    optionKey: 36,
    optionValue: 'cancerHistory.cancers.nevusAtypical',
  },
  {
    optionKey: 37,
    optionValue: 'cancerHistory.cancers.nevusDysplastic',
  },
  {
    optionKey: 15,
    optionValue: 'cancerHistory.cancers.nonEpithelialOvarian',
  },
  {
    optionKey: 39,
    optionValue: 'cancerHistory.cancers.osteomas',
  },
  {
    optionKey: 32,
    optionValue: 'cancerHistory.cancers.osteosarcoma',
  },
  {
    optionKey: 9,
    optionValue: 'cancerHistory.cancers.peritoneum',
  },
  {
    optionKey: 28,
    optionValue: 'cancerHistory.cancers.rectalPolyps',
  },
  {
    optionKey: 33,
    optionValue: 'cancerHistory.cancers.renalPelvis',
  },
  {
    optionKey: 12,
    optionValue: 'cancerHistory.cancers.sarcoma',
  },
  {
    optionKey: 20,
    optionValue: 'cancerHistory.cancers.sebaceous',
  },
  {
    optionKey: 21,
    optionValue: 'cancerHistory.cancers.smallIntestine',
  },

  {
    optionKey: 1,
    optionValue: 'cancerHistory.cancers.thyroid',
  },
  {
    optionKey: 43,
    optionValue: 'cancerHistory.cancers.upperGi',
  },
  {
    optionKey: 30,
    optionValue: 'cancerHistory.cancers.ureter',
  },
  {
    optionKey: 45,
    optionValue: 'cancerHistory.cancers.urinaryTract',
  },
  {
    optionKey: 44,
    optionValue: 'cancerHistory.cancers.notInThisList',
  },
];

const isPathologyApplicable = (pathology: Pathology, includeFemalePathology: boolean, includeMalePathology: boolean): boolean =>
  pathology.femaleApplicable === includeFemalePathology || pathology.maleApplicable === includeMalePathology;

const getPathology = (pathologyList: Pathology[], includeFemalePathology: boolean, includeMalePathology: boolean): Pathology[] =>
  pathologyList.filter((pathology) => isPathologyApplicable(pathology, includeFemalePathology, includeMalePathology));

const mapPathologyToOption = (pathologyList: Pathology[]): OptionKeyValue[] =>
  pathologyList.map((pathology) => ({
    optionKey: pathology.optionKey,
    optionValue: pathology.displayKey,
  }));

export const pathologySelect = (
  pathologyList: Pathology[] = defaultPathologies,
  includeFemalePathology = true,
  includeMalePathology = true,
  relative: Relative,
): OptionSelectComponent => ({
  type: 'OPTION_SELECT',
  id: -2,
  name: 'Choose cancer',
  description: 'Built in question.',
  data: {
    answerKey: 'cancer',
    questionKey: relative === 'relatives.self' ? 'cancerHistory.chooseCancerSelf' : 'cancerHistory.chooseCancerRelative',
    questionKeyParams: { relative },
    labelKey: '',
    placeholderKey: '',
    subtitleKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions:
      includeFemalePathology || includeMalePathology
        ? mapPathologyToOption(getPathology(pathologyList.length === 0 ? defaultPathologies : pathologyList, includeFemalePathology, includeMalePathology))
        : [],
  },
});

export const pathologyOtherSelect: OptionSelectComponent = {
  id: -3,
  type: 'OPTION_SELECT',
  name: 'Other Pathology Select',
  description: 'Used to select other pathology type',
  data: {
    questionKey: 'pathology.other',
    answerKey: `pathology`,
    isNative: true,
    isSingleSelect: true,
    required: true,
    labelKey: 'pathology.cancerType',
    placeholderKey: '',
    subtitleKey: '',
    optionSize: ComponentSizes.Large,
    selectionOptions: otherPathologies,
  },
};

export default { pathologySelect, pathologyOtherSelect, otherPathologies };
