import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionSelectComponent, ComponentSizes, OptionKeyValue, SurveyAccount, ContinueType } from '@myriadgenetics/mgh-types';
import { H2, Text1 } from '../../elements/typography';
import { OptionSelect } from '../question';
import { useHistory } from '../../helpers/routing';
import aux from '../../helpers/auxiliary-page';
import Continue from '../../elements/continue';
import { getSurveyAccountsConfigurations, findDefaultSurveyAccountForUrlByUrlSlug, splitDefaultSurveyAccountForUrl } from './utils';

import './index.scss';

type Provider = string | number | null | undefined;

interface Props {
  onProviderSelected: (surveyAccountId: number) => void;
  surveyAccounts: SurveyAccount[];
  urlSlug: string;
}

const getProviderName = (surveyAccount: SurveyAccount): string => surveyAccount.providerName || String(surveyAccount.surveyId);

const getSurveyAccountById = (surveyAccountId: number, surveyAccounts: SurveyAccount[]): SurveyAccount | null | undefined =>
  surveyAccounts.find((sa) => sa.id === surveyAccountId);

const getSurveyAccountByProvider = (providerList: OptionKeyValue[], provider: Provider): Provider[] =>
  providerList.filter((p) => p.optionValue === provider).map((p) => p.optionKey);

const sortByProviderName = (providers: OptionKeyValue[]): OptionKeyValue[] => providers.sort((a, b) => `${a.optionValue}`.localeCompare(`${b.optionValue}`));

const getProviderListFromSurveyAccounts = (surveyAccounts: SurveyAccount[]): OptionKeyValue[] =>
  sortByProviderName(
    surveyAccounts.map((surveyAccount) => ({
      optionKey: surveyAccount.id,
      optionValue: getProviderName(surveyAccount),
    })),
  );

function ProviderSelectModal({ onProviderSelected, surveyAccounts, urlSlug }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState<Provider | undefined>(null);
  const [surveyAccount, setSurveyAccount] = useState<SurveyAccount | null | undefined>(null);

  const providers = getProviderListFromSurveyAccounts(surveyAccounts);

  const providersComponent: OptionSelectComponent = {
    id: 1,
    type: 'OPTION_SELECT',
    data: {
      answerKey: 'providerSelectProvider',
      isNative: true,
      isSingleSelect: true,
      labelKey: 'providerModal.labelKey',
      optionSize: ComponentSizes.Large,
      placeholderKey: 'providerModal.placeholderKey',
      questionKey: '',
      required: true,
      selectionOptions: providers,
      subtitleKey: 'providerModal.placeholderKey',
    },
  };

  const selectProvider = () => {
    if (surveyAccount) {
      onProviderSelected(surveyAccount.id);
    } else {
      aux.routeToAuxPage(history, {
        titleKey: 'errorPages.server.title',
        messageKey: 'errorPages.server.message',
      });
    }
  };

  const selectDefaultProvider = () => {
    const configurations = getSurveyAccountsConfigurations(surveyAccounts);
    const defaultSurveyAccountForUrl = findDefaultSurveyAccountForUrlByUrlSlug(configurations, urlSlug);

    if (defaultSurveyAccountForUrl) {
      const { surveyAccountId } = splitDefaultSurveyAccountForUrl(defaultSurveyAccountForUrl.configurationValue);
      onProviderSelected(surveyAccountId);
    } else {
      onProviderSelected(surveyAccounts[0].id);
    }
  };

  return (
    <div className="provider-select">
      <H2 className="provider-select__heading">{t('providerModal.heading')}</H2>
      <Text1 className="provider-select__question">{t('providerModal.question')}</Text1>
      <OptionSelect
        className="provider-select__provider-option-select"
        compDef={providersComponent}
        responses={{
          providerSelectProvider: getSurveyAccountByProvider(providers, provider) || undefined,
        }}
        onResponseUpdated={(key, val) => {
          setProvider(val[0].optionValue);
          setSurveyAccount(getSurveyAccountById(val[0].optionKey, surveyAccounts));
        }}
      />

      <Continue buttonKey="continue" type={ContinueType.End} disabled={!provider} onContinue={selectProvider} />

      <Continue buttonKey="notSure" type={ContinueType.End} onContinue={selectDefaultProvider} />
    </div>
  );
}

export default ProviderSelectModal;
