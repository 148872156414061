import { useTranslation } from 'react-i18next';
import { Hint, HelpHintsComponent } from '@myriadgenetics/mgh-types';
import { Text1, Text2 } from '../../../elements/typography';
import Modal from '../../../elements/modal';
import './index.scss';

interface Props {
  compDef: any | HelpHintsComponent;
}

function HelpHints({ compDef }: Props) {
  const { t } = useTranslation();
  const {
    helpHints,
    modalTriggerKey,
    attachToQuestion,
    titleKey,
    containerClass,
  }: {
    helpHints: Hint[];
    modalTriggerKey: string;
    attachToQuestion: boolean | undefined;
    titleKey: string | undefined;
    containerClass?: string;
  } = compDef.data;
  const HINT_SEPARATOR = ':';

  return (
    <Modal triggerText={t(modalTriggerKey)} attachToQuestion={attachToQuestion} containerClass={containerClass}>
      {titleKey && <Text1 bold="true">{t(titleKey)}</Text1>}
      <dl className="help-hint__list">
        {helpHints.map((h, i) => (
          <p className="help-hint__list-item" key={i}>
            <Text2 bold="true" className="help-hint__item-term" tag="dfn">
              {t(h.termKey)}
            </Text2>
            <Text2 className="help-hint__separator" tag="span">
              {HINT_SEPARATOR}&nbsp;
            </Text2>
            <Text2 className="help-hint__item-definition" tag="span">
              {t(h.definitionKey)}
            </Text2>
          </p>
        ))}
      </dl>
    </Modal>
  );
}

export default HelpHints;
