import React from 'react';
import { PreloadExternalResource } from '@myriadgenetics/mgh-types/src/types/survey';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface Props {
  resources?: PreloadExternalResource[];
}

function PreloadExternalResources({ resources }: Props) {
  const { t } = useTranslation();

  if (!resources || resources.length === 0) return null;

  return (
    <Helmet>
      {resources.map((resource) => (
        <link key={resource.href} rel="preload" as={resource.type} href={t(resource.href)} />
      ))}
    </Helmet>
  );
}

export default PreloadExternalResources;
