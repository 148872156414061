import React, { useState, useEffect } from 'react';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { PageNode, Component } from '@myriadgenetics/mgh-types';
import ContinueWrapper from '../continue-wrapper';
import { typeToComponent } from '../../../helpers/component-map';
import Validation from '../../../validation';
import { Text2 } from '../../../elements/typography';
import NodeFooter from '../../../elements/node-footer';
import { getUniqueDataId } from '../helpers';
import { areAllValid, autoAble, isAutoType, isContinueInline, triggerContinue, trackGtmData } from '../helpers';
import PdfViewer from '../../pdf-viewer';
import wand from './wand.png';

import './index.scss';

interface Props {
  node: any | PageNode;
  responses: any;
  gotoNextNode: (nodeId?: number | null) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  isResultsPage?: boolean;
  isClosePageButtonExist?: boolean;
  hasBackButton?: boolean;
  gtmDataTracking?: ({ answerKey }: { answerKey: string }) => void;
  isDemo?: boolean;
}

function PageNodeReact({
  node,
  responses,
  gotoNextNode,
  onResponseUpdated,
  isResultsPage,
  isClosePageButtonExist,
  gtmDataTracking,
  hasBackButton,
  isDemo,
}: Props) {
  const { t } = useTranslation();
  const [actionTaken, setActionTaken] = useState<boolean>(false);
  const { continueKey, continueType, subtitle, footerKey, isColumns } = node?.data || {};
  const validationMap = Validation.createValidationMap(node?.components || [], responses);
  const allAreValid = areAllValid(validationMap);
  const [isPdfVisible, setIsPdfVisible] = useState<boolean>(false);

  const triggerOnResponseUpdated = (answerKey: string, val: any): void => {
    onResponseUpdated(answerKey, val);
    setActionTaken(true);
  };

  const uniqueDataId = node && `chat-modal-item--${getUniqueDataId(node.id, node.name)}`;

  useEffect(() => {
    isAutoType(continueType) && actionTaken && allAreValid && autoAble(node, get, responses) && triggerContinue(gotoNextNode, node, responses);
    return () => setActionTaken(false);
  });

  return (
    <div className={`page-node  ${isColumns ? 'page-node--columns' : ''}`} data-testid={uniqueDataId}>
      {subtitle && <Text2 className="page-node__subtitle subtitle">{t(subtitle)}</Text2>}
      <div className="page-node__content">
        {node &&
          node.components?.map((c: Component, i: number) => {
            const key = Validation.getKey(c, i);
            if (c.type === 'CONTINUE') {
              return (
                <ContinueWrapper
                  key={key}
                  allAreValid={allAreValid}
                  continueType={continueType}
                  isResultsPage={isResultsPage}
                  triggerContinue={() => triggerContinue(gotoNextNode, node, responses)}
                  isClosePageButtonExist={isClosePageButtonExist}
                  gtmDataTracking={() => {
                    trackGtmData(node, gtmDataTracking);
                  }}
                  hasBackButton={hasBackButton}
                />
              );
            } else {
              const Tag = typeToComponent(c);
              return (
                <Tag
                  key={key}
                  compDef={c}
                  responses={responses}
                  onResponseUpdated={triggerOnResponseUpdated}
                  validationResult={validationMap[key]}
                  onContinue={triggerContinue}
                  gtmDataTracking={() => {
                    trackGtmData(node, gtmDataTracking);
                  }}
                />
              );
            }
          })}

        {node && !isContinueInline(node) && (
          <ContinueWrapper
            continueKey={continueKey}
            allAreValid={allAreValid}
            continueType={continueType}
            isResultsPage={isResultsPage}
            triggerContinue={() => triggerContinue(gotoNextNode, node, responses)}
            isClosePageButtonExist={isClosePageButtonExist}
            gtmDataTracking={() => {
              trackGtmData(node, gtmDataTracking);
            }}
            hasBackButton={hasBackButton}
          />
        )}
      </div>
      {responses?.surveyEntrySave?.encodedPdf && isDemo && (
        <img data-testid="pdf-magic-wand" alt="magic" className="person-demographic__wand" src={wand} onClick={() => setIsPdfVisible(!isPdfVisible)} />
      )}
      {isPdfVisible && (
        <dialog open id="pdf-viewer-dialog" className="page-node__pdf-viewer-dialog">
          <PdfViewer encodedPdf={responses?.surveyEntrySave?.encodedPdf}></PdfViewer>
        </dialog>
      )}
      {footerKey && <NodeFooter footerKey={footerKey} />}
    </div>
  );
}
export default PageNodeReact;
