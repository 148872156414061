import get from 'lodash.get';
import { formFields } from '../components/question/person-demographic/form-fields';
import { formFields as insuranceFormFields } from '../components/question/person-insurance/form-fields';
import Validation from '.';
import {
  OptionSelectComponent,
  PersonDemographicComponent,
  YesNoComponent,
  ValidationResult,
  PersonInsuranceComponent,
  ImageUploadComponent,
  CheckboxComponent,
  RadioGroupComponent,
} from '@myriadgenetics/mgh-types';
import { getErrorsFromComponentFields } from './helper';

export const validateOptionSelect = (os: OptionSelectComponent, val: any, required: boolean): ValidationResult => {
  // TODO validate against single select
  const isValid =
    (!required && (val === undefined || val === null || (Array.isArray(val) && val.length === 0))) || (val && Array.isArray(val) && val.length > 0) || false;
  const errorMessage = isValid ? undefined : 'validation.optionSelect';

  return { isValid, errorMessage };
};

export const validateRequired = (os: CheckboxComponent | ImageUploadComponent, val: any, required: boolean): ValidationResult => {
  const isValid = (required && Boolean(val)) || !required;
  const errorMessage = isValid ? undefined : 'validation.required';

  return { isValid, errorMessage };
};

export const validatePersonDemographic = (tc: PersonDemographicComponent, val: any): ValidationResult => {
  const FormFields = formFields(tc);
  const invalidFieldResults = Object.keys(tc.data.fields)
    .filter((field) => Object.prototype.hasOwnProperty.call(tc.data.fields, field) && (tc.data.fields as any)[field] !== 'HIDDEN' && field !== 'phoneType')
    .map((field) => {
      // cast as any so we don't have to create index signatures
      // anytime we see "as any" it's usually in reference to usage of string index
      // and TS cannot guarantee that string
      const formField = (FormFields as any)[`${field}Question`];
      return {
        field: formField.data.answerKey,
        validationResult: Validation.validate((FormFields as any)[`${field}Question`], get(val, formField.data.answerKey)),
      };
    })
    .filter((vField) => !vField.validationResult.isValid)
    .reduce(
      (prev, curr) => ({
        ...prev,
        ...{
          [curr.field]: curr.validationResult,
        },
      }),
      {},
    );
  return {
    isValid: Object.keys(invalidFieldResults).length === 0,
    errorMessage: invalidFieldResults,
  };
};

export const validateRadioGroup = (tc: RadioGroupComponent, val: any, required: boolean): ValidationResult => {
  // TODO: a less broad and more aplicable validation. I was thinking that this radio group could be used
  // as a set of single/multiple selection radio buttons so any value other than undefined would do.
  const isValid = (!required && val === null) || val !== undefined;
  const errorMessage = isValid ? undefined : 'validation.yesNo';

  return { isValid, errorMessage };
};

export const validateYesNo = (tc: YesNoComponent | RadioGroupComponent, val: any, required: boolean): ValidationResult => {
  const isValid = (!required && val === null) || val === true || val === false;
  const errorMessage = isValid ? undefined : 'validation.yesNo';

  return { isValid, errorMessage };
};

export const validateInsurance = (personInsuranceComponent: PersonInsuranceComponent, val: any): ValidationResult => {
  const FormFields = insuranceFormFields(personInsuranceComponent);
  const invalidFieldResults = !get(val, FormFields[`isUninsuredQuestion`].data.answerKey)
    ? getErrorsFromComponentFields(personInsuranceComponent, FormFields, val)
    : {};

  return {
    isValid: Object.keys(invalidFieldResults).length === 0,
    errorMessage: invalidFieldResults,
  };
};
