import { BreastCancerRiskType, NodeTypes, PageNode, TextQuestionType, ComponentSizes } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5012,
    description: 'Built-in page node',
    name: 'Hormone Therapy Started input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.hormoneTherapyStartedInputNode',
    },
    components: [
      {
        id: -13,
        description: 'Built in component',
        name: 'Started Hormone Therapy Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.hormoneTherapyStartedInput`,
          questionKey: 'breastCancerRisk.hormoneTherapyStartedInput',
          labelKey: 'breastCancerRisk.yearsLabel',
          placeholderKey: '',
          inputType: TextQuestionType.Number,
          size: ComponentSizes.Large,
          required: type === BreastCancerRiskType.TcScore,
          secondaryResponse: {
            textKey: 'breastCancerRisk.dontKnow',
            value: type === BreastCancerRiskType.TcScore ? ' ' : null,
          },
        },
      },
    ],
    flows: [
      {
        id: -12,
        name: 'Started Hormone Therapy Flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: -5013,
        },
      },
    ],
  };
};
