import { NodeTypes, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5015,
    description: 'Built-in page node',
    name: 'Breast Biopsy History',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.breastBiopsyHistoryNode',
    },
    components: [
      {
        id: -16,
        description: 'Built in component',
        name: 'Breast Biopsy history',
        type: 'YES_NO',
        data: {
          answerKey: `${baseKey}.breastBiopsy`,
          questionKey: 'breastCancerRisk.breastBiopsy',
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.breastBiopsy',
            definitionKey: 'helpHints.breastBiopsyHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -15,
        type: 'BINARY',
        name: 'Breast Biopsy history flow',
        description: 'Built-in flow',
        data: {
          answerKey: `${baseKey}.breastBiopsy`,
          affirmativePageId: -5016,
          negativePageId: -5017,
        },
      },
    ],
  };
};
