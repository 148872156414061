import { NodeTypes, ComponentSizes, TextQuestionType, ChatModalNodeOptions, TextContentType, TextContentHighlightMod } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const mrnInfoStep = ({ continueType, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.MRN_INFO_NODE,
  name: 'MRN info',
  description: 'MRN info',
  type: NodeTypes.MrnInfo,
  data: {
    continueType,
    continueKey: 'chatbot.modal.next',
    answerKey: baseKey,
  },
  components: [
    {
      id: -1,
      type: 'TEXT_CONTENT',
      name: 'Header',
      data: {
        contentKey: 'chatbot.mrn.title',
        type: TextContentType.H2,
        highlightModifier: TextContentHighlightMod.Centered,
        delay: 0,
      },
    },
    {
      id: -3,
      type: 'TEXT_CONTENT',
      name: 'Enter MRN',
      data: {
        contentKey: 'chatbot.mrn.enterMRN',
        type: TextContentType.Text2,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 0,
      },
    },
    {
      id: -6,
      description: 'MRN Text Input Field',
      name: 'MRN input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.value`,
        inputSize: ComponentSizes.XSmall,
        inputType: TextQuestionType.Mrn,
        required: true,
        size: ComponentSizes.Small,
      },
    },
  ],
  flows: [
    {
      id: 2,
      type: 'UNCONDITIONAL',
      name: 'To start node',
      description: 'To start node',
      data: {
        nextPageId: NODE_IDS.START_NODE,
      },
    },
  ],
});
