import { NodeTypes, ComponentSizes, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5019,
    description: 'Built-in page node',
    name: 'Breast density',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.breastDensityNode',
    },
    components: [
      {
        type: 'OPTION_SELECT',
        id: -17,
        name: 'Breast density',
        description: 'Built in question',
        data: {
          answerKey: `${baseKey}.breastDensity`,
          questionKey: 'breastCancerRisk.breastDensity',
          labelKey: '',
          placeholderKey: '',
          subtitleKey: '',
          required: true,
          isSingleSelect: true,
          optionSize: ComponentSizes.Medium,
          selectionOptions: [
            {
              optionKey: 0,
              optionValue: 'unknown',
              optionDisplayKey: 'breastCancerRisk.breastDensityTypes.unknown',
            },
            {
              optionKey: 1,
              optionValue: 'typeA',
              optionDisplayKey: 'breastCancerRisk.breastDensityTypes.typeA',
            },
            {
              optionKey: 2,
              optionValue: 'typeB',
              optionDisplayKey: 'breastCancerRisk.breastDensityTypes.typeB',
            },
            {
              optionKey: 3,
              optionValue: 'typeC',
              optionDisplayKey: 'breastCancerRisk.breastDensityTypes.typeC',
            },
            {
              optionKey: 4,
              optionValue: 'typeD',
              optionDisplayKey: 'breastCancerRisk.breastDensityTypes.typeD',
            },
          ],
        },
      },
      {
        id: -18,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: '',
            definitionKey: 'breastCancerRisk.breastDensityBiRadsNote',
          },
        },
      },
    ],
    flows: [
      {
        id: -16,
        type: 'UNCONDITIONAL',
        name: 'Breast Biopsy type flow',
        description: 'Built-in flow',
        data: {
          nextPageId: -5015,
        },
      },
    ],
  };
};
