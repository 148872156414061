import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const thankYouExitLink: ChatbotNode = {
  id: NODE_IDS.THANK_YOU_EXIT,
  name: 'Chatbot Results Thank You Exit',
  description: 'Chatbot Thank You Exit',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.End,
    pageTitleKey: 'pageTitles.chatbot.exit',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Chatbot Thank You Exit',
      description: 'Chatbot Thank You Exit',
      data: {
        contentKey: 'chatbot.assessmentResults.thankYouExit',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
  ],
};

export default thankYouExitLink;
