import React from 'react';
import { Text1 } from '../typography';

import './index.scss';

type ButtonSizes = 'small' | 'large';

interface Props {
  className?: string;
  children?: any;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => any | null | undefined;
  size: ButtonSizes;
  secondary: boolean;
  disabled: boolean;
  selected: boolean;
  error: boolean;
}

const baseClass = 'button';

function sizeClass(size: ButtonSizes) {
  return `${baseClass}--${size}`;
}

function Button({ className, children, onClick, size, secondary, selected, disabled, error }: Props) {
  return (
    <button
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      className={`
        ${className || ''}
        ${baseClass} ${sizeClass(size)}
        ${secondary ? baseClass + '--secondary' : ''}
        ${selected ? baseClass + '--selected' : ''}
        ${error ? baseClass + '--error' : ''}
      `}
    >
      <Text1 bold="true">{children}</Text1>
    </button>
  );
}

Button.defaultProps = {
  size: 'small',
  disabled: false,
  secondary: false,
  selected: false,
  error: false,
};

export default Button;
