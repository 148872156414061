import { useTranslation } from 'react-i18next';
import { useLocation } from '../../../../../helpers/routing';
import { isSkipToSurveyUrl } from '../../../../../helpers/url';
import React, { useEffect } from 'react';
import Logger from '../../../../../helpers/logger';
import Loading from '../../../../loading';
import LanguagePage from '../../../../language-page';
import { SurveyAccount } from '@myriadgenetics/mgh-types';
import { SharedProps } from '../index';
import { defaultClinicName } from '../../../constants';

interface LangLocationState {
  locResponses: any;
}

interface LangLocation {
  state: LangLocationState;
}

interface Props extends SharedProps {
  surveyAccounts?: SurveyAccount[] | null;
  shouldHide?: boolean;
}

function LangStep(props: Props) {
  const { t } = useTranslation();
  const location: LangLocation = useLocation() as LangLocation;
  const { urlSlug, setProvider, onContinue, surveyAccounts, shouldHide } = props;
  const skipToSurvey = isSkipToSurveyUrl(urlSlug);

  useEffect(() => {
    if (skipToSurvey && surveyAccounts && surveyAccounts.length > 0) {
      const sa = surveyAccounts[0];
      const clinicName = sa.clinicName || defaultClinicName;
      Logger.logEvent('Auto Started', location?.state);
      setProvider(sa, clinicName, true);
    }
  });

  if (!surveyAccounts) {
    return <Loading translatedMsg={t('loading.providers')} />;
  } else if (!shouldHide) {
    return skipToSurvey ? <Loading /> : <LanguagePage onContinue={onContinue} />;
  } else {
    return null;
  }
}

export default LangStep;
