export type StandardProviderQuestionOrSimon = {
  isSimon: boolean;
  simonText: string;
  myraText: string;
};

export const standardProviderQuestionOrSimon = ({ isSimon, simonText, myraText }: StandardProviderQuestionOrSimon) => {
  if (isSimon) {
    return simonText;
  }
  return myraText;
};
