import { ContinueType, NodeTypes, ChatbotNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const patientLocationNode: ChatbotNode = {
  id: NODE_IDS.SOME_NEXT_NODE,
  name: 'Chatbot Some Example Node that can be removed',
  description: 'Chatbot Some Example Node that can be removed',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'CONTINUE',
      data: {
        modalTriggerKey: 'breastCancerRisk',
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Chatbot Some Example Flow that can be removed',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.PERSONAL_CANCER_NODE,
      },
    },
  ],
};

export default patientLocationNode;
