import Logger from '../../../../helpers/logger';
import { criteriaResult } from '../types';

export const didMeetCriteria = (res: criteriaResult): boolean => {
  let meetsCriteria;
  const { criteriaMetList } = res;
  if (typeof criteriaMetList === 'string') {
    try {
      meetsCriteria = JSON.parse(criteriaMetList).length > 0;
    } catch (ex) {
      Logger.logError(`Invalid json while parsing criteria met list: ${criteriaMetList}}`);
      throw new Error('Invalid criteriaMetList');
    }
  } else if (Array.isArray(criteriaMetList)) {
    meetsCriteria = criteriaMetList.length > 0;
  }

  if (meetsCriteria === undefined) {
    throw new Error('Invalid criteriaMetList');
  }

  Logger.logEvent('Criteria Check', res);

  return meetsCriteria;
};

export default {
  didMeetCriteria,
};
