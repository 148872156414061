import { ContinueType, NodeTypes, ChatModalNodeType, ChatModalNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const personalHistoryNode: ChatModalNode = {
  id: NODE_IDS.PERSONAL_HISTORY_NODE,
  type: NodeTypes.ChatModalNode,
  name: 'Chat Personal Info Node',
  description: 'Chat Personal Info Node',
  data: {
    answerKey: 'patient',
    continueType: ContinueType.AutoFixed,
    subtitle: 'chatbot.personalInformation.subtitle',
    flowType: ChatModalNodeType.PersonalInfo,
    pageTitleKey: 'pageTitles.chatbot.personalHistoryNode',
  },
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To At Home or In Office',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.PATIENT_LOCATION_NODE,
      },
    },
  ],
};

export default personalHistoryNode;
