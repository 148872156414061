import React from 'react';
import { useTranslation } from 'react-i18next';
import { Relative, ReviewPathology } from '@myriadgenetics/mgh-types';
import { Text1 } from '../../../elements/typography';
import Link from '../../../elements/link';
import CancerHistoryCard from '../cancer-history-card';

import './index.scss';

interface Props {
  relative: Relative;
  num?: number;
  pathologies: ReviewPathology[];
  onEditClicked?: (pathology: ReviewPathology, index: number, isDel: boolean) => void;
  onAddClicked?: () => void;
  onDeleteClicked?: () => void;
}

function CancerHistoryCards({ relative, num, pathologies, onEditClicked, onAddClicked, onDeleteClicked }: Props) {
  const { t } = useTranslation();
  const titleKey = relative === 'relatives.self' ? 'self.history' : relative;

  if (pathologies.length === 0) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <div className="cancer-history-cards">
      <Text1 bold="true" className="cancer-history-cards__relative">
        {`${t(titleKey)} ${num || ''}`}
        {onDeleteClicked && (
          <Link className="cancer-history-cards__del-relative" onClick={onDeleteClicked} visited={false}>
            {t('delete')}
          </Link>
        )}
      </Text1>
      <div className="cancer-history-cards__cards">
        {pathologies.length > 0 &&
          pathologies.map((pathology, i) => (
            <CancerHistoryCard
              key={i}
              pathology={pathology}
              relative={relative}
              onEditClicked={onEditClicked && ((isDel) => onEditClicked(pathology, i, isDel))}
            />
          ))}
        {onAddClicked && <CancerHistoryCard relative={relative} onAddClicked={onAddClicked} />}
      </div>
    </div>
  );
}

export default CancerHistoryCards;
