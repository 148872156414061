import { useTranslation } from 'react-i18next';
import { Relative, ReviewPathology, PathologyHistory } from '@myriadgenetics/mgh-types';
import PathologyDetails from '../pathology-details';
import Link from '../../../elements/link';

import './index.scss';

interface Props {
  pathology?: ReviewPathology | PathologyHistory;
  relative: Relative;
  onEditClicked?: (isDel: boolean) => void;
  onAddClicked?: () => void;
}

function CancerHistoryCard({ pathology, onAddClicked, onEditClicked, relative }: Props) {
  const { t } = useTranslation();

  return (
    <div className={`cancer-history-card ${pathology ? '' : 'cancer-history-card--add'}`}>
      <div className="cancer-history-card__container">
        {pathology ? (
          <PathologyDetails pathology={pathology} relative={relative} onEditClicked={onEditClicked} />
        ) : (
          <div className="cancer-history-card__add" onClick={onAddClicked}>
            <Link>{t('pathology.add')}</Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default CancerHistoryCard;
