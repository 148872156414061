import React, { useEffect, useCallback, useState } from 'react';
import { PageNode, ChatbotNode, NodeWorkflowLocationState, NodeWorkflowState, NodeTypes, ModalEvent } from '@myriadgenetics/mgh-types';
import { useHistory, useLocation } from '../../../helpers/routing';
import { findNodeById } from '../helpers';
import PageNodeReact from '../page-node';
import Logger from '../../../helpers/logger';
import EndpointCallNodeReact from '../endpoint-call-node';
import { useTranslation } from 'react-i18next';
import { SurveyNodeConsts } from '../helpers';
import { CompoundNode } from '../helpers';

interface Props {
  className: string;
  nodes: PageNode[] | ChatbotNode[];
  responses: any;
  gotoNextNode: () => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  isSubWorkflow?: boolean;
  answerKey?: string;
  initNode?: CompoundNode;
  closeModal?: () => void;
  hasBackButton?: boolean;
  isChatbot?: boolean;
}

function NodeWorkflow({
  className = 'page-node',
  nodes,
  responses,
  gotoNextNode,
  onResponseUpdated,
  initNode,
  isSubWorkflow,
  hasBackButton,
  closeModal,
  answerKey,
  isChatbot,
}: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation() as NodeWorkflowLocationState;
  const [startLocation, setStartLocation] = useState<NodeWorkflowLocationState | null>();
  const [pathnamesById, setPathnamesById] = useState<{ [nodeId: string]: string }>({});

  const hasLocationState = () => location && location.state;
  let isExitingWorkflow = false;

  const getWorkflowName = useCallback(
    () => (isSubWorkflow ? `${SurveyNodeConsts.SUB_NODE_WORKFLOW_STATE}-${answerKey}` : SurveyNodeConsts.NODE_WORKFLOW_STATE),
    [isSubWorkflow, answerKey],
  );

  const getWorkflowState = () => (hasLocationState() && (location.state[getWorkflowName()] as NodeWorkflowState)) || {};

  const { curNodeId = initNode?.id || nodes[0].id, baseUrl = `${location.pathname}/sub` } = getWorkflowState();

  const curNode = findNodeById(curNodeId || 0, nodes);
  const initNodeId = initNode?.id || nodes[0].id;
  const isFirstPage = initNodeId === curNodeId;

  const pushNodeIdToHistory = (id: number | null, state: NodeWorkflowState, isReplace?: boolean, pathname?: string) => {
    if (pathname || id) {
      const url = pathname || `${baseUrl}/${id}`;
      const method = isReplace ? history.replace : history.push;
      method(url, {
        ...location?.state,
        [getWorkflowName()]: {
          ...state,
          curNodeId: id,
        },
      });
    }
  };

  useEffect(() => {
    // TODO: Use more contexts and less props and states!
    if (isChatbot) {
      const isNotEndpointCall = curNode && curNode.type !== NodeTypes.EndpointCall;
      const isPathnameKeyExists = curNode && !pathnamesById[curNode.id];
      isFirstPage && !startLocation && setStartLocation(location);
      isNotEndpointCall && isPathnameKeyExists && setPathnamesById((prevState) => ({ ...prevState, [curNode.id]: location.pathname }));
    }
  }, [isFirstPage, location, curNode, isChatbot, startLocation, pathnamesById]);

  useEffect(() => {
    const workflowState = getWorkflowState();
    const hasWorkflowState = Object.keys(workflowState).length > 0;
    const noWorkflowAndNotChatbot = !hasWorkflowState && !isChatbot;
    const noWorkflowStateAndChatbotAndNotFirstPage = !hasWorkflowState && isChatbot && !isFirstPage;

    if (!isExitingWorkflow && (noWorkflowAndNotChatbot || noWorkflowStateAndChatbotAndNotFirstPage)) {
      closeModal && closeModal();
      pushNodeIdToHistory(curNodeId, { baseUrl }, true);
    }

    if (isChatbot) {
      const onModalBack = () => {
        if (isFirstPage) {
          const startState = startLocation?.state as NodeWorkflowState;
          return pushNodeIdToHistory(initNodeId, startState, false, startLocation?.pathname);
        } else {
          const pathnameIds = Object.keys(pathnamesById);
          const prevPathnameId = +pathnameIds[pathnameIds.length - 2];
          const prevPathname = pathnamesById[prevPathnameId];
          setPathnamesById((prevState) => {
            const lastPathnameIds = [prevPathnameId, curNodeId];
            lastPathnameIds[0] && delete prevState[lastPathnameIds[0]];
            lastPathnameIds[1] && delete prevState[lastPathnameIds[1]];
            return prevState;
          });
          pushNodeIdToHistory(prevPathnameId || curNodeId, {}, true, prevPathname);
        }
      };

      window.addEventListener(ModalEvent.Back, onModalBack);

      return () => {
        window.removeEventListener(ModalEvent.Back, onModalBack);
      };
    }
  });

  const exitSubNodeWorkflowState = () => {
    closeModal && closeModal();
    if (hasLocationState()) {
      location.state[getWorkflowName()] = {
        ...(location.state[getWorkflowName()] as NodeWorkflowState),
        curNodeId: undefined,
      };
    }
  };

  const nodeWorkflowGoToNextNodeNoId = () => {
    if (isSubWorkflow) {
      exitSubNodeWorkflowState();
    } else {
      if (hasLocationState()) {
        location.state[getWorkflowName()] = {};
      }
    }
  };

  const nodeWorkflowGoToNextNodeNoIdInNodeList = (id: number) => {
    isSubWorkflow ? exitSubNodeWorkflowState() : Logger.logError(`node: ${id} does not exist`);
  };

  const nodeWorkflowGoToNextNode = (id?: number | null) => {
    if (id) {
      if (history.goForwardOnContinue()) {
        // TODO: (SIMON) need to add testing for these lines
        history.go(1);
      } else {
        const nextNode = findNodeById(id, nodes);
        nextNode ? pushNodeIdToHistory(id, getWorkflowState()) : nodeWorkflowGoToNextNodeNoIdInNodeList(id);
      }
    } else {
      // if we have a next id it means we are exiting out of the chatbot flow
      isExitingWorkflow = true;
      nodeWorkflowGoToNextNodeNoId();
      gotoNextNode();
    }
  };

  useEffect(() => {
    if ((hasLocationState() && (location.state[getWorkflowName()] as NodeWorkflowState))?.curNodeId) {
      location.state.title = t(curNode?.data?.pageTitleKey || 'myGeneHistory');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curNode, t, getWorkflowName]);
  if (curNode?.type === NodeTypes.EndpointCall) {
    return (
      <div className={className}>
        <EndpointCallNodeReact
          key={`crt-${curNode.id}`}
          gotoNextNode={nodeWorkflowGoToNextNode}
          node={curNode}
          onResponseUpdated={onResponseUpdated}
          responses={responses}
        />
      </div>
    );
  } else {
    return (
      <div className={className}>
        <PageNodeReact
          node={curNode}
          responses={responses}
          onResponseUpdated={onResponseUpdated}
          key={curNodeId}
          gotoNextNode={nodeWorkflowGoToNextNode}
          hasBackButton={hasBackButton}
        />
      </div>
    );
  }
}

export default NodeWorkflow;
