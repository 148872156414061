import React from 'react';
import { useTranslation } from 'react-i18next';
import { OptionKeyValue, ValidationResult } from '@myriadgenetics/mgh-types';
import NotSure from '../not-sure';
import { notSure, getDisplayKey } from '../helpers';
import { Text2, Text3 } from '../../../../elements/typography';

interface Props {
  labelKey: string;
  isDisabled?: boolean;
  onOptionSelected: (selectedOption: OptionKeyValue) => void;
  placeholderKey: string;
  required: boolean;
  validationResult?: ValidationResult;
  selectedOptions: OptionKeyValue[] | string;
  selectionOptions: OptionKeyValue[];
}

const baseClass = 'option-select';

function NativeSelect({ labelKey, onOptionSelected, placeholderKey, required, selectedOptions, selectionOptions, validationResult, isDisabled }: Props) {
  const isInvalid = validationResult && !validationResult.isValid;
  const { t } = useTranslation();

  const triggerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    const selectedOption = selectionOptions.find((o) => o.optionKey.toString() === e.target.value.toString());
    selectedOption && onOptionSelected(selectedOption);
  };

  const optionContains = (value: string | number) => selectionOptions.some((o) => getDisplayKey(o) === value);

  const hasNotSure = () => optionContains(notSure);

  const filteredSelectionOptions = () => selectionOptions.filter((o) => getDisplayKey(o) !== notSure);

  return (
    <React.Fragment>
      <div className="option-select__label-container">
        {labelKey && <Text2 tag="label">{t(labelKey)}</Text2>}
        {required && validationResult && !validationResult.isValid && (
          <Text3 className="option-select__required-label" tag="span" bold="true">
            {t('validation.required')}
          </Text3>
        )}
      </div>
      <select
        className={`${baseClass}__select-input ${!isInvalid && selectedOptions.length > 0 ? `${baseClass}__select-input--selected` : ''} ${
          isInvalid && selectedOptions.length !== 0 ? `${baseClass}__select-input--error` : ''
        }`}
        disabled={isDisabled}
        onChange={triggerChange}
        value={Array.isArray(selectedOptions) && selectedOptions.length > 0 ? selectedOptions[0].optionKey : ''}
      >
        <option disabled value={selectedOptions.length === 0 ? '' : undefined}>
          {t(placeholderKey || labelKey)}
        </option>
        {filteredSelectionOptions().map((o, i) => (
          <option key={i} value={o.optionKey}>
            {t(getDisplayKey(o))}
          </option>
        ))}
      </select>
      {hasNotSure() && (
        <NotSure
          onAction={() => {
            const notSureOption = selectionOptions.find((o) => getDisplayKey(o) === notSure);
            notSureOption && onOptionSelected(notSureOption);
          }}
        />
      )}
    </React.Fragment>
  );
}

export default NativeSelect;
