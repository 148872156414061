import './index.scss';

type TitleTag = 'div' | 'dt';

interface BaseProps {
  className?: string;
  children: string;
  size: 1 | 2 | 3 | 4;
  tag: TitleTag;
}

function Base(props: BaseProps) {
  const { size, className, children, tag } = props;
  const Tag = tag || 'div';

  return (
    <Tag {...props} className={`title title--${size} ${className || ''}`}>
      {children}
    </Tag>
  );
}

Base.defaultProps = {
  tag: 'div',
};

interface Props {
  className?: string;
  children: string | any; // any because sometimes we run it through a Trans function which can return any
}

export function H1(props: Props) {
  return <Base {...props} size={1} />;
}

export function H2(props: Props) {
  return <Base {...props} size={2} />;
}

export function H3(props: Props) {
  return <Base {...props} size={3} />;
}

export function H4(props: Props) {
  return <Base {...props} size={4} />;
}
