import { ContinueType, NodeTypes, EndpointCallNode, HttpMethod } from '@myriadgenetics/mgh-types';
import { NODE_IDS, LIFETIME_TC_THRESHOLD } from '../constants';

const translationBasePath = 'chatbot.assessmentResults';
export const postSurveyEntryNode: EndpointCallNode = {
  id: NODE_IDS.POST_SURVEY_ENTRY_NODE,
  name: 'Endpoint Call Node to Survey Entry',
  type: NodeTypes.EndpointCall,
  data: {
    endpointPath: '/patient-survey-entries',
    errorKey: 'criteriaCheck.error',
    answerKey: 'surveyEntrySave',
    messageKey: 'criteriaCheck.message',
    endpointHttpMethod: HttpMethod.Post,
    continueType: ContinueType.AutoFixed,
    titleKey: 'criteriaCheck.title',
    pageTitleKey: 'pageTitles.endpointCallToCriteriaNode',
  },
  flows: [
    {
      id: -1,
      type: 'CONDITIONAL',
      name: 'Hide Results',
      description: '',
      data: {
        nextPageId: NODE_IDS.HIDE_RESULTS_NODE,
        conditions: [
          {
            answerKey: 'readyToViewResults[0].optionValue',
            comparator: '==',
            value: `${translationBasePath}.responseNotReadyToView`,
          },
        ],
      },
    },
    {
      id: -2,
      name: 'Conditional',
      description: 'To Met Criteria TC Over Threshold',
      type: 'CONDITIONAL',
      data: {
        nextPageId: NODE_IDS.MEETS_CRITERIA_TC_OVER_THRESHOLD_NODE,
        conditions: [
          {
            value: true,
            answerKey: 'surveyEntrySave.responses.meetsCriteria',
            comparator: '==',
          },
          {
            answerKey: 'breastCancerRisk',
            comparator: 'isExists',
            value: '',
          },
          {
            answerKey: 'surveyEntrySave.responses.tcResult.remaining_lifetime_risk',
            comparator: '<=',
            value: LIFETIME_TC_THRESHOLD,
          },
        ],
      },
    },
    {
      id: -3,
      name: 'Conditional',
      description: 'To Not Met TC Over Threshold',
      type: 'CONDITIONAL',
      data: {
        nextPageId: NODE_IDS.OUTSIDE_CRITERIA_TC_OVER_THRESHOLD_NODE,
        conditions: [
          {
            value: false,
            answerKey: 'surveyEntrySave.responses.meetsCriteria',
            comparator: '==',
          },
          {
            answerKey: 'breastCancerRisk',
            comparator: 'isExists',
            value: '',
          },
          {
            answerKey: 'surveyEntrySave.responses.tcResult.remaining_lifetime_risk',
            comparator: '<=',
            value: LIFETIME_TC_THRESHOLD,
          },
        ],
      },
    },
    {
      id: -4,
      name: 'Conditional',
      description: 'To Met Criteria Tc Under Threshold or no tc',
      type: 'CONDITIONAL',
      data: {
        nextPageId: NODE_IDS.MEETS_CRITERIA_TC_UNDER_THRESHOLD_NODE,
        conditions: [
          {
            value: true,
            answerKey: 'surveyEntrySave.responses.meetsCriteria',
            comparator: '==',
          },
        ],
      },
    },
    {
      id: -5,
      name: 'Conditional',
      description: 'To Not Met TC Under Threshold',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.OUTSIDE_CRITERIA_TC_UNDER_THRESHOLD_NODE,
      },
    },
  ],
};

export default postSurveyEntryNode;
