import { Relative, PageNode, PathologyName } from '@myriadgenetics/mgh-types';
import Pathologies from './pathologies';
import NodeWorkflow from '../node-workflow';

interface Props {
  pathology: PathologyName;
  baseAnswerKey: string;
  responses: any;
  relative: Relative;
  onResponseUpdated: (answerKey: string, val: any) => void;
  onContinue: () => void;
  initNode?: PageNode;
  isSubWorkflow?: boolean;
  hasBackButton?: boolean;
}

const isRelativeSelf = (relative: Relative) => relative === 'relatives.self';

const getPathologyNodes = (pathology: PathologyName, relative: Relative, baseAnswerKey: string): PageNode[] => {
  switch (pathology) {
    case 'cancerHistory.cancers.breastFemale':
      return isRelativeSelf(relative) ? Pathologies.BreastCancer.stepsSelf(baseAnswerKey) : Pathologies.BreastCancer.stepsRelative(baseAnswerKey, relative);
    case 'cancerHistory.cancers.dcis':
      return isRelativeSelf(relative) ? Pathologies.DCIS.stepsSelf(baseAnswerKey) : Pathologies.DCIS.stepsRelative(baseAnswerKey, relative);
    case 'cancerHistory.cancers.prostate':
      return isRelativeSelf(relative) ? Pathologies.ProstateCancer.selfSteps(baseAnswerKey) : Pathologies.ProstateCancer.relativeSteps(baseAnswerKey, relative);
    default:
      return isRelativeSelf(relative)
        ? Pathologies.AgePathologyCancer.selfSteps(baseAnswerKey)
        : Pathologies.AgePathologyCancer.relativeSteps(baseAnswerKey, relative);
  }
};

function Pathology({ pathology, baseAnswerKey, responses, relative, onResponseUpdated, onContinue, initNode, isSubWorkflow, hasBackButton }: Props) {
  const nodeList = getPathologyNodes(pathology, relative, baseAnswerKey);

  return (
    <NodeWorkflow
      className="pathology"
      nodes={nodeList}
      responses={responses}
      gotoNextNode={onContinue}
      onResponseUpdated={onResponseUpdated}
      initNode={initNode || nodeList[0]}
      isSubWorkflow={isSubWorkflow}
      hasBackButton={hasBackButton}
      isChatbot={false}
    />
  );
}

export default Pathology;
