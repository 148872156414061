import get from 'lodash.get';
import API from '../../api';
import { HttpMethod, RejectionErrorTypes } from '@myriadgenetics/mgh-types';
import Logger from '../logger';

export enum ENDPOINT_URLS {
  dtcOrderEndpoint = '/dtc-order-demographics',
  patientSurveys = '/patient-surveys',
  criteriaResults = '/criteria-results',
  patientSurveyEntries = '/patient-survey-entries',
  patientSurveyAccounts = '/patient-survey-accounts',
}

const endpointPathsToMemoize = ['/patient-survey-entries'];

const getAllowedValuesFromResponses = (payload: any): string => {
  const allowedKeys = [
    'externalSource',
    'externalSourceId',
    'responses.patient.firstName',
    'responses.patient.lastName',
    'responses.ajs_anonymous_id',
    'responses.patient.address',
    'responses.patient.dob',
    'responses.patient.email',
    'responses.url',
  ];
  let cacheKey = '';
  const seperator = '-';
  const numKeys = allowedKeys.length;
  for (let i = 0; i < numKeys; i++) {
    const key = allowedKeys[i];
    const isLast = i === numKeys - 1;
    cacheKey += get(payload, key);
    if (!isLast) {
      cacheKey += seperator;
    }
  }
  return cacheKey;
};

const buildCacheKeyFromResponses = (numArgs: number, args: any) => {
  let cacheKey = '';
  for (let i = 0; i < numArgs; i++) {
    const argsType = typeof args[i];
    if (argsType === 'object') {
      cacheKey += getAllowedValuesFromResponses(args[i]);
    } else {
      cacheKey += args[i];
    }
  }
  return cacheKey;
};

export const getRequestCacheKey = (...args: any) => {
  const numArgs = args.length;
  const endpointPath = numArgs > 0 && typeof args[0] === 'string' && args[0];

  if (!endpointPathsToMemoize.includes(endpointPath.toString())) {
    return Date.now().toString();
  }

  return buildCacheKeyFromResponses(numArgs, args);
};

export const getSurveyAccountsByUrl = async (url: string) => API.get(`${ENDPOINT_URLS.patientSurveyAccounts}?url=${url}`);

export const performRequest = (method: HttpMethod, endpointPath: string, requestBody?: any): Promise<any> => {
  switch (method) {
    case HttpMethod.Get:
      return API.get(endpointPath);
    case HttpMethod.Post:
      return API.post(endpointPath, requestBody);
    default:
      const errorText = `${method} is unsupported`;
      Logger.logError(errorText);
      return Promise.reject({
        type: RejectionErrorTypes.Application,
        status: null,
        body: errorText,
      });
  }
};

export default {
  getRequestCacheKey,
  getSurveyAccountsByUrl,
  performRequest,
};
