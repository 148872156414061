import { ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { personalInfoFlow, cancerHistoryFlow, breastCancerRiskFlow, medicalHistoryFlow, hideResultsFlow, mrnInfoFlow } from './steps';
import { NODE_IDS } from './constants';

export class MRNInfoFlow {
  static initNodeId = NODE_IDS.MRN_INFO_NODE;
  static endNodeId = NODE_IDS.MRN_INFO_NODE;
  static tag = 'MrnNodeReact';
  static steps = ({ baseKey, continueType, subtitle, type }: ChatModalNodeOptions) => mrnInfoFlow({ continueType, subtitle, baseKey, type });
}

export class PersonalInfoFlow {
  static initNodeId = NODE_IDS.PROVIDER_SELECTION_NODE;
  static endNodeId = NODE_IDS.PERSONAL_INFO_END_NODE;
  static steps = ({ baseKey, continueType, subtitle, type, surveyAccountsContext }: ChatModalNodeOptions) =>
    personalInfoFlow({ continueType, subtitle, baseKey, type, surveyAccountsContext });
}

export class CancerHistoryFlow {
  static initNodeId = NODE_IDS.CANCER_HISTORY_NODE;
  static endNodeId = NODE_IDS.CANCER_HISTORY_NODE;
  static tag = 'CancerHistoryNodeReact';
  static props = {
    useReviewAsDefaultPage: true,
  };
  static steps = ({ baseKey, continueType, subtitle, type }: ChatModalNodeOptions) => cancerHistoryFlow({ continueType, subtitle, baseKey, type });
}

export class BreastCancerRiskFlow {
  static initNodeId = NODE_IDS.TC_NODE;
  static endNodeId = NODE_IDS.TC_NODE;
  static tag = 'BreastCancerRiskNodeReact';
  static steps = ({ baseKey, continueType, subtitle, type }: ChatModalNodeOptions) => breastCancerRiskFlow({ continueType, subtitle, baseKey, type });
}

export class MedicalHistoryFlow {
  static initNodeId = NODE_IDS.COLON_POLYP_YES_NO_NODE;
  static endNodeId = NODE_IDS.TC_NODE;
  static steps = ({ baseKey, continueType, subtitle, type }: ChatModalNodeOptions) => medicalHistoryFlow({ continueType, subtitle, baseKey, type });
}

export class HideResultsFlow {
  static initNodeId = NODE_IDS.HIDE_RESULTS_NODE;
  static endNodeId = NODE_IDS.HIDE_RESULTS_NODE;
  static tag = 'HideResultsNodeReact';
  static steps = ({ baseKey, continueType, subtitle, type }: ChatModalNodeOptions) => hideResultsFlow({ continueType, subtitle, baseKey, type });
}
