import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';
export const previouslyTested: ChatbotNode = {
  id: NODE_IDS.PREVIOUSLY_TESTED_NODE,
  name: 'Previously Tested Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.previouslyTestedNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Personal History Intro',
      description: 'Personal History Intro',
      data: {
        contentKey: 'chatbot.cancerHistory.personal.letsGoOverHistory',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Example Component',
      data: {
        contentKey: 'chatbot.cancerHistory.personal.everTested',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1200,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      description: 'Previously Tested',
      name: 'Previously Tested',
      type: 'OPTION_SELECT',
      data: {
        answerKey: 'previouslyTested',
        questionKey: '',
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
        required: true,
        isSingleSelect: true,
        optionSize: ComponentSizes.Medium,
        delay: 1200,
        position: 'right',
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'chatbot.cancerHistory.personal.yes',
          },
          {
            optionKey: 2,
            optionValue: 'chatbot.cancerHistory.personal.noDontKnow',
          },
        ],
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Intro',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.QUALIFYING_QUESTION_NODE,
      },
    },
  ],
};

export default previouslyTested;
