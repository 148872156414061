import { NodeTypes, Node } from '@myriadgenetics/mgh-types';
import ChatbotModalNodeReact from '../chatbot-modal-node';
import RedirectNode from '../redirect-node';
import ChatbotNodeReact from '../chatbot-node';
import EndpointCallNodeReact from '../endpoint-call-node';

interface NodeTagProps {
  Tag: any;
  triggerOnResponseUpdatedOnTag?: boolean;
  gotoNextNodeNoReplace?: boolean;
}

export const getNodeTag = (node: Node, NODE_KEYS_SHOULD_RENDER_ONCE: string[], responses: any): NodeTagProps => {
  switch (node.type) {
    case NodeTypes.ChatModalNode:
      return { Tag: ChatbotModalNodeReact };
    case NodeTypes.EndpointCall:
      // Add a cache key by pairing the node id with the responses
      // if responses change we will re-render the endpoint call
      const cacheKey = `${node.id}-${JSON.stringify(responses)}`;
      // add the cache key and
      const tag = !NODE_KEYS_SHOULD_RENDER_ONCE.includes(cacheKey) ? EndpointCallNodeReact : null;
      NODE_KEYS_SHOULD_RENDER_ONCE.push(cacheKey);
      return { Tag: tag, gotoNextNodeNoReplace: true };
    case NodeTypes.Redirect:
      return { Tag: RedirectNode };
    default:
      return { Tag: ChatbotNodeReact, triggerOnResponseUpdatedOnTag: true };
  }
};
