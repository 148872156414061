import { useEffect, useState, useContext } from 'react';
import {
  ChatModalNode,
  PageNode,
  ChatModalNodeType,
  ModalEvent,
  NodeTypes,
  SurveyEvents,
  NodeWorkflowLocationState,
  SurveyAccount,
} from '@myriadgenetics/mgh-types';
import NodeWorkflow from '../node-workflow';
import { MRNInfoFlow, PersonalInfoFlow, CancerHistoryFlow, BreastCancerRiskFlow, MedicalHistoryFlow, HideResultsFlow } from './nodes';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { findNodeById, SurveyNodeConsts } from '../helpers';
import './index.scss';
import Modal from '../../../elements/modal';
import { modalContainerClassName, closeButtonText } from './constants';
import Nodes from '../index';
import * as constants from './constants';
import { useLocation } from '../../../helpers/routing';
import { NodeWorkflowState } from '@myriadgenetics/mgh-types';

import { SurveyAccountsContext } from '../../../context/SurveyAccountsContext';
import { setSurveyAccountsContext } from '../../../helpers/answers';
import { getPatientMRNUniquePath, PatientIdentifierTypes } from '../../../helpers/url';
import type { PatientIdentifierResponse } from '../../survey-container/types';
import get from 'lodash.get';

interface Props {
  node: ChatModalNode;
  initNode?: any;
  isShowModal: boolean | string;
  responses: Record<string, unknown>;
  gotoNextNode: (nodeId: number | null | undefined) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  surveyAccount?: SurveyAccount;
  theme?: string;
}

function ChatModalNodeReact({ node, initNode, responses, gotoNextNode, onResponseUpdated, isShowModal = '', theme, surveyAccount }: Props) {
  const location = useLocation() as NodeWorkflowLocationState;
  const { continueType, subtitle, flowType, answerKey } = node.data;
  const [isShowSurveyModal, setIsShowSurveyModal] = useState<boolean>(isShowModal === answerKey);
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true);

  const surveyAccountsContext = useContext(SurveyAccountsContext);
  if (surveyAccountsContext !== null) setSurveyAccountsContext(surveyAccountsContext);

  const nodeListFlow: () => any = () => {
    switch (flowType) {
      case ChatModalNodeType.MrnInfo:
        return MRNInfoFlow;
      case ChatModalNodeType.PersonalInfo:
        return PersonalInfoFlow;
      case ChatModalNodeType.CancerHistory:
        return CancerHistoryFlow;
      case ChatModalNodeType.BreastCancerRisk:
        return BreastCancerRiskFlow;
      case ChatModalNodeType.MedicalHistory:
        return MedicalHistoryFlow;
      case ChatModalNodeType.HideResults:
        return HideResultsFlow;
      default:
        return PersonalInfoFlow;
    }
  };

  const Tag = (Nodes as any)[nodeListFlow()?.tag] || null;

  const mrnPath = getPatientMRNUniquePath();
  const patientIdentifiers = get(responses, mrnPath, []) as PatientIdentifierResponse[];
  let mrnIndex = patientIdentifiers.findIndex((identifier) => identifier.use === PatientIdentifierTypes.OFFICIAL);
  if (mrnIndex === -1) mrnIndex = patientIdentifiers.length;
  const nodeList: PageNode[] = nodeListFlow().steps({
    baseKey: flowType === ChatModalNodeType.MrnInfo ? `${mrnPath}[${mrnIndex}]` : answerKey,
    continueType,
    subtitle,
    type: NodeTypes.Page,
    surveyAccountsContext,
  });
  const startNode = initNode || findNodeById(nodeListFlow().initNodeId, nodeList) || nodeList[0];
  const { curNodeId = initNode?.id || nodeList[0].id } =
    (location && location.state && (location.state[`${SurveyNodeConsts.SUB_NODE_WORKFLOW_STATE}-${answerKey}`] as NodeWorkflowState)) || {};

  const closeModal = () => setIsShowSurveyModal(false);
  const openModal = () => setIsShowSurveyModal(true);
  const onResponseUpdatedModal = (event: CustomEvent<{ answerKey: string; answer: any }>) => {
    const isReaction = constants.onResponseUpdatedModalReactionMapping[event?.detail?.answerKey];
    !isShowSurveyModal && Boolean(isReaction) && isReaction === event?.detail?.answer?.toString() && openModal();
  };
  const onModalOpen = (event: CustomEvent<{ answerKey: string; answer: any }>) => {
    answerKey === event?.detail?.answerKey && openModal();
  };

  useEffect(() => {
    startNode && curNodeId && setIsFirstPage(startNode.id === curNodeId);
  }, [startNode, curNodeId]);

  useEffect(() => {
    const onResponseUpdatedEventListener = (event: Event) => onResponseUpdatedModal(event as CustomEvent);
    const openModalEventListener = (event: Event) => onModalOpen(event as CustomEvent);

    document.addEventListener(ModalEvent.Close, closeModal);
    document.addEventListener(ModalEvent.Open, openModalEventListener);
    document.addEventListener(SurveyEvents.onResponseUpdated, onResponseUpdatedEventListener);

    return () => {
      document.removeEventListener(ModalEvent.Close, closeModal);
      document.removeEventListener(ModalEvent.Open, openModalEventListener);
      document.removeEventListener(SurveyEvents.onResponseUpdated, onResponseUpdatedEventListener);
    };
  });

  useEffect(() => {
    setIsShowSurveyModal(isShowModal === answerKey);
  }, [isShowModal, answerKey]);

  const goToNextNodeInternal = () => {
    gotoNextNode(getNextNodeId(node.flows || [], responses));
  };

  return (
    <Modal
      triggerDisplay={'setDisplay'}
      triggerText={'modal'}
      isShow={isShowSurveyModal}
      isFullScreen
      closeText={closeButtonText}
      theme={theme}
      onModalClose={closeModal}
      isAnimated
      containerClass={modalContainerClassName}
      isOverflow
      isShowCloseModal={false}
    >
      {Tag ? (
        <Tag
          onResponseUpdated={onResponseUpdated}
          node={startNode}
          gotoNextNode={goToNextNodeInternal}
          responses={responses}
          isSubWorkflow
          className="chat-modal-node"
          containerClass="chat-modal-node"
          hasBackButton={!isFirstPage}
          surveyAccount={surveyAccount}
          {...nodeListFlow().props}
        />
      ) : (
        <NodeWorkflow
          className="chat-modal-node"
          answerKey={answerKey}
          nodes={nodeList}
          initNode={startNode}
          gotoNextNode={goToNextNodeInternal}
          onResponseUpdated={onResponseUpdated}
          responses={responses}
          closeModal={closeModal}
          isSubWorkflow
          hasBackButton={!isFirstPage}
          isChatbot={true}
        />
      )}
    </Modal>
  );
}

export default ChatModalNodeReact;
