import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProstatePathology } from '@myriadgenetics/mgh-types';
import get from 'lodash.get';

import { Text3 } from '../../../elements/typography';

import './index.scss';

export enum YesNo {
  Yes = 'yes',
  No = 'no',
}

const SEPARATOR = ':';
const yesNoMapper = (val: boolean, noKey?: string) => (val ? YesNo.Yes : noKey || YesNo.No);

type DetailProps = {
  testId?: string;
  displayKey: string;
  labelKey: string;
};

function ProstateCancerDetail({ testId, displayKey, labelKey }: DetailProps) {
  const { t } = useTranslation();
  return (
    <p data-testid={testId} className={`prostate-cancer-details__detail`}>
      <Text3 className="prostate-cancer-details__detail-label" tag="span">
        {`${t(labelKey)}${SEPARATOR}`}
      </Text3>
      <Text3 tag="span" bold="true">
        {t(displayKey)}
      </Text3>
    </p>
  );
}

interface Props {
  pathology: ProstatePathology;
}

function ProstateCancerDetails({ pathology }: Props) {
  return (
    <div data-testid="prostate-cancer-details" className="prostate-cancer-details">
      {pathology.prostate?.metastatic !== undefined && (
        <ProstateCancerDetail
          testId="prostate-cancer-details-metastatic"
          labelKey="review.metastatic"
          displayKey={yesNoMapper(get(pathology, 'prostate.metastatic', false), 'pathology.noOrUnknown')}
        />
      )}
      {pathology.prostate?.gleasonScore !== undefined && (
        <ProstateCancerDetail
          testId="prostate-cancer-details-gleason-score"
          labelKey="review.gleasonScore"
          displayKey={get(pathology, 'prostate.gleasonScore[0].optionValue', 'pathology.dontKnow')}
        />
      )}
    </div>
  );
}

export default ProstateCancerDetails;
