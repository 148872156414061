import { NodeWorkflowState, SurveyAccount } from '@myriadgenetics/mgh-types';
import { PatientIdentifierTypes } from '../../helpers/url';

export enum SurveyContainerStep {
  Results = 'RESULTS',
  Fetch = 'FETCH',
  Lang = 'LANG',
  Survey = 'SURVEY',
  Start = 'START',
  Provider = 'PROVIDER',
  Final = 'FINAL',
  Process = 'PROCESS',
  Chatbot = 'CHAT',
}

export interface SurveyContainerTiming {
  firstPage: string;
  end: string;
}

export interface SurveyContainerLocationState {
  locSession: string;
  initData: any;
  title: string;
  surveyStep?: SurveyContainerStep;
  locSurveyId: number | null;
  survey: any;
  surveyAccount?: SurveyAccount;
  nodeId: number | null;
  locResponses: any;
  mghUrl: string;
  clinicName: string;
  timing: SurveyContainerTiming;
  accessionId: string | null;
  surveyEntryAccountId: number | null;
  resultsState: {
    nodeId: number | null;
  };
  nodeWorkFlowState: NodeWorkflowState;
  queryParams?: any;
}

export interface BaseLocation extends Location {
  pathname: string;
  key: any;
  search: any;
}

export interface LocationState extends BaseLocation {
  state: SurveyContainerLocationState;
}

export type PatientIdentifierResponse = {
  use: PatientIdentifierTypes;
  value: string;
};
