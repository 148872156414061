import { NodeTypes, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { getNextComponentId } from '../../chatbot-container/steps/helper';
import { NODE_IDS } from '../constants';
import { getTcFlow } from '../helpers/flow';

export const boneMarrowYesNoNode = ({ baseKey, continueType, subtitle }: ChatModalNodeOptions) => ({
  id: NODE_IDS.BONE_MARROW_TRANSPLANT_YES_NO_NODE,
  type: NodeTypes.Page,
  name: 'Bone Marrow History',
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.boneMarrowHistoryNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'YES_NO',
      name: 'Bone Marrow Transplant Yes No',
      data: {
        questionKey: baseKey,
        answerKey: baseKey,
        required: true,
        questionKeyParams: [],
      },
    },
    {
      id: getNextComponentId(),
      type: 'HELP_HINT',
      name: 'BMT Help Hint',
      data: {
        helpHint: {
          termKey: 'helpHints.boneMarrowTransplant',
          definitionKey: 'helpHints.boneMarrowTransplantHint',
        },
      },
    },
  ],
  flows: [
    {
      id: 0,
      type: 'CONDITIONAL',
      name: 'To TC Workflow',
      data: getTcFlow(),
    },
    {
      id: 1,
      type: 'UNCONDITIONAL',
      name: 'Exit Modal',
      data: {
        nextPageId: null,
      },
    },
  ],
});
