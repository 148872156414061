import { NodeTypes, ComponentSizes, TextQuestionType, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5003,
    description: 'Built-in page node',
    name: 'First menstruation input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.firstMenstruationInputNode',
    },
    components: [
      {
        id: -4,
        description: 'Built in component',
        name: 'First Menstruation Input',
        type: 'TEXT_QUESTION',
        data: {
          // ! [WARNING]: This Misspelling, "firstMenstrationAge", Is Intentional.
          // ! The answer keys MUST be what the BE is expecting. It's misspelled in the BE; it should be misspelled here.
          answerKey: `${baseKey}.firstMenstrationAge`,
          questionKey: 'breastCancerRisk.firstMenstruation',
          labelKey: 'breastCancerRisk.firstMenstruationAgeLabel',
          placeholderKey: 'breastCancerRisk.agePlaceHolder',
          inputType: TextQuestionType.Age,
          min: 0,
          max: 115,
          threshold: 100,
          size: ComponentSizes.Large,
          required: type === BreastCancerRiskType.TcScore,
          secondaryResponse: {
            textKey: 'breastCancerRisk.neverHadAPeriod',
            value: type === BreastCancerRiskType.TcScore ? ' ' : null,
          },
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.firstMenstruation',
            definitionKey: 'helpHints.firstMenstruationHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -305,
        name: 'First Menstration flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: -5007,
        },
      },
    ],
  };
};
