import { LanguageString } from '@myriadgenetics/mgh-types';

export const languageOptions = [
  {
    label: 'English',
    value: LanguageString.English,
  },
  {
    label: 'Español',
    value: LanguageString.Spanish,
  },
];
