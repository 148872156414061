import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { YesNoComponent } from '@myriadgenetics/mgh-types';
import Button from '../../../elements/button';
import QuestionText from '../../../elements/question-text';

import './index.scss';

interface Props {
  compDef: any | YesNoComponent;
  responses: Record<string, any>;
  onResponseUpdated: (answerKey: string, val: any) => void;
}

function YesNo({ compDef, responses, onResponseUpdated }: Props) {
  const { t } = useTranslation();
  const yn: YesNoComponent = compDef as YesNoComponent;
  const {
    answerKey,
    questionKey,
    noKey,
    yesKey,
    questionKeyParams, // not sure how to use this
  } = yn.data;

  const answerChanged = (val: boolean) => {
    onResponseUpdated(answerKey, val);
  };

  const response = get(responses, answerKey);

  return (
    <div className="yes-no">
      {questionKey && (
        <div className="yes-no__question-text">
          <QuestionText htmlFor="yesNoQuestion" questionKey={questionKey} questionKeyParams={questionKeyParams} />
        </div>
      )}
      <fieldset name="yesNoQuestion">
        <div className="yes-no__btns">
          <Button className="yes-no__btn yes-no_btn--yes" onClick={() => answerChanged(true)} selected={response === true} secondary={response !== true}>
            {t(yesKey || 'yes')}
          </Button>
          <Button className="yes-no__btn yes-no_btn--no" onClick={() => answerChanged(false)} selected={response === false} secondary={response !== false}>
            {t(noKey || 'no')}
          </Button>
        </div>
      </fieldset>
    </div>
  );
}

export default YesNo;
