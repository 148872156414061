import React, { useEffect, useState } from 'react';
import { ChatbotNode, Node, ChatModalNode, NodeTypes, NodeWorkflowLocationState, SurveyAccount } from '@myriadgenetics/mgh-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { useHistory, useLocation } from '../../../helpers/routing';
import { findNodeById } from '../helpers';
import { CHAT_CLASS, DISPLAY_NEXT_NODE_TIMEOUT, NODE_KEYS_SHOULD_RENDER_ONCE, SUB_URL, PATH_TO_SAVE_RESPONSES } from './constants';
import { getUniqueListBasedOnId } from '../helpers';
import { getNodeTag } from './helpers';
import { useNodeWorkflowGoToNextNode } from '../../../helpers/hooks/useNodeWorkflowGoToNextNode';
import { remapAnswer } from '../../../helpers/answers';
import { ChatbotNodeType } from '../chatbot-container/types';

import './index.scss';

interface Props {
  className: string;
  nodes: ChatbotNodeType[];
  responses: any;
  surveyAccount?: SurveyAccount;
  gotoNextNode: (id: number | null | undefined, replace: boolean) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  initNode: any | ChatbotNode;
  theme?: string;
}

function ChatWorkflow({ className = CHAT_CLASS, nodes, responses, surveyAccount, gotoNextNode, onResponseUpdated, initNode, theme }: Props) {
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation() as NodeWorkflowLocationState;
  const { nodeWorkFlowState = {} } = location.state || {};
  const [nodesList, setNodesList] = useState<ChatbotNodeType[]>([]);
  const [isShowSurveyModal, setIsShowSurveyModal] = useState<boolean | string>('');
  const [isPreventAnimate, setIsPreventAnimate] = useState(false);
  const [isResultsNode, setIsResultsNode] = useState<boolean>(false);

  const { curNodeId = initNode.id, baseUrl = `${location.pathname}/${SUB_URL}` } = nodeWorkFlowState;

  const onBeforePushNodeIdToHistory = () => {
    isShowSurveyModal && setIsShowSurveyModal(false);
  };

  const onAfterPushNodeIdToHistory = (nextNode: Node) => {
    setNextDisplayNode(nextNode);
    if (nextNode.type === NodeTypes.ChatModalNode) {
      setIsShowSurveyModal(nextNode.data.answerKey);
    }
  };

  const onBeforeNodeWorkflowGoToNextNodeNoIdInNodeList = () => {
    setIsShowSurveyModal(false);
    setNextDisplayNode(undefined);
  };

  const { nodeWorkflowGoToNextNode } = useNodeWorkflowGoToNextNode({
    initNode,
    nodes,
    gotoNextNode,
    onBeforePushNodeIdToHistory,
    onAfterPushNodeIdToHistory,
    onBeforeNodeWorkflowGoToNextNodeNoIdInNodeList,
  });

  let curNode = findNodeById(curNodeId, nodes);

  if (curNode === undefined) {
    console.error(`could not find node by id ${curNodeId}`);
    curNode = nodes[0];
  }

  // TODO (Simon): fix the any
  const [nextDisplayNode, setNextDisplayNode] = useState<any>(curNode);

  useEffect(() => {
    if (Object.keys(nodeWorkFlowState).length === 0) {
      history.replace(`${baseUrl}/${curNodeId}`, {
        ...location.state,
        nodeId: curNodeId,
        nodeWorkFlowState: {
          baseUrl,
          curNodeId,
        },
      });
    }
  });

  useEffect(() => {
    nextDisplayNode && setNodesList((prev) => getUniqueListBasedOnId([...prev, nextDisplayNode]));
  }, [nextDisplayNode]);

  useEffect(() => {
    if (location.state?.nodeWorkFlowState?.curNodeId) {
      location.state.title = t(curNode?.data?.pageTitleKey || 'myGeneHistory');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curNode, t]);

  const triggerOnResponseUpdated = (answerKey: string, val: any): void => {
    isPreventAnimate && setIsPreventAnimate(false);
    onResponseUpdated(answerKey, val);
  };

  const onResponseUndo = (answerKey: string, nodeId: number) => {
    setIsPreventAnimate(true);

    const remappedAnswer = remapAnswer(answerKey, responses[answerKey]);
    Array.isArray(remappedAnswer) &&
      remappedAnswer.forEach((remappedAnswer) => {
        delete responses[remappedAnswer.key];
      });

    delete responses[answerKey];

    history.replace(`${baseUrl}/${nodeId}`, {
      ...location.state,
      nodeWorkFlowState: {
        ...nodeWorkFlowState,
        curNodeId: nodeId,
      },
      locResponses: responses,
    });

    // The index of the current node plus one to keep it on slice.
    const nodeIndex = nodesList.findIndex((node) => node.id === nodeId) + 1;

    // Wait for animation to finish before removing the next node:
    setTimeout(() => {
      setNodesList((prev) => prev.slice(0, nodeIndex));
      setNextDisplayNode(undefined);
    }, DISPLAY_NEXT_NODE_TIMEOUT);
  };

  useEffect(() => {
    const hasSavedResponses = curNode && curNode.name && get(responses, PATH_TO_SAVE_RESPONSES);
    const isStartNodeAndTypeModal = curNode && initNode && curNode?.id === initNode.id && curNode?.type === NodeTypes.ChatModalNode;
    if (hasSavedResponses && !isResultsNode) setIsResultsNode(true);
    if (isStartNodeAndTypeModal) setIsShowSurveyModal((curNode as ChatModalNode).data.answerKey);
  }, [curNode, initNode, isResultsNode, responses]);

  useEffect(() => {
    if (language !== responses?.languageUsed) {
      onResponseUpdated('languageUsed', language);
    }
  }, [language, onResponseUpdated, responses?.languageUsed]);

  return (
    <div className={className}>
      {nodesList.map((node, index) => {
        const { Tag = null, triggerOnResponseUpdatedOnTag = false, gotoNextNodeNoReplace = false } = getNodeTag(node, NODE_KEYS_SHOULD_RENDER_ONCE, responses);

        return (
          Tag && (
            <Tag
              node={node}
              responses={responses}
              key={`crt-${node.id}`}
              onResponseUpdated={triggerOnResponseUpdatedOnTag ? triggerOnResponseUpdated : onResponseUpdated}
              surveyAccount={surveyAccount}
              onResponseUndo={onResponseUndo}
              gotoNextNode={(id?: number, replace = false) => nodeWorkflowGoToNextNode(id, gotoNextNodeNoReplace ? false : replace)}
              shouldAnimate={!isPreventAnimate && (index ? index : 0) + 1 === nodesList.length}
              isResultsNode={isResultsNode}
              isShowModal={isShowSurveyModal}
              theme={theme}
            />
          )
        );
      })}
    </div>
  );
}

export default ChatWorkflow;
