import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { ReactComponent as CheckedRadioIcon } from './images/radioChecked.svg';
import { ReactComponent as RadioIcon } from './images/radio.svg';
import { Text2 } from '../typography';
import { RadioGroupOptions } from '@myriadgenetics/mgh-types';

interface Props extends RadioGroupOptions {
  isSelected: boolean;
  onClick: (value: string) => void;
  optionKey: string;
  optionLabel: string;
  optionValue: string;
}

function Radio({ optionKey, optionLabel, optionValue, isSelected, onClick }: Props) {
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick(optionValue);
  };

  return (
    <div className={`radio ${isSelected ? 'radio--selected' : ''}`}>
      <label className="radio__label">
        <input type="radio" className="radio__input" name={optionKey} value={optionValue} onClick={handleClick} />
        {isSelected ? <CheckedRadioIcon /> : <RadioIcon />}
        <Text2>{t(optionLabel)}</Text2>
      </label>
    </div>
  );
}

export default Radio;
