import React from 'react';
import { Trans } from 'react-i18next';
import { GridItemProps } from '@myriadgenetics/mgh-types';
import { Text1 } from '../../../../elements/typography';
import '../index.scss';

const GridItem: React.FC<GridItemProps> = ({ contentKey, type, showOnDesktopOnly, showOnMobileOnly }) => {
  return (
    <li
      className={`grid__item 
      ${type ? type.toLowerCase() : ''} 
      ${showOnDesktopOnly ? 'desktop-only' : ''}
      ${showOnMobileOnly ? 'mobile-only' : ''}`}
    >
      <Text1>{<Trans i18nKey={contentKey} components={[<b />]} />}</Text1>
    </li>
  );
};

export default GridItem;
