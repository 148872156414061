import { IMAGE_QUALITY, MAX_IMAGE_SIZE } from './constants';

export const calculateSize = (imageElement: HTMLImageElement) => {
  let width = imageElement.width;
  let height = imageElement.height;

  if (width > height && width > MAX_IMAGE_SIZE.width) {
    height = Math.round((height * MAX_IMAGE_SIZE.width) / width);
    width = MAX_IMAGE_SIZE.width;
  } else if (height > MAX_IMAGE_SIZE.height) {
    width = Math.round((width * MAX_IMAGE_SIZE.height) / height);
    height = MAX_IMAGE_SIZE.height;
  }
  return [width, height];
};

export const optimizeImage = (file: File, onLoadCallback: (blob: Blob | null) => void, onErrorCallback: () => void) => {
  const blobURL = URL.createObjectURL(file);
  const imageElement = new Image();
  imageElement.src = blobURL;
  imageElement.onerror = onErrorCallback;
  imageElement.onload = function () {
    URL.revokeObjectURL((this as HTMLImageElement).src);
    const [newWidth, newHeight] = calculateSize(imageElement);
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(imageElement, 0, 0, newWidth, newHeight);
    canvas.toBlob(onLoadCallback, file.type, IMAGE_QUALITY);
  };
};
