import { ContinueType, NodeTypes, EndpointCallNode, HttpMethod } from '@myriadgenetics/mgh-types';
import { getTcFlow } from '../helpers/flow';
import { NODE_IDS } from '../constants';
export const criteriaCheckNode: EndpointCallNode = {
  id: NODE_IDS.CRITERIA_ENDPOINT_NODE,
  name: 'Endpoint Call Node to Criteria',
  type: NodeTypes.EndpointCall,
  data: {
    endpointPath: '/criteria-results',
    errorKey: 'endpoint.criteriaCheckError',
    answerKey: 'criteriaCheck',
    endpointHttpMethod: HttpMethod.Post,
    continueType: ContinueType.AutoFixed,
    titleKey: 'endpoint.saveMessage',
    pageTitleKey: 'pageTitles.endpointCallToCriteriaNode',
  },
  flows: [
    {
      id: -1,
      name: 'Conditional',
      description: 'To Blood Transfusion if Met Criteria',
      type: 'CONDITIONAL',
      data: {
        nextPageId: NODE_IDS.BLOOD_TRANSFUSION_YES_NO_NODE,
        conditions: [
          {
            value: true,
            answerKey: 'meetsCriteria',
            comparator: '==',
          },
        ],
      },
    },
    {
      id: -2,
      name: 'Conditional To TC if NOT met and tc eligible',
      type: 'CONDITIONAL',
      data: getTcFlow(),
    },
    {
      id: -3,
      name: 'Unconditional to Exit Medical History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: null, // will this exit?
      },
    },
  ],
};
