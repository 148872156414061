import React, { createRef, useState, useEffect, useRef } from 'react';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { ChatbotNode, Component, ModalEvent, SurveyAccount, SurveyEvents, TextContentHighlightMod } from '@myriadgenetics/mgh-types';
import ContinueWrapper from '../continue-wrapper';
import { typeToComponent } from '../../../helpers/component-map';
import Validation from '../../../validation';
import { Text2 } from '../../../elements/typography';
import Button from '../../../elements/button';
import { delay } from '../../../helpers/chatbot';
import { scrollIntoView } from '../helpers/scrollIntoView';
import Config from '../../../helpers/config';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document-icon.svg';
import { getUniqueDataId, getUniqueClassName } from '../helpers';

import { areAllValid, autoAble, getUniqueListBasedOnId, isAutoType, triggerContinue, trackGtmData } from '../helpers';

import './index.scss';

interface Props {
  node: any | ChatbotNode;
  responses: any;
  surveyAccount?: SurveyAccount;
  gotoNextNode: (nodeId?: number | null) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  onResponseUndo: (answerKey: string, nodeId: number) => void;
  gtmDataTracking?: ({ answerKey }: { answerKey: string }) => void;
  shouldAnimate: boolean;
  isResultsNode?: boolean;
  isClosePageButtonExist?: boolean;
}

const ChatbotNodeReactRenderFunction = ({
  node,
  responses,
  surveyAccount,
  gotoNextNode,
  onResponseUpdated,
  onResponseUndo,
  gtmDataTracking,
  shouldAnimate,
  isResultsNode,
  isClosePageButtonExist,
}: Props) => {
  const { t } = useTranslation();
  const { components = [] } = node;
  const componentsArray = useRef(components);
  const chatNodeElement = useRef(null);
  const [componentsList, setComponentsList] = useState<Component[]>(shouldAnimate ? [] : components);
  const [displayComponent, setDisplayComponent] = useState<Component>();
  const [actionTaken, setActionTaken] = useState<boolean>(false);
  const [continueClickedIdList, setContinueClickedIdList] = useState<number[]>([]);
  const [isAvatarLoaded, setIsAvatarLoaded] = useState<boolean>(false);
  const { continueKey, continueType, subtitle } = node.data;
  const validationMap = Validation.createValidationMap(components, responses);
  const allAreValid = areAllValid(validationMap);
  const bottomRef = createRef<HTMLDivElement>();

  const triggerOnResponseUpdated = (answerKey: string, val: any): void => {
    onResponseUpdated(answerKey, val);
    setActionTaken(true);
  };

  const getDelayNumber = (delay: number | string) => {
    if (Config.dontWaitForChats()) {
      return 0;
    }
    return typeof delay === 'number' ? delay : parseInt(delay, 10);
  };

  const disableContinue = (c: any) => {
    setContinueClickedIdList([...continueClickedIdList, c.id]);
  };

  const dispatchModalEventIfTriggerPresent = ({ data }: any) => {
    if (data.modalTriggerKey) {
      const event = new CustomEvent(ModalEvent.Open, {
        detail: { answerKey: data.modalTriggerKey },
      });
      document.dispatchEvent(event);
    }
  };

  const dispatchContinueEvent = ({ data }: any) => {
    const event = new CustomEvent(SurveyEvents.onContinue, { detail: { answerKey: data.buttonKey } });
    document.dispatchEvent(event);
  };

  const onContinue = (c: any) => {
    dispatchModalEventIfTriggerPresent(c);
    dispatchContinueEvent(c);
    triggerContinue(gotoNextNode, node, responses);
    disableContinue(c);
  };

  useEffect(() => {
    if (isAutoType(continueType) && actionTaken && allAreValid && autoAble(node, get, responses)) {
      dispatchModalEventIfTriggerPresent(componentsList.length && componentsList[componentsList.length - 1]);
      triggerContinue(gotoNextNode, node, responses);
    }
    return () => setActionTaken(false);
  });

  // use 2 useEffect hooks to drive animation behavior
  useEffect(() => {
    if (shouldAnimate) {
      (async function () {
        for (const component of componentsArray.current) {
          await delay(component.data.delay ? getDelayNumber(component.data.delay) : 500);
          setDisplayComponent(component);
        }
        setDisplayComponent(undefined);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAnimate, componentsArray]);

  useEffect(() => {
    const shouldAddToComponentList = shouldAnimate && displayComponent;
    if (shouldAddToComponentList) {
      setComponentsList((prev) => [...prev, displayComponent]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAnimate, displayComponent]);

  useEffect(() => {
    if (!shouldAnimate) {
      setComponentsList(getUniqueListBasedOnId(components));
    }
  }, [components, shouldAnimate]);

  useEffect(() => {
    const scrollToBottom = setTimeout(() => {
      shouldAnimate && scrollIntoView(bottomRef);
    }, 250);

    return () => {
      clearTimeout(scrollToBottom);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentsList]);

  const triggerOnRespondeUndo = (answerKey: string, nodeId: number) => {
    const chatNodeContainer = chatNodeElement.current as HTMLElement | null;
    chatNodeContainer?.nextElementSibling?.classList.add('chat-container--undo');

    onResponseUndo(answerKey, nodeId);
  };

  const componentRender = (c: Component, i: number, isSub = false) => {
    const key = Validation.getKey(c, i);
    const Tag = typeToComponent(c);
    if (c.type === 'CONTINUE') {
      return (
        <ContinueWrapper
          key={key}
          allAreValid={allAreValid}
          continueType={continueType}
          continueKey={c.data.buttonKey || continueKey}
          isResultsPage={isResultsNode}
          triggerContinue={() => onContinue(c)}
          isClosePageButtonExist={isClosePageButtonExist}
          gtmDataTracking={() => {
            trackGtmData(node, gtmDataTracking);
          }}
          isChatbotStyle={true}
          isTriggerModal={Boolean(c.data?.modalTriggerKey)}
          isPrimary={Boolean(c.data?.isPrimary)}
          disabled={continueClickedIdList.includes(c.id)}
          disabledIcon={c.data.modalTriggerKey && <DocumentIcon />}
        />
      );
    } else {
      const isOptionSelect = c.type === 'OPTION_SELECT';
      const lastAnsweredKey = Object.keys(responses).splice(-1)[0];
      const isShowUndoButton = isOptionSelect && lastAnsweredKey === c.data.answerKey && !isResultsNode;
      const showComponentsInDialog = c.data?.showComponentsInDialog;
      const childComponents = c.data?.components && c.data.components.length > 0 && c.data.components;
      const shouldCenter =
        c.type === 'TEXT_CONTENT' &&
        (c.data?.highlightModifier === TextContentHighlightMod.Callout || c.data?.highlightModifier === TextContentHighlightMod.Centered);
      return (
        <div
          key={`chat-item--${c.id}`}
          className={`
            chat-item
            ${shouldAnimate ? 'chat-item--animate' : ''}
            ${shouldCenter ? 'chat-item--centered' : ''}
            ${c.type === 'TEXT_CONTENT' ? getUniqueClassName(c.name) : ''}
          `}
          data-testid={`chat-item--${getUniqueDataId(c.id, c.name)}`}
        >
          {!isSub && c.data?.avatarSvg && c.data?.position !== 'right' && (
            <>
              <img
                src={t(c.data?.avatarSvg)}
                alt={t('chatbot.hcpAvatarAlt')}
                className={'chat-item_avatar'}
                onLoad={() => setIsAvatarLoaded(true)}
                style={{ display: isAvatarLoaded ? 'initial' : 'none' }}
              />
              {!isAvatarLoaded && <img src={t('chatbot.hcpAvatarPlaceholder')} alt={t('chatbot.hcpAvatarAlt')} className={'chat-item_avatar placeholder'} />}
            </>
          )}
          <div
            className={`
                ${!isSub ? 'chat-bubble' : 'chat-bubble--sub'}
                ${c.data?.position === 'right' ? 'chat-bubble--right' : 'chat-bubble--left'}
                ${c.type === 'OPTION_SELECT' ? 'chat-bubble--borderless' : ''}
                ${c.type === 'VIDEO' ? 'chat-bubble--video' : ''}
                ${c.type === 'TEXT_CONTENT' ? getUniqueClassName(c.name) : ''}
            `}
            key={`chat-bubble-${c.name}`}
          >
            {isShowUndoButton && (
              <Button className="chat-bubble--undo" onClick={() => triggerOnRespondeUndo(c.data.answerKey, node.id)}>
                {t('chatbot.undoLabel')}
              </Button>
            )}
            <Tag
              compDef={c}
              responses={responses}
              surveyAccount={surveyAccount}
              onResponseUpdated={triggerOnResponseUpdated}
              validationResult={validationMap[key]}
              onContinue={() => onContinue(c)}
              gtmDataTracking={() => {
                trackGtmData(node, gtmDataTracking);
              }}
              isChatbotStyle={true}
            />
            {!showComponentsInDialog && childComponents && childComponents.map((subC: Component, j: number) => componentRender(subC, j, true))}
          </div>
          {c.data?.avatarSvg && c.data?.position === 'right' && <img src={t(c.data?.avatarSvg)} alt="Your User Avatar" />}
        </div>
      );
    }
  };

  return (
    <div ref={chatNodeElement} className={`chat-container`} key={`chat-node-${node.id}`}>
      {subtitle && <Text2 className="chat__subtitle subtitle">{t(subtitle)}</Text2>}
      <div className="chat-content" key={`content-${node.name}`}>
        {componentsList.map((c: Component, i: number) => componentRender(c, i))}
      </div>
      {/* TODO (Simon): add scroll behavior after each component appears */}
      {shouldAnimate && <div id="scroll-to-bottom" ref={bottomRef}></div>}
    </div>
  );
};

const ChatbotNodeReact = ChatbotNodeReactRenderFunction;

export default ChatbotNodeReact;
