import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Logger from '../../helpers/logger';
import Link from '../link';
import Button from '../button';
import { Text1 } from '../typography';
import close from '../../assets/close.svg';
import back from '../../assets/back.svg';
import { ANIMATION_TIMEOUT, NO_OVERFLOW_CLASS_NAME } from './constants';
import './index.scss';

export type TriggerDisplay = 'link' | 'button' | 'setDisplay';
interface Props {
  children?: any;
  triggerDisplay?: TriggerDisplay;
  isShow?: boolean;
  setIsShow?: (isShow: boolean) => void;
  triggerText: string;
  closeText?: string;
  theme?: string;
  isAnimated?: boolean;
  attachToQuestion?: boolean;
  isFullScreen?: boolean;
  onModalClose?: () => void;
  containerClass?: string;
  isOverflow?: boolean;
  isShowCloseModal?: boolean;
}

function Modal({
  children,
  isOverflow,
  containerClass,
  isAnimated,
  triggerDisplay,
  onModalClose,
  triggerText,
  isShow,
  setIsShow,
  theme,
  isFullScreen,
  closeText,
  attachToQuestion,
  isShowCloseModal = true,
}: Props) {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState<boolean>(isShow || false);
  const [isAnimate, setIsAnimate] = useState<boolean>(false);
  const container = containerClass ? document.getElementsByClassName(containerClass)[0] || document.body : document.body;
  const [triggerWrapperStyles, setTriggerWrapperStyles] = React.useState<React.CSSProperties>({});
  const ESCAPE_KEY_CODE = 'Escape';
  const QUESTION_SELECTOR = '.yes-no label';

  const escListener = ({ code }: KeyboardEvent) => {
    if (isShown && code === ESCAPE_KEY_CODE) {
      setIsShown(false);
      onModalClose && onModalClose();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', escListener);
    return () => {
      window.removeEventListener('keyup', escListener);
    };
  });

  useEffect(() => {
    const questionElement = container.querySelector(QUESTION_SELECTOR);

    if (attachToQuestion && questionElement) {
      const { top, width, height, left } = questionElement.getBoundingClientRect();
      const mobileWidth = 518;
      const shouldDrop = width <= mobileWidth;
      const topPosition = shouldDrop ? top + height : top - 16;
      const leftOffset = shouldDrop ? left : width - 24;
      setTriggerWrapperStyles({
        position: 'absolute',
        display: 'inline-block',
        top: `${topPosition}px`,
        left: `${leftOffset}px`,
      });
    }
  }, [attachToQuestion, container]);

  const closeModalAnimate = () => setIsAnimate(true);
  const closeModal = useCallback(() => {
    setIsShown(false);
    isShow && setIsShow && setIsShow(false);
    onModalClose && onModalClose();
  }, [isShow, setIsShow, onModalClose]);
  const closeModalMethod = isAnimated ? closeModalAnimate : closeModal;

  useEffect(() => {
    if (isAnimated && !isShow) {
      setIsAnimate(true);
    } else {
      setIsAnimate(false);
      isShow !== undefined && setIsShown(isShow);
    }
  }, [isShow, isAnimated]);

  useEffect(() => {
    if (isAnimate) {
      const animationTimeout = setTimeout(() => {
        closeModal();
        setIsAnimate(false);
      }, ANIMATION_TIMEOUT);

      return () => clearTimeout(animationTimeout);
    }
  }, [isAnimate, closeModal]);

  const onTriggerClick = () => {
    Logger.logEvent('Clicked Modal Link', { triggerText });
    setIsShown(true);
  };

  const triggerElement = () => {
    switch (triggerDisplay) {
      case 'button':
        return (
          <Button className="modal__button" secondary={true} onClick={onTriggerClick}>
            <Text1 bold="true">{t(triggerText)}</Text1>
          </Button>
        );
      case 'setDisplay':
        return;
      case 'link':
      default:
        return (
          <Link className="modal__link" onClick={onTriggerClick}>
            {t(triggerText)}
          </Link>
        );
    }
  };

  useEffect(() => {
    isOverflow && document.body.classList[isShown ? 'add' : 'remove'](NO_OVERFLOW_CLASS_NAME);
  }, [isShown, isOverflow]);

  return (
    container && (
      <React.Fragment>
        {attachToQuestion ? (
          <div className="help-hint--attached" style={triggerWrapperStyles}>
            {triggerElement()}
          </div>
        ) : (
          triggerElement()
        )}
        {isShown &&
          ReactDOM.createPortal(
            <div
              className={`modal ${theme ? 'modal--' + theme : ''}
              ${isFullScreen ? 'modal--full-screen' : ''}
              ${isAnimate ? 'modal--animated-close' : ''}`}
            >
              <div className="modal__backdrop" onClick={closeModalMethod}></div>
              <div className="modal__content-area">
                <div className="modal__header">
                  {isShowCloseModal && (
                    <img className="modal__close-icon" alt={t(closeText || 'modal.close')} src={closeText ? back : close} onClick={closeModalMethod} />
                  )}
                  {isShowCloseModal && closeText && (
                    <span className="modal__close-text" onClick={closeModalMethod}>
                      {t(closeText)}
                    </span>
                  )}
                </div>
                <div className="modal__body">{children}</div>
                <div className="modal__footer">
                  <Link className="modal__close-link" onClick={closeModalMethod}>
                    {t('modal.close')}
                  </Link>
                </div>
              </div>
            </div>,
            container,
          )}
      </React.Fragment>
    )
  );
}

export default Modal;
