import { ContinueType, NodeTypes, ChatbotNode, TextContentType, TextContentHighlightMod } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const enterPersonalHistoryNode: ChatbotNode = {
  id: NODE_IDS.ENTER_PERSONAL_HISTORY_NODE,
  name: 'Enter Personal History Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.enterPersonalHistoryNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Hello Intro 2',
      description: 'Example of chat',
      data: {
        contentKey: 'chatbot.intro.details',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 5000,
        avatarSvg: 'chatbot.hcpAvatar',
        position: 'left',
      },
    },
    {
      id: getNextComponentId(),
      type: 'CONTINUE',
      data: {
        modalTriggerKey: 'patient',
        buttonKey: 'chatbot.personalHistory.enterInfo',
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Personal History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.PERSONAL_HISTORY_NODE,
      },
    },
  ],
};

export default enterPersonalHistoryNode;
