import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { EndpointCallNode, HttpMethod, RejectionErrorTypes, SurveyAccount } from '@myriadgenetics/mgh-types';
import { didMeetCriteria } from './helpers';
import { useHistory, useLocation } from '../../../helpers/routing';
import getNextNodeId from '../../../helpers/flow/route-flow';
import Logger from '../../../helpers/logger';
import aux, { errorStateMap, errorStatusTypesMap } from '../../../helpers/auxiliary-page';
import './index.scss';
import { performRequest } from '../../../helpers/api';
import { SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY, surveyAccountTypes } from '../../../helpers/surveyAccount';
import { SurveyAccountsContext } from '../../../context/SurveyAccountsContext';
import { getPatientIdentifiersUniquePaths } from '../../../helpers/url';
import { get as _get, unset as _unset } from 'lodash';
import { H2 } from '../../../elements/typography';
import Loading from '../../loading';

const ACCESSION_ID_SEARCH_PARAM = 'accessionId';
const IS_COMPLETED_PROPERTY = 'isCompleted';
const POST_SURVEY_ENTRY_PATH = '/patient-survey-entries';

interface Props {
  node: any | EndpointCallNode;
  responses: any;
  onResponseUpdated: (answerKey: string, val: any) => void;
  gotoNextNode: (nodeId?: number | null, replace?: boolean | null) => void;
  id?: number;
}

interface EndpointState {
  survey: {
    id: number;
  };
  surveyAccount: SurveyAccount;
  mghUrl: string;
  accessionId: string | null;
  surveyEntryAccountId: number | null;
  timing: {
    firstPage: number;
    end: number;
  };
}

interface EndpointLocationState {
  state: EndpointState;
}

function EndpointCallNodeReact(props: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation() as EndpointLocationState;
  const { node, responses, gotoNextNode, onResponseUpdated, id } = props;
  const { titleKey, messageKey, errorKey, endpointPath, endpointHttpMethod, answerKey } = node.data;

  const surveyAccountsContext = useContext(SurveyAccountsContext);

  // TODO; need a way for post data to be configurable
  const transformResponses = () => {
    const responsesToPost = {
      ...responses,
      [SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY]: undefined,
    };

    const patientIdentifiersPaths = getPatientIdentifiersUniquePaths();
    const patientIdentifiers = patientIdentifiersPaths.reduce((prev, curr) => {
      const patientIdentifierResponse = _get(responses, curr);
      if (patientIdentifierResponse) prev = { ...prev, [curr]: patientIdentifierResponse };
      return prev;
    }, {});

    patientIdentifiersPaths.forEach((responsePath) => {
      _unset(responsesToPost, responsePath);
    });

    return {
      surveyId: location?.state?.survey?.id || null,
      externalSource: surveyAccountsContext?.surveyAccount?.externalSource || location?.state?.surveyAccount?.externalSource || null,
      externalSourceId: surveyAccountsContext?.surveyAccount?.externalSourceId || location?.state?.surveyAccount?.externalSourceId || null,
      languageUsed: i18next.language,
      ...patientIdentifiers,
      responses: {
        ...responsesToPost,
        url: location && location.state && location.state.mghUrl,
      },
      [SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY]: responses[SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY],
      surveyStartTime: location?.state?.timing?.firstPage,
      surveyEndTime: endpointPath.includes(POST_SURVEY_ENTRY_PATH) ? new Date().toISOString() : location?.state?.timing?.end,
    };
  };

  const onRequestError = (error: any) => {
    Logger.logError(error);
    onResponseUpdated(`${answerKey}.${errorKey}`, error);
    aux.routeToAuxPage(history, errorStateMap[error.type as RejectionErrorTypes] || errorStateMap[RejectionErrorTypes.Application]);
  };

  const performAccessionIdCheck = () => {
    const accessionId = location.state?.accessionId || null;

    if (!accessionId) {
      onResponseUpdated(IS_COMPLETED_PROPERTY, false);
      gotoNextNode(getNextNodeId(node.flows, responses), true);
      return;
    }
    performRequest(HttpMethod.Get, `${endpointPath}=${accessionId}`, transformResponses())
      .then((res) => {
        if (!res?.accessionId) {
          Logger.logError(`Invalid Accession ID: ${accessionId}`);
          aux.routeToAuxPage(history, errorStateMap[RejectionErrorTypes.AccessionNotFound]);
          return;
        }

        if (res.isCompleted) {
          aux.routeToAuxPage(history, errorStateMap[RejectionErrorTypes.SurveyCompleted]);
          return;
        }

        res.demographic && onResponseUpdated(answerKey, res.demographic);
        onResponseUpdated(ACCESSION_ID_SEARCH_PARAM, res.accessionId);
        onResponseUpdated(IS_COMPLETED_PROPERTY, res.isCompleted);
        gotoNextNode(getNextNodeId(node.flows, responses), true);
      })
      .catch((error) => {
        const errorType = typeof error.status === 'number' && errorStatusTypesMap[error.status][error.body.error_type];

        if (errorType) {
          aux.routeToAuxPage(history, errorStateMap[errorType]);
          return;
        }

        onRequestError(error);
      });
  };

  const performSurveyEntryAccountIdCheck = () => {
    const surveyEntryAccountId = location.state?.surveyEntryAccountId || null;
    if (!surveyEntryAccountId) {
      aux.routeToAuxPage(history, errorStateMap[RejectionErrorTypes.SurveyAccountIdNotFound]);
      return;
    }
    performRequest(HttpMethod.Get, `${endpointPath}/${surveyEntryAccountId}`, transformResponses())
      .then((res) => {
        if (!res?.externalSourceId) {
          Logger.logError(`Invalid Survey Account ID: ${surveyEntryAccountId}`);
          aux.routeToAuxPage(history, errorStateMap[RejectionErrorTypes.SurveyAccountIdNotFound]);
          return;
        }
        onResponseUpdated(SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY, [
          {
            externalSourceId: res.externalSourceId,
            externalSource: res.externalSource,
            type: surveyAccountTypes.receiving,
          },
        ]);
        gotoNextNode(getNextNodeId(node.flows, responses), true);
      })
      .catch((error) => {
        onRequestError(error);
      });
  };

  useEffect(() => {
    // Only go through if it hasn't already been redirected to the auxiliary page:
    if (history.history.location.pathname !== '/auxiliary') {
      const shouldGetAccessionId = endpointPath.includes(ACCESSION_ID_SEARCH_PARAM) && !responses[ACCESSION_ID_SEARCH_PARAM];
      if (shouldGetAccessionId) {
        performAccessionIdCheck();
        return;
      }

      const shouldGetSurveyAccountFromQueryParam = answerKey === SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY && !responses[SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY];
      if (shouldGetSurveyAccountFromQueryParam) {
        performSurveyEntryAccountIdCheck();
        return;
      }

      if (history.action === 'PUSH') {
        performRequest(endpointHttpMethod, endpointPath, transformResponses())
          .then((res) => {
            onResponseUpdated(answerKey, res);
            // handle responses with criteriaMetList differently
            // THIS ALSO APPLIES TO THE SAVE SURVEY ENTRY node used in the chatbot experience
            if (res?.criteriaMetList) {
              try {
                const meetsCriteria = didMeetCriteria(res);
                onResponseUpdated('meetsCriteria', meetsCriteria);
                gotoNextNode(getNextNodeId(node.flows, responses), true);
              } catch (e) {
                Logger.logError(e);
                // if we cannot determine the criteria response we will route to the aux page
                // this prevents the user from possibly seeing an incorrect result
                // NOTE* this is post processing so the user may see an error page the data has persisted to the server
                // in this case we should rely on monitoring the above logError and Sentry
                aux.routeToAuxPage(history, {
                  titleKey: 'errorPages.server.title',
                  messageKey: 'errorPages.server.message',
                });
              }
            } else {
              gotoNextNode(getNextNodeId(node.flows, responses), true);
            }
          })
          .catch((error) => onRequestError(error));
      }
    }
  });

  return (
    <div key={`endpoint-call-${id}`} className="endpoint-call-node">
      <H2 className="endpoint-call-node__text">{t(titleKey)}</H2>
      <div className="endpoint-call-node__result">{t(messageKey)}</div>
      <div className="endpoint-call-node__loading">
        <Loading />
      </div>
    </div>
  );
}

export default EndpointCallNodeReact;
