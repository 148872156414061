import Validation from './index';
import get from 'lodash.get';

export const getErrorsFromComponentFields = (component: { [key: string]: any }, FormFields: { [key: string]: any }, val: any) => {
  const visibleFields = getNotHiddenFields(component);
  return visibleFields
    .map((field) => {
      const formField = FormFields[`${field}Question`];
      return {
        field: formField.data.answerKey,
        validationResult: Validation.validate(FormFields[`${field}Question`], get(val, formField.data.answerKey)),
      };
    })
    .filter((vField) => !vField.validationResult.isValid)
    .reduce(
      (prev, curr) => ({
        ...prev,
        ...{
          [curr.field]: curr.validationResult,
        },
      }),
      {},
    );
};

export const getNotHiddenFields = (component: { [key: string]: any }) =>
  Object.keys(component.data.fields).filter(
    (field) => Object.prototype.hasOwnProperty.call(component.data.fields, field) && component.data.fields[field] !== 'HIDDEN',
  );
