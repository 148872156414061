import { BloodTransfusionNode, PageNode } from '@myriadgenetics/mgh-types';
import NodeWorkflow from '../node-workflow';
import { BloodTransfusion } from './nodes';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { findNodeById } from '../helpers';

import './index.scss';

interface Props {
  node: any | BloodTransfusionNode;
  initNode?: any | PageNode;
  responses: any;
  gotoNextNode: (nodeId?: number | null) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
}

function BloodTransfusionNodeReact({ node, responses, gotoNextNode, onResponseUpdated, initNode }: Props) {
  const { answerKey, continueType, subtitle } = node.data;
  const nodeList = BloodTransfusion.steps(answerKey, continueType, subtitle);
  return (
    <NodeWorkflow
      className="blood-transfusion"
      nodes={nodeList}
      initNode={initNode || findNodeById(BloodTransfusion.initNodeId, nodeList)}
      gotoNextNode={() => gotoNextNode(getNextNodeId(node.flows, responses))}
      onResponseUpdated={onResponseUpdated}
      responses={responses}
      isChatbot={false}
    />
  );
}

export default BloodTransfusionNodeReact;
