import { SurveyAccount, SurveyAccountConfigurations } from '@myriadgenetics/mgh-types';

const DEFAULT_SURVEY_ACCOUNT_FOR_URL = 'defaultSurveyAccountForUrl';

export const getSurveyAccountsConfigurations = (surveyAccounts: SurveyAccount[]) => surveyAccounts.flatMap((surveyAccount) => surveyAccount.configurations);

export const splitDefaultSurveyAccountForUrl = (defaultSurveyAccountForUrl: string) => ({
  mghUrl: defaultSurveyAccountForUrl.split('|')[0],
  surveyAccountId: +defaultSurveyAccountForUrl.split('|')[1],
});

export const findDefaultSurveyAccountForUrlByUrlSlug = (configurations: SurveyAccountConfigurations[], urlSlug: string) =>
  configurations.find(
    (configuration) =>
      configuration.configurationKey === DEFAULT_SURVEY_ACCOUNT_FOR_URL && splitDefaultSurveyAccountForUrl(configuration.configurationValue).mghUrl === urlSlug,
  );

export default {
  getSurveyAccountsConfigurations,
  findDefaultSurveyAccountForUrlByUrlSlug,
  splitDefaultSurveyAccountForUrl,
};
