import { useTranslation } from 'react-i18next';
import { LanguageString } from '@myriadgenetics/mgh-types';
import TextLogo from '../../elements/text-logo';
import Button from '../../elements/button';
import { H2, H3 } from '../../elements/typography';

import './index.scss';

interface Props {
  onContinue: () => void;
}

function StartPage({ onContinue }: Props) {
  const { t, i18n } = useTranslation();

  const triggerContinue = (lang: LanguageString) => {
    i18n.changeLanguage(lang);

    onContinue();
  };
  const es = i18n.getFixedT('es', 'translation');
  return (
    <div className="language-page">
      <div className="language-page__logo">
        <TextLogo size="large" />
      </div>
      <div className="language-page__welcome">
        <H2>{t('start.title')}</H2>
        <H3>{es('start.title')}</H3>
      </div>
      <div className="language-page__language">
        <Button className="language-page__english-button" secondary onClick={() => triggerContinue(LanguageString.English)}>
          English
        </Button>
        <Button className="language-page__espanol-button" secondary onClick={() => triggerContinue(LanguageString.Spanish)}>
          Español
        </Button>
      </div>
    </div>
  );
}

export default StartPage;
