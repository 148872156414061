import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ComponentSizes, OptionKeyValue } from '@myriadgenetics/mgh-types';
import { getDisplayKey, isOptionSelected } from '../helpers';
import SelectionOption from '../../../../elements/selection-option';
import { Text2 } from '../../../../elements/typography';
import { ReactComponent as PatientAvatarIcon } from '../../../../assets/icons/patient-avatar.svg';

const baseClass = 'option-select';
interface Props {
  isSingleSelect?: boolean;
  isShowOptionAsCheckbox?: boolean;
  labelKey: string;
  onOptionSelected: (selectedOption: OptionKeyValue) => void;
  optionSize?: ComponentSizes;
  selectedOptions: OptionKeyValue[] | string;
  selectionOptions: OptionKeyValue[];
  isChatbotStyle?: boolean;
}

const convertToTestId = (value: string) => {
  if (value === undefined) {
    return '';
  }
  return value.split('.').join('-').replace('-unselected', '');
};

function SelectionList({
  isSingleSelect,
  labelKey,
  onOptionSelected,
  optionSize,
  selectedOptions,
  selectionOptions,
  isShowOptionAsCheckbox,
  isChatbotStyle,
}: Props) {
  const { t } = useTranslation();

  const optionsToShow = useMemo(() => {
    const hasMadeSelection = selectedOptions?.length > 0;
    if (isChatbotStyle && hasMadeSelection) {
      return selectionOptions.filter((option) => option === selectedOptions[0]);
    }
    return selectionOptions;
  }, [selectedOptions, selectionOptions, isChatbotStyle]);

  return (
    <React.Fragment>
      <fieldset name="optionSelect" className={`${baseClass}__container`}>
        {labelKey && <Text2 tag="legend">{t(labelKey)}</Text2>}
        <div className={`${baseClass}__options`}>
          {optionsToShow.map((option) => (
            <SelectionOption
              className={`${baseClass}__option`}
              isShowOptionAsCheckbox={isShowOptionAsCheckbox}
              isPrimary={option.isPrimary}
              openLink={option.openLink}
              key={option.optionKey}
              onClick={() => onOptionSelected(option)}
              selected={isOptionSelected(option, selectedOptions)}
              size={optionSize}
              type={isSingleSelect ? 'radio' : 'checkbox'}
              dataTestId={convertToTestId(getDisplayKey(option))}
            >
              <Trans i18nKey={getDisplayKey(option, isOptionSelected(option, selectedOptions))} />
            </SelectionOption>
          ))}
        </div>
        {isChatbotStyle && selectedOptions?.length > 0 && (
          <div className="avatar-container">
            <PatientAvatarIcon />
          </div>
        )}
      </fieldset>
    </React.Fragment>
  );
}

export default SelectionList;
