import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, VideoPlayerType } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';
import { standardProviderQuestionOrSimon } from '../../chatbot-modal-node/helpers/utilities';
import { isSimonMedUrl } from '../../../../helpers/url';

export const introWithTermsNode: ChatbotNode = {
  id: NODE_IDS.INTRO_WITH_TERMS_NODE,
  name: 'Chatbot Terms',
  description: 'Chatbot Terms bubbles and agreement',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.terms',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Personal Risk Factor',
      description: 'Personal Risk Factor',
      data: {
        contentKey: 'chatbot.intro.personalRiskFactor',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 2000,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Terms 2',
      description: 'Chat bubble for terms',
      data: {
        contentKey: 'chatbot.terms.clickHere',
        showComponentsInDialog: true,
        components: [
          {
            id: getNextComponentId(),
            type: 'TEXT_CONTENT',
            name: 'Hippa',
            description: 'Chat Hippa',
            data: {
              contentKey: 'chatbot.terms.hipaa',
              textLinks: [
                {
                  urlKey: standardProviderQuestionOrSimon({
                    isSimon: isSimonMedUrl(),
                    simonText: 'chatbot.terms.links.simonMedHippaPolicy',
                    myraText: 'chatbot.terms.links.radiantHippaPolicy',
                  }),
                  targetIsBlank: true,
                  openInModal: true,
                },
                { urlKey: 'chatbot.terms.links.myriadHippaPolicy', targetIsBlank: true, openInModal: true },
              ],
              type: TextContentType.Text1,
              highlightModifier: TextContentHighlightMod.Primary,
              delay: 0,
              avatarSvg: 'chatbot.hcpAvatar',
              position: 'left',
            },
          },
        ],
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
        position: 'left',
      },
    },
    {
      id: getNextComponentId(),
      name: 'Educational TC Video',
      type: 'VIDEO',
      data: {
        src: 'chatbot.educationalTC.video',
        delay: 3500,
        usePlayer: VideoPlayerType.Wistia,
        autoPlay: true,
        startMuted: true,
        isContinueControlledByPlayerEvent: true,
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Enter Personal History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.ENTER_PERSONAL_HISTORY_NODE,
      },
    },
  ],
};

export default introWithTermsNode;
