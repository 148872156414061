import { CompoundNode, findNodeById } from '../../components/survey-node/helpers';
import { NodeWorkflowLocationState, NodeWorkflowState, Node } from '@myriadgenetics/mgh-types';
import Logger from '../logger';
import { useHistory, useLocation } from '../routing';
import { SUB_URL } from '../../components/survey-node/chatbot-workflow/constants';
import { useCallback } from 'react';

interface NodeWorkflowGoToNextNodeProps {
  gotoNextNode: any;
  initNode: any;
  answerKey?: string;
  isSubWorkflow?: boolean;
  onBeforePushNodeIdToHistory?: () => void;
  onAfterPushNodeIdToHistory?: (nextNode: Node) => void;
  onBeforeNodeWorkflowGoToNextNodeNoIdInNodeList?: () => void;
  beforeExitSubNodeWorkflowState?: () => void;
  nodes: CompoundNode[];
}

export const useNodeWorkflowGoToNextNode = (options: NodeWorkflowGoToNextNodeProps) => {
  const {
    gotoNextNode,
    initNode,
    answerKey,
    isSubWorkflow,
    beforeExitSubNodeWorkflowState,
    onBeforePushNodeIdToHistory,
    onAfterPushNodeIdToHistory,
    onBeforeNodeWorkflowGoToNextNodeNoIdInNodeList,
    nodes,
  } = options;
  const history = useHistory();
  const location = useLocation() as NodeWorkflowLocationState;
  const getWorkflowName = useCallback(
    () => (isSubWorkflow ? `subNodeWorkflowState${answerKey ? '-' + answerKey : ''}` : 'nodeWorkFlowState'),
    [isSubWorkflow, answerKey],
  );
  const getWorkflowState = () => ((location.state || {})[getWorkflowName()] as NodeWorkflowState) || {};
  const { curNodeId = initNode?.id || nodes[0].id, baseUrl = `${location.pathname}/${SUB_URL}` } = getWorkflowState();

  const exitSubNodeWorkflowState = () => {
    beforeExitSubNodeWorkflowState && beforeExitSubNodeWorkflowState();
    location.state[getWorkflowName()] = {
      ...(location.state[getWorkflowName()] as NodeWorkflowState),
      curNodeId: undefined,
    };
  };

  const nodeWorkflowGoToNextNodeNoId = () => {
    if (isSubWorkflow) {
      exitSubNodeWorkflowState();
    } else {
      location.state[getWorkflowName()] = {};
    }
  };

  const pushNodeIdToHistory = (id: number | null, state: NodeWorkflowState, isReplace?: boolean | null, pathname?: string) => {
    if (pathname || id) {
      const url = pathname || `${baseUrl}/${id}`;
      const method = isReplace ? history.replace : history.push;
      method(url, {
        ...location.state,
        [getWorkflowName()]: {
          ...state,
          curNodeId: id,
        },
      });
    }
  };

  const nodeWorkflowGoToNextNodeNoIdInNodeList = (id: number) => {
    isSubWorkflow ? exitSubNodeWorkflowState() : Logger.logError(`node: ${id} does not exist`);
  };

  const nodeWorkflowGoToNextNode = (id: number | null | undefined, isReplace: boolean | null | undefined) => {
    if (id) {
      if (history.goForwardOnContinue()) {
        // TODO: (SIMON) need to add testing for these lines
        history.go(1);
      } else {
        const nextNode = findNodeById(id, nodes);
        onBeforePushNodeIdToHistory && onBeforePushNodeIdToHistory();
        if (nextNode) {
          pushNodeIdToHistory(id, getWorkflowState(), isReplace);
          onAfterPushNodeIdToHistory && onAfterPushNodeIdToHistory(nextNode as Node);
        } else {
          onBeforeNodeWorkflowGoToNextNodeNoIdInNodeList && onBeforeNodeWorkflowGoToNextNodeNoIdInNodeList();
          nodeWorkflowGoToNextNodeNoIdInNodeList(id);
        }
      }
    } else {
      nodeWorkflowGoToNextNodeNoId();
      gotoNextNode();
    }
  };

  return {
    nodeWorkflowGoToNextNode,
    getWorkflowState,
    curNodeId,
    baseUrl,
    getWorkflowName,
    pushNodeIdToHistory,
  };
};
