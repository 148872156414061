import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import Loading from './components/loading';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import Config from './helpers/config';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json';

import './themes/default.css';
import './index.scss';

Config.enableSentry() &&
  Sentry.init({
    dsn: 'https://be3d5db11cb940ceb303c28d62484a86@o359578.ingest.sentry.io/5290712',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });

Config.enableDataDog() &&
  datadogRum.init({
    applicationId: '6bd5257b-7394-4b6b-a9ad-9ee03f3ac1d7',
    clientToken: 'pub02980e0adbbfcd5c37fc695d3ad2c18e',
    site: 'datadoghq.com',
    service: 'mygenehistory',
    env: window.mghConfig?.env || 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

const random = Math.floor(Math.random() * 100000000);
const session = `${new Date().getTime()}-${random}`;

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <App session={session} />
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
