import { NodeTypes, ComponentSizes, TextQuestionType, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const sexStep = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.SEX_NODE,
  name: 'Ancestry Select',
  description: 'Select ancestries',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    continueKey: 'chatbot.modal.next',
    pageTitleKey: 'pageTitles.personalInfoDetailsNode',
  },
  components: [
    {
      id: -11,
      type: 'OPTION_SELECT',
      name: 'Chatbot Sex at Birth',
      data: {
        questionKey: 'chatbot.personalInformation.sexLabel',
        answerKey: 'patient.sex',
        isSingleSelect: true,
        optionSize: 'x-small',
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'personDemographic.female',
          },
          {
            optionKey: 2,
            optionValue: 'personDemographic.male',
          },
        ],
        labelKey: '',
        required: true,
        placeholderKey: '',
        subtitleKey: '',
      },
    },
    {
      id: -12,
      description: 'Built in component',
      name: 'Patient DOB',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.dob`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Dob,
        labelKey: 'chatbot.personalInformation.dobLabel',
        placeholderKey: '',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
  ],
  flows: [
    {
      id: 4,
      type: 'UNCONDITIONAL',
      name: 'Ancestry to cancer landing',
      description: 'Ancestry page to cancer landing page',
      data: {
        nextPageId: NODE_IDS.ANCESTRY_NODE,
      },
    },
  ],
});
