import { BreastCancerRiskType, ChatModalNodeOptions, SubtitleType } from '@myriadgenetics/mgh-types';
import { mrnInfoStep } from './mrnInfo.step';
import { ancestryStep } from './ancestry.step';
import { sexStep } from './sex.step';
import { infoStep } from './info.step';
import { addressStep } from './address.step';
import { cancerHistoryStep } from './cancerHistory.step';
import { breastCancerRiskStep } from './breastCancerRisk.step';
import { colonPolypYesNo } from './colonPolypYesNo.step';
import { colonPolypAge } from './colonPolypAge.step';
import { colonPolypCount } from './colonPolypCount.step';
import { criteriaCheckNode } from './criteriaEndpointNode';
import { bloodTransfusionYesNoNode } from './bloodTransfusionYesNo.step';
import { bloodTransfusionRecentNode } from './bloodTransfusionRecent.step';
import { bloodTransfusionTypeNode } from './bloodTransfusionType.step';
import { boneMarrowYesNoNode } from './boneMarrowYesNoStep.step';
import { BreastCancerRisk } from '../../breast-cancer-risk-node/nodes';
import { hideResultsStep } from './hideResults.step';
import { providerSelectionStep } from './providerSelection.step';
import { isSimonMedUrl } from '../../../../helpers/url';

export const mrnInfoFlow = (stepOptions: ChatModalNodeOptions) => [mrnInfoStep(stepOptions)];

export const personalInfoFlow = (stepOptions: ChatModalNodeOptions) => [
  providerSelectionStep(stepOptions),
  infoStep(stepOptions),
  addressStep(stepOptions),
  sexStep(stepOptions),
  ancestryStep({
    ...stepOptions,
    baseKey: '',
  }),
];

export const cancerHistoryFlow = (stepOptions: ChatModalNodeOptions) => [cancerHistoryStep(stepOptions)];

export const breastCancerRiskFlow = (stepOptions: ChatModalNodeOptions) => [breastCancerRiskStep(stepOptions)];

export const medicalHistoryFlow = (stepOptions: ChatModalNodeOptions) => {
  const { continueType, subtitle } = stepOptions;

  /*
    ! [SimonMed]
    The breast density node is currently useless. It is used only by SimonMed, even though it is not processed.
    In the future, we need to find a better solution for managing these nodes in the Medical History Flow.
    For now, the simplest solution to hide/show the breast density node is to identify whether it is a SimonMed URL or not.
  */
  const isBreastDensityVisible = isSimonMedUrl();

  return [
    colonPolypYesNo({
      ...stepOptions,
      baseKey: 'colonPolyp',
    }),
    colonPolypAge({
      ...stepOptions,
      baseKey: 'colonPolyp',
    }),
    colonPolypCount({
      ...stepOptions,
      baseKey: 'colonPolyp',
    }),
    criteriaCheckNode,
    bloodTransfusionYesNoNode({
      ...stepOptions,
      baseKey: 'bloodTransfusion',
    }),
    bloodTransfusionRecentNode({
      ...stepOptions,
      baseKey: 'bloodTransfusion',
    }),
    bloodTransfusionTypeNode({
      ...stepOptions,
      baseKey: 'bloodTransfusion',
    }),
    boneMarrowYesNoNode({
      ...stepOptions,
      baseKey: 'boneMarrowTransplantHistory',
    }),
    ...BreastCancerRisk.steps('breastCancerRisk', continueType, subtitle as SubtitleType, BreastCancerRiskType.TcScore, isBreastDensityVisible),
  ];
};

export const hideResultsFlow = (stepOptions: ChatModalNodeOptions) => [hideResultsStep(stepOptions)];
