import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

const translationBasePath = 'chatbot.assessmentResults';

export const assessmentCompleteNode: ChatbotNode = {
  id: NODE_IDS.ASSESSMENT_COMPLETE_NODE,
  name: 'Assessment Complete',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.assessmentComplete',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Complete',
      data: {
        contentKey: `${translationBasePath}.assessmentIsComplete`,
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Estimates Your Risk',
      data: {
        contentKey: `${translationBasePath}.thisEstimatesYourRisk`,
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 6000,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Ready to Review',
      data: {
        contentKey: `${translationBasePath}.questionReadyToView`,
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      description: 'Previously Tested',
      name: 'Previously Tested',
      type: 'OPTION_SELECT',
      data: {
        answerKey: 'readyToViewResults',
        questionKey: '',
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
        required: true,
        isSingleSelect: true,
        optionSize: ComponentSizes.Medium,
        delay: 1200,
        position: 'right',
        selectionOptions: [
          // TODO: property "optionDisplayKey" exists here but it is not implemented on survey admin.
          {
            optionKey: 1,
            optionValue: `${translationBasePath}.responseNotReadyToView`,
          },
          {
            optionKey: 2,
            optionValue: `${translationBasePath}.responseReadyToView`,
          },
        ],
      },
    },
  ],
  flows: [
    {
      id: -1,
      type: 'UNCONDITIONAL',
      name: 'To Survey Entry Save',
      description: '',
      data: {
        nextPageId: NODE_IDS.POST_SURVEY_ENTRY_NODE,
      },
    },
  ],
};

export default assessmentCompleteNode;
