import { Component } from '@myriadgenetics/mgh-types';

import {
  Introductory,
  TextContent,
  OtherDiagnoses,
  TextList,
  HelpHint,
  HelpHints,
  Video,
  CriteriaDetails,
  Image,
  Cards,
  Banner,
  Accordion,
  Grid,
} from '../../components/informational';
import { OptionSelect, PersonDemographic, PersonInsurance, TextQuestion, YesNo, ImageUpload, CheckboxComponent, RadioGroup } from '../../components/question';
import Continue from '../../elements/continue';
import ResultsButton from '../../elements/results-button';

const componentMap = {
  // informational
  INTRODUCTORY: Introductory,
  TEXT_CONTENT: TextContent,
  OTHER_DIAGNOSES: OtherDiagnoses,
  TEXT_LIST: TextList,
  HELP_HINT: HelpHint,
  HELP_HINTS: HelpHints,
  VIDEO: Video,
  CRITERIA_DETAILS: CriteriaDetails,
  IMAGE: Image,
  BANNER_SECTION: Banner,
  ACCORDION_SECTION: Accordion,
  GRID: Grid,
  CARDS_SECTION: Cards,
  // question
  OPTION_SELECT: OptionSelect,
  PERSON_DEMOGRAPHIC: PersonDemographic,
  IMAGE_UPLOAD: ImageUpload,
  PERSON_INSURANCE: PersonInsurance,
  TEXT_QUESTION: TextQuestion,
  YES_NO: YesNo,
  // other
  CONTINUE: Continue,
  BUTTON: ResultsButton,
  CHECKBOX: CheckboxComponent,
  RADIO_GROUP: RadioGroup,
};

// TODO remove once telemed 40 stuff is merged
export const typeToComponent = (component: Component) => (componentMap as any)[component.type];
