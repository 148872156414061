import { YesNoComponent, Relative } from '@myriadgenetics/mgh-types';

export const anyOtherCancer = (relative: Relative): YesNoComponent => ({
  type: 'YES_NO',
  id: -3,
  name: 'Any Other Cancer',
  description: 'Built in question.',
  data: {
    answerKey: 'anyOtherCancer',
    questionKey: relative === 'relatives.self' ? 'cancerHistory.myselfAnyOtherCancer' : 'cancerHistory.anyOtherCancer',
    questionKeyParams: relative === 'relatives.self' ? undefined : { relative },
    required: true,
  },
});

export const anyoneElse: YesNoComponent = {
  type: 'YES_NO',
  id: -3,
  name: 'Anyone Else',
  description: 'Built in question.',
  data: {
    answerKey: 'anyoneElse',
    questionKey: 'cancerHistory.anyoneElse',
    required: true,
  },
};

export const error = {
  type: 'TEXT_CONTENT',
  name: 'error',
  description: 'error',
  id: -1,
  data: { contentKey: 'cancerHistory.error' },
};

export default { anyOtherCancer, anyoneElse, error };
