import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import { ButtonComponent } from '@myriadgenetics/mgh-types';

type Props = {
  compDef: ButtonComponent;
};

/**
 * widow.close is only supposed to work on tabs opened by a script
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/close for more info
 * the following function is a bit of trick to open new window on itself and immediately close it
 */
const closeWindow = () => {
  try {
    window.open('about:blank', '_self');
    window.close();
  } catch (e) {
    console.log(e);
  }
};

function ResultsButton({ compDef }: Props) {
  const { t } = useTranslation();
  const closeButton: ButtonComponent = compDef as ButtonComponent;
  const { buttonKey, buttonModifier } = closeButton.data;
  const stylesModifier = buttonModifier ? `page-node__highlight--${buttonModifier}` : '';

  return (
    <Button className={`page-node__action page-node__action--start-over ${stylesModifier}`} onClick={closeWindow}>
      {t(buttonKey)}
    </Button>
  );
}

export default ResultsButton;
