import { ContinueType, NodeTypes, ChatModalNodeType, ChatModalNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const breastCancerRiskNode: ChatModalNode = {
  id: NODE_IDS.BREAST_CANCER_RISK_NODE,
  type: NodeTypes.ChatModalNode,
  name: 'Chat Personal Info Node',
  description: 'Chat Personal Info Node',
  data: {
    answerKey: 'breastCancerRisk',
    continueType: ContinueType.AutoFixed,
    subtitle: 'chatbot.breastCancerRisk.subtitle',
    flowType: ChatModalNodeType.BreastCancerRisk,
    pageTitleKey: 'pageTitles.chatbot.breastCancerNode',
  },
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Personal History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.ASSESSMENT_COMPLETE_NODE,
      },
    },
  ],
};

export default breastCancerRiskNode;
