import { NodeTypes, ChatModalNodeOptions, DetailsType } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const cancerHistoryStep = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.CANCER_HISTORY_NODE,
  type: NodeTypes.CancerHistory,
  name: 'Cancer History Node.',
  description: 'Cancer History Node description',
  data: {
    continueType,
    answerKey: baseKey,
    detailsType: DetailsType.Self,
    selfSexKey: 'patient.sex[0].optionValue',
    subtitle,
    pageTitleKey: 'pageTitles.cancerHistoryNode',
  },
  flows: [],
});
