const MAX_YEAR = 120;

export const getYearsAgo = (d: Date = new Date(), yearsAgo: number = MAX_YEAR): string => `01/01/${d.getFullYear() - MAX_YEAR}`;

export const getSimpleFormat = (d: Date = new Date()) =>
  `${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getDate().toString().padStart(2, '0')}/${d.getFullYear()}`;

export default {
  getYearsAgo,
  getSimpleFormat,
};
