import { Trans } from 'react-i18next';
import { Hint, HelpHintComponent } from '@myriadgenetics/mgh-types';
import { Text1, Text2 } from '../../../elements/typography';
import './index.scss';

interface Props {
  compDef: any | HelpHintComponent;
}

function HelpHint({ compDef }: Props) {
  const { helpHint, hintSeparator }: { helpHint: Hint; hintSeparator: string } = compDef.data;
  const HINT_SEPARATOR = helpHint.termKey === '' ? '' : hintSeparator || ':';
  return (
    <p className="help-hint">
      {helpHint.definitionKey ? (
        <>
          <Text2 bold="true" className="help-hint__term" tag="dfn">
            <Trans i18nKey={helpHint.termKey} />
          </Text2>
          {HINT_SEPARATOR.length !== 0 && (
            <Text2 className="help-hint__separator" tag="span">
              {HINT_SEPARATOR}&nbsp;
            </Text2>
          )}
        </>
      ) : (
        <>
          <Text1 bold="true" className="help-hint__term" tag="dfn">
            <Trans i18nKey={helpHint.termKey} />
          </Text1>
        </>
      )}
      {helpHint.definitionKey && (
        <Text2 className="help-hint__definition" tag="span">
          <Trans i18nKey={helpHint.definitionKey} />
        </Text2>
      )}
    </p>
  );
}

export default HelpHint;
