import TextLogo from '../../../elements/text-logo';

interface props {
  responses: any;
}

const PatientDemographicDisplay = ({ responses }: props): JSX.Element | null => {
  const { patient } = responses || {};
  const { firstName, lastName, dob } = patient || {};
  const hasPatientDemographicData = firstName && lastName && dob;
  let dateProperties = null;
  if (hasPatientDemographicData) {
    const [year, month, day] = dob.split('-');
    const isValid = !!year && !!month && !!day;
    dateProperties = isValid
      ? {
          year,
          month,
          day,
        }
      : null;
  }
  return hasPatientDemographicData ? (
    <div className="survey-container__patient-demographics">
      <TextLogo name={`${firstName} ${lastName}`} />
      {dateProperties && <TextLogo name={`${dateProperties.month}/${dateProperties.day}/${dateProperties.year}`} />}
    </div>
  ) : null;
};

export default PatientDemographicDisplay;
