import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { GridComponent, GridItemProps } from '@myriadgenetics/mgh-types';
import { H2 } from '../../../elements/typography';
import GridItem from './components/gridItem';

export interface Props {
  compDef: any | GridComponent;
}

const Grid: React.FC<Props> = ({ compDef }) => {
  const { titleKey, gridItems, gridColumns } = compDef.data;
  const { t } = useTranslation();
  const componentNameClass = compDef.name.split(' ').join('').toLowerCase();

  return (
    <div className={`grid ${componentNameClass}`}>
      {titleKey && <H2 className="grid__title">{t(titleKey)}</H2>}
      <ul className={`grid__items columns-${gridColumns}`}>
        {gridItems?.map((item: GridItemProps, index: number) => (
          <GridItem {...item} key={item.contentKey + index} />
        ))}
      </ul>
    </div>
  );
};

export default Grid;
