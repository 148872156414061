import get from 'lodash.get';
import {
  ComponentSizes,
  ContinueType,
  DetailsType,
  FieldOptionType,
  HttpMethod,
  NodeTypes,
  Survey,
  TextContentHighlightMod,
  TextContentType,
  GridItemType,
  Theme,
} from '@myriadgenetics/mgh-types';
import ChatbotSteps from '../components/survey-node/chatbot-container/steps';
import { getAncestryOptions } from '../helpers/components';

export const defaultByUrl = [
  {
    id: 823,
    surveyId: 1,
    externalSource: 'MGL_ACCOUNT',
    externalSourceId: '82370',
    clinicName: 'Clinic name',
    providerName: 'Test Provider',
    configurations: [
      {
        id: 701,
        configurationKey: 'mghUrl',
        configurationValue: 'demo',
        createdDate: '2020-09-09T11:11:17.732403-06:00',
        createdBy: 'dbhatt',
      },
      {
        id: 697,
        configurationKey: 'mghUrl',
        configurationValue: 'accountmigrationsuccess',
        createdDate: '2020-09-09T11:11:12.823913-06:00',
        createdBy: 'astrunk',
      },
      {
        id: 701,
        configurationKey: 'clinicLogo',
        configurationValue: 'https://davpzco6k33zaqxrn32z9ki5-wpengine.netdna-ssl.com/wp-content/uploads/2021/08/Hereditary-Cancer-Testing-480x480.png',
        createdDate: '2020-09-09T11:11:17.732403-06:00',
        createdBy: 'dbhatt',
      },
      {
        id: 701,
        configurationKey: 'assessmentTheme',
        configurationValue: ':root { --black: #444;}',
        createdDate: '2020-09-09T11:11:17.732403-06:00',
        createdBy: 'dbhatt',
      },
    ],
  },
  {
    id: 824,
    surveyId: 1,
    externalSource: 'MGL_ACCOUNT',
    externalSourceId: '82370',
    clinicName: 'SimonMed',
    providerName: 'Dr. Simon',
    configurations: [
      {
        id: 701,
        configurationKey: 'mghUrl',
        configurationValue: 'cb-test',
        createdDate: '2020-09-09T11:11:17.732403-06:00',
        createdBy: 'dbhatt',
      },
      {
        id: 701,
        configurationKey: 'clinicLogo',
        configurationValue: 'https://myriad-media-test.s3.amazonaws.com/mgh-clinic-logos/logo-25491.png',
        createdDate: '2020-09-09T11:11:17.732403-06:00',
        createdBy: 'dariia',
      },
    ],
  },
];

const criteriaResultsNotMet = {
  criteriaList: [
    {
      criteria: {
        criteria_code: 'mghBracNccnHbocCriteria2019',
        test_family: 'MGH Test Family',
        description: 'NCCN',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghExtendedCriteria',
        test_family: 'MGH Test Family',
        description: 'Miscellaneous',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghNccnLynchCriteria2017',
        test_family: 'MGH Test Family',
        description: 'NCCN Lynch',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghASBS2019Criteria',
        test_family: 'MGH Test Family',
        description: 'ASBS',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghUspstfCriteria',
        test_family: 'MGH Test Family',
        description: 'USPSTF',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghNccnFAP2018Criteria',
        test_family: 'MGH Test Family',
        description: 'NCCN FAP',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
  ],
  criteriaMetList: [],
  criteriaNotMetList: ['NCCN', 'Miscellaneous', 'NCCN Lynch', 'ASBS', 'USPSTF', 'NCCN FAP'],
};

const criteriaResultsMet = {
  criteriaList: [
    {
      criteria: {
        criteria_code: 'mghBracNccnHbocCriteria2019',
        test_family: 'MGH Test Family',
        description: 'NCCN',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghExtendedCriteria',
        test_family: 'MGH Test Family',
        description: 'Miscellaneous',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghNccnLynchCriteria2017',
        test_family: 'MGH Test Family',
        description: 'NCCN Lynch',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghASBS2019Criteria',
        test_family: 'MGH Test Family',
        description: 'ASBS',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghUspstfCriteria',
        test_family: 'MGH Test Family',
        description: 'USPSTF',
      },
      criteria_check_result: 'MEETS',
      coverage_reasons: [],
      coverage: [],
    },
    {
      criteria: {
        criteria_code: 'mghNccnFAP2018Criteria',
        test_family: 'MGH Test Family',
        description: 'NCCN FAP',
      },
      criteria_check_result: 'NOT_MET',
      coverage_reasons: [],
      coverage: [],
    },
  ],
  criteriaMetList: ['USPSTF'],
  criteriaNotMetList: ['NCCN', 'Miscellaneous', 'NCCN Lynch', 'ASBS', 'NCCN FAP'],
};

export const surveyEntryAccountIdEndpointCallNode = {
  id: 85,
  name: 'Get Survey Entry Account from query',
  description: 'Uses surveyAccountId query param to add surveyEntryAccount. Used in DTC Complete.',
  type: NodeTypes.EndpointCall,
  data: {
    endpointPath: '/patient-survey-accounts',
    answerKey: 'surveyEntryAccounts',
    errorKey: 'endpoint.surveyEntryAccountIdError',
    endpointHttpMethod: HttpMethod.Get,
    continueType: ContinueType.AutoFixed,
  },
  flows: [
    {
      id: 2580,
      type: 'UNCONDITIONAL',
      name: 'To DTC Welcome Page',
      description: 'Navigate to DTC Welcome Page',
      data: {
        nextPageId: 1023,
      },
    },
  ],
};

const saveResp = {
  surveyEntryId: 731,
  surveyId: 1,
  updateDate: '2020-09-11T14:43:15.263-0600',
  createDate: '2020-09-11T14:43:15.263-0600',
  surveyStartTime: '2020-09-23T11:08:10.582-0600',
  surveyEndTime: '2020-09-23T11:09:15.008-0600',
  externalSource: 'MGL_ACCOUNT',
  externalSourceId: '82370',
  responses: {
    patient: {
      firstName: 'Check',
      lastName: 'Clinic',
      dob: '1956-01-01',
      age: 64,
      phoneType: 'mobile',
      phone: '222-342-3423',
      address: 'Should have polyp meets',
      city: 'That is all',
      state: [{ optionKey: 2, optionValue: 'personDemographic.states.alaska' }],
      postalCode: '44444',
      sex: [{ optionKey: 2, optionValue: 'personDemographic.male' }],
    },
    ancestry: [{ optionKey: 1, optionValue: 'ancestryList.ashkenazi' }],
    qualifyingHistory: true,
    cancerHistory: {
      relatives: {
        self: [
          {
            pathology: [
              {
                type: 'cancerHistory.cancers.other',
                other: [
                  {
                    optionKey: 30,
                    optionValue: 'pathology.otherCancers.ureter',
                  },
                ],
                age: '5',
              },
            ],
          },
        ],
      },
    },
    personalBreastCancerHistory: false,
    colonPolyp: {
      hasHistory: true,
      firstPolypAge: '6',
      polypRange: [{ optionKey: 5, optionValue: 'colonPolyp.polypRanges.20to99' }],
    },
    url: 'demo',
  },
  languageUsed: 'en-US',
  sessionId: null,
  rawCriteria:
    '[{"criteria":{"criteria_code":"mghUspstfCriteria","test_family":"MGH Test Family","description":"USPSTF"},"criteria_check_result":"NOT_MET","coverage_reasons":[],"coverage":[]},{"criteria":{"criteria_code":"mghNccnLynchCriteria2017","test_family":"MGH Test Family","description":"NCCN Lynch"},"criteria_check_result":"NOT_MET","coverage_reasons":[],"coverage":[]},{"criteria":{"criteria_code":"mghNccnFAP2018Criteria","test_family":"MGH Test Family","description":"NCCN FAP"},"criteria_check_result":"MEETS","coverage_reasons":["Personal diagnosis with greater than or equal to 20 polyps"],"coverage":[{"key":"","reason":"Personal diagnosis with greater than or equal to 20 polyps"}]},{"criteria":{"criteria_code":"mghBracNccnHbocCriteria2019","test_family":"MGH Test Family","description":"NCCN"},"criteria_check_result":"NOT_MET","coverage_reasons":[],"coverage":[]},{"criteria":{"criteria_code":"mghASBS2019Criteria","test_family":"MGH Test Family","description":"ASBS"},"criteria_check_result":"NOT_MET","coverage_reasons":[],"coverage":[]},{"criteria":{"criteria_code":"mghExtendedCriteria","test_family":"MGH Test Family","description":"Miscellaneous"},"criteria_check_result":"NOT_MET","coverage_reasons":[],"coverage":[]}]',
  criteriaMetList: '["NCCN FAP"]',
  criteriaNotMetList: '["USPSTF","NCCN Lynch","NCCN","ASBS","Miscellaneous"]',
};

const defaultSurvey = {
  id: 1,
  name: 'Default Survey',
  description: 'Hereditary Cancer Survey',
  startNode: {
    id: 14,
    name: 'Basic Info Pause',
    description: 'Basic Information Pause',
    type: NodeTypes.ContextBreak,
    data: {
      messageKey: 'pausePages.basicInformation',
    },

    flows: [
      {
        id: 1,
        type: 'UNCONDITIONAL',
        name: 'Basic Info to Demographic form',
        description: 'basic information page to person demographic info page',
        data: {
          nextPageId: 3,
        },
      },
    ],
  },
  endNode: {
    id: 200,
    name: 'Results Page',
    description: 'Processing results page',
    type: NodeTypes.ContextBreak,
    data: {
      messageKey: 'Processing results!',
    },

    flows: [
      {
        id: 24,
        type: 'CONDITIONAL',
        name: 'Save meets',
        description: 'Save survey to meets results',
        data: {
          nextPageId: 17,
          conditions: [
            {
              comparator: '==',
              answerKey: 'surveyEntrySave.criteriaMetList',
              value: '[]',
            },
          ],
        },
      },
      {
        id: 25,
        type: 'UNCONDITIONAL',
        name: 'Save not meets',
        description: 'Save survey to not meets results',
        data: {
          nextPageId: 18,
        },
      },
    ],
  },
  createdDate: '2020-09-22T09:59:20-06:00',
  createdBy: 'jstarr',
  nodes: [
    {
      id: 3,
      name: 'Person demographics',
      description: 'Person demographic form',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 3,
          type: 'PERSON_DEMOGRAPHIC',
          name: 'Person demographics',
          description: 'Person Demographic Form',
          data: {
            questionKey: 'personDemographic.personalInformation',
            answerKey: 'patient',
            fields: {
              firstName: FieldOptionType.REQUIRED,
              lastName: FieldOptionType.REQUIRED,
              dob: FieldOptionType.REQUIRED,
            },
            required: true,
          },
        },
        {
          id: 4,
          type: 'TEXT_CONTENT',
          name: 'Contact Message',
          description: 'Message for contact info',
          data: {
            contentKey: 'personDemographic.contactMessage',
            type: TextContentType.Text2,
          },
        },
      ],
      flows: [
        {
          id: 3,
          type: 'UNCONDITIONAL',
          name: 'Person Demographic flow',
          description: 'Person demographic flow',
          data: {
            nextPageId: 15,
          },
        },
      ],
    },
    {
      id: 4,
      name: 'Ancestry Select',
      description: 'Select ancestries',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.Fixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'Ancestry Selection',
          description: 'Ancestry selection',
          data: {
            questionKey: 'ancestry.question',
            answerKey: 'ancestry',
            isSingleSelect: false,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: getAncestryOptions(),
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 4,
          type: 'UNCONDITIONAL',
          name: 'Ancestry to cancer landing',
          description: 'Ancestry page to cancer landing page',
          data: {
            nextPageId: 11,
          },
        },
      ],
    },
    {
      id: 5,
      name: 'Qualifying Question',
      description: 'Qualifying Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'Cancer List',
          description: 'Cancer list for qualifying question',
          data: {
            contentKey: 'qualifyingQuestion.question',
            listKeys: [
              'qualifyingQuestion.qualifyingPathologies.breast',
              'qualifyingQuestion.qualifyingPathologies.colon',
              'qualifyingQuestion.qualifyingPathologies.dcis',
              'qualifyingQuestion.qualifyingPathologies.endometrial',
              'qualifyingQuestion.qualifyingPathologies.fallopian',
              'qualifyingQuestion.qualifyingPathologies.gastric',
              'qualifyingQuestion.qualifyingPathologies.melanoma',
              'qualifyingQuestion.qualifyingPathologies.ovarian',
              'qualifyingQuestion.qualifyingPathologies.pancreatic',
              'qualifyingQuestion.qualifyingPathologies.peritoneal',
              'qualifyingQuestion.qualifyingPathologies.prostate',
            ],
          },
        },
        {
          id: 5,
          type: 'YES_NO',
          name: 'Qualifying Question',
          description: 'Qualifying question to determine if patient immediately does not meet criteria',
          data: {
            questionKey: '',
            answerKey: 'qualifyingHistory',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'Qualifying question flow',
          description: 'Qualifying question flow',
          data: {
            answerKey: 'qualifyingHistory',
            affirmativePageId: 6,
            negativePageId: 12,
          },
        },
      ],
    },
    {
      id: 6,
      name: 'Cancer history',
      description: 'Cancer history selection',
      type: NodeTypes.CancerHistory,
      data: {
        answerKey: 'cancerHistory',
        selfSexKey: 'patient.sex[0].optionValue',
        continueType: ContinueType.AutoFixed,
        detailsType: DetailsType.Both,
        subtitle: 'subtitles.personalFamilyHistory',
        selectionOptions: [],
      },

      flows: [
        {
          id: 6,
          type: 'UNCONDITIONAL',
          name: 'Cancer history selection',
          description: 'Cancer history selection',
          data: {
            nextPageId: 12,
          },
        },
      ],
    },
    {
      id: 7,
      name: 'Blood Transfusion',
      description: 'Blood transfusion history',
      type: NodeTypes.BloodTransfusion,
      data: {
        answerKey: 'bloodTransfusion',
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.additionalInformation',
      },

      flows: [
        {
          id: 7,
          type: 'UNCONDITIONAL',
          name: 'Blood Transfusion Flow',
          description: 'Blood Transfusion Flow',
          data: {
            nextPageId: 8,
          },
        },
      ],
    },
    {
      id: 8,
      name: 'Bone Marrow',
      description: 'Bone Marrow Transplant History',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.additionalInformation',
      },
      components: [
        {
          id: 8,
          type: 'YES_NO',
          name: 'Bone Marrow Transplant',
          description: 'Bone Marrow Transplant History',
          data: {
            questionKey: 'boneMarrowTransplantHistory',
            answerKey: 'boneMarrowTransplantHistory',
            required: true,
          },
        },
        {
          id: 22,
          type: 'HELP_HINT',
          name: 'BMT help',
          description: 'Bone Marrow Transplant Help Hint',
          data: {
            helpHint: {
              termKey: 'helpHints.boneMarrowTransplant',
              definitionKey: 'helpHints.boneMarrowTransplantHint',
            },
          },
        },
      ],
      flows: [
        {
          id: 8,
          type: 'CONDITIONAL',
          name: 'Bone Marrow Transplant History',
          description: 'Bone Marrow Transplant History',
          data: {
            nextPageId: 9,
            conditions: [
              {
                comparator: '==',
                answerKey: 'patient.sex.0.optionValue',
                value: 'personDemographic.female',
              },
              {
                comparator: '==',
                answerKey: 'personalBreastCancerHistory',
                value: false,
              },
              {
                comparator: '>',
                answerKey: 'patient.age',
                value: 84,
              },
            ],
          },
        },
        {
          id: 23,
          type: 'UNCONDITIONAL',
          name: 'Not tc to end',
          description: 'Not meets tc criteria to review page',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
    {
      id: 9,
      name: 'TC Workflow',
      description: 'Breast cancer risk/TC workflow',
      type: NodeTypes.BreastCancerRisk,
      data: {
        answerKey: 'breastCancerRisk',
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.additionalInformation',
      },

      flows: [
        {
          id: 9,
          type: 'UNCONDITIONAL',
          name: 'TC to Review',
          description: 'Breast cancer risk to review',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
    {
      id: 11,
      name: 'Cancer History Pause',
      description: 'Pause page for cancer history',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'pausePages.cancerLanding',
      },

      flows: [
        {
          id: 11,
          type: 'UNCONDITIONAL',
          name: 'Cancer hx pause to Qualifying',
          description: 'cancer history landing to qualifying question page',
          data: {
            nextPageId: 1001,
          },
        },
      ],
    },
    {
      id: 12,
      name: 'Add. Info Pause',
      description: 'Additional Information Pause Page',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'pausePages.additionalInfo',
      },

      flows: [
        {
          id: 12,
          type: 'UNCONDITIONAL',
          name: 'Add. Info to BT',
          description: 'additional info to blood transfusion',
          data: {
            nextPageId: 16,
          },
        },
      ],
    },
    {
      id: 13,
      name: 'Sex at Birth',
      description: 'Sex at birth selection',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 1,
          type: 'OPTION_SELECT',
          name: 'Sex at birth',
          description: 'Sex at birth selection',
          data: {
            questionKey: 'personDemographic.sex',
            answerKey: 'patient.sex',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 'personDemographic.female',
              },
              {
                optionKey: 2,
                optionValue: 'personDemographic.male',
              },
            ],
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 10,
          type: 'UNCONDITIONAL',
          name: 'Sex to Ancestry',
          description: 'Sex at birth page to ancestry page',
          data: {
            nextPageId: 4,
          },
        },
      ],
    },
    {
      id: 14,
      name: 'Basic Info Pause',
      description: 'Basic Information Pause',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'pausePages.basicInformation',
      },

      flows: [
        {
          id: 1,
          type: 'UNCONDITIONAL',
          name: 'Basic Info to Demographic form',
          description: 'basic information page to person demographic info page',
          data: {
            nextPageId: 3,
          },
        },
      ],
    },
    {
      id: 15,
      name: 'Person contact form',
      description: 'Person contact form',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 2,
          type: 'PERSON_DEMOGRAPHIC',
          name: 'Contact Info',
          description: 'Contact Info Form',
          data: {
            descriptionKey: 'personDemographic.contactInformationDescription',
            questionKey: 'personDemographic.contactInformation',
            answerKey: 'patient',
            fields: {
              phone: FieldOptionType.REQUIRED,
              phoneType: FieldOptionType.REQUIRED,
              email: 'DISPLAYED',
              address: FieldOptionType.REQUIRED,
              city: FieldOptionType.REQUIRED,
              state: FieldOptionType.REQUIRED,
              postalCode: FieldOptionType.REQUIRED,
            },
            required: true,
          },
        },
        {
          id: 4,
          type: 'TEXT_CONTENT',
          name: 'Contact Message',
          description: 'Message for contact info',
          data: {
            contentKey: 'personDemographic.contactMessage',
            type: TextContentType.Text2,
          },
        },
      ],
      flows: [
        {
          id: 2,
          type: 'UNCONDITIONAL',
          name: 'Contact page flow',
          description: 'contact page to sex page',
          data: {
            nextPageId: 13,
          },
        },
      ],
    },
    {
      id: 16,
      name: 'Colon Polyps',
      description: 'Colon Polyp workflow',
      type: NodeTypes.ColonPolyp,
      data: {
        answerKey: 'colonPolyp',
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalFamilyHistory',
      },

      flows: [
        {
          id: 14,
          type: 'UNCONDITIONAL',
          name: 'Polyp to criteria',
          description: 'colon polyp to criteria check',
          data: {
            nextPageId: 20,
          },
        },
      ],
    },
    {
      id: 17,
      name: 'Not Met Results',
      description: 'Not met results page',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
        subtitle: 'subtitles.assessmentResults',
      },
      components: [
        {
          id: 27,
          type: 'TEXT_CONTENT',
          name: 'Not Met Title',
          description: 'Not a candidate for testing',
          data: {
            contentKey: 'results.notMet.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H2,
          },
        },
        {
          id: 10,
          type: 'TEXT_CONTENT',
          name: 'Description Not Met',
          description: 'Description not met guidelines',
          data: {
            contentKey: 'results.notMet.description',
            type: TextContentType.Text1,
          },
        },
        {
          id: 12,
          type: 'TEXT_CONTENT',
          name: 'Important Not Met',
          description: 'Important not met guidelines',
          data: {
            contentKey: 'results.important',
            highlightModifier: TextContentHighlightMod.Alert,
            type: TextContentType.H4,
          },
        },
        {
          id: 11,
          type: 'TEXT_CONTENT',
          name: 'Discuss Not Met',
          description: 'Discuss not met guidelines',
          data: {
            contentKey: 'results.notMet.discuss',
            type: TextContentType.Text1,
          },
        },
        {
          id: 14,
          type: 'TEXT_CONTENT',
          name: 'Direction Not Met',
          description: 'Direction not met guidelines',
          data: {
            contentKey: 'results.direction',
            type: TextContentType.Text1,
          },
        },
        {
          id: 21,
          type: 'OTHER_DIAGNOSES',
          name: 'Other diagnoses',
          description: 'Other diagnoses',
          data: {},
        },
      ],
      flows: [
        {
          id: 13,
          type: 'UNCONDITIONAL',
          name: 'Results flow',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
    {
      id: 18,
      name: 'Met Results',
      description: 'Met results page',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
        subtitle: 'subtitles.assessmentResults',
      },
      components: [
        {
          id: 25,
          type: 'TEXT_CONTENT',
          name: 'Met Title',
          description: 'Candidate for testing',
          data: {
            contentKey: 'results.met.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H2,
          },
        },
        {
          id: 16,
          type: 'TEXT_CONTENT',
          name: 'Description Met',
          description: 'Description met guidelines',
          data: {
            contentKey: 'results.met.description',
            highlightModifier: TextContentHighlightMod.Alert,
            type: TextContentType.Text1,
          },
        },
        {
          id: 17,
          type: 'TEXT_CONTENT',
          name: 'Discuss Met',
          description: 'Discuss met guidelines',
          data: {
            contentKey: 'results.met.discuss',
            highlightModifier: TextContentHighlightMod.Alert,
            type: TextContentType.H4,
          },
        },
        {
          id: 18,
          type: 'TEXT_CONTENT',
          name: 'Learn More Met',
          description: 'Learn more met guidelines',
          data: {
            contentKey: 'results.met.learn',
            type: TextContentType.H4,
          },
        },
        {
          id: 20,
          type: 'VIDEO',
          name: 'Video myRisk Met',
          description: 'Video for met',
          data: {
            src: 'results.met.video',
          },
        },
        {
          id: 26,
          type: 'CRITERIA_DETAILS',
          name: 'Criteria Details',
          description: 'Displays criteria details',
          data: {},
        },
        {
          id: 12,
          type: 'TEXT_CONTENT',
          name: 'Important Not Met',
          description: 'Important not met guidelines',
          data: {
            contentKey: 'results.important',
            highlightModifier: TextContentHighlightMod.Alert,
            type: TextContentType.H4,
          },
        },
        {
          id: 13,
          type: 'TEXT_CONTENT',
          name: 'Caveat Not Met',
          description: 'Caveat not met guidelines',
          data: {
            contentKey: 'results.caveat',
            type: TextContentType.Text1,
          },
        },
        {
          id: 14,
          type: 'TEXT_CONTENT',
          name: 'Direction Not Met',
          description: 'Direction not met guidelines',
          data: {
            contentKey: 'results.direction',
            type: TextContentType.Text1,
          },
        },
        {
          id: 21,
          type: 'OTHER_DIAGNOSES',
          name: 'Other diagnoses',
          description: 'Other diagnoses',
          data: {},
        },
        {
          id: 28,
          type: 'CONTINUE',
          name: 'Continue',
          description: 'Continue Button',
          data: {},
        },
        {
          id: 777,
          type: 'BUTTON',
          name: 'Close Button',
          description: 'Close page button',
          data: {
            buttonKey: 'results.dtc.button',
            buttonType: 'close-page',
            buttonModifier: TextContentHighlightMod.Primary,
          },
        },
        {
          id: 29,
          type: 'TEXT_CONTENT',
          name: 'NCCN Disclaimer Title',
          description: 'NCCN disclaimer title',
          data: {
            contentKey: 'results.met.nccnDisclaimerTitle',
            type: TextContentType.Text2,
          },
        },
        {
          id: 24,
          type: 'TEXT_CONTENT',
          name: 'NCCN Disclaimer',
          description: 'Legal Disclaimer for NCCN',
          data: {
            contentKey: 'results.met.nccnDisclaimer',
            type: TextContentType.Text3,
          },
        },
      ],
      flows: [
        {
          id: 13,
          type: 'UNCONDITIONAL',
          name: 'Results flow',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
    {
      id: 20,
      name: 'Criteria check',
      description: 'Criteria check call',
      type: NodeTypes.EndpointCall,
      data: {
        endpointPath: '/criteria-results',
        errorKey: 'endpoint.criteriaCheckError',
        answerKey: 'criteriaCheck',
        endpointHttpMethod: HttpMethod.Post,
        continueType: ContinueType.AutoFixed,
        titleKey: 'endpoint.saveMessage',
      },

      flows: [
        {
          id: 21,
          type: 'CONDITIONAL',
          name: 'Meets to BT',
          description: 'Meets criteria to blood transfusion',
          data: {
            nextPageId: 7,
            conditions: [
              {
                comparator: '==',
                answerKey: 'meetsCriteria',
                value: true,
              },
            ],
          },
        },
        {
          id: 22,
          type: 'UNCONDITIONAL',
          name: 'Not met to end',
          description: 'Not meets criteria to review page',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
    {
      id: 200,
      name: 'Results Page',
      description: 'Processing results page',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'Processing results!',
      },

      flows: [
        {
          id: 24,
          type: 'CONDITIONAL',
          name: 'Save meets',
          description: 'Save survey to meets results',
          data: {
            nextPageId: 17,
            conditions: [
              {
                comparator: '==',
                answerKey: 'surveyEntrySave.criteriaMetList',
                value: '[]',
              },
            ],
          },
        },
        {
          id: 25,
          type: 'UNCONDITIONAL',
          name: 'Save not meets',
          description: 'Save survey to not meets results',
          data: {
            nextPageId: 18,
          },
        },
      ],
    },
    {
      id: 1000,
      name: 'Known Mutation History',
      description: 'Known Family Mutation History Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalFamilyHistory',
      },
      components: [
        {
          id: 1000,
          type: 'YES_NO',
          name: 'Known Family Mutation History',
          description: 'Known family positive mutation history for hereditary risk question',
          data: {
            questionKey: 'knownFamilyMutation',
            answerKey: 'knownFamilyMutation',
            required: true,
            noKey: 'pathology.noOrUnknown',
          },
        },
      ],
      flows: [
        {
          id: 1001,
          type: 'UNCONDITIONAL',
          name: 'Known mutation to qualifying question',
          description: 'known mutation to qualiyfing question',
          data: {
            nextPageId: 5,
          },
        },
      ],
    },
    {
      id: 1001,
      name: 'Genetic Testing History',
      description: 'Genetic Testing History Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalFamilyHistory',
      },
      components: [
        {
          id: 1001,
          type: 'YES_NO',
          name: 'Genetic Testing History',
          description: 'Genetic Testing History Question',
          data: {
            questionKey: 'hasGeneticTestingHistory',
            answerKey: 'hasGeneticTestingHistory',
            required: true,
            noKey: 'pathology.noOrUnknown',
          },
        },
        {
          id: 5180,
          type: 'HELP_HINT',
          name: 'Genetic Testing Help Hint',
          description: 'Genetic Testing Help Hint',
          data: {
            helpHint: {
              definitionKey: 'helpHints.geneticTestingHint',
              termKey: 'helpHints.geneticTesting',
            },
          },
        },
        {
          id: 5181,
          type: 'HELP_HINT',
          name: 'Hereditary Cancer Risk Help Hint',
          description: 'Help hint for hereditary Cancer Risk',
          data: {
            helpHint: {
              termKey: 'helpHints.hereditaryCancerRisk',
              definitionKey: 'helpHints.hereditaryCancerRiskHint',
            },
          },
        },
        {
          id: 5200,
          type: 'HELP_HINT',
          name: 'Healthcare Provider Hint',
          description: 'Help hint for healthcare providers',
          data: {
            helpHint: {
              termKey: 'helpHints.healthCareProvider',
              definitionKey: 'helpHints.healthCareProviderHint',
            },
          },
        },
      ],
      flows: [
        {
          id: 1000,
          type: 'UNCONDITIONAL',
          name: 'genetic testing to known mutation',
          description: 'genetic testing history to known mutation flow',
          data: {
            nextPageId: 1000,
          },
        },
      ],
    },
  ],
};

// welcome -> gender -> ancestries -> qualifying question -> cancer history -> demographics -> outbound
const dtcWelcome = {
  id: 5014,
  name: 'DTC Welcome',
  description: 'Welcome page for DTC.',
  type: NodeTypes.ContextBreak,
  data: {
    messageKey: 'welcome',
    subMessageKey: 'dtc.welcomeMessage',
    footerKey: 'dtc.welcomeFooter',
  },

  flows: [
    {
      id: 501,
      type: 'UNCONDITIONAL',
      name: 'To Info Page',
      description: 'to sex at birth',
      data: {
        nextPageId: 13,
      },
    },
  ],
};

const dtcNotMetRedirect = {
  id: 5501,
  type: NodeTypes.Redirect,
  name: 'DTC Redirect to Learn More',
  data: {
    url: 'https://myriad.com/genetics-risk/learn-more',
    titleKey: 'redirecting.title',
    queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'campaign_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'],
  },
  flows: [],
};

const dtcMetUnaffectedRedirect = {
  id: 5502,
  type: NodeTypes.Redirect,
  name: 'DTC Redirect to Access',
  data: {
    url: 'https://myriad.com/genetics-risk/myrisk-order',
    titleKey: 'redirecting.title',
    queryParamKeys: [
      'campaign_id',
      'ajs_anonymous_id',
      'campaign_id',
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'gclid',
      'survey_entry_id',
    ],
  },
  flows: [],
};

const dtcMetAffectedRedirect = {
  id: 5503,
  type: NodeTypes.Redirect,
  name: 'DTC Redirect to myWay',
  data: {
    url: 'https://myriad.com/genetics-risk/order-a-kit',
    titleKey: 'redirecting.title',
    queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'campaign_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'],
  },
  flows: [],
};

const dtcEnd = {
  id: 5200,
  name: 'DTC Results Page',
  description: 'DTC results page',
  type: NodeTypes.ContextBreak,
  data: {
    messageKey: 'Processing results!',
  },

  flows: [
    {
      id: 525,
      type: 'UNCONDITIONAL',
      name: 'To DTC Redirect to Access',
      description: 'To DTC Redirect to Access',
      data: {
        nextPageId: 5502,
      },
    },
  ],
};

const dtcSurvey = {
  id: 3,
  name: 'DTC Survey',
  description: 'DTC Hereditary Cancer Survey',
  startNode: dtcWelcome,
  endNode: dtcEnd,
  createdDate: '2021-01-26T09:59:20-06:00',
  createdBy: 'dbhatt',
  nodes: [
    {
      id: 4,
      name: 'Ancestry Select',
      description: 'Select ancestries',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.Fixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'Ancestry Selection',
          description: 'Ancestry selection',
          data: {
            questionKey: 'ancestry.question',
            answerKey: 'ancestry',
            isSingleSelect: false,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: getAncestryOptions(),
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 504,
          type: 'UNCONDITIONAL',
          name: 'To Qualifying Question',
          description: 'To qualifying question',
          data: {
            nextPageId: 5,
          },
        },
      ],
    },
    {
      id: 5,
      name: 'Qualifying Question',
      description: 'Qualifying Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'Cancer List',
          description: 'Cancer list for qualifying question',
          data: {
            contentKey: 'qualifyingQuestion.question',
            listKeys: [
              'qualifyingQuestion.qualifyingPathologies.breast',
              'qualifyingQuestion.qualifyingPathologies.colon',
              'qualifyingQuestion.qualifyingPathologies.dcis',
              'qualifyingQuestion.qualifyingPathologies.endometrial',
              'qualifyingQuestion.qualifyingPathologies.fallopian',
              'qualifyingQuestion.qualifyingPathologies.gastric',
              'qualifyingQuestion.qualifyingPathologies.melanoma',
              'qualifyingQuestion.qualifyingPathologies.ovarian',
              'qualifyingQuestion.qualifyingPathologies.pancreatic',
              'qualifyingQuestion.qualifyingPathologies.peritoneal',
              'qualifyingQuestion.qualifyingPathologies.prostate',
            ],
          },
        },
        {
          id: 5,
          type: 'YES_NO',
          name: 'Qualifying Question',
          description: 'Qualifying question to determine if patient immediately does not meet criteria',
          data: {
            questionKey: '',
            answerKey: 'qualifyingHistory',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 505,
          type: 'BINARY',
          name: 'DTC Qualifying question flow',
          description: 'DTC Qualifying question flow',
          data: {
            answerKey: 'qualifyingHistory',
            affirmativePageId: 6,
            negativePageId: 5501,
          },
        },
      ],
    },
    {
      id: 6,
      name: 'Cancer history',
      description: 'Cancer history selection',
      type: NodeTypes.CancerHistory,
      data: {
        answerKey: 'cancerHistory',
        selfSexKey: 'patient.sex[0].optionValue',
        continueType: ContinueType.AutoFixed,
        detailsType: DetailsType.Both,
        subtitle: 'subtitles.personalFamilyHistory',
        selectionOptions: [],
      },

      flows: [
        {
          id: 6,
          type: 'UNCONDITIONAL',
          name: 'Cancer history selection',
          description: 'Cancer history selection',
          data: {
            nextPageId: 20,
          },
        },
      ],
    },
    {
      id: 13,
      name: 'Sex at Birth',
      description: 'Sex at birth selection',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 1,
          type: 'OPTION_SELECT',
          name: 'Sex at birth',
          description: 'Sex at birth selection',
          data: {
            questionKey: 'personDemographic.sex',
            answerKey: 'patient.sex',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 'personDemographic.female',
              },
              {
                optionKey: 2,
                optionValue: 'personDemographic.male',
              },
            ],
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 10,
          type: 'UNCONDITIONAL',
          name: 'Sex to Ancestry',
          description: 'Sex at birth page to ancestry page',
          data: {
            nextPageId: 4,
          },
        },
      ],
    },
    dtcWelcome,
    dtcNotMetRedirect,
    dtcMetUnaffectedRedirect,
    dtcMetAffectedRedirect,
    {
      id: 20,
      name: 'Criteria check',
      description: 'Criteria check call',
      type: NodeTypes.EndpointCall,
      data: {
        endpointPath: '/criteria-results',
        errorKey: 'endpoint.criteriaCheckError',
        answerKey: 'criteriaCheck',
        endpointHttpMethod: HttpMethod.Post,
        continueType: ContinueType.AutoFixed,
        titleKey: 'endpoint.saveMessage',
      },

      flows: [
        {
          id: 521,
          type: 'CONDITIONAL',
          name: 'Meets Unaffected to Demographic',
          description: 'Meets criteria unaffected to demographic',
          data: {
            nextPageId: 503,
            conditions: [
              { comparator: '==', answerKey: 'meetsCriteria', value: true },
              {
                comparator: 'isEmpty',
                answerKey: 'cancerHistory.relatives.self[0].pathology',
                value: 0,
              },
            ],
          },
        },
        {
          id: 522,
          type: 'CONDITIONAL',
          name: 'Meets Affected to myWay',
          description: 'Meets criteria affected to myWay redirect',
          data: {
            nextPageId: 5503,
            conditions: [{ comparator: '==', answerKey: 'meetsCriteria', value: true }],
          },
        },
        {
          id: 523,
          name: 'Not met to dtc not met',
          description: 'Not meets criteria to dtc not met redirect',
          data: { nextPageId: 5501 },
        },
      ],
    },
    {
      id: 200,
      name: 'Results Page',
      description: 'Processing results page',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'Processing results!',
      },

      flows: [
        {
          id: 24,
          type: 'CONDITIONAL',
          name: 'Save meets',
          description: 'Save survey to meets results',
          data: {
            nextPageId: 17,
            conditions: [
              {
                comparator: '==',
                answerKey: 'surveyEntrySave.criteriaMetList',
                value: '[]',
              },
            ],
          },
        },
        {
          id: 25,
          type: 'UNCONDITIONAL',
          name: 'Save not meets',
          description: 'Save survey to not meets results',
          data: {
            nextPageId: 18,
          },
        },
      ],
    },
  ],
  configuration: null,
};

export const dtc2Survey = {
  id: 4244,
  name: 'DTC Survey Galileo 2 test',
  description: 'This survey asks for patient contact information as the first question.',
  startNode: {
    id: 1023,
    name: 'DTC Welcome',
    description: 'Welcome page for DTC.',
    type: NodeTypes.ContextBreak,
    data: {
      messageKey: 'welcome',
      footerKey: 'dtc.welcomeFooter',
      subMessageKey: 'dtc.welcomeMessage',
      termsAndPrivacyLinks: true,
    },

    flows: [
      {
        id: 2451,
        type: 'UNCONDITIONAL',
        name: 'To DTC Person Demographic Front',
        description: 'Goes to DTC Person Demographic front node unconditionally.',
        data: { nextPageId: 5333 },
      },
    ],
  },
  endNode: {
    id: 1024,
    name: 'DTC Results',
    description: 'Results page for DTC.',
    type: NodeTypes.ContextBreak,
    data: { messageKey: 'Processing results!' },

    flows: [
      {
        id: 2253,
        type: 'CONDITIONAL',
        name: 'Meets Unaffected to Redirect to Access test',
        description: 'Meets Unaffected to Redirect to Access',
        data: {
          nextPageId: 1021,
          conditions: [
            {
              value: true,
              answerKey: 'meetsCriteria',
              comparator: '==',
            },
            {
              value: '0',
              answerKey: 'cancerHistory.relatives.self[0].pathology',
              comparator: 'isEmpty',
            },
          ],
        },
      },
      {
        id: 1023,
        type: 'CONDITIONAL',
        name: 'Meets Affected to Redirect to myWay',
        description: 'Meets Affected to Redirect to myWay.',
        data: {
          nextPageId: 1022,
          conditions: [{ comparator: '==', answerKey: 'meetsCriteria', value: true }],
        },
      },
      {
        id: 1024,
        type: 'UNCONDITIONAL',
        name: 'To DTC Redirect to Learn More',
        description: 'To DTC Redirect to Learn More.',
        data: { nextPageId: 1020 },
      },
    ],
  },
  createdDate: '2021-07-12T10:56:22.889823-06:00',
  createdBy: 'dbhatt',
  nodes: [
    {
      id: 1023,
      name: 'DTC Welcome',
      description: 'Welcome page for DTC.',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'welcome',
        footerKey: 'dtc.welcomeFooter',
        subMessageKey: 'dtc.welcomeMessage',
        termsAndPrivacyLinks: true,
      },

      flows: [
        {
          id: 2451,
          type: 'UNCONDITIONAL',
          name: 'To DTC Person Demographic Front',
          description: 'Goes to DTC Person Demographic front node unconditionally.',
          data: { nextPageId: 5333 },
        },
      ],
    },
    {
      id: 4,
      name: 'Ancestry Select',
      description: 'Select ancestries',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.Fixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'Ancestry Selection',
          description: 'Ancestry selection',
          data: {
            questionKey: 'ancestry.question',
            answerKey: 'ancestry',
            isSingleSelect: false,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: getAncestryOptions(),
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 1001,
          type: 'UNCONDITIONAL',
          name: 'Known mutation to qualifying question',
          description: 'known mutation to qualiyfing question',
          data: { nextPageId: 5 },
        },
      ],
    },
    {
      id: 1020,
      name: 'DTC Redirect to Learn More',
      description: 'DTC redirect to learn more.',
      type: NodeTypes.Redirect,
      data: {
        queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'],
        url: 'https://myriadstg.wpengine.com/genetics-risk/learn-more/',
        titleKey: 'redirecting.title',
      },

      flows: [],
    },
    {
      id: 5333,
      name: 'DTC Person Demographics Front',
      description: 'Person demographics component that comes in the beginning of the assessment.',
      type: NodeTypes.Page,
      data: {
        footerKey: 'dtc.personDemographic.footer',
        continueType: ContinueType.End,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 3249,
          type: 'PERSON_DEMOGRAPHIC',
          name: 'DTC Person Demographics Front',
          description: 'Person demographics component that comes in the beginning of the assessment.',
          data: {
            descriptionKey: 'personDemographic.contactInformationDescription',
            questionKey: 'personDemographic.contactInformation',
            answerKey: 'patient',
            fields: {
              phone: FieldOptionType.REQUIRED,
              phoneType: FieldOptionType.REQUIRED,
              email: 'DISPLAYED',
              address: FieldOptionType.REQUIRED,
              city: FieldOptionType.REQUIRED,
              state: FieldOptionType.REQUIRED,
              postalCode: FieldOptionType.REQUIRED,
            },
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 2,
          type: 'UNCONDITIONAL',
          name: 'Contact page flow',
          description: 'contact page to sex page',
          data: { nextPageId: 13 },
        },
      ],
    },
    {
      id: 13,
      name: 'Sex at Birth',
      description: 'Sex at birth selection',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 1,
          type: 'OPTION_SELECT',
          name: 'Sex at birth',
          description: 'Sex at birth selection',
          data: {
            questionKey: 'personDemographic.sex',
            answerKey: 'patient.sex',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              { optionKey: 1, optionValue: 'personDemographic.female' },
              { optionKey: 2, optionValue: 'personDemographic.male' },
            ],
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 10,
          type: 'UNCONDITIONAL',
          name: 'Sex to Ancestry',
          description: 'Sex at birth page to ancestry page',
          data: { nextPageId: 4 },
        },
      ],
    },
    {
      id: 1022,
      name: 'DTC Redirect to myWay',
      description: 'DTC redirect to myWay.',
      type: NodeTypes.Redirect,
      data: {
        queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'],
        url: 'https://myriadstg.wpengine.com/genetics-risk/order-a-kit/',
        titleKey: 'redirecting.title',
      },

      flows: [],
    },
    {
      id: 1021,
      name: 'DTC Redirect to Access',
      description: 'DTC redirect to access.',
      type: NodeTypes.Redirect,
      data: {
        queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', 'pse_id'],
        url: 'https://myriadstg.wpengine.com/genetics-risk/myrisk-order/',
        titleKey: 'redirecting.title',
      },

      flows: [],
    },
    {
      id: 1024,
      name: 'DTC Results',
      description: 'Results page for DTC.',
      type: NodeTypes.ContextBreak,
      data: { messageKey: 'Processing results!' },

      flows: [
        {
          id: 2253,
          type: 'CONDITIONAL',
          name: 'Meets Unaffected to Redirect to Access test',
          description: 'Meets Unaffected to Redirect to Access',
          data: {
            nextPageId: 1021,
            conditions: [
              {
                value: true,
                answerKey: 'meetsCriteria',
                comparator: '==',
              },
              {
                value: '0',
                answerKey: 'cancerHistory.relatives.self[0].pathology',
                comparator: 'isEmpty',
              },
            ],
          },
        },
        {
          id: 1023,
          type: 'CONDITIONAL',
          name: 'Meets Affected to Redirect to myWay',
          description: 'Meets Affected to Redirect to myWay.',
          data: {
            nextPageId: 1022,
            conditions: [
              {
                comparator: '==',
                answerKey: 'meetsCriteria',
                value: true,
              },
            ],
          },
        },
        {
          id: 1024,
          type: 'UNCONDITIONAL',
          name: 'To DTC Redirect to Learn More',
          description: 'To DTC Redirect to Learn More.',
          data: { nextPageId: 1020 },
        },
      ],
    },
    {
      id: 5,
      name: 'Qualifying Question',
      description: 'Qualifying Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'Cancer List',
          description: 'Cancer list for qualifying question',
          data: {
            contentKey: 'qualifyingQuestion.question',
            listKeys: [
              'qualifyingQuestion.qualifyingPathologies.breast',
              'qualifyingQuestion.qualifyingPathologies.colon',
              'qualifyingQuestion.qualifyingPathologies.dcis',
              'qualifyingQuestion.qualifyingPathologies.endometrial',
              'qualifyingQuestion.qualifyingPathologies.fallopian',
              'qualifyingQuestion.qualifyingPathologies.gastric',
              'qualifyingQuestion.qualifyingPathologies.melanoma',
              'qualifyingQuestion.qualifyingPathologies.ovarian',
              'qualifyingQuestion.qualifyingPathologies.pancreatic',
              'qualifyingQuestion.qualifyingPathologies.peritoneal',
              'qualifyingQuestion.qualifyingPathologies.prostate',
              'qualifyingQuestion.qualifyingPathologies.rectum',
            ],
          },
        },
        {
          id: 5,
          type: 'YES_NO',
          name: 'Qualifying Question',
          description: 'Qualifying question to determine if patient immediately does not meet criteria',
          data: {
            questionKey: '',
            answerKey: 'qualifyingHistory',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 2476,
          type: 'BINARY',
          name: 'DTC 2 Qualifying Question test',
          description: 'Asks cancer history if yes otherwise goes to end.',
          data: {
            answerKey: 'qualifyingHistory',
            negativePageId: 6,
            affirmativePageId: 6,
          },
        },
      ],
    },
    {
      id: 6,
      name: 'Cancer history',
      description: 'Cancer history selection',
      type: NodeTypes.CancerHistory,
      data: {
        answerKey: 'cancerHistory',
        selfSexKey: 'patient.sex[0].optionValue',
        continueType: ContinueType.AutoFixed,
        detailsType: DetailsType.Both,
        subtitle: 'subtitles.personalFamilyHistory',
        selectionOptions: [],
      },

      flows: [],
    },
  ],
  configuration: {
    criteriaCheckType: 'bytestfamily',
    criteriaCheckName: 'MGH+Test+Family',
    criteriaCheckResponseKeyMap: {
      sexResponseKey: 'patient.sex',
      ashkenaziResponseKey: 'ancestry',
      cancerHistoryResponseKey: 'cancerHistory',
    },
  },
};

export const dtcComplete = {
  id: 6961,
  name: 'DTC Complete',
  description: 'The survey for DTC Complete',
  startNode: {
    id: 10782,
    name: 'Get Demographics From Accession',
    description: 'Uses accession id to get patient demographics. Used in DTC Complete.',
    type: NodeTypes.EndpointCall,
    data: {
      endpointPath: '/dtc-order-demographics?accessionId',
      answerKey: 'patient',
      errorKey: 'endpoint.accessionIdError',
      endpointHttpMethod: HttpMethod.Get,
      continueType: ContinueType.AutoFixed,
      titleKey: 'endpoint.processing',
    },
  },
  endNode: {
    id: 1024,
    name: 'DTC Results',
    description: 'Results page for DTC.',
    type: NodeTypes.ContextBreak,
    data: {
      messageKey: 'Processing results!',
    },
    flows: [
      {
        id: 2340,
        type: 'UNCONDITIONAL',
        name: 'To DTC Complete Results',
        description: 'To DTC Complete Results',
        data: {
          nextPageId: 9862,
        },
      },
    ],
  },
  createdDate: '2021-11-30T08:37:31.130265-07:00',
  createdBy: 'svc_dxtest1',
  nodes: [
    {
      id: 1023,
      name: 'DTC Complete Welcome',
      description: 'Welcome page for DTC Complete.',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'welcome',
        footerKey: 'dtc.welcomeFooter1',
        subMessageKey: 'dtc.welcomeMessage1',
        termsAndPrivacyLinks: true,
      },
      components: [],
      flows: [
        {
          id: 1,
          type: 'UNCONDITIONAL',
          name: 'Basic Info to Demographic form',
          description: 'basic information page to person demographic info page',
          data: {
            nextPageId: 3,
          },
        },
      ],
    },
    {
      id: 9,
      name: 'TC Workflow',
      description: 'Breast cancer risk/TC workflow',
      type: NodeTypes.BreastCancerRisk,
      data: {
        answerKey: 'breastCancerRisk',
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.additionalInformation',
      },
      flows: [
        {
          id: 2360,
          type: 'UNCONDITIONAL',
          name: 'To Person Demographic Context',
          description: 'Navigate to the person demographic context node',
          data: {
            nextPageId: 9882,
          },
        },
      ],
    },
    {
      id: 1024,
      name: 'DTC Results',
      description: 'Results page for DTC.',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'Processing results!',
      },

      flows: [
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'To DTC Complete Results',
          description: 'To DTC Complete Results',
          data: {
            nextPageId: 9862,
          },
        },
      ],
    },
    {
      id: 8,
      name: 'Bone Marrow',
      description: 'Bone Marrow Transplant History',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.additionalInformation',
      },
      components: [
        {
          id: 8,
          type: 'YES_NO',
          name: 'Bone Marrow Transplant',
          description: 'Bone Marrow Transplant History',
          data: {
            questionKey: 'boneMarrowTransplantHistory',
            answerKey: 'boneMarrowTransplantHistory',
            required: true,
          },
        },
        {
          id: 22,
          type: 'HELP_HINT',
          name: 'BMT help',
          description: 'Bone Marrow Transplant Help Hint',
          data: {
            helpHint: {
              termKey: 'helpHints.boneMarrowTransplant',
              definitionKey: 'helpHints.boneMarrowTransplantHint',
            },
          },
        },
      ],
      flows: [
        {
          id: 8,
          type: 'CONDITIONAL',
          name: 'To TC Workflow',
          description: 'Navigate to TC workflow if patient is female, has no personal breast cancer history, and is less than 84',
          data: {
            nextPageId: 9,
            conditions: [
              {
                comparator: '==',
                answerKey: 'patient.sex.0.optionValue',
                value: 'personDemographic.female',
              },
              {
                comparator: '==',
                answerKey: 'personalBreastCancerHistory',
                value: false,
              },
              {
                comparator: '>',
                answerKey: 'patient.age',
                value: 84,
              },
            ],
          },
        },
        {
          id: 2360,
          type: 'UNCONDITIONAL',
          name: 'To Person Demographic Context',
          description: 'Navigate to the person demographic context node',
          data: {
            nextPageId: 9882,
          },
        },
      ],
    },
    {
      id: 9882,
      name: 'Person Demographic Pause',
      description: 'Pause to notify patients that we need to collect contact information',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'personDemographic.contextMessage',
      },

      flows: [
        {
          id: 3,
          type: 'UNCONDITIONAL',
          name: 'To Person Demographic Contact Node',
          description: 'Navigate to person demographic contact node with form',
          data: {
            nextPageId: 15,
          },
        },
      ],
    },
    {
      id: 6,
      name: 'Cancer history',
      description: 'Cancer history selection',
      type: NodeTypes.CancerHistory,
      data: {
        answerKey: 'cancerHistory',
        selfSexKey: 'patient.sex[0].optionValue',
        continueType: ContinueType.AutoFixed,
        detailsType: DetailsType.Both,
        subtitle: 'subtitles.personalFamilyHistory',
        selectionOptions: [],
      },

      flows: [
        {
          id: 6,
          type: 'UNCONDITIONAL',
          name: 'Cancer history selection',
          description: 'Cancer history selection',
          data: {
            nextPageId: 12,
          },
        },
      ],
    },
    {
      id: 10082,
      name: 'Person Insurance',
      description: 'Collecting person insurance data',
      type: NodeTypes.Page,
      data: {
        continueKey: 'submit',
        continueType: ContinueType.End,
      },
      components: [
        {
          id: 3780,
          type: 'PERSON_INSURANCE',
          name: 'Person Insurance Component',
          description: 'Insurance collection form',
          data: {
            questionKey: 'personInsurance.insuranceInformation',
            answerKey: 'personInsurance',
            patientDataKey: 'patient',
            descriptionKey: '',
            required: true,
            fields: {
              insuranceCardImageBack: FieldOptionType.DISPLAYED,
              insuranceCardImageFront: FieldOptionType.DISPLAYED,
              insuranceId: FieldOptionType.REQUIRED,
              policyHolderDob: FieldOptionType.REQUIRED,
              insuranceProvider: FieldOptionType.REQUIRED,
              policyHolderFirstName: FieldOptionType.REQUIRED,
              policyHolderLastName: FieldOptionType.REQUIRED,
              policyHolderRelation: FieldOptionType.REQUIRED,
              isUninsured: FieldOptionType.DISPLAYED,
            },
          },
        },
      ],
      flows: [
        {
          id: 13,
          type: 'UNCONDITIONAL',
          name: 'To dtc video page',
          description: 'Flow to dtc video page',
          data: {
            nextPageId: 18,
          },
        },
      ],
    },
    {
      id: 12,
      name: 'Add. Info Pause',
      description: 'Additional Information Pause Page',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'pausePages.additionalInfo',
      },

      flows: [
        {
          id: 12,
          type: 'UNCONDITIONAL',
          name: 'Add. Info to BT',
          description: 'additional info to blood transfusion',
          data: {
            nextPageId: 16,
          },
        },
      ],
    },
    {
      id: 20,
      name: 'Criteria check',
      description: 'Criteria check call',
      type: NodeTypes.EndpointCall,
      data: {
        endpointPath: '/criteria-results',
        errorKey: 'endpoint.criteriaCheckError',
        answerKey: 'criteriaCheck',
        endpointHttpMethod: HttpMethod.Post,
        continueType: ContinueType.AutoFixed,
        titleKey: 'endpoint.saveMessage',
      },

      flows: [
        {
          id: 21,
          type: 'CONDITIONAL',
          name: 'To Blood Transfusion Flow',
          description: 'Navigates to blood transfusion flow when patient meets hereditary cancer testing guidelines',
          data: {
            nextPageId: 7,
            conditions: [
              {
                comparator: '==',
                answerKey: 'meetsCriteria',
                value: true,
              },
            ],
          },
        },
        {
          id: 2360,
          type: 'UNCONDITIONAL',
          name: 'To Person Demographic Context',
          description: 'Navigate to the person demographic context node',
          data: {
            nextPageId: 9882,
          },
        },
      ],
    },
    {
      id: 16,
      name: 'Colon Polyps',
      description: 'Colon Polyp workflow',
      type: NodeTypes.ColonPolyp,
      data: {
        answerKey: 'colonPolyp',
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalFamilyHistory',
      },

      flows: [
        {
          id: 14,
          type: 'UNCONDITIONAL',
          name: 'Polyp to criteria',
          description: 'colon polyp to criteria check',
          data: {
            nextPageId: 20,
          },
        },
      ],
    },
    {
      id: 1001,
      name: 'Genetic Testing History',
      description: 'Genetic Testing History Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalFamilyHistory',
      },
      components: [
        {
          id: 1001,
          type: 'YES_NO',
          name: 'Genetic Testing History',
          description: 'Genetic Testing History Question',
          data: {
            questionKey: 'hasGeneticTestingHistory',
            answerKey: 'hasGeneticTestingHistory',
            required: true,
            noKey: 'pathology.noOrUnknown',
          },
        },
        {
          id: 5180,
          type: 'HELP_HINT',
          name: 'Genetic Testing Help Hint',
          description: 'Genetic Testing Help Hint',
          data: {
            helpHint: {
              definitionKey: 'helpHints.geneticTestingHint',
              termKey: 'helpHints.geneticTesting',
            },
          },
        },
        {
          id: 5181,
          type: 'HELP_HINT',
          name: 'Hereditary Cancer Risk Help Hint',
          description: 'Help hint for hereditary Cancer Risk',
          data: {
            helpHint: {
              termKey: 'helpHints.hereditaryCancerRisk',
              definitionKey: 'helpHints.hereditaryCancerRiskHint',
            },
          },
        },
        {
          id: 5200,
          type: 'HELP_HINT',
          name: 'Healthcare Provider Hint',
          description: 'Help hint for healthcare providers',
          data: {
            helpHint: {
              termKey: 'helpHints.healthCareProvider',
              definitionKey: 'helpHints.healthCareProviderHint',
            },
          },
        },
      ],
      flows: [
        {
          id: 1001,
          type: 'UNCONDITIONAL',
          name: 'To Qualifying Question',
          description: 'Navigate to qualifying question',
          data: {
            nextPageId: 5,
          },
        },
      ],
    },
    {
      id: 5,
      name: 'Qualifying Question',
      description: 'Qualifying Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'Cancer List',
          description: 'Cancer list for qualifying question',
          data: {
            contentKey: 'qualifyingQuestion.question',
            listKeys: [
              'qualifyingQuestion.qualifyingPathologies.breast',
              'qualifyingQuestion.qualifyingPathologies.colon',
              'qualifyingQuestion.qualifyingPathologies.dcis',
              'qualifyingQuestion.qualifyingPathologies.endometrial',
              'qualifyingQuestion.qualifyingPathologies.fallopian',
              'qualifyingQuestion.qualifyingPathologies.gastric',
              'qualifyingQuestion.qualifyingPathologies.melanoma',
              'qualifyingQuestion.qualifyingPathologies.ovarian',
              'qualifyingQuestion.qualifyingPathologies.pancreatic',
              'qualifyingQuestion.qualifyingPathologies.peritoneal',
              'qualifyingQuestion.qualifyingPathologies.prostate',
              'qualifyingQuestion.qualifyingPathologies.rectum',
            ],
            columned: true,
          },
        },
        {
          id: 5,
          type: 'YES_NO',
          name: 'Qualifying Question',
          description: 'Qualifying question to determine if patient immediately does not meet criteria',
          data: {
            questionKey: '',
            answerKey: 'qualifyingHistory',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'Qualifying question flow',
          description: 'Qualifying question flow',
          data: {
            answerKey: 'qualifyingHistory',
            affirmativePageId: 6,
            negativePageId: 12,
          },
        },
      ],
    },
    {
      id: 15,
      name: 'Person contact form',
      description: 'Person contact form',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 2,
          type: 'PERSON_DEMOGRAPHIC',
          name: 'Contact Info',
          description: 'Contact Info Form',
          data: {
            questionKey: 'personDemographic.contactInformation',
            answerKey: 'patient',
            fields: {
              phone: FieldOptionType.REQUIRED,
              phoneType: FieldOptionType.REQUIRED,
              email: 'DISPLAYED',
              address: FieldOptionType.REQUIRED,
              city: FieldOptionType.REQUIRED,
              state: FieldOptionType.REQUIRED,
              postalCode: FieldOptionType.REQUIRED,
            },
            required: true,
          },
        },
        {
          id: 4,
          type: 'TEXT_CONTENT',
          name: 'Contact Message',
          description: 'Message for contact info',
          data: {
            contentKey: 'personDemographic.contactMessage',
            type: TextContentType.Text2,
          },
        },
      ],
      flows: [
        {
          id: 2420,
          type: 'CONDITIONAL',
          name: 'To Person Insurance',
          description: 'Flow to route users to insurance collection page',
          data: {
            nextPageId: 10082,
            conditions: [
              {
                comparator: '==',
                answerKey: 'meetsCriteria',
                value: true,
              },
            ],
          },
        },
        {
          id: 2421,
          type: 'UNCONDITIONAL',
          name: 'T404P Redirect to Programs',
          description: 'T404P Redirect to Programs',
          data: {
            nextPageId: 9883,
          },
        },
      ],
    },
    {
      id: 9883,
      name: 'Acknowledgement of Self-Pay Option',
      description: 'Pause to notify patients that we need to collect contact information',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
      },
      components: [
        {
          id: 1,
          type: 'TEXT_CONTENT',
          name: 'DTC Complete Is Self Pay Title',
          description: 'DTC Complete Is Self Pay Title',
          data: {
            contentKey: 'selfPay.title',
            highlightModifier: TextContentHighlightMod.Alert,
            type: TextContentType.H3,
          },
        },
        {
          id: 2,
          type: 'TEXT_CONTENT',
          name: 'DTC Complete Is Self Pay Text',
          description: 'DTC Complete Is Self Pay Text',
          data: {
            contentKey: 'selfPay.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 3,
          type: 'RADIO_GROUP',
          name: 'DTC Complete Is Self Pay Checkbox',
          description: 'DTC Complete Is Self Pay Checkbox',
          data: {
            answerKey: 'personInsurance.isSelfPay',
            radioOptions: [
              {
                optionName: 'selfPay',
                optionLabel: 'selfPay.yes',
                optionValue: 1,
              },
              {
                optionName: 'selfPay',
                optionLabel: 'selfPay.no',
                optionValue: 0,
              },
            ],
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 3,
          type: 'UNCONDITIONAL',
          name: 'To Person Demographic Contact Node',
          description: 'Navigate to person demographic contact node with form',
          data: {
            nextPageId: 10082,
          },
        },
      ],
    },
    {
      id: 10782,
      name: 'Get Demographics From Accession',
      description: 'Uses accession id to get patient demographics. Used in DTC Complete.',
      type: NodeTypes.EndpointCall,
      data: {
        endpointPath: '/dtc-order-demographics?accessionId',
        answerKey: 'patient',
        errorKey: 'endpoint.accessionIdError',
        endpointHttpMethod: HttpMethod.Get,
        continueType: ContinueType.AutoFixed,
        titleKey: 'endpoint.processing',
      },
      flows: [
        {
          id: 2580,
          type: 'UNCONDITIONAL',
          name: 'To DTC Welcome Page',
          description: 'Navigate to DTC Welcome Page',
          data: {
            nextPageId: 85,
          },
        },
      ],
    },
    {
      ...surveyEntryAccountIdEndpointCallNode,
      flows: [
        {
          id: 2580,
          type: 'UNCONDITIONAL',
          name: 'To DTC Welcome Page',
          description: 'Navigate to DTC Welcome Page',
          data: {
            nextPageId: 1023,
          },
        },
      ],
    },
    {
      id: 11,
      name: 'Cancer History Pause',
      description: 'Pause page for cancer history',
      type: NodeTypes.ContextBreak,
      data: {
        messageKey: 'pausePages.cancerLanding',
      },

      flows: [
        {
          id: 11,
          type: 'UNCONDITIONAL',
          name: 'To Genetic Testing History',
          description: 'Navigate to genetic testing history node',
          data: {
            nextPageId: 1001,
          },
        },
      ],
    },
    {
      id: 13,
      name: 'Sex at Birth',
      description: 'Sex at birth selection',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 1,
          type: 'OPTION_SELECT',
          name: 'Sex at birth',
          description: 'Sex at birth selection',
          data: {
            questionKey: 'personDemographic.sex',
            answerKey: 'patient.sex',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 'personDemographic.female',
              },
              {
                optionKey: 2,
                optionValue: 'personDemographic.male',
              },
            ],
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 10,
          type: 'UNCONDITIONAL',
          name: 'To Ancestry Selection Node',
          description: 'Navigate, unconditionally, to the ancestry selection node',
          data: {
            nextPageId: 4,
          },
        },
      ],
    },
    {
      id: 4,
      name: 'Ancestry Select',
      description: 'Select ancestries',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.Fixed,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'Ancestry Selection',
          description: 'Ancestry selection',
          data: {
            questionKey: 'ancestry.question',
            answerKey: 'ancestry',
            isSingleSelect: false,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: getAncestryOptions(),
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 4,
          type: 'UNCONDITIONAL',
          name: 'Ancestry to cancer landing',
          description: 'Ancestry page to cancer landing page',
          data: {
            nextPageId: 11,
          },
        },
      ],
    },
    {
      id: 9862,
      name: 'DTC Complete Results',
      description: 'Results page for DTC Complete Assessment',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'DTC Complete Results Title',
          description: 'Title for DTC Complete Results',
          data: {
            contentKey: 'results.dtc.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 3661,
          type: 'TEXT_CONTENT',
          name: 'DTC Complete Results Description',
          description: 'Description of what happens',
          data: {
            contentKey: 'results.dtc.description',
            highlightModifier: TextContentHighlightMod.Alert,
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'DTC Complete Results Text',
          description: 'DTC Results Page Text',
          data: {
            contentKey: 'results.dtc.text',
            type: TextContentType.Text1,
            textLinks: [
              {
                urlKey: 'results.dtc.links.email',
              },
              {
                urlKey: 'results.dtc.links.phone',
              },
              {
                urlKey: 'results.dtc.links.link',
                targetIsBlank: true,
              },
            ],
          },
        },
      ],
      flows: [],
    },
    {
      id: 18,
      name: 'DTC complete video page',
      description: 'Video page for DTC Complete.',
      type: 'PAGE',
      data: {
        continueType: 'FIXED',
      },
      components: [
        {
          id: 16,
          type: 'TEXT_CONTENT',
          name: 'DTC complete video page entry text',
          description: 'DTC complete video page entry text',
          data: {
            contentKey: 'dtc.video.subtitle',
            type: 'Text1',
          },
        },
        {
          id: 16,
          type: 'TEXT_CONTENT',
          name: 'DTC complete video page entry title',
          description: 'DTC complete video page entry title',
          data: {
            contentKey: 'dtc.video.title',
            type: 'H2',
          },
        },
        {
          id: 16,
          type: 'TEXT_CONTENT',
          name: 'DTC complete video page entry text',
          description: 'DTC complete video page entry text',
          data: {
            contentKey: 'dtc.video.text1',
            type: 'Text1',
          },
        },
        {
          id: 20,
          type: 'VIDEO',
          name: 'DTC complete Video',
          description: 'Video for DTC Complete',
          data: {
            src: 'dtc.video.src',
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'DTC Complete Video Contact Text',
          description: 'DTC Complete Video Contact Text',
          data: {
            contentKey: 'dtc.video.text2',
            type: 'Text1',
            textLinks: [{ urlKey: 'dtc.video.links.email' }, { urlKey: 'dtc.video.links.phone' }, { urlKey: 'dtc.video.links.link', targetIsBlank: true }],
          },
        },
        {
          id: 1,
          type: 'OPTION_SELECT',
          name: 'DTC complete video checkbox',
          description: 'DTC complete video checkbox',
          data: {
            questionKey: '',
            answerKey: 'video',
            isShowOptionAsCheckbox: true,
            optionSize: 'large',
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 'dtc.video.checkbox',
              },
            ],
            labelKey: '',
            required: true,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [],
    },
    {
      id: 3,
      name: 'Person demographics',
      description: 'Person demographic form',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.End,
        subtitle: 'subtitles.personalInformation',
      },
      components: [
        {
          id: 3,
          type: 'PERSON_DEMOGRAPHIC',
          name: 'Person demographics',
          description: 'Person Demographic Form',
          data: {
            questionKey: 'personDemographic.personalInformation',
            answerKey: 'patient',
            fields: {
              firstName: FieldOptionType.REQUIRED,
              lastName: FieldOptionType.REQUIRED,
              dob: 'REQUIRED',
            },
            required: true,
          },
        },
        {
          id: 4,
          type: 'TEXT_CONTENT',
          name: 'Contact Message',
          description: 'Message for contact info',
          data: {
            contentKey: 'personDemographic.contactMessage',
            type: TextContentType.Text2,
          },
        },
      ],
      flows: [
        {
          id: 2,
          type: 'UNCONDITIONAL',
          name: 'To Sex At Birth Node',
          description: 'Navigate to the sex at birth node',
          data: {
            nextPageId: 13,
          },
        },
      ],
    },
    {
      id: 7,
      name: 'Blood Transfusion',
      description: 'Blood transfusion history',
      type: NodeTypes.BloodTransfusion,
      data: {
        answerKey: 'bloodTransfusion',
        continueType: ContinueType.AutoFixed,
        subtitle: 'subtitles.additionalInformation',
      },

      flows: [
        {
          id: 7,
          type: 'UNCONDITIONAL',
          name: 'Blood Transfusion Flow',
          description: 'Blood Transfusion Flow',
          data: {
            nextPageId: 8,
          },
        },
      ],
    },
  ],
  configuration: {
    criteriaCheckType: 'bytestfamily',
    criteriaCheckName: 'MGH+Test+Family',
    criteriaCheckResponseKeyMap: {
      sexResponseKey: 'patient.sex',
      ashkenaziResponseKey: 'ancestry',
      cancerHistoryResponseKey: 'cancerHistory',
    },
  },
};

export const t404pDemo: Survey = {
  id: 6961,
  name: 'T404P Quick Qualify Questions',
  description: 'The survey for T404P Quick Qualify Questions',
  startNode: {
    id: 10782,
    name: 'T404P Cancer history',
    description: 'T404P Cancer history',
    type: NodeTypes.Page,
    data: {
      continueType: ContinueType.AutoFixed,
    },
    components: [
      {
        id: 3660,
        type: 'TEXT_CONTENT',
        name: 'T404P Cancer history Title',
        description: 'T404P Cancer history Title',
        data: {
          contentKey: 't404p.cancerHistory.title',
          highlightModifier: TextContentHighlightMod.Primary,
          type: TextContentType.H1,
        },
      },
      {
        id: 500,
        type: 'TEXT_LIST',
        name: 'Cancer List',
        description: 'Cancer list for qualifying question',
        data: {
          contentKey: 't404p.cancerHistory.question',
          listKeys: [
            't404p.cancerHistory.qualifyingPathologies.breast',
            't404p.cancerHistory.qualifyingPathologies.ovarian',
            't404p.cancerHistory.qualifyingPathologies.uterine',
            't404p.cancerHistory.qualifyingPathologies.colorectal',
            't404p.cancerHistory.qualifyingPathologies.pancreatic',
          ],
          columned: false,
        },
      },
      {
        id: 8,
        type: 'YES_NO',
        name: 'Cancer history List',
        description: 'Cancer history List',
        data: {
          questionKey: '',
          answerKey: 'qualifyingCancerHistory',
          required: true,
        },
      },
      {
        id: 22,
        type: 'HELP_HINT',
        name: 't404p Cancer history hint',
        description: 'Cancer history help hint',
        data: {
          helpHint: {
            termKey: 't404p.cancerHistory.hintKey',
            definitionKey: 't404p.cancerHistory.hint',
          },
        },
      },
    ],
    flows: [
      {
        id: 5,
        type: 'BINARY',
        name: 'T404P To Family Cancer history flow',
        description: 'T404P To Family Cancer history flow',
        data: {
          answerKey: 'qualifyingCancerHistory',
          affirmativePageId: 8,
          negativePageId: 1023,
        },
      },
    ],
  },
  endNode: {
    id: 1024,
    name: 'T404P Results - opt-out',
    description: 'T404P Results - opt-out',
    type: NodeTypes.Page,
    data: {
      continueType: ContinueType.AutoEnd,
      continueKey: 't404p.resultsOptOut.noInsurance',
    },
    components: [
      {
        id: 3660,
        type: 'TEXT_CONTENT',
        name: 'T404P resultsTitle',
        description: 'Title for T404P resultsTitle',
        data: {
          contentKey: 't404p.resultsTitle',
          highlightModifier: TextContentHighlightMod.Primary,
          type: TextContentType.H1,
        },
      },
      {
        id: 3660,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - subtitle',
        description: 'T404P Results opt-out - subtitle',
        data: {
          contentKey: 't404p.resultsOptOut.subtitle',
          highlightModifier: TextContentHighlightMod.Primary,
          type: TextContentType.H3,
        },
      },
      {
        id: 3662,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - text',
        description: 'T404P Results opt-out - text',
        data: {
          contentKey: 't404p.resultsOptOut.text',
          type: TextContentType.Text1,
        },
      },
      {
        id: 3660,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - subtitle1',
        description: 'T404P Results opt-out - subtitle1',
        data: {
          contentKey: 't404p.resultsOptOut.subtitle1',
          highlightModifier: TextContentHighlightMod.Primary,
          type: TextContentType.H3,
        },
      },
      {
        id: 3662,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - text1',
        description: 'T404P Results opt-out - text1',
        data: {
          contentKey: 't404p.resultsOptOut.text1',
          type: TextContentType.Text1,
        },
      },
      {
        id: 3660,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - resultsSubtitleNext',
        description: 'T404P Results opt-out - resultsSubtitleNext',
        data: {
          contentKey: 't404p.resultsSubtitleNext',
          highlightModifier: TextContentHighlightMod.Primary,
          type: TextContentType.H3,
        },
      },
      {
        id: 3662,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - text2',
        description: 'T404P Results opt-out - text2',
        data: {
          contentKey: 't404p.resultsOptOut.text2',
          type: TextContentType.Text1,
        },
      },
      {
        id: 3660,
        type: 'TEXT_CONTENT',
        name: 'T404P Results opt-out - subtitle1',
        description: 'T404P Results opt-out - subtitle2',
        data: {
          contentKey: 't404p.resultsOptOut.subtitle2',
          highlightModifier: TextContentHighlightMod.Primary,
          type: TextContentType.H3,
        },
      },
      {
        id: 44,
        type: 'OPTION_SELECT',
        name: 'T404P Results opt-out - No Insurance Selection',
        description: 'T404P Results Met - No Insurance selection',
        data: {
          questionKey: '',
          answerKey: 'resultsOptOut',
          isSingleSelect: true,
          optionSize: ComponentSizes.XSmall,
          selectionOptions: [
            {
              optionKey: 1,
              optionValue: 't404p.resultsOptOut.useInsurance',
            },
          ],
          labelKey: '',
          required: false,
          placeholderKey: '',
          subtitleKey: '',
        },
      },
    ],
    flows: [
      {
        id: 21,
        type: 'CONDITIONAL',
        name: 'T404P Results opt-out - to payment',
        description: 'T404P Results opt-out - to payment',
        data: {
          nextPageId: 7,
          conditions: [
            {
              comparator: '==',
              answerKey: 'resultsOptOut[0].optionValue',
              value: 't404p.resultsOptOut.useInsurance',
            },
          ],
        },
      },
      {
        id: 2340,
        type: 'UNCONDITIONAL',
        name: 'T404P Redirect to Programs',
        description: 'T404P Redirect to Programs',
        data: {
          nextPageId: 20,
        },
      },
    ],
  },
  createdDate: '2021-11-30T08:37:31.130265-07:00',
  createdBy: 'svc_dxtest1',
  nodes: [
    {
      id: 10782,
      name: 'T404P Cancer history',
      description: 'T404P Cancer history',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Cancer history Title',
          description: 'T404P Cancer history Title',
          data: {
            contentKey: 't404p.cancerHistory.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'T404P Cancer List',
          description: 'T404P Cancer list for qualifying question',
          data: {
            contentKey: 't404p.cancerHistory.question',
            listKeys: [
              't404p.cancerHistory.qualifyingPathologies.breast',
              't404p.cancerHistory.qualifyingPathologies.ovarian',
              't404p.cancerHistory.qualifyingPathologies.uterine',
              't404p.cancerHistory.qualifyingPathologies.colorectal',
              't404p.cancerHistory.qualifyingPathologies.pancreatic',
            ],
            columned: false,
          },
        },
        {
          id: 22,
          type: 'HELP_HINT',
          name: 'T404P Cancer history hint',
          description: 'T404P Cancer history help hint',
          data: {
            helpHint: {
              termKey: 't404p.cancerHistory.hintKey',
              definitionKey: 't404p.cancerHistory.hint',
            },
          },
        },
        {
          id: 8,
          type: 'YES_NO',
          name: 'Cancer history List',
          description: 'Cancer history List',
          data: {
            questionKey: '',
            answerKey: 'qualifyingCancerHistory',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'Qualifying cancer history flow',
          description: 'Qualifying cancer history flow',
          data: {
            answerKey: 'qualifyingCancerHistory',
            affirmativePageId: 8,
            negativePageId: 1023,
          },
        },
      ],
    },
    {
      id: 1023,
      name: 'T404P Family Cancer history',
      description: 'T404P Family Cancer history',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Family Cancer history Title',
          description: 'T404P Family Cancer history Title',
          data: {
            contentKey: 't404p.familyCancerHistory.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'T404P Family Cancer List',
          description: 'T404P Family Cancer list for qualifying question',
          data: {
            contentKey: 't404p.familyCancerHistory.question',
            listKeys: [
              't404p.familyCancerHistory.qualifyingPathologies.breast',
              't404p.familyCancerHistory.qualifyingPathologies.breast-two',
              't404p.familyCancerHistory.qualifyingPathologies.breast-three',
              't404p.familyCancerHistory.qualifyingPathologies.ovarian',
              't404p.familyCancerHistory.qualifyingPathologies.other',
              't404p.familyCancerHistory.qualifyingPathologies.ashkenazi',
            ],
            columned: false,
          },
        },
        {
          id: 8,
          type: 'YES_NO',
          name: 'T404P Family Cancer history Yes/No',
          description: 'T404P Family Cancer history Yes/No',
          data: {
            questionKey: '',
            answerKey: 'qualifyingFamilyCancerHistory',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'T404P Family Cancer history flow',
          description: 'T404P Family Cancer history flow',
          data: {
            answerKey: 'qualifyingFamilyCancerHistory',
            affirmativePageId: 8,
            negativePageId: 16,
          },
        },
      ],
    },
    {
      id: 8,
      name: 'T404P Is Private Insurance',
      description: 'T404P Is Private Insurance Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Is Private Insurance Title',
          description: 'T404P Is Private Insurance Title',
          data: {
            contentKey: 't404p.isPrivateHealthCareInsurance.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Is Private Insurance Subtitle',
          description: 'T404P Is Private Insurance Subtitle',
          data: {
            contentKey: 't404p.isPrivateHealthCareInsurance.question',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Is Private Insurance Text',
          description: 'T404P Is Private Insurance Text',
          data: {
            contentKey: 't404p.isPrivateHealthCareInsurance.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 8,
          type: 'YES_NO',
          name: 'T404P Is Private Insurance - Yes/No',
          description: 'Question to indicate if the user has a private insurance',
          data: {
            questionKey: '',
            answerKey: 'isPrivateHealthCareInsurance',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'T404P Is Private Insurance flow',
          description: 'T404P Is Private Insurance flow',
          data: {
            answerKey: 'isPrivateHealthCareInsurance',
            affirmativePageId: 14,
            negativePageId: 15,
          },
        },
      ],
    },
    {
      id: 14,
      name: 'T404P Government Insurance',
      description: 'T404P Government Insurance Question',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Government Insurance Title',
          description: 'T404P Government Insurance Title',
          data: {
            contentKey: 't404p.isGovernmentSponsoredInsurance.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Government Insurance Subtitle',
          description: 'T404P Government Insurance Subtitle',
          data: {
            contentKey: 't404p.isGovernmentSponsoredInsurance.question',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Government Insurance Text',
          description: 'T404P Government Insurance Text',
          data: {
            contentKey: 't404p.isGovernmentSponsoredInsurance.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 8,
          type: 'YES_NO',
          name: 'T404P Is Private Insurance - Yes/No',
          description: 'Question to indicate if the user has a private insurance',
          data: {
            questionKey: '',
            answerKey: 'isGovernmentSponsoredInsurance',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'T404P Is Government Insurance flow',
          description: 'T404P Is Government Insurance flow',
          data: {
            answerKey: 'isGovernmentSponsoredInsurance',
            affirmativePageId: 4,
            negativePageId: 13,
          },
        },
      ],
    },
    {
      id: 13,
      name: 'T404P Insurance Provider',
      description: 'T404P Insurance Provider',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Insurance Provider Title',
          description: 'T404P Insurance Provider Title',
          data: {
            contentKey: 't404p.insuranceProvider.title',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 500,
          type: 'TEXT_LIST',
          name: 'T404P Insurance Providers List',
          description: 'T404P Insurance providers list',
          data: {
            contentKey: 't404p.insuranceProvider.question',
            listKeys: [
              't404p.insuranceProvider.providersList.1',
              't404p.insuranceProvider.providersList.2',
              't404p.insuranceProvider.providersList.3',
              't404p.insuranceProvider.providersList.4',
              't404p.insuranceProvider.providersList.5',
              't404p.insuranceProvider.providersList.6',
              't404p.insuranceProvider.providersList.7',
              't404p.insuranceProvider.providersList.8',
              't404p.insuranceProvider.providersList.9',
              't404p.insuranceProvider.providersList.10',
              't404p.insuranceProvider.providersList.11',
              't404p.insuranceProvider.providersList.12',
              't404p.insuranceProvider.providersList.13',
              't404p.insuranceProvider.providersList.14',
              't404p.insuranceProvider.providersList.15',
              't404p.insuranceProvider.providersList.16',
              't404p.insuranceProvider.providersList.17',
              't404p.insuranceProvider.providersList.18',
              't404p.insuranceProvider.providersList.19',
              't404p.insuranceProvider.providersList.20',
              't404p.insuranceProvider.providersList.21',
              't404p.insuranceProvider.providersList.22',
              't404p.insuranceProvider.providersList.23',
            ],
            columned: true,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Insurance provider Text',
          description: 'T404P Insurance provider Text',
          data: {
            contentKey: 't404p.insuranceProvider.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 8,
          type: 'YES_NO',
          name: 'T404P Payer list - Yes/No',
          description: 'T404P Payer list - Yes/No',
          data: {
            questionKey: '',
            answerKey: 'insuranceProvider',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: 5,
          type: 'BINARY',
          name: 'T404P Payer list flow',
          description: 'T404P Payer list flow',
          data: {
            answerKey: 'insuranceProvider',
            affirmativePageId: 1024,
            negativePageId: 5,
          },
        },
      ],
    },
    {
      id: 1024,
      name: 'T404P Results - opt-out',
      description: 'T404P Results - opt-out',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoEnd,
        continueKey: 't404p.resultsOptOut.useInsurance',
        isColumns: true,
        subtitle: 't404p.resultsTitle',
      },
      components: [
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - text',
          description: 'T404P Results opt-out - text',
          data: {
            contentKey: 't404p.resultsOptOut.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'T404P Results opt-out - Payment breakdown',
          description: 'T404P Results opt-out - Payment breakdown',
          data: {
            questionKey: '',
            answerKey: 'resultsOptOut',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 't404p.resultsOptOut.paymentBreakdown',
              },
            ],
            labelKey: '',
            required: false,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - resultsSubtitleNext',
          description: 'T404P Results opt-out - resultsSubtitleNext',
          data: {
            contentKey: 't404p.resultsSubtitleNext',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - text2',
          description: 'T404P Results opt-out - text2',
          data: {
            contentKey: 't404p.resultsOptOut.text2',
            type: TextContentType.Text1,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - subtitle1',
          description: 'T404P Results opt-out - subtitle2',
          data: {
            contentKey: 't404p.resultsOptOut.subtitle2',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'T404P Results opt-out - No Insurance Selection',
          description: 'T404P Results Met - No Insurance selection',
          data: {
            questionKey: '',
            answerKey: 'resultsOptOut',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 't404p.resultsOptOut.noInsurance',
              },
            ],
            labelKey: '',
            required: false,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 22,
          type: 'CONDITIONAL',
          name: 'T404P Results opt-out - to payment breakdown',
          description: 'T404P Results opt-out - to payment breakdown',
          data: {
            nextPageId: 17,
            conditions: [
              {
                comparator: '==',
                answerKey: 'resultsOptOut[0].optionValue',
                value: 't404p.resultsOptOut.paymentBreakdown',
              },
            ],
          },
        },
        {
          id: 21,
          type: 'CONDITIONAL',
          name: 'T404P Results opt-out - to payment',
          description: 'T404P Results opt-out - to payment',
          data: {
            nextPageId: 7,
            conditions: [
              {
                comparator: '==',
                answerKey: 'resultsOptOut[0].optionValue',
                value: 't404p.resultsOptOut.noInsurance',
              },
            ],
          },
        },
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'T404P Redirect to Programs',
          description: 'T404P Redirect to Programs',
          data: {
            nextPageId: 20,
          },
        },
      ],
    },
    {
      id: 17,
      name: 'T404P Results - payment breakdown',
      description: 'T404P Results - payment breakdown',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoEnd,
        continueKey: 't404p.paymentBreakdown.useInsurance',
        subtitle: 't404p.paymentBreakdown.title',
      },
      components: [
        {
          id: 3662,
          type: 'GRID',
          name: 'T404P Payment breakdown - text',
          description: 'T404P Payment breakdown  - text',
          data: {
            titleKey: 'grid title',
            gridColumns: 3,
            gridItems: [
              {
                contentKey: 't404p.paymentBreakdown.textSpace',
                type: GridItemType.empty,
                showOnDesktopOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text1',
                type: GridItemType.heading,
                showOnDesktopOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text2',
                type: GridItemType.heading,
                showOnDesktopOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text3',
                type: GridItemType.label,
              },
              {
                contentKey: 't404p.paymentBreakdown.text4',
                type: GridItemType.heading,
                showOnMobileOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text5',
                type: GridItemType.cell,
              },
              {
                contentKey: 't404p.paymentBreakdown.text6',
                type: GridItemType.heading,
                showOnMobileOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text7',
                type: GridItemType.cell,
              },
              {
                contentKey: 't404p.paymentBreakdown.text8',
                type: GridItemType.label,
              },
              {
                contentKey: 't404p.paymentBreakdown.text9',
                type: GridItemType.heading,
                showOnMobileOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text10',
                type: GridItemType.cell,
              },
              {
                contentKey: 't404p.paymentBreakdown.text11',
                type: GridItemType.heading,
                showOnMobileOnly: true,
              },
              {
                contentKey: 't404p.paymentBreakdown.text12',
                type: GridItemType.cell,
              },
            ],
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Payment breakdown - subtitle',
          description: 'T404P Payment breakdown - subtitle',
          data: {
            contentKey: 't404p.paymentBreakdown.subtitle',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 44,
          type: 'OPTION_SELECT',
          name: 'T404P Payment breakdown - No Insurance Selection',
          description: 'T404P Payment breakdown - No Insurance selection',
          data: {
            questionKey: '',
            answerKey: 'paymentBreakdown',
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 't404p.paymentBreakdown.noInsurance',
              },
            ],
            labelKey: '',
            required: false,
            placeholderKey: '',
            subtitleKey: '',
          },
        },
      ],
      flows: [
        {
          id: 21,
          type: 'CONDITIONAL',
          name: 'T404P Results opt-out - to payment',
          description: 'T404P Results opt-out - to payment',
          data: {
            nextPageId: 7,
            conditions: [
              {
                comparator: '==',
                answerKey: 'paymentBreakdown[0].optionValue',
                value: 't404p.paymentBreakdown.noInsurance',
              },
            ],
          },
        },
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'T404P Redirect to Programs',
          description: 'T404P Redirect to Programs',
          data: {
            nextPageId: 20,
          },
        },
      ],
    },
    {
      id: 4,
      name: 'T404P Results - government',
      description: 'T404P Results - government',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoEnd,
        continueKey: 't404p.resultsGovernment.button',
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P resultsTitle',
          description: 'Title for T404P resultsTitle',
          data: {
            contentKey: 't404p.resultsTitle',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - no pre-test Text',
          description: 'T404P Results - no pre-test Page Text',
          data: {
            contentKey: 't404p.resultsGovernment.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - resultsSubtitleNext',
          description: 'T404P Results opt-out - resultsSubtitleNext',
          data: {
            contentKey: 't404p.resultsSubtitleNext',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - no pre-test Text 1',
          description: 'T404P Results - no pre-test Page Text 1',
          data: {
            contentKey: 't404p.resultsGovernment.text1',
            type: TextContentType.Text1,
          },
        },
      ],
      flows: [
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'T404P To Redirect to Myriad Programs',
          description: 'T404P To Redirect to Myriad Programs',
          data: {
            nextPageId: 20,
          },
        },
      ],
    },
    {
      id: 5,
      name: 'T404P Results - resultsGeneral',
      description: 'T404P Results - resultsGeneral',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoEnd,
        continueKey: 't404p.resultsGeneral.button',
        isColumns: true,
        subtitle: 't404p.resultsTitle',
      },
      components: [
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - resultsGeneral Text',
          description: 'T404P Results - resultsGeneral Page Text',
          data: {
            contentKey: 't404p.resultsGeneral.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - resultsGeneral Subtitle',
          description: 'T404P Results - resultsGeneral Page Subtitle',
          data: {
            contentKey: 't404p.resultsGeneral.subtitle',
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - resultsGeneral Text 3',
          description: 'T404P Results - resultsGeneral Page Text 3',
          data: {
            contentKey: 't404p.resultsGeneral.text3',
            type: TextContentType.Text3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - resultsGeneral Text 1',
          description: 'T404P Results - resultsGeneral Page Text 1',
          data: {
            contentKey: 't404p.resultsGeneral.text1',
            type: TextContentType.Text3,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - resultsSubtitleNext',
          description: 'T404P Results opt-out - resultsSubtitleNext',
          data: {
            contentKey: 't404p.resultsSubtitleNext',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results - resultsGeneral Text 2',
          description: 'T404P Results - resultsGeneral Page Text 2',
          data: {
            contentKey: 't404p.resultsGeneral.text2',
            type: TextContentType.Text1,
          },
        },
      ],
      flows: [
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'T404P To Redirect to Payments',
          description: 'T404P To Redirect to Payments',
          data: {
            nextPageId: 7,
          },
        },
      ],
    },
    {
      id: 15,
      name: 'T404P Results No Insurance',
      description: 'T404P Results Not Met page',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoEnd,
        continueKey: 't404p.resultsNoInsurance.button',
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P resultsTitle',
          description: 'Title for T404P resultsTitle',
          data: {
            contentKey: 't404p.resultsTitle',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results No Insurance Text',
          description: 'T404P Results No Insurance Page Text',
          data: {
            contentKey: 't404p.resultsNoInsurance.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - resultsSubtitleNext',
          description: 'T404P Results opt-out - resultsSubtitleNext',
          data: {
            contentKey: 't404p.resultsSubtitleNext',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results No Insurance Text 1',
          description: 'T404P Results No Insurance Page Text 1',
          data: {
            contentKey: 't404p.resultsNoInsurance.text1',
            type: TextContentType.Text1,
          },
        },
      ],
      flows: [
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'T404P To Redirect to Payments',
          description: 'T404P To Redirect to Payments',
          data: {
            nextPageId: 7,
          },
        },
      ],
    },
    {
      id: 16,
      name: 'T404P Results Unqualified',
      description: 'T404P Results Unqualified page',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoEnd,
        continueKey: 't404p.resultsUnqualified.button',
      },
      components: [
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P resultsTitle',
          description: 'Title for T404P resultsTitle',
          data: {
            contentKey: 't404p.resultsTitle',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H1,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results Unqualified Text',
          description: 'T404P Results Unqualified Text',
          data: {
            contentKey: 't404p.resultsUnqualified.text',
            type: TextContentType.Text1,
          },
        },
        {
          id: 3660,
          type: 'TEXT_CONTENT',
          name: 'T404P Results opt-out - resultsSubtitleNext',
          description: 'T404P Results opt-out - resultsSubtitleNext',
          data: {
            contentKey: 't404p.resultsSubtitleNext',
            highlightModifier: TextContentHighlightMod.Primary,
            type: TextContentType.H3,
          },
        },
        {
          id: 3662,
          type: 'TEXT_CONTENT',
          name: 'T404P Results Unqualified Text1',
          description: 'T404P Results Unqualified Text1',
          data: {
            contentKey: 't404p.resultsUnqualified.text1',
            type: TextContentType.Text1,
          },
        },
      ],
      flows: [
        {
          id: 2340,
          type: 'UNCONDITIONAL',
          name: 'T404P To Redirect to Payments',
          description: 'T404P To Redirect to Payments',
          data: {
            nextPageId: 7,
          },
        },
      ],
    },
    {
      id: 7,
      type: NodeTypes.Redirect,
      name: 'T404P Redirect to Payment',
      data: {
        url: 'https://www.payment.myriad.com/xifin-pay',
        titleKey: '',
        queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'],
      },
    },
    {
      id: 20,
      type: NodeTypes.Redirect,
      name: 'T404P Redirect to Myriad Programs',
      data: {
        url: 'https://www.genomemedical.com/programs/myriadproviders',
        titleKey: '',
        queryParamKeys: ['campaign_id', 'ajs_anonymous_id', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term', 'gclid'],
      },
    },
  ],
  contentTopNode: {
    id: 1023,
    name: 'T404P Welcome',
    description: 'Welcome page for T404P.',
    type: NodeTypes.Content,
    components: [
      {
        id: -1,
        type: 'BANNER_SECTION',
        data: {
          imageSrc: './images/img.png',
          titleKey: 'customProvider.banner.title',
          contentKey: 'customProvider.banner.text',
          buttonKey: 'customProvider.buttonText',
          isCustomProvider: true,
          isBottomBorderImage: true,
          backgroundColor: '#f2faff',
        },
      },
      {
        id: -2,
        type: 'CARDS_SECTION',
        data: {
          titleKey: 'customProvider.partners.title',
          subtitleKey: 'customProvider.partners.subtitle',
          isAlternativeStyle: true,
          bgImage: {
            src: 'placeholder.png',
            altKey: 'MyriadGenetics',
          },
          buttonKey: 'customProvider.buttonText',
          cards: [
            {
              isCustomProvider: true,
              titleKey: 'customProvider.partners.cards.provider.title',
              contentKey: 'customProvider.partners.cards.provider.text',
            },
            {
              imageSrc: 'https://myriad-media.s3.amazonaws.com/mgh/genome-medical.png',
              titleKey: 'customProvider.partners.cards.genome.title',
              contentKey: 'customProvider.partners.cards.genome.text',
            },
            {
              imageSrc: 'https://myriad-media.s3.amazonaws.com/mgh/medical-genetics.png',
              titleKey: 'customProvider.partners.cards.myriad.title',
              contentKey: 'customProvider.partners.cards.myriad.text',
            },
          ],
        },
      },
    ],
  },
  contentBottomNode: {
    id: 1023,
    name: 'T404P Welcome',
    description: 'Welcome page for T404P.',
    type: NodeTypes.Content,
    components: [
      {
        id: -2,
        type: 'CARDS_SECTION',
        data: {
          titleKey: 'customProvider.howItWorks.title',
          backgroundColor: '#f2faff',
          cards: [
            {
              icon: 'dialog.svg',
              titleKey: 'customProvider.howItWorks.answer.title',
              contentKey: 'customProvider.howItWorks.answer.text',
            },
            {
              icon: 'plus.svg',
              titleKey: 'customProvider.howItWorks.share.title',
              contentKey: 'customProvider.howItWorks.share.text',
            },
            {
              icon: 'delivery.svg',
              titleKey: 'customProvider.howItWorks.submit.title',
              contentKey: 'customProvider.howItWorks.submit.text',
            },
            {
              icon: 'receive.svg',
              titleKey: 'customProvider.howItWorks.receive.title',
              contentKey: 'customProvider.howItWorks.receive.text',
            },
          ],
        },
      },
      {
        id: -2,
        type: 'CARDS_SECTION',
        data: {
          titleKey: 'customProvider.review.title',
          subtitleKey: 'customProvider.review.subtitle',
          isBannerView: true,
          cards: [
            {
              titleKey: 'customProvider.review.ordering.title',
              contentKey: 'customProvider.review.ordering.text',
            },
            {
              titleKey: 'customProvider.review.costs.title',
              contentKey: 'customProvider.review.costs.text',
            },
          ],
        },
      },
      {
        id: -2,
        type: 'ACCORDION_SECTION',
        data: {
          titleKey: 'customProvider.faq.title',
          subtitleKey: 'customProvider.faq.subtitle',
          items: [
            {
              titleKey: 'customProvider.faq.questions.question1.title',
              contentKey: 'customProvider.faq.questions.question1.text',
            },
            {
              titleKey: 'customProvider.faq.questions.question2.title',
              contentKey: 'customProvider.faq.questions.question2.text',
            },
            {
              titleKey: 'customProvider.faq.questions.question3.title',
              contentKey: 'customProvider.faq.questions.question3.text',
            },
            {
              titleKey: 'customProvider.faq.questions.question4.title',
              contentKey: 'customProvider.faq.questions.question4.text',
            },
            {
              titleKey: 'customProvider.faq.questions.question5.title',
              contentKey: 'customProvider.faq.questions.question5.text',
            },
          ],
          moreLink: {
            linkKey: 'customProvider.faq.learnMore',
            linkValue: 'https://www.myriad.com/virtual-care-faqs',
          },
        },
      },
    ],
  },
  configuration: {
    criteriaCheckType: 'bytestfamily',
    criteriaCheckName: 'MGH+Test+Family',
    header: 'alternative',
    footer: 'alternative',
    theme: Theme.VirtualCare,
    isShowInModal: true,
  },
};

export const chatbot = {
  id: 6962,
  name: 'Chatbot Survey',
  description: 'Chatbot like survey',
  startNode: ChatbotSteps.mrnNode,
  endNode: dtcEnd,
  nodes: [
    ChatbotSteps.mrnNode,
    ChatbotSteps.startNode,
    ChatbotSteps.introWithTermsNode,
    ChatbotSteps.enterPersonalHistoryNode,
    ChatbotSteps.patientLocationNode,
    ChatbotSteps.personalHistoryIntroNode,
    ChatbotSteps.personalHistoryNode,
    ChatbotSteps.previouslyTested,
    ChatbotSteps.qualifyingQuestionNode,
    ChatbotSteps.personalCancerNode,
    ChatbotSteps.assessmentCompleteNode,
    ChatbotSteps.nextNode,
    ChatbotSteps.halfwayNode,
    ChatbotSteps.hideResultsNode,
    ChatbotSteps.postSurveyEntry,
    ChatbotSteps.meetsCriteriaOverNextStepsNode,
    ChatbotSteps.meetsCriteriaUnderNextStepsNode,
    ChatbotSteps.meetsCriteriaTCOverThresholdNode,
    ChatbotSteps.meetsCriteriaTCUnderThresholdNode,
    ChatbotSteps.meetsCriteriaGeneticCounselorContactNode,
    ChatbotSteps.outsideCriteriaTCOverThresholdNode,
    ChatbotSteps.outsideCriteriaTCUnderThresholdNode,
    ChatbotSteps.breastCancerRiskNode,
    ChatbotSteps.medicalHistoryModalNode,
    ChatbotSteps.cancerHistoryIntroNode,
    ChatbotSteps.thankYouExitLink,
  ],
  createdDate: '2021-11-30T08:37:31.130265-07:00',
  createdBy: 'svc_dxtest1',
  configuration: {
    criteriaCheckType: 'bytestfamily',
    criteriaCheckName: 'MGH+Test+Family',
    header: 'chatbot',
    footer: 'chatbot',
    theme: Theme.Chatbot,
    isShowInModal: false,
    preloadExternalResources: [
      {
        type: 'image',
        href: 'chatbot.meetsCriteriaResults.metCheck.src',
      },
      {
        type: 'image',
        href: 'chatbot.meetsCriteriaResults.candidateForScreeningImage.src',
      },
      {
        type: 'image',
        href: 'chatbot.meetsCriteriaResults.mayQualify.src',
      },
      {
        type: 'image',
        href: 'chatbot.outsideCriteriaResults.minusImg.src',
      },
      {
        type: 'image',
        href: 'chatbot.outsideCriteriaResults.notCandidateImage.src',
      },
    ],
  },
};

export const getSurveyAccounts = (url: string) => {
  if (url.indexOf('dtc2demo') !== -1) {
    return [{ ...defaultByUrl[0], surveyId: 4 }];
  }

  if (url.indexOf('dtcdemo') !== -1) {
    return [{ ...defaultByUrl[0], surveyId: 3 }];
  }

  if (url.indexOf('dtc-complete') !== -1) {
    return [{ ...defaultByUrl[0], surveyId: 5 }];
  }

  if (url.indexOf('vc-') !== -1) {
    return [{ ...defaultByUrl[0], surveyId: 6 }];
  }

  if (url.indexOf('cb-') !== -1) {
    return [{ ...defaultByUrl[1], surveyId: 7 }];
  }

  if (url.indexOf('demo-hcstandard') !== -1) {
    return [{ ...defaultByUrl[0], surveyId: 1 }];
  }

  return defaultByUrl;
};

export const getSurveyAccountById = (id: number) => {
  if (id === 1) {
    return { ...defaultByUrl[0], surveyId: 5 };
  }

  return defaultByUrl[0];
};

export const getPatientSurvey = (id: number) => {
  switch (id) {
    case 3:
      return dtcSurvey;
    case 4:
      return dtc2Survey;
    case 5:
      return dtcComplete;
    case 6:
      return t404pDemo;
    case 7:
      return chatbot;
    default:
      return defaultSurvey;
  }
};

export const postCriteriaResults = (body: any) => {
  const relatives = get(body, 'responses.cancerHistory.relatives');
  if (relatives && Object.keys(relatives).length > 0) {
    return criteriaResultsMet;
  }
  return criteriaResultsNotMet;
};

export const postPatientSurveyEntries = (body: any) => {
  let tcResult;
  const hasBreastCancerRisk = body.responses && body.responses.breastCancer;
  const { menopauseCurrentOrPast } = hasBreastCancerRisk ? body.responses.breastCancerRisk : false;
  if (menopauseCurrentOrPast) {
    tcResult = {
      remaining_lifetime_risk: 0.15,
      period_risk: 0.001,
    };
  } else if (menopauseCurrentOrPast === false) {
    tcResult = {
      remaining_lifetime_risk: 0.2,
      period_risk: 0.001,
    };
  }

  const newBody = {
    ...body,
    responses: {
      ...body.responses,
      tcResult,
    },
  };
  return {
    ...saveResp,
    ...newBody,
  };
};

const dtcOrderDemographicMock = {
  firstName: 'TEST Accession',
  lastName: 'TESTERSON Accession',
  dob: '1970-02-02',
  age: '14',
  phoneType: 'mobile',
  phone: '333-333-3333',
  email: 'test.testerson@exaample.com',
  address: '12 TEST STREET',
  city: 'BEVERLY HILLS',
  postalCode: '12345',
  state: [
    {
      optionKey: 1,
      optionValue: 'personDemographic.states.alabama',
    },
  ],
};

export const dtcOrderDemographicsInComplete = {
  accessionId: 'GM2',
  isCompleted: false,
};

export const dtcOrderDemographicsComplete = {
  accessionId: 'GM1',
  isCompleted: true,
  demographic: dtcOrderDemographicMock,
};

export const dtcOrderDemographicsInCompleteWithData = {
  ...dtcOrderDemographicsComplete,
  accessionId: 'GM3',
  isCompleted: false,
};

export const dtcOrderDemographicsNotFound = {
  status: {
    statusCode: 501,
    statusMessage: 'Data Not Found.',
  },
};

export const dtcOrderDemographicsBadRequest = {
  status: {
    statusCode: 400,
    statusMessage: 'Invalid Order Id',
  },
};

export const dtcOrderDemographicsOrderNotFound = {
  status: {
    statusCode: 404,
    statusMessage: 'Order Not Found',
  },
};
export const dtcOrderDemographicsAccessionNotFound = {
  status: {
    statusCode: 404,
    statusMessage: 'Accession Not Found',
  },
};

export const dtcOrderDemographicsClashingId = {
  status: {
    statusCode: 422,
    statusMessage: 'orderUuid (C18475B9-496F-463C-93F1-ECDDC2E10BD7) did not match',
  },
};

export const dtcOrderDemographicsInvalidStatus = {
  status: {
    statusCode: 500,
    statusMessage: 'Dtc Order Status is invalid for the dtc order with uuid: C18475B9-496F-463C-93F1-ECDDC2E10BD7',
  },
};

export const dtcOrderDemographicsInternalError = {
  status: {
    statusCode: 500,
    statusMessage: 'internal error',
  },
};

export const surveyEntryAccountNotFound = {
  status_code: 404,
  error_type: 'error.code.undefined',
  error_message: 'blox.error.status.code',
};
