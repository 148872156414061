import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube, faInstagram, IconDefinition } from '@fortawesome/free-brands-svg-icons';

import '../index.scss';

interface componentsMapProps {
  [key: string]: IconDefinition;
}

const iconsMap: componentsMapProps = {
  facebook: faFacebookF,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
  twitter: faTwitter,
  youtube: faYoutube,
};

interface FooterItemProps {
  urlKey: string;
  iconKey: string;
}

interface FooterNavProps {
  items: FooterItemProps[];
}

function SocialMenu(props: FooterNavProps) {
  const { t } = useTranslation();

  return (
    <ul className="social-menu">
      {props.items.map(({ urlKey, iconKey }, index) => (
        <li key={index}>
          <a href={t(urlKey)} title={iconKey} target="_blank" rel="noreferrer" className="social-menu__link">
            <FontAwesomeIcon icon={iconsMap[iconKey]} className="social-menu__icon" />
          </a>
        </li>
      ))}
    </ul>
  );
}

export default SocialMenu;
