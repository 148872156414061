import { OptionKeyValue } from '@myriadgenetics/mgh-types';

export const isOptionSelected = (selectedOption: OptionKeyValue, selectedOptions: OptionKeyValue[] | string): boolean =>
  Array.isArray(selectedOptions) && selectedOptions.some(({ optionKey }) => optionKey === selectedOption.optionKey);

export const getDisplayKey = (selectedOption: OptionKeyValue, isSelected?: boolean): string =>
  (isSelected && selectedOption.optionSelectedDisplayKey) || selectedOption.optionDisplayKey || selectedOption.optionValue || '';

export const notSure = 'notSure';

export default {
  isOptionSelected,
  notSure,
};
