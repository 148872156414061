import { BreastCancerRiskNode, PageNode } from '@myriadgenetics/mgh-types';
import NodeWorkflow from '../node-workflow';
import { BreastCancerRisk } from './nodes';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { findNodeById } from '../helpers';
import './index.scss';

interface Props {
  node: BreastCancerRiskNode;
  initNode?: PageNode;
  responses: any;
  gotoNextNode: (nodeId: number | null | undefined) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  isSubWorkflow?: boolean;
  hasBackButton?: boolean;
  className?: string;
}

function BreastCancerRiskNodeReact({ node, responses, gotoNextNode, onResponseUpdated, initNode, isSubWorkflow, hasBackButton, className }: Props) {
  const { continueType, subtitle, type } = node?.data || {};
  const nodeList = BreastCancerRisk.steps('breastCancerRisk', continueType, subtitle, type);

  return (
    <>
      <NodeWorkflow
        className={`breast-cancer-risk ${className ? className : ''}`}
        nodes={nodeList}
        initNode={initNode || findNodeById(BreastCancerRisk.initNodeId, nodeList)}
        gotoNextNode={() => gotoNextNode(getNextNodeId(node?.flows || [], responses))}
        onResponseUpdated={onResponseUpdated}
        responses={responses}
        isSubWorkflow={isSubWorkflow}
        hasBackButton={hasBackButton}
        isChatbot={false}
      />
    </>
  );
}

export default BreastCancerRiskNodeReact;
