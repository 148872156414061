import { ChatModalNodeOptions, ComponentSizes, ContinueType, NodeTypes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const hideResultsStep = ({ subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.HIDE_RESULTS_NODE,
  name: 'Chatbot Meets Criteria Hide Results Checkbox',
  description: 'Chatbot Meets Criteria Hide Results Checkbox',
  type: NodeTypes.Page,
  data: {
    continueType: ContinueType.Fixed,
    subtitle,
    continueKey: 'chatbot.modal.next',
    pageTitleKey: 'pageTitles.hideResultsNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'OPTION_SELECT',
      name: 'Meets Criteria Hide Results',
      description: 'Meets Criteria Hide Results',
      data: {
        questionKey: '',
        answerKey: 'consentHideResults',
        isSingleSelect: false,
        optionSize: ComponentSizes.Large,
        isShowOptionAsCheckbox: true,
        required: true,
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'chatbot.assessmentResults.discussResultsWithProviderConsent',
          },
        ],
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
      },
    },
  ],
  flows: [],
});
