import { BaseError, RecoverTypes, RejectionErrorTypes, TextLink } from '@myriadgenetics/mgh-types';

type AuxiliaryPageState = {
  titleKey: string;
  messageKey: string;
  recoverAction?: RecoverTypes;
  recoverTextKey?: string;
  textLinks?: TextLink[];
};

type ErrorStatusTypes = {
  [status: number]: {
    [type: string]: RejectionErrorTypes;
  };
};

const routeToAuxPage = (history: any, { titleKey, messageKey, recoverAction, recoverTextKey, textLinks }: AuxiliaryPageState) => {
  history.push('/auxiliary', {
    ...history.location.state,
    auxState: {
      titleKey,
      messageKey,
      recoverAction,
      recoverTextKey,
      textLinks,
    },
  });
};

export const errorStateMap: Record<RejectionErrorTypes, BaseError> = {
  [RejectionErrorTypes.Application]: {
    titleKey: 'errorPages.application.title',
    messageKey: 'errorPages.application.message',
    textLinks: [{ urlKey: 'errorPages.application.links.phone' }, { urlKey: 'errorPages.application.links.email' }],
  },
  [RejectionErrorTypes.Server]: {
    titleKey: 'errorPages.server.title',
    messageKey: 'errorPages.server.message',
    textLinks: [{ urlKey: 'errorPages.server.links.phone' }, { urlKey: 'errorPages.server.links.email' }],
    recoverTextKey: 'errorPages.server.action',
    recoverAction: RecoverTypes.GoBack,
  },
  [RejectionErrorTypes.Network]: {
    titleKey: 'errorPages.network.title',
    messageKey: 'errorPages.network.message',
    textLinks: [{ urlKey: 'errorPages.network.links.email' }, { urlKey: 'errorPages.network.links.phone' }],
  },
  [RejectionErrorTypes.InvalidJson]: {
    titleKey: 'errorPages.server.title',
    messageKey: 'errorPages.server.message',
    recoverTextKey: 'startOver',
    recoverAction: RecoverTypes.Restart,
  },
  [RejectionErrorTypes.AccessionNotFound]: {
    titleKey: 'errorPages.accession.title',
    messageKey: 'errorPages.accession.message',
    textLinks: [{ urlKey: 'errorPages.accession.links.email' }, { urlKey: 'errorPages.accession.links.phone' }],
  },
  [RejectionErrorTypes.SurveyAccountIdNotFound]: {
    titleKey: 'errorPages.surveyAccountId.title',
    messageKey: 'errorPages.surveyAccountId.message',
    textLinks: [{ urlKey: 'errorPages.surveyAccountId.links.email' }, { urlKey: 'errorPages.surveyAccountId.links.phone' }],
  },
  [RejectionErrorTypes.MissingAccount]: {
    titleKey: 'provider.missingAccountsTitle',
    messageKey: 'provider.missingAccounts',
    textLinks: [{ urlKey: 'provider.links.phone' }, { urlKey: 'provider.links.email' }],
  },
  [RejectionErrorTypes.SurveyCompleted]: {
    titleKey: 'errorPages.surveyCompleted.title',
    messageKey: 'errorPages.surveyCompleted.message',
    textLinks: [{ urlKey: 'errorPages.surveyCompleted.links.email' }, { urlKey: 'errorPages.surveyCompleted.links.phone' }],
  },
};

export const errorStatusTypesMap: ErrorStatusTypes = {
  400: {
    'order.id.BadRequest': RejectionErrorTypes.AccessionNotFound,
  },
  404: {
    'accession.NotFound': RejectionErrorTypes.AccessionNotFound,
    'order.NotFound': RejectionErrorTypes.AccessionNotFound,
  },
  422: {
    'order.id.Clash': RejectionErrorTypes.AccessionNotFound,
  },
  500: {
    'order.status.invalid': RejectionErrorTypes.AccessionNotFound,
    'security.error.request.isbad': RejectionErrorTypes.Application,
  },
};

export default {
  routeToAuxPage,
};
