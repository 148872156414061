import {
  PageNode,
  Relative,
  PathologyName,
  UnconditionalFlow,
  TextQuestionComponent,
  OptionSelectComponent,
  HelpHintComponent,
  HelpHintsComponent,
  YesNoComponent,
  Hint,
  SecondaryResponse,
  ComponentSizes,
  OptionKeyValue,
  TextQuestionType,
} from '@myriadgenetics/mgh-types';
import { ContinueType, NodeTypes } from '@myriadgenetics/mgh-types/dist/types/surveyNode';

export enum BreastSide {
  Right = 'right',
  Left = 'left',
  Bilateral = 'bilateral',
}

export enum Occurrence {
  First = 'pathology.breast.first',
  Second = 'pathology.breast.second',
}

const createHelpHint = ({ id, termKey, definitionKey }: { id: number; termKey: string; definitionKey: string }): HelpHintComponent => ({
  id,
  type: 'HELP_HINT',
  data: {
    helpHint: {
      termKey,
      definitionKey,
    },
  },
});

const createHelpHints = ({ id, helpHints }: { id: number; helpHints: Hint[] }): HelpHintsComponent => ({
  id,
  type: 'HELP_HINTS',
  data: {
    modalTriggerKey: 'helpHints.modalTrigger',
    helpHints,
  },
});

const createYesNoComponent = ({
  id,
  questionKey,
  questionKeyParams,
  answerKey,
  noKey,
}: {
  id: number;
  questionKey: string;
  questionKeyParams?: Record<string, string | number>;
  answerKey: string;
  noKey?: string;
}): YesNoComponent => ({
  id,
  type: 'YES_NO',
  data: {
    questionKey,
    questionKeyParams,
    answerKey,
    required: true,
    noKey,
  },
});

const createAgeQuestion = ({
  id,
  questionKey,
  questionKeyParams,
  labelKey,
  placeholderKey,
  answerKey,
  required,
  secondaryResponse,
  min,
  max,
  threshold,
}: {
  id: number;
  questionKey: string;
  questionKeyParams?: Record<string, string | number>;
  labelKey: string;
  placeholderKey: string;
  answerKey: string;
  required: boolean;
  secondaryResponse?: SecondaryResponse;
  min: number;
  max: number;
  threshold: number;
}): TextQuestionComponent => ({
  id,
  type: 'TEXT_QUESTION',
  data: {
    required,
    questionKey,
    questionKeyParams,
    labelKey,
    placeholderKey,
    answerKey,
    size: ComponentSizes.Large,
    inputType: TextQuestionType.Age,
    secondaryResponse,
    min,
    max,
    threshold,
  },
});

const createOptionSelect = ({
  id,
  questionKey,
  questionKeyParams,
  answerKey,
  required,
  isSingleSelect,
  isNative,
  labelKey,
  subtitleKey,
  placeholderKey,
  selectionOptions,
  optionSize,
}: {
  id: number;
  questionKey: string;
  questionKeyParams?: Record<string, string | number>;
  answerKey: string;
  required: boolean;
  isSingleSelect: boolean;
  isNative: boolean;
  labelKey: string;
  subtitleKey: string;
  placeholderKey: string;
  selectionOptions: OptionKeyValue[];
  optionSize: ComponentSizes;
}): OptionSelectComponent => ({
  id,
  type: 'OPTION_SELECT',
  data: {
    questionKey,
    questionKeyParams,
    answerKey,
    required,
    isSingleSelect,
    isNative,
    selectionOptions,
    labelKey,
    subtitleKey,
    placeholderKey,
    optionSize,
  },
});

// only for self
const breastSideComponent = ({ id, baseKey }: { id: number; baseKey: string }): OptionSelectComponent =>
  createOptionSelect({
    id,
    answerKey: `${baseKey}.affected`,
    questionKey: 'pathology.breast.affected',
    questionKeyParams: {},
    required: true,
    isSingleSelect: true,
    isNative: false,
    subtitleKey: '',
    placeholderKey: '',
    labelKey: '',
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      { optionKey: 1, optionValue: 'pathology.breast.left' },
      { optionKey: 2, optionValue: 'pathology.breast.right' },
      { optionKey: 3, optionValue: 'pathology.breast.bilateral' },
    ],
  });

// only for non-self relatives
const breastBilateral = ({ id, baseKey, relative }: { id: number; baseKey: string; relative: Relative }): OptionSelectComponent =>
  createOptionSelect({
    id,
    // name: 'Bilateral Relative',
    answerKey: `${baseKey}.isBilateral`,
    questionKey: 'pathology.breast.bilateralRelative',
    questionKeyParams: { relative },
    required: true,
    isSingleSelect: true,
    isNative: false,
    subtitleKey: '',
    placeholderKey: '',
    labelKey: '',
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      { optionKey: 1, optionValue: 'yes' },
      { optionKey: 2, optionValue: 'no' },
      { optionKey: 3, optionValue: 'pathology.dontKnow' },
    ],
  });

const relativeBilateralAge = ({
  id,
  baseKey,
  relative,
  occurrence,
  cancer,
}: {
  id: number;
  baseKey: string;
  relative: Relative;
  occurrence: Occurrence;
  cancer: PathologyName;
}): TextQuestionComponent =>
  createAgeQuestion({
    id,
    questionKey: 'pathology.breast.bilateralRelativeAge',
    questionKeyParams: { relative, occurrence, cancer },
    labelKey: 'ageLabel',
    placeholderKey: 'agePlaceHolder',
    answerKey: `${baseKey}.age`,
    required: true,
    min: 0,
    max: 115,
    threshold: 100,
    secondaryResponse: {
      textKey: 'pathology.dontKnow',
      value: 'notSure',
    },
  });

const ageComponent = ({ id, baseKey, relative }: { id: number; baseKey: string; relative: Relative }): TextQuestionComponent =>
  createAgeQuestion({
    id,
    answerKey: `${baseKey}.age`,
    labelKey: 'ageLabel',
    placeholderKey: 'agePlaceHolder',
    questionKey: relative === 'relatives.self' ? 'pathology.age' : 'pathology.ageRelative',
    questionKeyParams: { relative },
    required: true,
    min: 0,
    max: 115,
    threshold: 100,
    secondaryResponse:
      relative === 'relatives.self'
        ? undefined
        : {
            textKey: 'pathology.dontKnow',
            value: 'notSure',
          },
  });

const ageRangeComponent = ({
  id,
  baseKey,
  relative,
  occurrence,
  pathology,
}: {
  id: number;
  baseKey: string;
  relative: Relative;
  occurrence?: Occurrence;
  pathology?: PathologyName;
}): OptionSelectComponent =>
  createOptionSelect({
    id,
    questionKey: pathology === 'cancerHistory.cancers.breastFemale' ? 'pathology.breast.bilateralRelativeAgeRange' : 'pathology.ageRange',
    questionKeyParams: { relative, occurrence: occurrence || ' ' },
    isNative: true,
    isSingleSelect: true,
    answerKey: `${baseKey}.age`,
    labelKey: 'pathology.ageRangeLabel',
    required: true,
    placeholderKey: '',
    subtitleKey: '',
    optionSize: ComponentSizes.Large,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: 'pathology.ageRanges.0-9',
      },
      {
        optionKey: 2,
        optionValue: 'pathology.ageRanges.10-19',
      },
      {
        optionKey: 3,
        optionValue: 'pathology.ageRanges.20-29',
      },
      {
        optionKey: 4,
        optionValue: 'pathology.ageRanges.30-39',
      },
      {
        optionKey: 5,
        optionValue: 'pathology.ageRanges.40-49',
      },
      {
        optionKey: 6,
        optionValue: 'pathology.ageRanges.50-59',
      },
      {
        optionKey: 7,
        optionValue: 'pathology.ageRanges.60-69',
      },
      {
        optionKey: 8,
        optionValue: 'pathology.ageRanges.70-79',
      },
      {
        optionKey: 9,
        optionValue: 'pathology.ageRanges.80-89',
      },
      {
        optionKey: 10,
        optionValue: 'pathology.ageRanges.90-99',
      },
    ],
  });

const breastSideAgeComponent = ({ id, baseKey, breastSide }: { id: number; baseKey: string; breastSide: BreastSide }) =>
  createAgeQuestion({
    id,
    questionKey: `pathology.breast.${breastSide}BreastAge`,
    labelKey: 'ageLabel',
    placeholderKey: 'agePlaceHolder',
    answerKey: `${baseKey}.${breastSide}BreastAge`,
    questionKeyParams: {},
    required: true,
    min: 0,
    max: 115,
    threshold: 100,
    secondaryResponse: undefined,
  });

const breastDetailsComponent = ({
  id,
  baseKey,
  relative,
  occurrence,
}: {
  id: number;
  baseKey: string;
  relative: Relative;
  occurrence?: Occurrence;
}): OptionSelectComponent =>
  createOptionSelect({
    id,
    questionKey: relative === 'relatives.self' ? 'pathology.breast.details' : 'pathology.breast.detailsRelative',
    questionKeyParams: { relative, occurrence: occurrence || ' ' },
    answerKey: `${baseKey}.details`,
    isNative: false,
    isSingleSelect: true,
    subtitleKey: '',
    placeholderKey: '',
    labelKey: '',
    required: true,
    optionSize: ComponentSizes.Medium,
    selectionOptions: [
      { optionKey: 1, optionValue: 'pathology.breast.ductal' },
      { optionKey: 2, optionValue: 'pathology.breast.lobular' },
      { optionKey: 3, optionValue: 'pathology.breast.dcis' },
      { optionKey: 4, optionValue: 'pathology.breast.none' },
    ],
  });

const tripleNegativeComponent = ({
  id,
  baseKey,
  relative,
  occurrence,
  pathology,
}: {
  id: number;
  baseKey: string;
  relative: Relative;
  occurrence?: Occurrence;
  pathology?: PathologyName;
}): YesNoComponent =>
  createYesNoComponent({
    id,
    questionKey: relative === 'relatives.self' ? 'pathology.breast.tripleNegative' : 'pathology.breast.tripleNegativeRelative',
    questionKeyParams:
      relative === 'relatives.self'
        ? undefined
        : {
            relative,
            occurrence: occurrence || ' ',
            cancer: pathology || ' ',
          },
    answerKey: `${baseKey}.tripleNegative`,
    noKey: 'pathology.noOrUnknown',
  });

const metastaticComponent = ({ id, baseKey }: { id: number; baseKey: string }): YesNoComponent =>
  createYesNoComponent({
    id,
    questionKey: 'pathology.metastatic',
    answerKey: `${baseKey}.metastatic`,
    noKey: 'pathology.noOrUnknown',
  });

const currentTreatmentComponent = ({ id, baseKey }: { id: number; baseKey: string }): YesNoComponent =>
  createYesNoComponent({
    id,
    questionKey: 'pathology.breast.currentTreatment',
    answerKey: `${baseKey}.currentTreatment`,
  });

const gleasonScoreComponent = ({ id, baseKey, relative }: { id: number; baseKey: string; relative: Relative }): OptionSelectComponent =>
  createOptionSelect({
    id,
    questionKey: relative === 'relatives.self' ? 'pathology.prostate.gleasonScore' : 'pathology.prostate.gleasonScoreRelative',
    questionKeyParams: { relative },

    answerKey: `${baseKey}.gleasonScore`,
    required: false,
    isNative: true,
    isSingleSelect: true,
    labelKey: 'pathology.prostate.gleasonScoreText',
    placeholderKey: '',
    subtitleKey: '',
    optionSize: ComponentSizes.Large,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: '1',
      },
      {
        optionKey: 2,
        optionValue: '2',
      },
      {
        optionKey: 3,
        optionValue: '3',
      },
      {
        optionKey: 4,
        optionValue: '4',
      },
      {
        optionKey: 5,
        optionValue: '5',
      },
      {
        optionKey: 6,
        optionValue: '6',
      },
      {
        optionKey: 7,
        optionValue: '7',
      },
      {
        optionKey: 8,
        optionValue: '8',
      },
      {
        optionKey: 9,
        optionValue: '9',
      },
      {
        optionKey: 10,
        optionValue: '10',
      },
      {
        optionKey: 11,
        optionValue: 'notSure',
      },
    ],
  });

const nextPageNode = ({ id, nextPageId }: { id: number; nextPageId: number | null }): UnconditionalFlow => ({
  id,
  type: 'UNCONDITIONAL',
  data: {
    nextPageId,
  },
});

const SELF_RELATIVE: Relative = 'relatives.self';

export class BreastCancer {
  static bilateralInitId = -9020;
  static leftSideInitId = -9040;
  static initId = -9000;
  static relativeInitId = -9060;

  static stepsSelf = (baseKey: string): PageNode[] => [
    {
      id: BreastCancer.initId,
      name: 'Breast Cancer Side',
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerNode',
      },
      type: NodeTypes.Page,
      components: [
        breastSideComponent({ id: 1, baseKey }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.bilateral',
          definitionKey: 'helpHints.bilateralHint',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: BreastCancer.bilateralInitId,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.affected.0.optionValue`,
                value: 'pathology.breast.bilateral',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9001 }),
      ],
    },
    {
      id: -9001,
      name: 'Breast Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerAgeNode',
      },
      components: [
        ageComponent({
          id: 1,
          baseKey,
          relative: SELF_RELATIVE,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9002 })],
    },
    {
      id: -9002,
      name: 'Breast Cancer Type',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerTypeNode',
      },
      components: [
        breastDetailsComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
          relative: SELF_RELATIVE,
        }),
        createHelpHints({
          id: 2,
          helpHints: [
            {
              termKey: 'helpHints.ductalInvasive',
              definitionKey: 'helpHints.ductalInvasiveHint',
            },
            {
              termKey: 'helpHints.lobularInvasive',
              definitionKey: 'helpHints.lobularInvasiveHint',
            },
            {
              termKey: 'helpHints.dcis',
              definitionKey: 'helpHints.dcisHint',
            },
          ],
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9003 })],
    },
    {
      id: -9003,
      name: 'Breast Cancer Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerTripleNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9004 })],
    },
    {
      id: -9004,
      name: 'Breast Cancer Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.breast` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9005 })],
    },
    {
      id: -9005,
      name: 'Currently Being Treated',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerNode',
      },
      components: [
        currentTreatmentComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
    {
      id: BreastCancer.bilateralInitId,
      name: 'Breast Cancer Bilateral Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerBilateralNode',
      },
      components: [
        breastSideAgeComponent({
          id: 1,
          baseKey,
          breastSide: BreastSide.Right,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9021 })],
    },
    {
      id: -9021,
      name: 'Breast Cancer Type',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRightSideNode',
      },
      components: [
        breastDetailsComponent({
          id: 1,
          baseKey: `${baseKey}.right`,
          relative: SELF_RELATIVE,
        }),
        createHelpHints({
          id: 2,
          helpHints: [
            {
              termKey: 'helpHints.ductalInvasive',
              definitionKey: 'helpHints.ductalInvasiveHint',
            },
            {
              termKey: 'helpHints.lobularInvasive',
              definitionKey: 'helpHints.lobularInvasiveHint',
            },
            {
              termKey: 'helpHints.dcis',
              definitionKey: 'helpHints.dcisHint',
            },
          ],
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9022 })],
    },
    {
      id: -9022,
      name: 'Breast Cancer Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.right`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9023 })],
    },
    {
      id: -9023,
      name: 'Breast Cancer Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerMetastaticRightNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.right` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9024 })],
    },
    {
      id: -9024,
      name: 'Currently Being Treated',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerCurrentTreatmentRightNode',
      },
      components: [
        currentTreatmentComponent({
          id: 1,
          baseKey: `${baseKey}.right`,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: BreastCancer.leftSideInitId })],
    },
    {
      id: BreastCancer.leftSideInitId,
      name: 'Breast Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerLeftSideNode',
      },
      components: [
        breastSideAgeComponent({
          id: 1,
          baseKey,
          breastSide: BreastSide.Left,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9041 })],
    },
    {
      id: -9041,
      name: 'Breast Cancer Type',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerLeftSideDuctalNode',
      },
      components: [
        breastDetailsComponent({
          id: 1,
          baseKey: `${baseKey}.left`,
          relative: SELF_RELATIVE,
        }),
        createHelpHints({
          id: 2,
          helpHints: [
            {
              termKey: 'helpHints.ductalInvasive',
              definitionKey: 'helpHints.ductalInvasiveHint',
            },
            {
              termKey: 'helpHints.lobularInvasive',
              definitionKey: 'helpHints.lobularInvasiveHint',
            },
            {
              termKey: 'helpHints.dcis',
              definitionKey: 'helpHints.dcisHint',
            },
          ],
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9042 })],
    },
    {
      id: -9042,
      name: 'Breast Cancer Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerLeftSideTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.left`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9043 })],
    },
    {
      id: -9043,
      name: 'Breast Cancer Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerLeftSideMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.left` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9044 })],
    },
    {
      id: -9044,
      name: 'Currently Being Treated',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerCurrentTreatmentLeftNode',
      },
      components: [
        currentTreatmentComponent({
          id: 1,
          baseKey: `${baseKey}.left`,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];

  static stepsRelative = (baseKey: string, relative: Relative): PageNode[] => [
    {
      id: BreastCancer.relativeInitId,
      name: 'Breast Cancer Relative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeNode',
      },
      components: [
        breastBilateral({ id: 1, baseKey, relative }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.bilateral',
          definitionKey: 'helpHints.bilateralHint',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9061,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.isBilateral[0].optionValue`,
                value: 'yes',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9069 }),
      ],
    },
    {
      id: -9061,
      name: 'Breast Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeFemaleNode',
      },
      components: [
        relativeBilateralAge({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
          cancer: 'cancerHistory.cancers.breastFemale',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9062,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.first.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9063 }),
      ],
    },
    {
      id: -9062,
      name: 'Breast Cancer Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeFemaleNode',
      },
      components: [
        ageRangeComponent({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
          pathology: 'cancerHistory.cancers.breastFemale',
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 2, nextPageId: -9063 })],
    },
    {
      id: -9063,
      type: NodeTypes.Page,
      name: 'Breast Cancer Type',
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeDuctalNode',
      },
      components: [
        breastDetailsComponent({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
        }),
        createHelpHints({
          id: 2,
          helpHints: [
            {
              termKey: 'helpHints.ductalInvasive',
              definitionKey: 'helpHints.ductalInvasiveHint',
            },
            {
              termKey: 'helpHints.lobularInvasive',
              definitionKey: 'helpHints.lobularInvasiveHint',
            },
            {
              termKey: 'helpHints.dcis',
              definitionKey: 'helpHints.dcisHint',
            },
          ],
        }),
      ],
      flows: [nextPageNode({ id: 2, nextPageId: -9064 })],
    },
    {
      id: -9064,
      name: 'Breast Cancer Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9065 })],
    },
    {
      id: -9065,
      name: 'Breast Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeBilateralAgeNode',
      },
      components: [
        relativeBilateralAge({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
          cancer: 'cancerHistory.cancers.breastFemale',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9066,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.second.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9067 }),
      ],
    },
    {
      id: -9066,
      name: 'Breast Cancer Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeAgeRangeNode',
      },
      components: [
        ageRangeComponent({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
          pathology: 'cancerHistory.cancers.breastFemale',
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 2, nextPageId: -9067 })],
    },
    {
      id: -9067,
      name: 'Breast Cancer Types',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeInvasiveNode',
      },
      components: [
        breastDetailsComponent({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
        }),
        createHelpHints({
          id: 2,
          helpHints: [
            {
              termKey: 'helpHints.ductalInvasive',
              definitionKey: 'helpHints.ductalInvasiveHint',
            },
            {
              termKey: 'helpHints.lobularInvasive',
              definitionKey: 'helpHints.lobularInvasiveHint',
            },
            {
              termKey: 'helpHints.dcis',
              definitionKey: 'helpHints.dcisHint',
            },
          ],
        }),
      ],
      flows: [nextPageNode({ id: 2, nextPageId: -9068 })],
    },
    {
      id: -9068,
      name: 'Breast Cancer Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
    {
      id: -9069,
      name: 'Breast Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeAgeNode',
      },
      components: [ageComponent({ id: 1, baseKey, relative })],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9070,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9071 }),
      ],
    },
    {
      id: -9070,
      name: 'Breast Cancer Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeAgeRangeNode',
      },
      components: [
        ageRangeComponent({ id: 1, baseKey, relative }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9071 })],
    },
    {
      id: -9071,
      name: 'Breast Cancer Type',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeDuctalNode',
      },
      components: [
        breastDetailsComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
          relative,
        }),
        createHelpHints({
          id: 2,
          helpHints: [
            {
              termKey: 'helpHints.ductalInvasive',
              definitionKey: 'helpHints.ductalInvasiveHint',
            },
            {
              termKey: 'helpHints.lobularInvasive',
              definitionKey: 'helpHints.lobularInvasiveHint',
            },
            {
              termKey: 'helpHints.dcis',
              definitionKey: 'helpHints.dcisHint',
            },
          ],
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9072 })],
    },
    {
      id: -9072,
      name: 'Breast Cancer Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
          relative,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9073 })],
    },
    {
      id: -9073,
      name: 'Breast Cancer Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.breastCancerRelativeMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.breast` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];
}

export class DCIS {
  static bilateralInitId = -9120;
  static leftSideInitId = -9140;
  static initId = -9100;
  static relativeInitId = -9160;

  static stepsSelf = (baseKey: string): PageNode[] => [
    {
      id: DCIS.initId,
      name: 'DCIS',
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISNode',
      },
      type: NodeTypes.Page,
      components: [
        breastSideComponent({ id: 1, baseKey: `${baseKey}.breast` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.bilateral',
          definitionKey: 'helpHints.bilateralHint',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: DCIS.bilateralInitId,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.breast.affected.0.optionValue`,
                value: 'pathology.breast.bilateral',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9101 }),
      ],
    },
    {
      id: -9101,
      name: 'DCIS Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISAgeNode',
      },
      components: [
        ageComponent({
          id: 1,
          baseKey,
          relative: SELF_RELATIVE,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9103 })],
    },
    {
      id: -9103,
      name: 'DCIS Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9104 })],
    },
    {
      id: -9104,
      name: 'DCIS Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.breast` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9105 })],
    },
    {
      id: -9105,
      name: 'Currently Being Treated',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISCurrentTreatmentNode',
      },
      components: [
        currentTreatmentComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
    {
      id: DCIS.bilateralInitId,
      name: 'DCIS Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRightNode',
      },
      components: [
        breastSideAgeComponent({
          id: 1,
          baseKey,
          breastSide: BreastSide.Right,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9122 })],
    },
    {
      id: -9122,
      name: 'DCIS Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISTripleNegativeRightNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.right`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9123 })],
    },
    {
      id: -9123,
      name: 'DCIS Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISMetastaticRightNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.right` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9124 })],
    },
    {
      id: -9124,
      name: 'Currently Being Treated',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRightNode',
      },
      components: [
        currentTreatmentComponent({
          id: 1,
          baseKey: `${baseKey}.right`,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: DCIS.leftSideInitId })],
    },
    {
      id: DCIS.leftSideInitId,
      name: 'DCIS Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISLeftNode',
      },
      components: [breastSideAgeComponent({ id: 1, baseKey, breastSide: BreastSide.Left })],
      flows: [nextPageNode({ id: 1, nextPageId: -9142 })],
    },
    {
      id: -9142,
      name: 'DCIS Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISTripleNegativeLeftNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.left`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9143 })],
    },
    {
      id: -9143,
      name: 'DCIS Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISMetastaticLeftNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.left` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9144 })],
    },
    {
      id: -9144,
      name: 'Currently Being Treated',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISLeftNode',
      },
      components: [
        currentTreatmentComponent({
          id: 1,
          baseKey: `${baseKey}.left`,
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];

  static stepsRelative = (baseKey: string, relative: Relative): PageNode[] => [
    {
      id: DCIS.relativeInitId,
      name: 'DCIS Relative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeNode',
      },
      components: [
        breastBilateral({ id: 1, baseKey, relative }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.bilateral',
          definitionKey: 'helpHints.bilateralHint',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9161,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.isBilateral[0].optionValue`,
                value: 'yes',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9167 }),
      ],
    },
    {
      id: -9161,
      name: 'DCIS Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeAgeNode',
      },
      components: [
        relativeBilateralAge({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
          cancer: 'cancerHistory.cancers.dcis',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9162,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.first.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9163 }),
      ],
    },
    {
      id: -9162,
      name: 'DCIS Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeAgeRangeNode',
      },
      components: [
        ageRangeComponent({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
          pathology: 'cancerHistory.cancers.dcis',
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9163 })],
    },
    {
      id: -9163,
      name: 'DCIS Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.first`,
          relative,
          occurrence: Occurrence.First,
          pathology: 'cancerHistory.cancers.dcis',
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9164 })],
    },
    {
      id: -9164,
      name: 'DCIS Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeAgeNode',
      },
      components: [
        relativeBilateralAge({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
          cancer: 'cancerHistory.cancers.dcis',
        }),
      ],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9165,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.second.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9166 }),
      ],
    },
    {
      id: -9165,
      name: 'DCIS Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeAgeNode',
      },
      components: [
        ageRangeComponent({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
          pathology: 'cancerHistory.cancers.dcis',
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9166 })],
    },
    {
      id: -9166,
      name: 'DCIS Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.second`,
          relative,
          occurrence: Occurrence.Second,
          pathology: 'cancerHistory.cancers.dcis',
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
    {
      id: -9167,
      name: 'DCIS Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeAgeNode',
      },
      components: [ageComponent({ id: 1, baseKey, relative })],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9168,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9169 }),
      ],
    },
    {
      id: -9168,
      name: 'DCIS Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeAgeNode',
      },
      components: [
        ageRangeComponent({ id: 1, baseKey, relative }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9169 })],
    },
    {
      id: -9169,
      name: 'DCIS Triple Negative',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeTripleNegativeNode',
      },
      components: [
        tripleNegativeComponent({
          id: 1,
          baseKey: `${baseKey}.breast`,
          relative,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.tripleNegative',
          definitionKey: 'helpHints.tripleNegativeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9170 })],
    },
    {
      id: -9170,
      name: 'DCIS Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.DCISRelativeMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.breast` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];
}

export class AgePathologyCancer {
  static intiId = -9200;
  static relativeInitId = -9210;

  static selfSteps = (baseKey: string): PageNode[] => [
    {
      id: AgePathologyCancer.intiId,
      name: 'Age Pathology',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.agePathologyNode',
      },
      components: [ageComponent({ id: 1, baseKey, relative: SELF_RELATIVE })],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];

  static relativeSteps = (baseKey: string, relative: Relative): PageNode[] => [
    {
      id: AgePathologyCancer.relativeInitId,
      name: 'Pathology Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.agePathologyRelativeNode',
      },
      components: [ageComponent({ id: 1, baseKey, relative })],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9211,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: null }),
      ],
    },
    {
      id: -9211,
      name: 'Pathology Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.agePathologyRelativeNode',
      },
      components: [
        ageRangeComponent({ id: 1, baseKey, relative }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];
}

export class ProstateCancer {
  static initId = -9300;
  static relativeInitId = -9310;

  static selfSteps = (baseKey: string): PageNode[] => [
    {
      id: ProstateCancer.initId,
      name: 'Prostate Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerNode',
      },
      components: [ageComponent({ id: 1, baseKey, relative: SELF_RELATIVE })],
      flows: [nextPageNode({ id: 1, nextPageId: -9301 })],
    },
    {
      id: -9301,
      name: 'Prostate Cancer Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.prostate` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9302 })],
    },
    {
      id: -9302,
      name: 'Prostate Cancer Gleason Score',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerNode',
      },
      components: [
        gleasonScoreComponent({
          id: 1,
          baseKey: `${baseKey}.prostate`,
          relative: SELF_RELATIVE,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.gleasonScore',
          definitionKey: 'helpHints.gleasonScoreHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];

  static relativeSteps = (baseKey: string, relative: Relative): PageNode[] => [
    {
      id: ProstateCancer.relativeInitId,
      name: 'Prostate Cancer Age',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerRelativeNode',
      },
      components: [ageComponent({ id: 1, baseKey, relative })],
      flows: [
        {
          id: 1,
          type: 'CONDITIONAL',
          data: {
            nextPageId: -9311,
            conditions: [
              {
                comparator: '==',
                answerKey: `${baseKey}.age`,
                value: 'notSure',
              },
            ],
          },
        },
        nextPageNode({ id: 2, nextPageId: -9312 }),
      ],
    },
    {
      id: -9311,
      name: 'Prostate Cancer Age Range',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerRelativeAgeNode',
      },
      components: [
        ageRangeComponent({ id: 1, baseKey, relative }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.ageRange',
          definitionKey: 'helpHints.ageRangeHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9312 })],
    },
    {
      id: -9312,
      name: 'Prostate Cancer Metastatic',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerRelativeMetastaticNode',
      },
      components: [
        metastaticComponent({ id: 1, baseKey: `${baseKey}.prostate` }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.metastatic',
          definitionKey: 'helpHints.metastaticHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: -9313 })],
    },
    {
      id: -9313,
      name: 'Prostate Cancer Gleason Score',
      type: NodeTypes.Page,
      data: {
        continueType: ContinueType.AutoFixed,
        pageTitleKey: 'pageTitles.prostateCancerRelativeNode',
      },
      components: [
        gleasonScoreComponent({
          id: 1,
          baseKey: `${baseKey}.prostate`,
          relative,
        }),
        createHelpHint({
          id: 2,
          termKey: 'helpHints.gleasonScore',
          definitionKey: 'helpHints.gleasonScoreHint',
        }),
      ],
      flows: [nextPageNode({ id: 1, nextPageId: null })],
    },
  ];
}

export default {
  BreastCancer,
  DCIS,
  ProstateCancer,
  AgePathologyCancer,
};
