import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const meetsCriteriaOverNextStepsNode: ChatbotNode = {
  id: NODE_IDS.MEETS_CRITERIA_TC_OVER_NEXT_STEPS_NODE,
  name: 'Meets Criteria TC Over Next Stpes Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.End,
    pageTitleKey: 'pageTitles.chatbot.meetCriteriaNextSteps',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Write Down or Take Screenshot',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.takeScreenshot',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'You May Qualify For Breast Imaging',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.mayQualifyForBreastImaging',
        type: TextContentType.Text1,
        inlineImages: [
          {
            src: 'chatbot.meetsCriteriaResults.metCheck.src',
            altKey: 'chatbot.meetsCriteriaResults.metCheck.alt',
          },
          {
            src: 'chatbot.meetsCriteriaResults.mayQualify.src',
            altKey: 'chatbot.meetsCriteriaResults.mayQualify.alt',
          },
        ],
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Thank you',
      description: 'Thank you',
      data: {
        contentKey: 'chatbot.thankYou.finishAssessment',
        inlineImages: [
          {
            src: 'chatbot.thankYou.checkImg.src',
            altKey: 'chatbot.thankYou.checkImg.alt',
          },
          {
            src: 'chatbot.thankYou.thankYouImage.src',
            altKey: 'chatbot.thankYou.thankYouImage.alt',
          },
        ],
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Close browser',
      description: 'Close browser',
      data: {
        contentKey: 'chatbot.closePage',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
  ],
};

export default meetsCriteriaOverNextStepsNode;
