import { Trans, useTranslation } from 'react-i18next';
import { ContextBreakNode, ContinueType } from '@myriadgenetics/mgh-types';
import getNextNodeId from '../../../helpers/flow/route-flow';
import Continue from '../../../elements/continue';
import { H2, Text1 } from '../../../elements/typography';
import NodeFooter from '../../../elements/node-footer';

import './index.scss';

interface Props {
  node: any | ContextBreakNode;
  responses: any;
  gotoNextNode: (nodeId?: number | null) => void;
}

function ContextBreakNodeReact({ node, responses, gotoNextNode }: Props) {
  const { t } = useTranslation();
  const { messageKey, subMessageKey, footerKey, continueKey, termsAndPrivacyLinks } = node.data;

  return (
    <div className="context-break-node">
      <H2 className="context-break-node__message">{t(messageKey)}</H2>
      {subMessageKey && (
        <Text1 className="context-break-node__sub-message">
          <Trans i18nKey={subMessageKey} />
        </Text1>
      )}
      <div className="context-break-node__continue">
        <Continue buttonKey={continueKey} type={ContinueType.End} onContinue={() => gotoNextNode(getNextNodeId(node.flows, responses))} />
      </div>
      {footerKey && <NodeFooter footerKey={footerKey} termsAndPrivacyLinks={termsAndPrivacyLinks} />}
    </div>
  );
}

export default ContextBreakNodeReact;
