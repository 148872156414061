import {
  TextQuestionComponent,
  OptionSelectComponent,
  PersonDemographicComponent,
  PersonDemographicFieldOption,
  ComponentSizes,
  TextQuestionType,
  PersonDemographicTypes,
} from '@myriadgenetics/mgh-types';
import { getStateOptions } from '../../../helpers/components';

const isRequired = (option?: PersonDemographicFieldOption) => (option && option === 'REQUIRED' ? true : false);

export const firstNameQuestion = (option?: PersonDemographicFieldOption, personDemographicType?: PersonDemographicTypes): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -1,
  data: {
    answerKey: 'firstName',
    questionKey: '',
    placeholderKey: '',
    labelKey: personDemographicType === PersonDemographicTypes.DirectToConsumer ? 'personDemographic.firstName' : 'personDemographic.firstNameLegal',
    pattern: "^[\\w\\s&/.()\\-,']+$",
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Small,
    inputType: TextQuestionType.Name,
  },
});

export const lastNameQuestion = (option?: PersonDemographicFieldOption, personDemographicType?: PersonDemographicTypes): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -2,
  data: {
    answerKey: 'lastName',
    questionKey: '',
    placeholderKey: '',
    labelKey: personDemographicType === PersonDemographicTypes.DirectToConsumer ? 'personDemographic.lastName' : 'personDemographic.lastNameLegal',
    pattern: "^[\\w\\s&/.()\\-,']+$",
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Small,
    inputType: TextQuestionType.Name,
  },
});

export const dobQuestion = (option?: PersonDemographicFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -5,
  data: {
    answerKey: 'dob',
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personDemographic.dob',
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Small,
    inputType: TextQuestionType.Dob,
  },
});

export const emailQuestion = (option?: PersonDemographicFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -3,
  data: {
    answerKey: 'email',
    questionKey: '',
    placeholderKey: 'email@acme.com',
    labelKey: 'personDemographic.email',
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.XLarge,
    inputType: TextQuestionType.Email,
  },
});

export const phoneQuestion = (option?: PersonDemographicFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -4,
  data: {
    answerKey: 'phone',
    questionKey: '',
    placeholderKey: '555-555-5555',
    labelKey: 'personDemographic.phone',
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Tel,
  },
});

export const addressQuestion = (option?: PersonDemographicFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -6,
  data: {
    answerKey: 'address',
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personDemographic.address',
    pattern: "^[\\w\\s&/.()\\-,'#;:°]+$",
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.XLarge,
    inputType: TextQuestionType.Address,
  },
});

export const cityQuestion = (option?: PersonDemographicFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -7,
  data: {
    answerKey: 'city',
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personDemographic.city',
    pattern: "^[\\w\\s&/.()\\-,'#;:°]+$",
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.XLarge,
    inputType: TextQuestionType.Address,
  },
});

export const postalCodeQuestion = (option?: PersonDemographicFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -8,
  data: {
    answerKey: 'postalCode',
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personDemographic.postalCode',
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Postal,
    pattern: '^[0-9]{5}$',
  },
});

export const stateQuestion = (option?: PersonDemographicFieldOption): OptionSelectComponent => ({
  type: 'OPTION_SELECT',
  id: -9,
  data: {
    answerKey: 'state',
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personDemographic.state',
    subtitleKey: '',
    required: isRequired(option),
    isSingleSelect: true,
    isNative: true,
    optionSize: ComponentSizes.Medium,
    selectionOptions: getStateOptions('personDemographic.states'),
  },
});

export const formFieldList = [
  firstNameQuestion('REQUIRED'),
  lastNameQuestion('REQUIRED'),
  dobQuestion('REQUIRED'),
  emailQuestion('REQUIRED'),
  phoneQuestion('REQUIRED'),
  addressQuestion('REQUIRED'),
  cityQuestion('REQUIRED'),
  postalCodeQuestion('REQUIRED'),
  stateQuestion('REQUIRED'),
];

export const formFields = (pd: PersonDemographicComponent) => ({
  firstNameQuestion: firstNameQuestion(pd.data.fields.firstName, pd.data.type),
  lastNameQuestion: lastNameQuestion(pd.data.fields.lastName, pd.data.type),
  dobQuestion: dobQuestion(pd.data.fields.dob),
  emailQuestion: emailQuestion(pd.data.fields.email),
  phoneQuestion: phoneQuestion(pd.data.fields.phone),
  addressQuestion: addressQuestion(pd.data.fields.address),
  cityQuestion: cityQuestion(pd.data.fields.city),
  postalCodeQuestion: postalCodeQuestion(pd.data.fields.postalCode),
  stateQuestion: stateQuestion(pd.data.fields.state),
});
