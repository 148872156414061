import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';
export const halfwayNode: ChatbotNode = {
  id: NODE_IDS.HALFWAY_THROUGH_NODE,
  name: 'Halfway Through Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.halfwayThroughNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Halfway Through Text',
      data: {
        contentKey: 'chatbot.medicalHistory.infoAboutYourHistory',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 0,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'CONTINUE',
      data: {
        modalTriggerKey: 'medicalHistory',
        buttonKey: 'chatbot.medicalHistory.enterHistory',
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Medical History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.MEDICAL_HISTORY_NODE,
      },
    },
  ],
};

export default halfwayNode;
