import { ContinueType, NodeTypes, ChatModalNodeType, ChatModalNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const personalCancerNode: ChatModalNode = {
  id: NODE_IDS.PERSONAL_CANCER_NODE,
  type: NodeTypes.ChatModalNode,
  name: 'Chat Personal Abd Family Cancer',
  description: 'Chat  Personal Abd Family Cancer',
  data: {
    answerKey: 'cancerHistory',
    continueType: ContinueType.AutoFixed,
    subtitle: 'chatbot.cancerHistory.subtitle',
    flowType: ChatModalNodeType.CancerHistory,
    pageTitleKey: 'pageTitles.chatbot.personalAndFamilyCancerNode',
  },
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Some  example node',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.HALFWAY_THROUGH_NODE,
      },
    },
  ],
};

export default personalCancerNode;
