import { useEffect } from 'react';
import { OptionKeyValue, Pathology, Relative } from '@myriadgenetics/mgh-types';
import { useHistory, useLocation } from '../../../helpers/routing';
import { OptionSelect } from '../../question';
import Steps from './selections';
import { isRelativeFemale, isRelativeMale } from './helper';

enum PathologySelectStep {
  Options = 'options',
  Other = 'other',
}

interface PathologySelectState {
  curStep: PathologySelectStep;
  baseUrl: string;
}

interface Props {
  isSelfFemale: boolean;
  isSelfMale: boolean;
  onPathologySelected: (selectedPathology: OptionKeyValue[]) => void;
  relative: Relative;
  selectionOptions?: Pathology[];
}

interface PathologySelectLocationState {
  state: {
    pathologySelectState?: PathologySelectState;
  };
  pathname: string;
}

function PathologySelectNode({ isSelfFemale, isSelfMale, onPathologySelected, relative, selectionOptions }: Props) {
  const history = useHistory();
  const location: PathologySelectLocationState = useLocation() as PathologySelectLocationState;
  const { pathologySelectState } = location.state || {};
  const { curStep, baseUrl } = pathologySelectState || {};

  useEffect(() => {
    if (!pathologySelectState) {
      history.replace(`${location.pathname}/select`, {
        ...location.state,
        pathologySelectState: {
          baseUrl: location.pathname,
          curStep: PathologySelectStep.Options,
        },
      });
    }
  });

  const triggerOnPathologySelected = (selectedPathology: OptionKeyValue[]) => {
    onPathologySelected(selectedPathology);
  };

  let internal;
  switch (curStep) {
    case PathologySelectStep.Options:
      const includeFemale = !isRelativeMale(relative, isSelfMale);
      const includeMale = !isRelativeFemale(relative, isSelfFemale);
      internal = (
        <OptionSelect
          compDef={Steps.pathologySelect(selectionOptions, includeFemale, includeMale, relative)}
          onResponseUpdated={(optionAnswerKey, val) => {
            const selectedPathology: string = val[0].optionValue;
            if (selectedPathology === 'cancerHistory.cancers.other') {
              history.push(`${baseUrl}/select/other`, {
                ...location.state,
                pathologySelectState: {
                  ...pathologySelectState,
                  curStep: PathologySelectStep.Other,
                },
              });
            } else {
              triggerOnPathologySelected(val);
            }
          }}
          responses={{}}
        />
      );
      break;
    case PathologySelectStep.Other:
      internal = (
        <OptionSelect
          compDef={Steps.pathologyOtherSelect}
          onResponseUpdated={(optionAnswerKey, val) => {
            triggerOnPathologySelected(val);
          }}
          responses={{}}
        />
      );
      break;
    default:
      internal = <p>Initializing...</p>;
  }

  return (
    <div className="pathology-select">
      <div>{internal}</div>
    </div>
  );
}

export default PathologySelectNode;
