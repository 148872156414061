import { NodeTypes, ComponentSizes, ChatModalNodeOptions, ContinueType } from '@myriadgenetics/mgh-types';
import { getSurveyAccountOptions } from '../../../../helpers/components';
import { NODE_IDS } from '../constants';
import { standardProviderQuestionOrSimon } from '../helpers/utilities';
import { isSimonMedUrl } from '../../../../helpers/url';

export const providerSelectionStep = ({ continueType, subtitle, surveyAccountsContext, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.PROVIDER_SELECTION_NODE,
  name: 'Location Node',
  description: 'Fill personal location data',
  type: NodeTypes.Page,
  data: {
    continueType: ContinueType.Fixed,
    subtitle,
    continueKey: 'chatbot.modal.next',
    pageTitleKey: 'pageTitles.addressNode',
  },
  components: [
    {
      id: -4,
      type: 'OPTION_SELECT',
      name: 'Provider selection',
      description: 'Built in component',
      data: {
        questionKey: standardProviderQuestionOrSimon({
          isSimon: isSimonMedUrl(),
          simonText: 'chatbot.personalInformation.providerLocation',
          myraText: 'provider.question',
        }),
        answerKey: `${baseKey}.providerLocation`,
        isNative: true,
        optionSize: ComponentSizes.Medium,
        selectionOptions: [...getSurveyAccountOptions(surveyAccountsContext?.surveyAccounts || [])],
        labelKey: 'chatbot.personalInformation.providerLocationLabel',
        required: true,
        placeholderKey: '',
        subtitleKey: '',
      },
    },
  ],
  flows: [
    {
      id: 4,
      type: 'UNCONDITIONAL',
      name: 'Ancestry to cancer landing',
      description: 'Ancestry page to cancer landing page',
      data: {
        nextPageId: NODE_IDS.INFO_NODE,
      },
    },
  ],
});
