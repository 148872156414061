import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { OptionSelectComponent, OptionKeyValue, ValidationResult } from '@myriadgenetics/mgh-types';
import NativeSelect from './native-select';
import SelectionList from './selection-list';
import { getDisplayKey, isOptionSelected, notSure } from './helpers';
import QuestionText from '../../../elements/question-text';
import { Text2 } from '../../../elements/typography';

import './index.scss';

interface Props {
  className?: string;
  isDisabled?: boolean;
  compDef: any | OptionSelectComponent;
  responses: Record<string, any>;
  onResponseUpdated: (answerKey: string, val: any) => void;
  validationResult?: ValidationResult;
  isChatbotStyle?: boolean;
}

export const baseClass = 'option-select';

function OptionSelect({ className, compDef, onResponseUpdated, responses, validationResult, isDisabled, isChatbotStyle }: Props) {
  const { t } = useTranslation();
  const os: OptionSelectComponent = compDef as OptionSelectComponent;
  const {
    answerKey,
    questionKey,
    labelKey,
    placeholderKey,
    questionKeyParams,
    isSingleSelect,
    isNative,
    required,
    selectionOptions,
    subtitleKey,
    optionSize,
    isShowOptionAsCheckbox,
  } = os.data;
  let selectedOptions: OptionKeyValue[] | string = get(responses, answerKey, []);

  const answerChanged = (val: OptionKeyValue[]): void => {
    onResponseUpdated(answerKey, val.length === 0 && isNative ? null : val);
  };
  const requiresSingleSelection = !!(isSingleSelect || isNative);
  const isMultipleSelected = Array.isArray(selectedOptions);
  const singleSelection = (selectedOption: OptionKeyValue): OptionKeyValue[] => (getDisplayKey(selectedOption) === notSure ? [] : [selectedOption]);
  const filterUnselected = (selectedOption: OptionKeyValue): OptionKeyValue[] =>
    Array.isArray(selectedOptions) ? selectedOptions.filter(({ optionKey }: OptionKeyValue) => optionKey !== selectedOption.optionKey) : [];
  const addSelected = (selectedOption: OptionKeyValue): OptionKeyValue[] => (Array.isArray(selectedOptions) ? selectedOptions.concat(selectedOption) : []);
  const multiSelection = (selectedOption: OptionKeyValue): OptionKeyValue[] =>
    isOptionSelected(selectedOption, selectedOptions) ? filterUnselected(selectedOption) : addSelected(selectedOption);
  const setSingleSelection = (selectedOption: OptionKeyValue): void => answerChanged(singleSelection(selectedOption));
  const setMultipleSelection = (selectedOption: OptionKeyValue): void => answerChanged(multiSelection(selectedOption));

  const onOptionSelected = (selectedOption: OptionKeyValue): void => {
    if (requiresSingleSelection) {
      setSingleSelection(selectedOption);
    }
    if (!requiresSingleSelection && isMultipleSelected) {
      setMultipleSelection(selectedOption);
    }
  };

  if ((typeof selectedOptions === 'string' && selectedOptions === notSure) || selectedOptions === null) {
    selectedOptions = [];
  }

  const customClass = answerKey?.split('.')?.join('-');

  return (
    <div
      className={`${baseClass} ${className || ''} ${customClass}-answerKey ${isNative ? `${baseClass}__select-input--${optionSize || 'large'}` : ''} ${
        !isNative && isShowOptionAsCheckbox ? `${baseClass}__native-checkbox` : ''
      }`}
    >
      {questionKey && (
        <div className={`${baseClass}__question`}>
          <QuestionText htmlFor="optionSelect" questionKey={questionKey} questionKeyParams={questionKeyParams} />
          {subtitleKey && <Text2 className={`${baseClass}__subtitle `}>{t(subtitleKey)}</Text2>}
        </div>
      )}

      {isNative && (
        <NativeSelect
          labelKey={labelKey}
          onOptionSelected={onOptionSelected}
          placeholderKey={placeholderKey}
          required={required}
          selectedOptions={selectedOptions}
          selectionOptions={selectionOptions}
          validationResult={validationResult}
          isDisabled={isDisabled}
        />
      )}

      {!isNative && (
        <SelectionList
          isSingleSelect={isSingleSelect}
          labelKey={labelKey}
          isShowOptionAsCheckbox={isShowOptionAsCheckbox}
          onOptionSelected={onOptionSelected}
          optionSize={optionSize}
          selectedOptions={selectedOptions}
          selectionOptions={selectionOptions}
          isChatbotStyle={isChatbotStyle}
        />
      )}
    </div>
  );
}

export default OptionSelect;
