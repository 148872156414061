import {
  OptionSelectComponent,
  TextQuestionComponent,
  ComponentSizes,
  TextQuestionType,
  PersonInsuranceComponent,
  PersonInsuranceFieldOption,
  FieldOptionType,
  PersonInsuranceFormFieldsType,
  PolicyHolderRelationsType,
  ImageUploadComponent,
  CheckboxComponent,
} from '@myriadgenetics/mgh-types';

const isRequired = (option?: PersonInsuranceFieldOption) => Boolean(option && option === FieldOptionType.REQUIRED);

export const DEFAULT_PATIENT_DATA_KEY = 'patient';

export const policyHolderFirstNameQuestion = (option?: PersonInsuranceFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -1,
  data: {
    answerKey: PersonInsuranceFormFieldsType.PolicyHolderFirstName,
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personInsurance.firstName',
    pattern: "^[\\w\\s&/.()\\-,']+$",
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Name,
  },
});

export const policyHolderLastNameQuestion = (option?: PersonInsuranceFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -2,
  data: {
    answerKey: PersonInsuranceFormFieldsType.PolicyHolderLastName,
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personInsurance.lastName',
    pattern: "^[\\w\\s&/.()\\-,']+$",
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Name,
  },
});

export const policyHolderDobQuestion = (option?: PersonInsuranceFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -3,
  data: {
    answerKey: PersonInsuranceFormFieldsType.PolicyHolderDob,
    questionKey: '',
    placeholderKey: 'personInsurance.dobPlaceHolder',
    labelKey: 'personInsurance.dob',
    required: isRequired(option),
    size: ComponentSizes.Small,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Dob,
  },
});

export const insuranceIdQuestion = (option?: PersonInsuranceFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -4,
  data: {
    answerKey: PersonInsuranceFormFieldsType.InsuranceId,
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personInsurance.insuranceId',
    pattern: '',
    required: isRequired(option),
    size: ComponentSizes.Medium,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Text,
  },
});

export const insuranceProviderQuestion = (option?: PersonInsuranceFieldOption): TextQuestionComponent => ({
  type: 'TEXT_QUESTION',
  id: -4,
  data: {
    answerKey: PersonInsuranceFormFieldsType.InsuranceProvider,
    questionKey: '',
    placeholderKey: '',
    labelKey: 'personInsurance.insuranceProvider',
    pattern: '',
    required: isRequired(option),
    size: ComponentSizes.Medium,
    inputSize: ComponentSizes.Medium,
    inputType: TextQuestionType.Text,
  },
});

export const insuranceCardImageFrontQuestion = (option?: PersonInsuranceFieldOption): ImageUploadComponent => ({
  type: 'IMAGE_UPLOAD',
  id: -6,
  data: {
    answerKey: PersonInsuranceFormFieldsType.InsuranceCardImageFront,
    questionKey: '',
    placeholderKey: 'personInsurance.frontCardImagePlaceholder',
    labelKey: 'personInsurance.frontCardImage',
    required: isRequired(option),
  },
});

export const insuranceCardImageBackQuestion = (option?: PersonInsuranceFieldOption): ImageUploadComponent => ({
  type: 'IMAGE_UPLOAD',
  id: -7,
  data: {
    answerKey: PersonInsuranceFormFieldsType.InsuranceCardImageBack,
    questionKey: '',
    placeholderKey: 'personInsurance.backCardImagePlaceholder',
    labelKey: 'personInsurance.backCardImage',
    required: isRequired(option),
  },
});

export const isUninsuredQuestion = (option?: PersonInsuranceFieldOption): CheckboxComponent => ({
  type: 'CHECKBOX',
  id: -9,
  data: {
    answerKey: PersonInsuranceFormFieldsType.isUninsured,
    labelKey: 'personInsurance.uninsured',
    questionKey: '',
    required: isRequired(option),
  },
});

export const policyHolderRelationQuestion = (option?: PersonInsuranceFieldOption): OptionSelectComponent => ({
  type: 'OPTION_SELECT',
  id: -9,
  data: {
    answerKey: PersonInsuranceFormFieldsType.PolicyHolderRelation,
    questionKey: '',
    placeholderKey: 'personInsurance.policyHolderPlaceHolder',
    labelKey: 'personInsurance.policyHolderRelation',
    subtitleKey: '',
    required: isRequired(option),
    isSingleSelect: true,
    isNative: true,
    optionSize: ComponentSizes.Large,
    selectionOptions: [
      { optionKey: PolicyHolderRelationsType.self, optionValue: 'personInsurance.policyHolderRelations.self' },
      { optionKey: PolicyHolderRelationsType.spouse, optionValue: 'personInsurance.policyHolderRelations.spouse' },
      { optionKey: PolicyHolderRelationsType.child, optionValue: 'personInsurance.policyHolderRelations.child' },
      { optionKey: PolicyHolderRelationsType.other, optionValue: 'personInsurance.policyHolderRelations.other' },
    ],
  },
});

export const patientInfoFieldsInSelfFlow = {
  [PersonInsuranceFormFieldsType.PolicyHolderFirstName]: 'firstName',
  [PersonInsuranceFormFieldsType.PolicyHolderLastName]: 'lastName',
  [PersonInsuranceFormFieldsType.PolicyHolderDob]: 'dob',
};

export const formFieldList = [
  policyHolderFirstNameQuestion(FieldOptionType.REQUIRED),
  policyHolderLastNameQuestion(FieldOptionType.REQUIRED),
  policyHolderDobQuestion(FieldOptionType.REQUIRED),
  policyHolderRelationQuestion(FieldOptionType.REQUIRED),
  insuranceIdQuestion(FieldOptionType.REQUIRED),
  insuranceProviderQuestion(FieldOptionType.REQUIRED),
  insuranceCardImageFrontQuestion(FieldOptionType.REQUIRED),
  insuranceCardImageBackQuestion(FieldOptionType.REQUIRED),
];

export const formFields = (pi: PersonInsuranceComponent) => {
  const obligatoryFields = {
    policyHolderRelationQuestion: policyHolderRelationQuestion(pi.data.fields.policyHolderRelation),
    policyHolderFirstNameQuestion: policyHolderFirstNameQuestion(pi.data.fields.policyHolderFirstName),
    policyHolderLastNameQuestion: policyHolderLastNameQuestion(pi.data.fields.policyHolderLastName),
    policyHolderDobQuestion: policyHolderDobQuestion(pi.data.fields.policyHolderDob),
    insuranceIdQuestion: insuranceIdQuestion(pi.data.fields.insuranceId),
    insuranceProviderQuestion: insuranceProviderQuestion(pi.data.fields.insuranceProvider),
    insuranceCardImageFrontQuestion: insuranceCardImageFrontQuestion(pi.data.fields.insuranceCardImageFront),
    insuranceCardImageBackQuestion: insuranceCardImageBackQuestion(pi.data.fields.insuranceCardImageBack),
    isUninsuredQuestion: isUninsuredQuestion(pi.data.fields.isUninsured),
  };
  return { ...obligatoryFields };
};
