import { NodeTypes, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type, includesBreastDensity }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5005,
    description: 'Built-in page node',
    name: 'Current menopause',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.currentMenopauseNode',
    },
    components: [
      {
        id: -6,
        description: 'Built in component',
        name: 'Current menopause',
        type: 'YES_NO',
        data: {
          answerKey: `${baseKey}.menopauseCurrent`,
          questionKey: 'breastCancerRisk.menopauseCurrent',
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStages',
            definitionKey: '',
          },
        },
      },
      {
        id: -301,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStagesPeri',
            definitionKey: 'helpHints.menopauseStagesPeriHint',
          },
        },
      },
      {
        id: -4,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStagesMeno',
            definitionKey: 'helpHints.menopauseStagesMenoHint',
          },
        },
      },
      {
        id: -5,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStagesPost',
            definitionKey: 'helpHints.menopauseStagesPostHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -5,
        type: 'BINARY',
        name: 'Current menopause',
        description: 'Built-in flow',
        data: {
          answerKey: `${baseKey}.menopauseCurrent`,
          affirmativePageId: -5009,
          negativePageId: includesBreastDensity ? -5019 : -5015,
        },
      },
    ],
  };
};
