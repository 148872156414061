import MghTypes, { Relative } from '@myriadgenetics/mgh-types';
import { relativeInRelativeList } from '../cancer-history-node/helper';

const { femaleRelatives, maleRelatives } = MghTypes;

export const isRelativeSelf = (relative: Relative): boolean => !!relative && relative === 'relatives.self';

export const isRelativeFemale = (relative: Relative, isSelfFemale: boolean): boolean =>
  isRelativeSelf(relative) ? isSelfFemale : relativeInRelativeList(femaleRelatives, relative);

export const isRelativeMale = (relative: Relative, isSelfMale: boolean): boolean =>
  isRelativeSelf(relative) ? isSelfMale : relativeInRelativeList(maleRelatives, relative);

export default {
  isRelativeFemale,
  isRelativeMale,
  isRelativeSelf,
};
