import loading from '../../assets/loading.gif';

import './index.scss';

interface Props {
  translatedMsg: string;
}
function Loading({ translatedMsg }: Props) {
  return (
    <div className="loading__container">
      <div className="loading__content">
        <div className="loading__spinner">
          <img alt="loading" src={loading} />
        </div>
        {translatedMsg}
      </div>
    </div>
  );
}

Loading.defaultProps = {
  translatedMsg: 'Loading',
};

export default Loading;
