import { PageNode, ContinueType, SubtitleType, NodeTypes, TextQuestionType, ComponentSizes } from '@myriadgenetics/mgh-types';

export class ColonPolyp {
  static initNodeId = -3000;
  static steps = (baseKey: string, continueType: ContinueType, subtitle?: SubtitleType): PageNode[] => [
    {
      id: -3000,
      name: 'Colon Polyp',
      type: NodeTypes.Page,
      data: {
        continueType,
        subtitle,
        pageTitleKey: 'pageTitles.colonPolypNode',
      },
      components: [
        {
          id: -1,
          type: 'YES_NO',
          data: {
            answerKey: `${baseKey}.hasHistory`,
            questionKey: 'colonPolyp.history',
            required: true,
          },
        },
        {
          id: -2,
          type: 'HELP_HINT',
          data: {
            helpHint: {
              termKey: 'colonPolyp.helpHintTerm',
              definitionKey: 'colonPolyp.helpHint',
            },
          },
        },
      ],
      flows: [
        {
          id: -1,
          type: 'BINARY',
          data: {
            answerKey: `${baseKey}.hasHistory`,
            affirmativePageId: -3001,
            negativePageId: null,
          },
        },
      ],
    },
    {
      id: -3001,
      type: NodeTypes.Page,
      data: {
        continueType,
        subtitle,
        pageTitleKey: 'pageTitles.colonPolypAgeNode',
      },
      components: [
        {
          id: -2,
          type: 'TEXT_QUESTION',
          data: {
            answerKey: `${baseKey}.firstPolypAge`,
            questionKey: 'colonPolyp.firstPolyp',
            labelKey: 'ageLabel',
            placeholderKey: 'agePlaceHolder',
            required: true,
            inputType: TextQuestionType.Age,
            min: 0,
            max: 115,
            threshold: 100,
            size: ComponentSizes.Large,
          },
        },
      ],
      flows: [
        {
          id: -2,
          type: 'UNCONDITIONAL',
          data: {
            nextPageId: -3003,
          },
        },
      ],
    },
    {
      id: -3003,
      type: NodeTypes.Page,
      data: {
        continueType,
        subtitle,
        pageTitleKey: 'pageTitles.colonPolypCountNode',
      },
      components: [
        {
          type: 'OPTION_SELECT',
          id: -3,
          data: {
            answerKey: `${baseKey}.polypRange`,
            questionKey: 'colonPolyp.numberOfPolyps',
            required: true,
            isSingleSelect: true,
            isNative: true,
            subtitleKey: '',
            labelKey: 'colonPolyp.polypRange',
            placeholderKey: '',
            optionSize: ComponentSizes.Large,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 'colonPolyp.polypRanges.1',
              },
              {
                optionKey: 2,
                optionValue: 'colonPolyp.polypRanges.2to5',
              },
              {
                optionKey: 3,
                optionValue: 'colonPolyp.polypRanges.6to9',
              },
              {
                optionKey: 4,
                optionValue: 'colonPolyp.polypRanges.10to19',
              },
              {
                optionKey: 5,
                optionValue: 'colonPolyp.polypRanges.20to99',
              },
              {
                optionKey: 6,
                optionValue: 'colonPolyp.polypRanges.100to999',
              },
              {
                optionKey: 7,
                optionValue: 'colonPolyp.polypRanges.1000+',
              },
            ],
          },
        },
      ],
      flows: [
        {
          id: -3,
          type: 'UNCONDITIONAL',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
  ];
}
