import React, { useState, useEffect } from 'react';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { OptionSelectComponent, PersonDemographicComponent, TextQuestionComponent, ValidationResult } from '@myriadgenetics/mgh-types';
import TextQuestion from '../text-question';
import OptionSelect from '../option-select';
import PhoneTypeSelect from '../../phone-type-select';
import { H3, Text2 } from '../../../elements/typography';
import { formFields } from './form-fields';
import { getSlugFromPath, isDemoUrl } from '../../../helpers/url';
import { getAge, getDateFromInput, isValidDate } from '../helper';

import './index.scss';
import wand from './wand.png';

interface Props {
  compDef: any | PersonDemographicComponent;
  responses: Record<string, any>;
  onResponseUpdated: (key: string, val: any) => void;
  validationResult?: ValidationResult;
}

interface PhoneTypeToggleProps {
  answerKey: string;
  responses: Record<string, any>;
  onResponseUpdated: (key: string, val: any) => void;
  className: string;
}

function PhoneTypeToggle({ answerKey, responses, onResponseUpdated }: PhoneTypeToggleProps) {
  const phoneType = get(responses, answerKey);
  const [isHome, setIsHome] = useState<boolean>(phoneType === 'home');

  useEffect(() => {
    if (!get(responses, answerKey)) {
      onResponseUpdated(answerKey, 'mobile');
    }
  }, [responses, answerKey, onResponseUpdated]);

  return (
    <PhoneTypeSelect
      isHome={isHome}
      onClick={() => {
        const newVal = !isHome;
        setIsHome(newVal);
        onResponseUpdated(answerKey, newVal ? 'home' : 'mobile');
      }}
    />
  );
}

function PersonDemographic({ compDef, responses, onResponseUpdated, validationResult }: Props) {
  const { t } = useTranslation();
  const pd = compDef as PersonDemographicComponent;
  const updateResponse = (answerKey: string, val: string) => {
    onResponseUpdated(`${pd.data.answerKey}.${answerKey}`, val);
    if (answerKey === 'dob') {
      onResponseUpdated(`${pd.data.answerKey}.age`, isValidDate(val) ? getAge(getDateFromInput(val)) : null);
    }
  };
  // cast as any so we don't have to create index signatures
  // anytime we see "as any" it's usually in reference to usage of string index
  // and TS cannot guarantee that string
  const validate = (subCompDef: TextQuestionComponent | OptionSelectComponent) =>
    validationResult && validationResult.errorMessage && (validationResult.errorMessage as any)[subCompDef.data.answerKey];

  const FormFields = formFields(pd);

  const isFieldDisplayed = (field: string): boolean =>
    Object.keys(FormFields).some((fieldComp) => fieldComp.startsWith(field) && (pd.data.fields as any)[field] && (pd.data.fields as any)[field] !== 'HIDDEN');

  const curAnswer = get(responses, pd.data.answerKey);
  const autoFill = () => {
    const autofillDob = '2000-10-10';
    onResponseUpdated(pd.data.answerKey, {
      firstName: 'Test',
      phoneType: 'mobile',
      lastName: 'Testerson',
      dob: autofillDob,
      age: getAge(getDateFromInput(autofillDob)),
      phone: '555-523-4234',
      email: 'test@test.com',
      address: 'Some Small St.',
      city: 'Somewhere',
      state: [{ optionKey: 6, optionValue: 'personDemographic.states.colorado' }],
      postalCode: '23432',
      ...curAnswer,
    });
  };

  const slug = getSlugFromPath(window.location) as string;
  const isDemo = isDemoUrl(slug);

  return (
    <div className="person-demographic">
      <H3 className="person-demographic__title">{t(pd.data.questionKey)}</H3>
      {pd.data.descriptionKey && <Text2 className="person-demographic__description">{t(pd.data.descriptionKey)}</Text2>}
      <div className="person-demographic__fields">
        {isFieldDisplayed('firstName') && (
          <TextQuestion
            key={'firstName'}
            className="person-demographic-field__first-name"
            compDef={FormFields.firstNameQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.firstNameQuestion)}
          />
        )}
        {isFieldDisplayed('lastName') && (
          <TextQuestion
            key={'lastName'}
            className="person-demographic-field__last-name"
            compDef={FormFields.lastNameQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.lastNameQuestion)}
          />
        )}
        {isFieldDisplayed('dob') && (
          <TextQuestion
            key={'dob'}
            className="person-demographic-field__dob"
            compDef={FormFields.dobQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.dobQuestion)}
          />
        )}
        {isFieldDisplayed('phone') && (
          <div className="person-demographic__phone-container">
            <TextQuestion
              key={'phone'}
              className="person-demographic-field__phone"
              compDef={FormFields.phoneQuestion}
              responses={curAnswer}
              onResponseUpdated={updateResponse}
              validationResult={validate(FormFields.phoneQuestion)}
            />
            <PhoneTypeToggle
              key={'phoneType'}
              className="person-demographic-field__phone-type"
              answerKey={'phoneType'}
              responses={curAnswer}
              onResponseUpdated={updateResponse}
            />
          </div>
        )}
        {isFieldDisplayed('email') && (
          <TextQuestion
            key={'email'}
            className="person-demographic-field__email"
            compDef={FormFields.emailQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.emailQuestion)}
          />
        )}
        {isFieldDisplayed('address') && (
          <TextQuestion
            key={'address'}
            className="person-demographic-field__address"
            compDef={FormFields.addressQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.addressQuestion)}
          />
        )}
        {isFieldDisplayed('city') && (
          <TextQuestion
            key={'city'}
            className="person-demographic-field__city"
            compDef={FormFields.cityQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.cityQuestion)}
          />
        )}
        {isFieldDisplayed('state') && (
          <OptionSelect
            key={'state'}
            className="person-demographic-field__state"
            compDef={FormFields.stateQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.stateQuestion)}
          />
        )}
        {isFieldDisplayed('postalCode') && (
          <TextQuestion
            key={'postalCode'}
            className="person-demographic-field__postal-code"
            compDef={FormFields.postalCodeQuestion}
            responses={curAnswer}
            onResponseUpdated={updateResponse}
            validationResult={validate(FormFields.postalCodeQuestion)}
          />
        )}
      </div>
      {isDemo && <img alt="magic" className="person-demographic__wand" src={wand} onClick={autoFill} />}
    </div>
  );
}

export default PersonDemographic;
