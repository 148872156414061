let startNodeId = -999;

export const getNextComponentId = () => {
  startNodeId--;
  return startNodeId;
};

export default {
  getNextComponentId,
};
