import { ComponentSizes, NodeTypes, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, includesBreastDensity }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5018,
    description: 'Built-in page node',
    name: 'Hormone Replacement Intake Method',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.hormoneReplacementIntakeMethodNode',
    },
    components: [
      {
        type: 'OPTION_SELECT',
        id: -11,
        name: 'Hormone Replacement Therapy Intake Methods',
        description: 'Built in question',
        data: {
          answerKey: `${baseKey}.hormoneTherapyIntakeMethod`,
          questionKey: 'breastCancerRisk.hormoneTherapyIntakeMethod',
          labelKey: '',
          placeholderKey: '',
          subtitleKey: '',
          required: true,
          isSingleSelect: true,
          optionSize: ComponentSizes.Medium,
          selectionOptions: [
            {
              optionKey: 1,
              optionValue: 'breastCancerRisk.hormoneTherapyIntakeMethods.vaginally',
            },
            {
              optionKey: 2,
              optionValue: 'breastCancerRisk.hormoneTherapyIntakeMethods.other',
            },
          ],
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtIntakeVaginal',
            definitionKey: 'helpHints.hrtIntakeVaginalHint',
          },
        },
      },
      {
        id: -3,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtIntakeOther',
            definitionKey: 'helpHints.hrtIntakeOtherHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -9,
        type: 'CONDITIONAL',
        name: 'To Breast Biopsy When Hormone Therapy is Vaginally Only',
        description: 'Built-in flow',
        data: {
          nextPageId: includesBreastDensity ? -5019 : -5015,
          conditions: [
            {
              answerKey: `${baseKey}.hormoneTherapyIntakeMethod.0.optionValue`,
              comparator: '==',
              value: 'breastCancerRisk.hormoneTherapyIntakeMethods.vaginally',
            },
          ],
        },
      },
      {
        id: -10,
        type: 'UNCONDITIONAL',
        name: 'Hormone Replacement Therapy history flow',
        description: 'Built-in flow',
        data: {
          nextPageId: -5010,
        },
      },
    ],
  };
};
