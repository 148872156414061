import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { ReactComponent as CheckedCheckboxIcon } from './images/checkboxChecked.svg';
import { ReactComponent as CheckboxIcon } from './images/checkbox.svg';
import { Text2 } from '../typography';

type Props = {
  labelKey: string;
  disabled: boolean;
  onResponseUpdated: (val: boolean) => void;
};

function Checkbox({ labelKey, onResponseUpdated, disabled }: Props) {
  const { t } = useTranslation();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onResponseUpdated(!isSelected);
    setIsSelected(!isSelected);
  };

  return (
    <div className={`checkbox ${isSelected ? 'checkbox--selected' : ''}`}>
      <label onClick={onClick} className="checkbox__label">
        <input type="checkbox" disabled={disabled} className={'checkbox__input'} />
        {isSelected ? <CheckedCheckboxIcon /> : <CheckboxIcon />}
        <Text2>{t(labelKey)}</Text2>
      </label>
    </div>
  );
}

Checkbox.defaultProps = { disabled: false };

export default Checkbox;
