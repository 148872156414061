import React, { useEffect } from 'react';
import { Group, SubGroup, Relative } from '@myriadgenetics/mgh-types';
import { useHistory, useLocation } from '../../helpers/routing';
import OptionSelect from '../question/option-select';
import Steps from './steps';

import './index.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  initGroup?: Group | null;
  initSubGroup?: SubGroup | null;
  onRelativeSelected: (relative: Relative) => void;
}

export enum RelativeStep {
  Group = 'group',
  SubGroup = 'subGroup',
  Other = 'other',
  SideOfFamily = 'sideOfFamily',
  Selected = 'selected',
}

interface RelativeSelectorState {
  baseUrl?: any;
  group?: Group;
  subGroup?: SubGroup;
  step?: RelativeStep;
}

const stripUnselected = (subGroup: SubGroup) => subGroup.replace('.unselected', '');

const getNextStep = (subGroup: SubGroup): RelativeStep => {
  switch (subGroup) {
    case 'relatives.greatGrandmother.unselected':
    case 'relatives.greatGrandfather.unselected':
    case 'relatives.grandmother.unselected':
    case 'relatives.grandfather.unselected':
    case 'relatives.greatAunt.unselected':
    case 'relatives.greatUncle.unselected':
    case 'relatives.aunt.unselected':
    case 'relatives.uncle.unselected':
    case 'relatives.femaleCousin.unselected':
    case 'relatives.maleCousin.unselected':
    case 'relatives.halfSister.unselected':
    case 'relatives.halfBrother.unselected':
      return RelativeStep.SideOfFamily;
    default:
      return RelativeStep.Selected;
  }
};

const getStep = (initGroup: Group | null | undefined, initSubGroup: SubGroup | null | undefined): RelativeStep => {
  if (initGroup && initSubGroup) {
    return getNextStep(initSubGroup);
  } else if (initGroup && !initSubGroup) {
    return initGroup === 'relatives.other' ? RelativeStep.Other : RelativeStep.SubGroup;
  } else {
    return RelativeStep.Group;
  }
};

interface LocationState {
  state: {
    rsState?: RelativeSelectorState;
  };
  pathname: string;
}

function RelativeSelector({ initGroup, initSubGroup, onRelativeSelected }: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation() as LocationState;
  const { rsState } = location.state || {};

  useEffect(() => {
    if (!rsState) {
      history.replace(`${location.pathname}/group`, {
        ...location.state,
        title: t('pageTitles.relativeAddGroup'),
        rsState: {
          baseUrl: location.pathname,
          group: initGroup,
          subGroup: initSubGroup,
          step: getStep(initGroup, initSubGroup),
        },
      });
    }
  });

  const { baseUrl, group, subGroup, step }: RelativeSelectorState = rsState || {};

  const triggerOnRelativeSelected = (relative: Relative) => {
    location.state.rsState = undefined; // clear out sub state
    onRelativeSelected(relative);
  };

  return (
    <div className="relative-selector">
      {step === RelativeStep.Group && (
        <OptionSelect
          className="relative-group-selector"
          compDef={Steps.relativeGroupSelect}
          onResponseUpdated={(answerKey, val) => {
            const isSelf = val[0].optionValue === 'relatives.self';
            const isOther = val[0].optionValue === 'relatives.other';
            if (isSelf) {
              triggerOnRelativeSelected('relatives.self');
              return;
            }
            history.push(`${baseUrl}/subGroup`, {
              ...location.state,
              rsState: {
                ...rsState,
                group: val[0].optionValue,
                step: isOther ? 'other' : 'subGroup',
              },
            });
            return;
          }}
          responses={{}}
        />
      )}
      {group && step === RelativeStep.SubGroup && group !== 'relatives.self' && group !== 'relatives.other' && (
        <OptionSelect
          compDef={Steps.relativeGroupMap[group]}
          onResponseUpdated={(answerKey, val) => {
            const nextStep = getNextStep(val[0].optionValue);
            const selSubGroup = val[0].optionValue;
            if (nextStep === 'selected') {
              triggerOnRelativeSelected(selSubGroup);
            } else {
              history.push(`${baseUrl}/side`, {
                ...location.state,
                rsState: {
                  ...rsState,
                  subGroup: selSubGroup,
                  step: nextStep,
                },
              });
            }
          }}
          responses={{}}
        />
      )}
      {step === RelativeStep.Other && (
        <div className="relative-selector__other">
          <OptionSelect
            compDef={Steps.otherSelect}
            responses={{}}
            onResponseUpdated={(answerKey, val) => {
              const relative = val && Array.isArray(val) && val[0] && val[0].optionValue ? val[0].optionValue : null;
              if (relative) {
                triggerOnRelativeSelected(relative);
              }
            }}
          />
        </div>
      )}
      {subGroup && step === RelativeStep.SideOfFamily && (
        <OptionSelect
          compDef={Steps.sideOfFamilySelect(subGroup)}
          onResponseUpdated={(answerKey, val) => {
            // this crazy long, seemingly unnecessary statement allows
            //    us to leverage `Relative` flow type to save us
            //    from ourselves in the future
            const optionVal = val && Array.isArray(val) && val[0].optionValue;
            const sideOfFamily = optionVal === 'relatives.sideOfFamily.maternal' ? 'maternal' : 'paternal';
            const relative = `${stripUnselected(subGroup)}.${sideOfFamily}`;
            switch (relative) {
              case 'relatives.greatGrandmother.maternal':
              case 'relatives.greatGrandfather.maternal':
              case 'relatives.grandmother.maternal':
              case 'relatives.grandfather.maternal':
              case 'relatives.greatAunt.maternal':
              case 'relatives.greatUncle.maternal':
              case 'relatives.aunt.maternal':
              case 'relatives.uncle.maternal':
              case 'relatives.femaleCousin.maternal':
              case 'relatives.maleCousin.maternal':
              case 'relatives.halfSister.maternal':
              case 'relatives.halfBrother.maternal':
              case 'relatives.greatGrandmother.paternal':
              case 'relatives.greatGrandfather.paternal':
              case 'relatives.grandmother.paternal':
              case 'relatives.grandfather.paternal':
              case 'relatives.greatAunt.paternal':
              case 'relatives.greatUncle.paternal':
              case 'relatives.aunt.paternal':
              case 'relatives.uncle.paternal':
              case 'relatives.femaleCousin.paternal':
              case 'relatives.maleCousin.paternal':
              case 'relatives.halfSister.paternal':
              case 'relatives.halfBrother.paternal':
                triggerOnRelativeSelected(relative);
                break;
              default:
            }
          }}
          responses={{}}
        />
      )}
    </div>
  );
}

export default RelativeSelector;
