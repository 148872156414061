import heightStep from './height.step';
import weightStep from './weight.step';
import firstMenstruationStep from './firstMenstruation.step';
import currentOrPastMenopauseStep from './currentOrPastMenopause.step';
import currentMenopauseStep from './currentMenopause.step';
import menopauseAgeStep from './menopauseAge.step';
import liveBirthHistoryStep from './liveBirthHistory.step';
import liveBirthStep from './liveBirth.step';
import firstLiveBirthAgeStep from './firstLiveBirthAge.step';
import hrtHistoryStep from './hrtHistory.step';
import hrtIntakeStep from './hrtIntake.step';
import hrtTypesStep from './hrtTypes.step';
import hrtCurrentStep from './hrtCurrent.step';
import hrtStartedStep from './hrtStarted.step';
import hrtExpectedStep from './hrtExpected.step';
import hrtStoppedStep from './hrtStopped.step';
import breastDensityStep from './breastDensityStep.step';
import breastBiopsyHistoryStep from './breastBiopsyHistory.step';
import breastBiopsyTypesStep from './breastBiopsyTypes.step';
import femaleRelativesStep from './femaleRelatives.step';
import { BreastCancerRiskNodeOptions } from './types';

export default (stepOptions: BreastCancerRiskNodeOptions) => {
  return {
    currentMenopauseStep: currentMenopauseStep(stepOptions),
    currentOrPastMenopauseStep: currentOrPastMenopauseStep(stepOptions),
    firstMenstruationStep: firstMenstruationStep(stepOptions),
    heightStep: heightStep(stepOptions),
    liveBirthHistoryStep: liveBirthHistoryStep(stepOptions),
    menopauseAgeStep: menopauseAgeStep(stepOptions),
    weightStep: weightStep(stepOptions),
    liveBirthStep: liveBirthStep(stepOptions),
    firstLiveBirthAgeStep: firstLiveBirthAgeStep(stepOptions),
    hrtHistoryStep: hrtHistoryStep(stepOptions),
    hrtIntakeStep: hrtIntakeStep(stepOptions),
    hrtTypesStep: hrtTypesStep(stepOptions),
    hrtCurrentStep: hrtCurrentStep(stepOptions),
    hrtStartedStep: hrtStartedStep(stepOptions),
    hrtExpectedStep: hrtExpectedStep(stepOptions),
    hrtStoppedStep: hrtStoppedStep(stepOptions),
    breastDensityStep: breastDensityStep(stepOptions),
    breastBiopsyHistoryStep: breastBiopsyHistoryStep(stepOptions),
    breastBiopsyTypesStep: breastBiopsyTypesStep(stepOptions),
    femaleRelativesStep: femaleRelativesStep(stepOptions),
  };
};
