import { BreastCancerRiskType, NodeTypes, ComponentSizes, TextContentType, TextQuestionType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5017,
    description: 'Built-in page node',
    name: 'Female Relatives input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.femaleRelativesInputNode',
    },
    components: [
      {
        id: -22,
        description: 'Built in component',
        type: 'TEXT_CONTENT',
        name: 'Female Relatives question',
        data: {
          type: TextContentType.H3,
          contentKey: 'breastCancerRisk.femaleRelatives',
        },
      },
      {
        id: -18,
        description: 'Built in component',
        name: 'Sisters Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.femaleRelatives.sisters`,
          questionKey: '',
          labelKey: 'breastCancerRisk.sisters',
          placeholderKey: 'breastCancerRisk.femaleRelativesPlaceHolder',
          inputType: TextQuestionType.Number,
          min: 0,
          max: 25,
          threshold: 13,
          size: ComponentSizes.Small,
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -19,
        description: 'Built in component',
        name: 'Daughters Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.femaleRelatives.daughters`,
          questionKey: '',
          labelKey: 'breastCancerRisk.daughters',
          placeholderKey: 'breastCancerRisk.femaleRelativesPlaceHolder',
          inputType: TextQuestionType.Number,
          min: 0,
          max: 25,
          threshold: 13,
          size: ComponentSizes.Small,
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -20,
        description: 'Built in component',
        name: 'Maternal Aunts Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.femaleRelatives.maternalAunts`,
          questionKey: '',
          labelKey: 'breastCancerRisk.maternalAunts',
          placeholderKey: 'breastCancerRisk.femaleRelativesPlaceHolder',
          inputType: TextQuestionType.Number,
          min: 0,
          max: 25,
          threshold: 13,
          size: ComponentSizes.Small,
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -21,
        description: 'Built in component',
        name: 'Paternal Aunts Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.femaleRelatives.paternalAunts`,
          questionKey: '',
          labelKey: 'breastCancerRisk.paternalAunts',
          placeholderKey: 'breastCancerRisk.femaleRelativesPlaceHolder',
          inputType: TextQuestionType.Number,
          min: 0,
          max: 25,
          threshold: 13,
          size: ComponentSizes.Small,
          required: type === BreastCancerRiskType.TcScore,
        },
      },
    ],
    flows: [
      {
        id: -17,
        name: 'Female Relatives flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: null,
        },
      },
    ],
  };
};
