import React from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import {
  OptionSelectComponent,
  TextQuestionComponent,
  ValidationResult,
  OptionKeyValue,
  PersonInsuranceComponent,
  PolicyHolderRelationsType,
  FieldOptionType,
  PersonInsuranceFormFieldsType,
  ImageUploadComponent,
} from '@myriadgenetics/mgh-types';
import UploadImage from '../image-upload';
import { formFields, patientInfoFieldsInSelfFlow, DEFAULT_PATIENT_DATA_KEY } from './form-fields';
import { H3, Text2 } from '../../../elements/typography';
import TextQuestion from '../text-question';
import OptionSelect from '../option-select';
import './index.scss';
import CheckboxComponent from '../checkbox';

interface PersonInsuranceProps {
  compDef: PersonInsuranceComponent;
  responses: Record<string, any>;
  onResponseUpdated: (key: string, val: any) => void;
  validationResult?: ValidationResult;
}

const PersonInsurance: React.FC<PersonInsuranceProps> = ({ compDef, responses, onResponseUpdated, validationResult }) => {
  const { t } = useTranslation();
  const pi = compDef as PersonInsuranceComponent;
  const curAnswer = get(responses, pi.data.answerKey);
  const patientDataKey = pi.data.patientDataKey || DEFAULT_PATIENT_DATA_KEY;
  const patientData = get(responses, patientDataKey);
  const isSelfFlow = curAnswer?.policyHolderRelation && curAnswer.policyHolderRelation[0]?.optionKey === PolicyHolderRelationsType.self;
  const FormFields = formFields(pi);
  const isShowAdditionalFields = curAnswer?.policyHolderRelation && (!isSelfFlow || (isSelfFlow && !patientData));
  const autofillPatientFields = (fields: { [key: string]: string }, isClear = false) =>
    Object.entries(fields).forEach(([fieldName, value]) => onResponseUpdated(`${pi.data.answerKey}.${fieldName}`, isClear ? '' : patientData[value]));
  const updateResponse = (answerKey: string, val: string | OptionKeyValue[]) => {
    if (
      answerKey === PersonInsuranceFormFieldsType.PolicyHolderRelation &&
      Array.isArray(val) &&
      patientData &&
      val[0].optionKey === PolicyHolderRelationsType.self
    ) {
      autofillPatientFields(patientInfoFieldsInSelfFlow);
    }
    if (isSelfFlow && patientData && answerKey === PersonInsuranceFormFieldsType.PolicyHolderRelation) {
      autofillPatientFields(patientInfoFieldsInSelfFlow, true);
    }
    onResponseUpdated(`${pi.data.answerKey}.${answerKey}`, val);
  };

  const updateUninsuredResponse = (answerKey: string, val: boolean) => {
    const insuranceFields = Object.keys(pi.data.fields);
    const updatedInsuranceResponses = get(responses, pi.data.answerKey) || {};

    val &&
      Object.keys(updatedInsuranceResponses).forEach((insuranceAnswerKey) => {
        if (insuranceFields.includes(insuranceAnswerKey)) delete updatedInsuranceResponses[insuranceAnswerKey];
      });

    onResponseUpdated(`${pi.data.answerKey}`, { ...updatedInsuranceResponses, [answerKey]: val });
  };

  const validate = (subCompDef: TextQuestionComponent | OptionSelectComponent | ImageUploadComponent): ValidationResult =>
    validationResult && validationResult.errorMessage && (validationResult.errorMessage as any)[subCompDef.data.answerKey];

  const isFieldDisplayed = (field: string): boolean =>
    Object.keys(FormFields).some(
      (fieldComp) =>
        fieldComp.toLowerCase().includes(field.toLowerCase()) && (pi.data.fields as any)[field] && (pi.data.fields as any)[field] !== FieldOptionType.HIDDEN,
    );

  return (
    <div className="insurance">
      <div className="insurance__wrapper">
        <H3 className="insurance__title">{t(pi.data.questionKey)}</H3>
        {pi.data.descriptionKey && <Text2 className="insurance__description">{t(pi.data.descriptionKey)}</Text2>}
        <div className="insurance__fields">
          {isFieldDisplayed(PersonInsuranceFormFieldsType.PolicyHolderRelation) && (
            <>
              <OptionSelect
                key={PersonInsuranceFormFieldsType.PolicyHolderRelation}
                isDisabled={Boolean(curAnswer?.[FormFields.isUninsuredQuestion.data.answerKey])}
                className={`insurance-field__state`}
                compDef={FormFields.policyHolderRelationQuestion}
                responses={curAnswer}
                onResponseUpdated={updateResponse}
                validationResult={validate(FormFields.policyHolderRelationQuestion)}
              />
              {isFieldDisplayed(PersonInsuranceFormFieldsType.isUninsured) && (
                <CheckboxComponent
                  compDef={FormFields.isUninsuredQuestion}
                  onResponseUpdated={updateUninsuredResponse}
                  className="insurance-field__is-uninsured"
                />
              )}
            </>
          )}
          {curAnswer?.policyHolderRelation && isFieldDisplayed(PersonInsuranceFormFieldsType.InsuranceId) && (
            <div className="insurance-id-wrapper">
              <TextQuestion
                key={PersonInsuranceFormFieldsType.InsuranceId}
                className="insurance-field__insurance-id"
                compDef={FormFields.insuranceIdQuestion}
                responses={curAnswer}
                onResponseUpdated={updateResponse}
                validationResult={validate(FormFields.insuranceIdQuestion)}
              />
            </div>
          )}
          {curAnswer?.policyHolderRelation && isFieldDisplayed(PersonInsuranceFormFieldsType.InsuranceProvider) && (
            <div className="insurance-provider-wrapper">
              <TextQuestion
                key={PersonInsuranceFormFieldsType.InsuranceProvider}
                className="insurance-field__insurance-provider"
                compDef={FormFields.insuranceProviderQuestion}
                responses={curAnswer}
                onResponseUpdated={updateResponse}
                validationResult={validate(FormFields.insuranceProviderQuestion)}
              />
            </div>
          )}
          {isShowAdditionalFields && (
            <>
              {isFieldDisplayed(PersonInsuranceFormFieldsType.PolicyHolderFirstName) && FormFields.policyHolderFirstNameQuestion && (
                <TextQuestion
                  key={PersonInsuranceFormFieldsType.PolicyHolderFirstName}
                  className="insurance-field__first-name"
                  compDef={FormFields.policyHolderFirstNameQuestion}
                  responses={curAnswer}
                  onResponseUpdated={updateResponse}
                  validationResult={validate(FormFields.policyHolderFirstNameQuestion)}
                />
              )}
              {isFieldDisplayed(PersonInsuranceFormFieldsType.PolicyHolderLastName) && (
                <TextQuestion
                  key={PersonInsuranceFormFieldsType.PolicyHolderLastName}
                  className="insurance-field__last-name"
                  compDef={FormFields.policyHolderLastNameQuestion}
                  responses={curAnswer}
                  onResponseUpdated={updateResponse}
                  validationResult={validate(FormFields.policyHolderLastNameQuestion)}
                />
              )}
              {isFieldDisplayed(PersonInsuranceFormFieldsType.PolicyHolderDob) && (
                <TextQuestion
                  key={PersonInsuranceFormFieldsType.PolicyHolderDob}
                  className="insurance-field__dob"
                  compDef={FormFields.policyHolderDobQuestion}
                  responses={curAnswer}
                  onResponseUpdated={updateResponse}
                  validationResult={validate(FormFields.policyHolderDobQuestion)}
                />
              )}
            </>
          )}
          {curAnswer?.policyHolderRelation && (
            <>
              {isFieldDisplayed(PersonInsuranceFormFieldsType.InsuranceCardImageFront) && (
                <UploadImage
                  key={PersonInsuranceFormFieldsType.InsuranceCardImageFront}
                  className="insurance-field__front-image"
                  compDef={FormFields.insuranceCardImageFrontQuestion}
                  onResponseUpdated={updateResponse}
                  validationResult={validate(FormFields.insuranceCardImageFrontQuestion)}
                />
              )}
              {isFieldDisplayed(PersonInsuranceFormFieldsType.InsuranceCardImageBack) && (
                <UploadImage
                  key={PersonInsuranceFormFieldsType.InsuranceCardImageBack}
                  className="insurance-field__back-image"
                  compDef={FormFields.insuranceCardImageBackQuestion}
                  onResponseUpdated={updateResponse}
                  validationResult={validate(FormFields.insuranceCardImageBackQuestion)}
                />
              )}
            </>
          )}
        </div>
        <div className="insurance__text_wrapper">
          {curAnswer?.policyHolderRelation && (
            <>
              <p className="insurance__images_text">{t('personInsurance.imagesText')}</p>
              <p className="insurance__images_text">{t('personInsurance.imageRestrictionText')}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonInsurance;
