import * as MockFixtures from './mock-axios-fixtures';
import { ENDPOINT_URLS } from '../helpers/api';

export const mockApi: any = {
  get: (url: string): any => {
    console.log(`GET: ${url}`);
    let data = {};
    const isGetSurveyAccountsUrl = url.startsWith(`${ENDPOINT_URLS.patientSurveyAccounts}?url=`);
    if (isGetSurveyAccountsUrl) {
      return Promise.resolve({ data: MockFixtures.getSurveyAccounts(url) });
    }

    const isPatientSurveyUrl = url.startsWith(ENDPOINT_URLS.patientSurveys);

    if (isPatientSurveyUrl) {
      const surveyId = parseInt(url.charAt(url.length - 1), 10);
      data = MockFixtures.getPatientSurvey(surveyId);
    }

    const isPatientSurveyAccountUrl = url.startsWith(ENDPOINT_URLS.patientSurveyAccounts);

    if (isPatientSurveyAccountUrl) {
      const surveyAccountId = parseInt(url.charAt(url.length - 1), 10);
      switch (surveyAccountId) {
        case 2:
          data = MockFixtures.surveyEntryAccountNotFound;
          break;
        default:
          data = MockFixtures.getSurveyAccountById(surveyAccountId);
          break;
      }
    }

    const demographicsUrl = '/dtc-order-demographics?accessionId=';
    const isDemographicsUrl = url.startsWith(demographicsUrl);
    if (isDemographicsUrl) {
      switch (url.charAt(url.length - 1)) {
        case '1':
          data = MockFixtures.dtcOrderDemographicsComplete;
          break;
        case '2':
          data = MockFixtures.dtcOrderDemographicsInComplete;
          break;
        case '3':
          data = MockFixtures.dtcOrderDemographicsInCompleteWithData;
          break;
        case '4':
          data = MockFixtures.dtcOrderDemographicsBadRequest;
          break;
        case '5':
          data = MockFixtures.dtcOrderDemographicsInComplete;
          break;
        case '6':
          data = MockFixtures.dtcOrderDemographicsAccessionNotFound;
          break;
        case '7':
          data = MockFixtures.dtcOrderDemographicsClashingId;
          break;
        case '8':
          data = MockFixtures.dtcOrderDemographicsInvalidStatus;
          break;
        case '9':
          data = MockFixtures.dtcOrderDemographicsInternalError;
          break;
        default:
          data = MockFixtures.dtcOrderDemographicsNotFound;
          break;
      }
    }
    console.log(data);
    return Promise.resolve({ data });
  },
  post: (url: string, body: any): any => {
    console.log(`POST: ${url}`);
    console.log('body', body);
    let data = {};
    if (url === ENDPOINT_URLS.criteriaResults) {
      data = MockFixtures.postCriteriaResults(body);
    } else if (url === ENDPOINT_URLS.patientSurveyEntries) {
      data = MockFixtures.postPatientSurveyEntries(body);
    }
    console.log('response', data);

    return Promise.resolve({ data });
  },
  interceptors: {
    response: {
      use: () => {},
    },
  },
};
