import { useTranslation } from 'react-i18next';
import { ReviewPathology, BreastPathology, ProstatePathology, AgeValue, Relative, PathologyHistory, PathologyName } from '@myriadgenetics/mgh-types';
import close from '../../../assets/close.svg';
import { Text1, Text3 } from '../../../elements/typography';
import Link from '../../../elements/link';
import BreastCancerDetails from '../breast-cancer-details';
import ProstateCancerDetails from '../prostate-cancer-details';

import './index.scss';

const SEPARATOR = ':';

const ageValue = (ageResponse?: AgeValue): string => {
  if (Array.isArray(ageResponse)) {
    return ageResponse[0].optionValue;
  }
  if (ageResponse || ageResponse === 0) {
    return ageResponse.toString();
  }
  return 'N/A';
};

type Props = {
  pathology: ReviewPathology | PathologyHistory;
  relative: Relative;
  onEditClicked?: (arg0: boolean) => void;
};
function PathologyDetails({ relative, pathology, onEditClicked }: Props) {
  const { t } = useTranslation();
  const { type } = pathology;
  const isProstateCancer = (type: PathologyName) => type === 'cancerHistory.cancers.prostate';
  const isFemaleBreastCancer = (type: PathologyName) => type === `cancerHistory.cancers.breastFemale` || type === `cancerHistory.cancers.dcis`;

  const relativeBilateralAge = (pathology: BreastPathology) => (
    <div>
      {pathology.first && <AgeDisplay age={ageValue(pathology.first.age)} labelKey="review.firstAge" />}
      {pathology.second && <AgeDisplay age={ageValue(pathology.second.age)} labelKey="review.secondAge" />}
    </div>
  );

  const bilateralAge = (pathology: BreastPathology) => (
    <div>
      {pathology.leftBreastAge && <AgeDisplay age={ageValue(pathology.leftBreastAge)} labelKey="review.leftAge" />}
      {pathology.rightBreastAge && <AgeDisplay age={ageValue(pathology.rightBreastAge)} labelKey="review.rightAge" />}
    </div>
  );

  const breastCancerAge = (pathology: BreastPathology) => {
    if (pathology.first && pathology.second) {
      return relativeBilateralAge(pathology);
    }
    if (pathology.left && pathology.right) {
      return bilateralAge(pathology);
    }
    return <AgeDisplay age={ageValue(pathology.age)} />;
  };

  const getDisplayedAge = (pathology: ReviewPathology | PathologyHistory) => {
    if (pathology.type === 'cancerHistory.cancers.breastFemale' || pathology.type === 'cancerHistory.cancers.dcis') {
      return breastCancerAge(pathology as BreastPathology);
    }
    return <AgeDisplay age={ageValue(pathology.age)} />;
  };

  return (
    <div className="pathology-details">
      {onEditClicked && (
        <div className="pathology-details__buttons">
          <Link onClick={() => onEditClicked(false)} className="pathology-details__edit">
            {t('edit')}
          </Link>
          <div onClick={() => onEditClicked(true)} className="pathology-details__del">
            <img alt={t('pathology.delete')} src={close} />
          </div>
        </div>
      )}
      <Text1 className="pathology-details__type" bold="true">
        {t(type)}
      </Text1>
      {getDisplayedAge(pathology)}
      {isProstateCancer(pathology.type) && <ProstateCancerDetails pathology={pathology as ProstatePathology} />}
      {isFemaleBreastCancer(pathology.type) && <BreastCancerDetails pathology={pathology as BreastPathology} />}
    </div>
  );
}

type AgeDisplayProps = {
  age: string;
  labelKey?: string;
};

function AgeDisplay({ age, labelKey }: AgeDisplayProps) {
  const { t } = useTranslation();
  return (
    <p className="pathology-details__age-display">
      <Text3 className="pathology-details__age-aod" tag="span">{`${t(labelKey || 'review.ageOfDiagnosis')}${SEPARATOR}`}</Text3>
      <Text3 className="pathology-details__age-text" tag="span" bold="true">
        {t(age)}
      </Text3>
    </p>
  );
}

export default PathologyDetails;
