import React, { useState, useEffect } from 'react';
import { ComponentSizes } from '@myriadgenetics/mgh-types';
import { ReactComponent as CheckboxIcon } from './images/checkbox.svg';
import { ReactComponent as CheckedCheckboxIcon } from './images/checkboxChecked.svg';
import { Text1 } from '../typography/index';

import './index.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  children?: any;
  className?: string;
  isPrimary?: boolean;
  openLink?: string;
  onClick?: () => void;
  error: boolean;
  selected: boolean;
  size: ComponentSizes;
  type: string;
  isShowOptionAsCheckbox?: boolean;
  dataTestId?: string;
};

export const baseClass = 'selection-option';
const inputClass = `${baseClass}__input`;

function SelectionOption({ className, isPrimary, openLink, children, error, onClick, selected, size, type, isShowOptionAsCheckbox, dataTestId }: Props) {
  const { t } = useTranslation();
  const sizeClass = `${baseClass}--${size}`;
  const checkboxClass = isShowOptionAsCheckbox ? `${baseClass}--checkbox` : '';
  const [selectedClass, setSelectedClass] = useState(selected ? `${baseClass}--selected` : '');
  const errorClass = error ? `${baseClass}--error` : '';
  const primaryClass = !selected && isPrimary ? `${baseClass}--primary` : '';
  const triggerClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    !selectedClass && setSelectedClass(`${baseClass}--selected`);
    onClick && onClick();
    if (openLink) window.open(t(openLink), '_blank');
  };

  useEffect(() => {
    setSelectedClass(selected ? `${baseClass}--selected` : '');
  }, [selected]);

  return (
    <div
      className={`${baseClass}
        ${checkboxClass}
        ${selectedClass}
        ${className || ''}
        ${errorClass}
        ${sizeClass}
        ${selectedClass.length ? '' : primaryClass}
        noselect
      `}
      onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => (e.key === ' ' || e.key === 'Enter') && triggerClick(e)}
      onClick={triggerClick}
      tabIndex={0}
    >
      <label className={`${baseClass}__label  ${isShowOptionAsCheckbox ? baseClass + '--left' : ''}`} data-testid={dataTestId}>
        <input
          checked={selected}
          className={`${inputClass}
            ${selected ? inputClass + '--selected' : ''}
            ${error ? inputClass + '--error' : ''}
          `}
          hidden
          onChange={() => undefined}
          readOnly={!onClick}
          type={type}
        />
        {isShowOptionAsCheckbox && (selected ? <CheckedCheckboxIcon /> : <CheckboxIcon />)}
        <Text1 className={`${baseClass}__text`} bold={isShowOptionAsCheckbox ? undefined : 'true'}>
          {children}
        </Text1>
      </label>
    </div>
  );
}

SelectionOption.defaultProps = {
  selected: false,
  type: 'checkbox',
  error: false,
  size: 'small',
};

export default SelectionOption;
