import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TextContent from '../text-content';
import { ImageComponent, TextContentType, TextContentComponent } from '@myriadgenetics/mgh-types';

import './index.scss';

interface Props {
  compDef: any | ImageComponent;
}

const textContentComp = (contentKey: string, type?: TextContentType): TextContentComponent => ({
  id: -1,
  type: 'TEXT_CONTENT',
  data: {
    contentKey,
    type: type || TextContentType.Text1,
  },
});

const loadImage = async (imageName: string) => {
  const { default: img } = await import(`../../../assets/${imageName}`);
  return img;
};

function Image({ compDef }: Props) {
  const [image, setImage] = useState<string>('');
  const { t } = useTranslation();
  const { src, altKey, captionKey, captionTextType } = compDef.data;

  useEffect(() => {
    loadImage(src)
      .then((img) => {
        setImage(img);
      })
      .catch(() => {
        setImage('');
      });
  });

  return (
    <div className="image">
      <img className="image__img" src={image} alt={t(altKey)} loading="lazy" decoding="async" />
      {captionKey && <TextContent compDef={textContentComp(captionKey, captionTextType)} />}
    </div>
  );
}

export default Image;
