import { PageNode, ContinueType, SubtitleType, NodeTypes, ComponentSizes } from '@myriadgenetics/mgh-types';

export class BloodTransfusion {
  static initNodeId = -2000;
  static steps = (baseKey: string, continueType: ContinueType, subtitle?: SubtitleType): PageNode[] => [
    {
      id: -2000,
      description: 'Built-in page node',
      name: 'Blood Transfusion History',
      type: NodeTypes.Page,
      data: {
        continueType,
        subtitle,
        pageTitleKey: 'pageTitles.bloodTransfusionHistoryNode',
      },
      components: [
        {
          id: -1,
          description: 'Built in component',
          name: 'Blood Transfusion past year',
          type: 'YES_NO',
          data: {
            answerKey: `${baseKey}.bloodTransfusionPastYear`,
            questionKey: 'bloodTransfusion.bloodTransfusionPastYear',
            required: true,
          },
        },
        {
          id: -2,
          type: 'HELP_HINT',
          data: {
            helpHint: {
              termKey: 'helpHints.bloodTransfusion',
              definitionKey: 'helpHints.bloodTransfusionHint',
            },
          },
        },
      ],
      flows: [
        {
          id: -1,
          type: 'BINARY',
          name: 'Blood Transfusion Past Year',
          description: 'Built-in flow',
          data: {
            answerKey: `${baseKey}.bloodTransfusionPastYear`,
            affirmativePageId: -2001,
            negativePageId: null,
          },
        },
      ],
    },
    {
      id: -2001,
      description: 'Built-in page node',
      name: 'Recent Blood Transfusion History',
      type: NodeTypes.Page,
      data: {
        continueType,
        subtitle,
        pageTitleKey: 'pageTitles.recentBloodTransfusionHistoryNode',
      },
      components: [
        {
          id: -2,
          description: 'Built in component',
          name: 'Recent Blood Transfusion',
          type: 'YES_NO',
          data: {
            answerKey: `${baseKey}.bloodTransfusionRecent`,
            questionKey: 'bloodTransfusion.bloodTransfusionRecent',
            required: true,
          },
        },
      ],
      flows: [
        {
          id: -2,
          type: 'BINARY',
          name: 'Blood Transfusion Recent flow',
          description: 'Built-in flow',
          data: {
            answerKey: `${baseKey}.bloodTransfusionRecent`,
            affirmativePageId: -2002,
            negativePageId: null,
          },
        },
      ],
    },
    {
      id: -2002,
      description: 'Built-in page node',
      name: 'Blood Transfusion types',
      type: NodeTypes.Page,
      data: {
        continueType,
        subtitle,
        pageTitleKey: 'pageTitles.bloodTransfusionTypesNode',
      },
      components: [
        {
          type: 'OPTION_SELECT',
          id: -3,
          name: 'Blood Transfusion Types',
          description: 'Built in question',
          data: {
            answerKey: `${baseKey}.bloodTransfusionType`,
            questionKey: 'bloodTransfusion.bloodTransfusionType',
            labelKey: '',
            placeholderKey: '',
            subtitleKey: '',
            required: true,
            isSingleSelect: true,
            optionSize: ComponentSizes.XSmall,
            selectionOptions: [
              {
                optionKey: 1,
                optionValue: 'bloodTransfusion.bloodTransfusionTypes.redBloodCells',
              },
              {
                optionKey: 5,
                optionValue: 'bloodTransfusion.bloodTransfusionTypes.wholeBlood',
              },
              {
                optionKey: 7,
                optionValue: 'bloodTransfusion.bloodTransfusionTypes.dontKnow',
              },
            ],
          },
        },
        {
          id: -3,
          type: 'HELP_HINT',
          data: {
            helpHint: {
              termKey: 'helpHints.bloodTransfusionTypes',
              definitionKey: 'helpHints.bloodTransfusionTypesHint',
            },
          },
        },
      ],
      flows: [
        {
          id: -3,
          type: 'UNCONDITIONAL',
          name: 'Blood Transfusion types flow',
          description: 'Built-in flow',
          data: {
            nextPageId: null,
          },
        },
      ],
    },
  ];
}
