export interface GTMDataMappingAnswerKeyProps {
  [key: string]: { [key: string]: string };
}

export interface GTMDataMappingProps {
  event: string;
  action: { [key: string]: string };
  id: GTMDataMappingAnswerKeyProps;
  label: GTMDataMappingAnswerKeyProps;
  value: GTMDataMappingAnswerKeyProps;
}

enum qqContinueButtonsMap {
  resultsNoInsurance = 't404p.resultsNoInsurance.button',
  resultsUnqualified = 't404p.resultsUnqualified.button',
  resultsGeneral = 't404p.resultsGeneral.button',
  resultsGovernment = 't404p.resultsGovernment.button',
  paymentBreakdownUseInsurance = 't404p.paymentBreakdown.useInsurance',
  paymentBreakdownNoInsurance = 't404p.paymentBreakdown.noInsurance',
  resultsOptOutUseInsurance = 't404p.resultsOptOut.useInsurance',
  resultsOptOutNoInsurance = 't404p.resultsOptOut.noInsurance',
  resultsOptOutPaymentBreakdown = 't404p.resultsOptOut.paymentBreakdown',
}

export const qqSurveyGTMMapping: GTMDataMappingProps = {
  event: 'mvc', // Myriad Virtual Care
  action: {
    surveyAccountId: 'provider selection',
    qualifyingCancerHistory: 'personal',
    qualifyingFamilyCancerHistory: 'family',
    isPrivateHealthCareInsurance: 'insurance',
    isGovernmentSponsoredInsurance: 'govt insurance',
    insuranceProvider: 'payer list',
    resultsOptOut: 'screen a',
    paymentBreakdown: 'payment breakdown',
    [qqContinueButtonsMap.resultsNoInsurance]: 'screen d',
    [qqContinueButtonsMap.resultsUnqualified]: 'screen e',
    [qqContinueButtonsMap.resultsGeneral]: 'screen c',
    [qqContinueButtonsMap.resultsGovernment]: 'screen b',
    [qqContinueButtonsMap.paymentBreakdownUseInsurance]: 'screen f',
    [qqContinueButtonsMap.paymentBreakdownNoInsurance]: 'screen f',
    [qqContinueButtonsMap.resultsOptOutUseInsurance]: 'screen a',
    [qqContinueButtonsMap.resultsOptOutNoInsurance]: 'screen a',
    redirect: 'redirect',
  },
  id: {
    qualifyingCancerHistory: { default: 'Have you ever been diagnosed with any one of the following cancers?' },
    qualifyingFamilyCancerHistory: { default: 'Do you have a family history of any one of the following?' },
    isPrivateHealthCareInsurance: { default: 'Do you have health insurance?' },
    isGovernmentSponsoredInsurance: { default: 'Is your insurance government-sponsored (i.e., Medicare, Medicaid, Medicare Advantage)?' },
    insuranceProvider: { default: 'Is your insurance provider one of the following?' },
    resultsOptOut: {
      [qqContinueButtonsMap.resultsOptOutPaymentBreakdown]: 'View payment breakdown',
      [qqContinueButtonsMap.resultsOptOutNoInsurance]: 'How would you like to proceed? screen a',
    },
    paymentBreakdown: {
      [qqContinueButtonsMap.paymentBreakdownNoInsurance]: 'Opt out of insurance screen f',
    },
    surveyAccountId: { default: "Please select your healthcare provider's name." },
    [qqContinueButtonsMap.resultsNoInsurance]: { default: 'Continue screen d' },
    [qqContinueButtonsMap.resultsUnqualified]: { default: 'Continue screen e' },
    [qqContinueButtonsMap.resultsGeneral]: { default: 'Continue screen c' },
    [qqContinueButtonsMap.resultsGovernment]: { default: 'Continue screen b' },
    [qqContinueButtonsMap.paymentBreakdownUseInsurance]: { default: 'Use my insurance screen f' },
    [qqContinueButtonsMap.resultsOptOutUseInsurance]: { default: 'How would you like to proceed? screen a' },
  },
  value: {
    qualifyingCancerHistory: {
      true: 'yes personal',
      false: 'no personal',
    },
    qualifyingFamilyCancerHistory: {
      true: 'yes family',
      false: 'no family',
    },
    isPrivateHealthCareInsurance: {
      true: 'yes insurance',
      false: 'no insurance',
    },
    isGovernmentSponsoredInsurance: {
      true: 'yes govt insurance',
      false: 'no govt insurance',
    },
    insuranceProvider: {
      true: 'yes payer list',
      false: 'no payer list ',
    },
    resultsOptOut: {
      [qqContinueButtonsMap.resultsOptOutPaymentBreakdown]: 'view payment breakdown screen a',
      [qqContinueButtonsMap.resultsOptOutNoInsurance]: 'opt out of insurance screen a',
    },
    paymentBreakdown: {
      [qqContinueButtonsMap.paymentBreakdownNoInsurance]: 'opt out of insurance screen f',
    },
    [qqContinueButtonsMap.resultsNoInsurance]: { default: 'continue screen d' },
    [qqContinueButtonsMap.resultsUnqualified]: { default: 'continue screen e' },
    [qqContinueButtonsMap.resultsGeneral]: { default: 'continue screen c' },
    [qqContinueButtonsMap.resultsGovernment]: { default: 'continue screen b' },
    [qqContinueButtonsMap.paymentBreakdownUseInsurance]: { default: 'use my insurance screen f' },
    [qqContinueButtonsMap.resultsOptOutUseInsurance]: { default: 'use my insurance screen a' },
    surveyAccountId: { default: 'continue provider' },
  },
  label: {
    redirect: { default: 'redirect node' },
    qualifyingCancerHistory: {
      true: 'Yes',
      false: 'No',
    },
    qualifyingFamilyCancerHistory: {
      true: 'Yes',
      false: 'No',
    },
    isPrivateHealthCareInsurance: {
      true: 'Yes',
      false: 'No',
    },
    isGovernmentSponsoredInsurance: {
      true: 'Yes',
      false: 'No',
    },
    insuranceProvider: {
      true: 'Yes',
      false: 'No',
    },
    resultsOptOut: {
      [qqContinueButtonsMap.resultsOptOutPaymentBreakdown]: 'View payment breakdown',
      [qqContinueButtonsMap.resultsOptOutNoInsurance]: 'Opt out of insurance $42',
    },
    paymentBreakdown: {
      [qqContinueButtonsMap.paymentBreakdownNoInsurance]: 'Opt out of insurance $42',
    },
    [qqContinueButtonsMap.resultsNoInsurance]: { default: 'Continue' },
    [qqContinueButtonsMap.resultsUnqualified]: { default: 'Continue' },
    [qqContinueButtonsMap.resultsGeneral]: { default: 'Continue' },
    [qqContinueButtonsMap.resultsGovernment]: { default: 'Continue' },
    [qqContinueButtonsMap.paymentBreakdownUseInsurance]: { default: 'Use my insurance $50 (or max $179)' },
    [qqContinueButtonsMap.resultsOptOutUseInsurance]: { default: 'Use my insurance $50 (or max $179)' },
    [qqContinueButtonsMap.resultsOptOutNoInsurance]: { default: 'Opt out of insurance $42' },
  },
};

export default {
  qqSurveyGTMMapping,
};
