import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, VideoPlayerType } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const meetsCriteriaTCOverThresholdNode: ChatbotNode = {
  id: NODE_IDS.MEETS_CRITERIA_TC_OVER_THRESHOLD_NODE,
  name: 'Meets Criteria TC Over Threshold Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.meetCriteriaResultsNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'You are a candidate',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.candidateForScreening',
        inlineImages: [
          {
            src: 'chatbot.meetsCriteriaResults.metCheck.src',
            altKey: 'chatbot.meetsCriteriaResults.metCheck.alt',
          },
          {
            src: 'chatbot.meetsCriteriaResults.candidateForScreeningImage.src',
            altKey: 'chatbot.meetsCriteriaResults.candidateForScreeningImage.alt',
          },
        ],
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Benefit From Genetic Testing',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.mayBenefitFromTesting',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Understand Why Testing',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.understandWhyTesting',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'VIDEO',
      name: 'Video',
      data: {
        src: 'chatbot.results.met.video',
        delay: 3500,
        usePlayer: VideoPlayerType.Wistia,
        autoPlay: true,
        startMuted: true,
        isContinueControlledByPlayerEvent: true,
      },
    },
  ],
  flows: [
    {
      id: 1,
      type: 'UNCONDITIONAL',
      name: 'Go To Meets Criteria Genetic Counselor Contact Node',
      data: {
        nextPageId: NODE_IDS.MEETS_CRITERIA_GENETIC_COUNSELOR_CONTACT_NODE,
      },
    },
  ],
};

export default meetsCriteriaTCOverThresholdNode;
