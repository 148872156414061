import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';
import { TextQuestionComponent, ValidationResult, ComponentSizes } from '@myriadgenetics/mgh-types';
import TextInput from '../../../elements/text-input';
import QuestionText from '../../../elements/question-text';
import Button from '../../../elements/button';
import { Text2, Text3 } from '../../../elements/typography';
import { isNumber } from 'lodash';

import './index.scss';
import { getAge, getDateFromInput, isValidDate } from '../helper';

type inputTypesWithNumber = 'age' | 'number';

interface Props {
  compDef: TextQuestionComponent | any;
  className?: string;
  onResponseUpdated: (answerKey: string, val: any) => void;
  responses: Record<string, any>;
  validationResult?: ValidationResult;
}
function TextQuestion({ compDef, className, onResponseUpdated, responses, validationResult }: Props) {
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [isOutOfBounds, setIsOutOfBounds] = useState<boolean>(false);
  const { t } = useTranslation();
  const ti: TextQuestionComponent = compDef as TextQuestionComponent;
  const { answerKey, inputSize, inputType, labelKey, placeholderKey, questionKey, questionKeyParams, required, secondaryResponse, size, min, max, threshold } =
    ti.data;

  const response = get(responses, answerKey);

  const getWarningMessage = (isOutOfBounds: boolean, isOverThreshold: boolean, type: inputTypesWithNumber, intVal: number) => {
    if (isOutOfBounds) {
      const outOfBoundsType = type === 'age' ? 'an age' : 'a value';
      return t('validation.numberOutOfBounds', { min, max, type: outOfBoundsType });
    }

    if (isOverThreshold) {
      const overThresholdType = type === 'age' ? ' for age' : '';
      return t('validation.numberOverThreshold', { threshold: intVal, type: overThresholdType });
    }
    return '';
  };

  const ageAndNumberChecker = (val: string, type: inputTypesWithNumber) => {
    const intVal = parseInt(val);
    if (isNumber(intVal)) {
      const isOutOfBounds = (isNumber(min) && intVal < min) || (isNumber(max) && intVal > max);
      const isOverThreshold = isNumber(threshold) && intVal >= threshold && isNumber(max) && intVal <= max;
      const warningMessage = getWarningMessage(isOutOfBounds, isOverThreshold, type, intVal);
      setWarningMessage(warningMessage);
      setIsOutOfBounds(isOutOfBounds);
    }
  };

  const answerChanged = (val?: string | boolean | number | null): void => {
    if ((inputType === 'age' || inputType === 'number') && typeof val === 'string') {
      ageAndNumberChecker(val, inputType);
    }
    if (inputType === 'dob' && val && typeof val === 'string' && answerKey.includes('patient')) {
      onResponseUpdated('patient.age', isValidDate(val) ? getAge(getDateFromInput(val)) : null);
    }
    onResponseUpdated(answerKey, val);
  };

  // TODO update secondaryResponse in text-question, validation, and option-select
  // I really don't like how we handle "secondaryResponse" - d$
  // we really should just create something around "notSure" specifically
  const isSecondary = secondaryResponse && (Array.isArray(response) || response === secondaryResponse.value);

  const isInvalid: boolean = (!!validationResult && !validationResult.isValid) || isOutOfBounds;

  const hasValidationError = (errorMessage: string): boolean => isInvalid && !!validationResult && validationResult.errorMessage === errorMessage;

  const isResponseDirty = response !== undefined;

  const displayValidationResult = isInvalid && (!secondaryResponse || isOutOfBounds) && !isInputFocused && isResponseDirty;

  const displayValidationResultLabel = isInvalid && !secondaryResponse && (hasValidationError('validation.required') || (isResponseDirty && !isInputFocused));

  return (
    <div className={`text-question ${className || ''} text-question--${inputSize || 'large'}`}>
      {questionKey && (
        <div className="text-question__question-text">
          <QuestionText htmlFor="textQuestion" questionKey={questionKey} questionKeyParams={questionKeyParams} size={size} />
          {required && !labelKey}
        </div>
      )}
      <div className="text-question__label-container">
        {labelKey && (
          <Text2 className="text-question__label" tag="label">
            {t(labelKey)}
          </Text2>
        )}
        {displayValidationResultLabel && (
          <Text3 className="text-question__validation-label" bold="true" tag="span">
            {validationResult !== undefined && t(validationResult.errorMessage as string)}
          </Text3>
        )}
      </div>
      <TextInput
        className="text-question__text-input"
        error={displayValidationResult}
        onBlur={() => setIsInputFocused(false)}
        onChange={answerChanged}
        onFocus={() => setIsInputFocused(true)}
        placeholder={t(placeholderKey)}
        type={inputType}
        value={isSecondary ? '' : response}
        min={min}
        max={max}
      />
      {warningMessage && <div className="text-question__warning-message">{warningMessage}</div>}
      {secondaryResponse && (
        <Button
          className="text-question__secondary-button"
          secondary={true}
          size={'small'}
          disabled={!isSecondary && isResponseDirty && response !== ''}
          selected={isSecondary}
          onClick={() => answerChanged(secondaryResponse.value)}
        >
          {t(secondaryResponse.textKey)}
        </Button>
      )}
    </div>
  );
}

TextQuestion.defaultProps = {
  size: ComponentSizes.Large,
};

export default TextQuestion;
