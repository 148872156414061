import { OptionKeyValue } from '@myriadgenetics/mgh-types';
import { SurveyAccountsContextType } from '@myriadgenetics/mgh-types';
import i18n from '../../lang/i18n';

export interface RemappedResponse {
  key: string;
  value: string | boolean | undefined;
}

let surveyAccountsContext: SurveyAccountsContextType | null = null;

export const setSurveyAccountsContext = (context: SurveyAccountsContextType | null) => {
  surveyAccountsContext = context;
};

enum remapAnswerKeys {
  BC_CURRENT_OR_PAST = 'breastCancerRisk.menopauseCurrentOrPast',
  BC_MENOPAUSE_CURRENT_OR_PAST_OPTION_SELECT = 'breastCancerRisk.menopauseCurrentOrPastOption',
  BC_MENOPAUSE_CURRENT = 'breastCancerRisk.menopauseCurrent',
  LANGUAGE = 'language',
  PREVIOUSLY_TESTED = 'previouslyTested',
  HAS_CANCER_HISTORY = 'hasCancerHistory',
  IN_OFFICE_OPTION_SELECT = 'inOfficeOptionSelect',
  PHONE_TYPE = 'patient.phoneType',
  PHONE_TYPE_OPTION_SELECT = 'patient.phoneTypeOptionSelect',
  LANGUAGE_USED = 'languageUsed',
  HAS_BEEN_PREVIOUSLY_TESTED = 'hasGeneticTestingHistory',
  QUALIFYING_HISTORY = 'qualifyingHistory',
  IN_OFFICE = 'inOffice',
  HEALTHCARE_FACILITY = 'healthcareFacility',
  MYRA_HEALTHCARE_FACILITY = 'myraHealthcareFacility',
  PROVIDER_LOCATION = 'patient.providerLocation',
  HRT_INTAKE_METHOD = 'breastCancerRisk.hormoneTherapyIntakeMethod',
  HRT_HISTORY = 'breastCancerRisk.hormoneTherapyHistory',
}

const getValueFromOptionSelect = (optionSelect: OptionKeyValue) => {
  return optionSelect.optionValue ?? null;
};

const getLastKeyInJsonPath = (val: any) => {
  const optionSelectKeyArr = getValueFromOptionSelect(val[0]).split('.');
  return optionSelectKeyArr[optionSelectKeyArr.length - 1];
};

const getBoolFromYesNoOption = (val: any): boolean => {
  return getLastKeyInJsonPath(val) === 'yes';
};

const getMenoPausePastOrCurrentValue = (optionKey: number | undefined) => {
  switch (optionKey) {
    case 1:
      return true;
    case 2:
      return false;
    case 3:
      return true;
    case 4:
      return false;
    default:
      return false;
  }
};

// TODO: document why we need this
const remapMap = {
  [remapAnswerKeys.BC_MENOPAUSE_CURRENT_OR_PAST_OPTION_SELECT]: (answer: any): RemappedResponse[] => {
    const currentOrPastOption = {
      // 1 = yes, 2 = no, 3 = current, 4 = i don't know
      key: remapAnswerKeys.BC_CURRENT_OR_PAST,
      value: getMenoPausePastOrCurrentValue(answer[0]?.optionKey),
    };

    const currentOption = {
      key: remapAnswerKeys.BC_MENOPAUSE_CURRENT,
      value: answer[0]?.optionKey === 3 ? true : answer[0]?.optionKey === 2 ? undefined : false,
    };

    return [currentOrPastOption, currentOption];
  },
  [remapAnswerKeys.BC_MENOPAUSE_CURRENT]: (answer: any): RemappedResponse[] => {
    return [
      {
        key: remapAnswerKeys.BC_MENOPAUSE_CURRENT,
        value: answer,
      },
      {
        key: remapAnswerKeys.BC_CURRENT_OR_PAST,
        value: answer,
      },
    ];
  },
  [remapAnswerKeys.PHONE_TYPE_OPTION_SELECT]: (answer: any): RemappedResponse[] => {
    return [
      {
        key: remapAnswerKeys.PHONE_TYPE,
        value: answer[0]?.optionKey.toLowerCase(),
      },
    ];
  },
  [remapAnswerKeys.LANGUAGE]: (answer: any): RemappedResponse[] => {
    const langSelected = getLastKeyInJsonPath(answer);
    i18n.changeLanguage(langSelected);
    return [
      {
        key: remapAnswerKeys.LANGUAGE_USED,
        value: langSelected,
      },
    ];
  },
  [remapAnswerKeys.PREVIOUSLY_TESTED]: (answer: any): RemappedResponse[] => [
    {
      key: remapAnswerKeys.HAS_BEEN_PREVIOUSLY_TESTED,
      value: getBoolFromYesNoOption(answer),
    },
  ],
  [remapAnswerKeys.HAS_CANCER_HISTORY]: (answer: any): RemappedResponse[] => [
    {
      key: remapAnswerKeys.QUALIFYING_HISTORY,
      value: getBoolFromYesNoOption(answer),
    },
  ],
  [remapAnswerKeys.IN_OFFICE_OPTION_SELECT]: (answer: any): RemappedResponse[] => [
    {
      key: remapAnswerKeys.IN_OFFICE,
      value: getLastKeyInJsonPath(answer) === remapAnswerKeys.HEALTHCARE_FACILITY || getLastKeyInJsonPath(answer) === remapAnswerKeys.MYRA_HEALTHCARE_FACILITY,
    },
  ],
  [remapAnswerKeys.PROVIDER_LOCATION]: (answer: any): void => {
    if (surveyAccountsContext === null) return;
    surveyAccountsContext.setSurveyAccount(answer);
  },
  [remapAnswerKeys.HRT_INTAKE_METHOD]: (answer: any): RemappedResponse[] => {
    const intakeMethodIsVaginally = getLastKeyInJsonPath(answer) === 'vaginally';
    return [
      {
        key: remapAnswerKeys.HRT_HISTORY,
        value: !intakeMethodIsVaginally,
      },
    ];
  },
};

export const remapAnswer = (answerKey: string, answer: any): RemappedResponse[] | null => {
  if (Object.prototype.hasOwnProperty.call(remapMap, answerKey)) {
    return (remapMap as any)[answerKey](answer);
  }
  return null;
};

export default {
  remapAnswer,
  setSurveyAccountsContext,
};
