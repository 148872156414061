import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { AccordionItem as AccordionItemProps, AccordionComponent } from '@myriadgenetics/mgh-types';
import { H2 } from '../../../elements/typography';
import AccordionItem from './components/accordionItem';

export interface Props {
  compDef: any | AccordionComponent;
  onContinue?: () => void;
  clinicName?: string;
  clinicLogo?: string;
}

const Accordion: React.FC<Props> = ({ compDef }) => {
  const { titleKey, subtitleKey, items, moreLink } = compDef.data;
  const { t } = useTranslation();

  return (
    <div className={'accordion'}>
      <div className="accordion__title-wrapper">
        <H2 className="accordion__title">{t(titleKey)}</H2>
        <p className="accordion__subtitle">{t(subtitleKey)}</p>
      </div>
      <ul className="accordion__items">
        {items?.map((item: AccordionItemProps) => (
          <AccordionItem {...item} key={item.titleKey} />
        ))}
      </ul>
      {moreLink && (
        <a href={moreLink.linkValue} title={t(moreLink.linkKey)} rel="noreferrer" target="_blank" className="accordion__more-link">
          {t(moreLink.linkKey)}
        </a>
      )}
    </div>
  );
};

export default Accordion;
