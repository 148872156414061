import { Trans, useTranslation } from 'react-i18next';
import { RecoverTypes, TextLink } from '@myriadgenetics/mgh-types';
import { useHistory, useLocation, startOver } from '../../helpers/routing';
import Button from '../../elements/button';
import { H1, Text1 } from '../../elements/typography';

import './index.scss';

export interface AuxState {
  titleKey: string;
  messageKey: string;
  textLinks?: TextLink[];
  recoverAction?: RecoverTypes;
  recoverTextKey?: string;
}

interface AuxLocation {
  auxState: AuxState;
}

function AuxiliaryPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { auxState }: AuxLocation = (location.state || {}) as AuxLocation;

  const { titleKey, messageKey, recoverAction, recoverTextKey, textLinks } = auxState || {
    titleKey: 'errorPages.application.title',
    messageKey: 'errorPages.application.message',
  };

  const executeRecover = (recoverType: RecoverTypes) => {
    switch (recoverType) {
      case RecoverTypes.Restart:
        startOver();
        break;
      case RecoverTypes.GoBack:
        history.goBack();
        break;
      default:
    }
  };

  const getLinksComponents = (): JSX.Element[] =>
    (textLinks || []).map(({ urlKey, targetIsBlank }: TextLink, index: number) => (
      /* eslint-disable-next-line jsx-a11y/anchor-has-content */
      <a className="auxiliary-page__link" key={`${urlKey}-${index}`} href={t(urlKey)} target={targetIsBlank ? '_blank' : '_self'} rel="noreferrer" />
    ));

  return (
    <div className="auxiliary-page">
      <H1 className="auxiliary-page__title">{t(titleKey)}</H1>
      <Text1 className="auxiliary-page__message">
        <Trans i18nKey={messageKey} components={[...getLinksComponents()]} />
      </Text1>
      {recoverAction && recoverAction !== RecoverTypes.None && (
        <Button className="auxiliary-page__button" onClick={() => executeRecover(recoverAction)}>
          {recoverTextKey ? t(recoverTextKey) : 'Continue'}
        </Button>
      )}
    </div>
  );
}

export default AuxiliaryPage;
