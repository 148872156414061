import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DialogFrame, { DialogType } from '../../components/dialog-frame';
import Logger from '../../helpers/logger';
import Link from '../link';
import { Text2 } from '../typography';
import './index.scss';

interface Props {
  footerKey: string;
  termsAndPrivacyLinks?: boolean;
}

function NodeFooter({ footerKey, termsAndPrivacyLinks }: Props) {
  // TODO think of a better solution here: problem need a link in text with action
  const { t } = useTranslation();
  const [dialogDisplay, setDialogDisplay] = useState<DialogType | null>(null);
  const footerTranslation = footerKey && <Trans i18nKey={footerKey} />;

  return (
    <React.Fragment>
      {footerTranslation && (
        <Text2 className="node-footer">
          <Text2 tag="span" className="footer-content">
            {footerTranslation}
          </Text2>
          {termsAndPrivacyLinks && (
            <>
              <Link
                className="node-footer__tu-link"
                onClick={() => {
                  Logger.logEvent('Clicked Terms of Use', {});
                  setDialogDisplay(DialogType.TermsOfUse);
                }}
              >
                <Text2 tag="span">{t('dtc.termsOfUse')}</Text2>
              </Link>
              <Text2 tag="span">{t('dtc.and')}</Text2>
              <Link
                className="node-footer__pp-link"
                onClick={() => {
                  Logger.logEvent('Clicked Privacy Policy', {});
                  setDialogDisplay(DialogType.PrivacyPolicy);
                }}
              >
                <Text2 tag="span">{t('dtc.privacy')}</Text2>
              </Link>
            </>
          )}
        </Text2>
      )}
      {dialogDisplay && <DialogFrame type={dialogDisplay} onClose={() => setDialogDisplay(null)} />}
    </React.Fragment>
  );
}

export default NodeFooter;
