import { NodeTypes, ComponentSizes, ContinueType, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type, includesBreastDensity }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5004,
    description: 'Built-in page node',
    name: 'Current or past menopause',
    type: NodeTypes.Page,
    data: {
      continueType: ContinueType.AutoFixed,
      subtitle,
      pageTitleKey: 'pageTitles.currentOrPastMenopauseNode',
    },
    components: [
      {
        id: -5,
        description: 'Built in component',
        name: 'Current or past menopause',
        type: 'OPTION_SELECT',
        data: {
          answerKey: `${baseKey}.menopauseCurrentOrPastOption`,
          questionKey: 'breastCancerRisk.menopauseCurrentOrPastOption',
          labelKey: '',
          placeholderKey: '',
          subtitleKey: '',
          required: type === BreastCancerRiskType.TcScore,
          isSingleSelect: true,
          optionSize: ComponentSizes.Medium,
          selectionOptions: [
            {
              optionKey: 1,
              optionValue: 'breastCancerRisk.menopauseCurrentOrPastOptions.yes',
            },
            {
              optionKey: 2,
              optionValue: 'breastCancerRisk.menopauseCurrentOrPastOptions.no',
            },
            {
              optionKey: 3,
              optionValue: 'breastCancerRisk.menopauseCurrentOrPastOptions.other',
            },
            {
              optionKey: 4,
              optionValue: 'breastCancerRisk.menopauseCurrentOrPastOptions.unknown',
            },
          ],
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStages',
            definitionKey: '',
          },
        },
      },
      {
        id: -300,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStagesPeri',
            definitionKey: 'helpHints.menopauseStagesPeriHint',
          },
        },
      },
      {
        id: -4,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStagesMeno',
            definitionKey: 'helpHints.menopauseStagesMenoHint',
          },
        },
      },
      {
        id: -5,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopauseStagesPost',
            definitionKey: 'helpHints.menopauseStagesPostHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -9,
        type: 'CONDITIONAL',
        name: 'To Menopause Age When gone through menopause',
        description: 'Built-in flow',
        data: {
          nextPageId: -5006,
          conditions: [
            {
              answerKey: `${baseKey}.menopauseCurrentOrPastOption.0.optionValue`,
              comparator: '==',
              value: 'breastCancerRisk.menopauseCurrentOrPastOptions.yes',
            },
          ],
        },
      },
      {
        id: -10,
        type: 'CONDITIONAL',
        name: 'To HRT when currently going through',
        description: 'Built-in flow',
        data: {
          nextPageId: -5009,
          conditions: [
            {
              answerKey: `${baseKey}.menopauseCurrentOrPastOption.0.optionValue`,
              comparator: '==',
              value: 'breastCancerRisk.menopauseCurrentOrPastOptions.other',
            },
          ],
        },
      },
      {
        id: -10,
        type: 'CONDITIONAL',
        name: 'To going through or in perimenopause when not gone through menopause',
        description: 'Built-in flow',
        data: {
          nextPageId: -5005,
          conditions: [
            {
              answerKey: `${baseKey}.menopauseCurrentOrPastOption.0.optionValue`,
              comparator: '==',
              value: 'breastCancerRisk.menopauseCurrentOrPastOptions.no',
            },
          ],
        },
      },
      {
        id: -6,
        name: 'To Breast Biopsy',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: includesBreastDensity ? -5019 : -5015,
        },
      },
    ],
  };
};
