import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../elements/button';
import { H1 } from '../../elements/typography';

import './index.scss';

type Props = {
  delay: number;
  onContinue: () => void;
  onStartOver: () => void;
  onTimeout: () => void;
  theme?: string;
};

const SEC = 1000;

function CountdownPage({ delay, onContinue, onStartOver, onTimeout, theme = '' }: Props) {
  const { t } = useTranslation();
  const [second, setSecond] = useState<number>(delay);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSecond(second - 1);
    }, SEC);
    if (second <= 0) {
      clearTimeout(timer);
      onTimeout();
    }
    return () => clearTimeout(timer);
  });

  const isChatbotTheme = theme === 'chatbot';

  return (
    <div className="countdown-page">
      {isChatbotTheme ? (
        <H1 className="countdown-page__title">
          <p className="countdown-page__timing-out">
            <Trans i18nKey={'chatbot.countdown.timingOut'} components={[<strong />]} />
          </p>
          <span className="countdown-page__seconds">{second > 1 ? t('chatbot.countdown.seconds', { second }) : t('chatbot.countdown.second', { second })}</span>
        </H1>
      ) : (
        <H1 className="countdown-page__title">{t('countdown.timingOut', { second })}</H1>
      )}
      <div className="countdown-page__actions">
        <Button className="countdown-page__continue-button" onClick={onContinue}>
          {t('resume')}
        </Button>
        <Button className="countdown-page__start-over-button" onClick={onStartOver}>
          {t('startOver')}
        </Button>
      </div>
    </div>
  );
}

CountdownPage.defaultProps = {
  delay: 60,
};

export default CountdownPage;
