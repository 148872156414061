import React from 'react';
import './index.scss';

interface PdfViewerProps {
  encodedPdf: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ encodedPdf }) => {
  return <object aria-label="pdf viewer" className="pdf-viewer__object" data={`data:application/pdf;base64,${encodedPdf}`} type="application/pdf"></object>;
};

export default PdfViewer;
