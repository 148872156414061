import { ANCESTRY_LIST, USA_STATES } from './constants';
import { OptionKeyValue, SurveyAccount } from '@myriadgenetics/mgh-types';

export const getOptions = (list: string[], translationKey: string): OptionKeyValue[] =>
  list.map((state, index) => ({
    optionKey: index + 1,
    optionValue: `${translationKey}.${state}`,
  }));

export const getAncestryOptions = (translationKey = 'ancestryList') => getOptions(ANCESTRY_LIST, translationKey);
export const getStateOptions = (translationKey = 'personDemographic.states') => getOptions(USA_STATES, translationKey);

export const getSurveyAccountOptions = (surveyAccounts: SurveyAccount[] | null | undefined) => {
  if (!Array.isArray(surveyAccounts)) return [];
  return surveyAccounts.map((account, index) => ({
    optionKey: index,
    optionValue: account.providerName,
    optionDisplayKey: account.providerName,
  }));
};

let startNodeId = -999;

export const getNextComponentId = () => {
  startNodeId--;
  return startNodeId;
};

export default {
  getNextComponentId,
};
