import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';
export const exampleNode: ChatbotNode = {
  id: NODE_IDS.QUALIFYING_QUESTION_NODE,
  name: 'Qualifying Question Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.qualifyingQuestionNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Qualifying Question',
      data: {
        contentKey: 'chatbot.cancerHistory.personal.everDiagnosed',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_LIST',
      name: 'Qualifying Question List',
      data: {
        columned: true,
        delay: 500,
        avatarSvg: 'chatbot.hcpAvatar',
        listKeys: [
          'chatbot.qualifyingPathologies.breast',
          'chatbot.qualifyingPathologies.melanoma',
          'chatbot.qualifyingPathologies.colon',
          'chatbot.qualifyingPathologies.ovarian',
          'chatbot.qualifyingPathologies.dcis',
          'chatbot.qualifyingPathologies.pancreatic',
          'chatbot.qualifyingPathologies.endometrial',
          'chatbot.qualifyingPathologies.peritoneal',
          'chatbot.qualifyingPathologies.fallopian',
          'chatbot.qualifyingPathologies.prostate',
          'chatbot.qualifyingPathologies.gastric',
          'chatbot.qualifyingPathologies.rectum',
        ],
        components: [
          {
            id: getNextComponentId(),
            type: 'HELP_HINT',
            name: 'DCIS Help Hint',
            data: {
              delay: 500,
              helpHint: {
                termKey: 'chatbot.cancerHistory.dcisHintKey',
                definitionKey: 'chatbot.cancerHistory.dcisFootnote',
              },
              hintSeparator: ' =',
              avatarSvg: 'chatbot.hcpAvatar',
            },
          },
        ],
      },
    },
    {
      id: getNextComponentId(),
      name: 'Has personal or family chx',
      type: 'OPTION_SELECT',
      data: {
        answerKey: 'hasCancerHistory',
        questionKey: '',
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
        required: true,
        isSingleSelect: true,
        optionSize: ComponentSizes.Medium,
        delay: 5000,
        position: 'right',
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'chatbot.cancerHistory.personal.yes',
          },
          {
            optionKey: 2,
            optionValue: 'chatbot.cancerHistory.personal.no',
          },
        ],
      },
    },
  ],
  flows: [
    {
      id: -2,
      name: 'Conditional to Cancer History Intro',
      description: '',
      type: 'CONDITIONAL',
      data: {
        nextPageId: NODE_IDS.CANCER_HISTORY_INTRO_NODE,
        conditions: [
          {
            value: 'chatbot.cancerHistory.personal.yes',
            answerKey: 'hasCancerHistory.0.optionValue',
            comparator: '==',
          },
        ],
      },
    },
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Cancer History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.HALFWAY_THROUGH_NODE,
      },
    },
  ],
};

export default exampleNode;
