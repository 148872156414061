import { ContinueType, NodeTypes, ChatModalNodeType, ChatModalNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const hideResultsNode: ChatModalNode = {
  id: NODE_IDS.HIDE_RESULTS_NODE,
  type: NodeTypes.ChatModalNode,
  name: 'Chatbot Meets Criteria Hide Results',
  description: 'Chatbot Meets Criteria Hide Results',
  data: {
    answerKey: 'hideResults',
    continueType: ContinueType.AutoFixed,
    subtitle: 'chatbot.assessmentResults.discussResultsWithProviderTitle',
    flowType: ChatModalNodeType.HideResults,
    pageTitleKey: 'pageTitles.chatbot.hideResultsNode',
  },
  flows: [
    {
      id: -1,
      type: 'UNCONDITIONAL',
      name: 'Thank You - Exit Chat',
      description: '',
      data: {
        nextPageId: NODE_IDS.THANK_YOU_EXIT,
      },
    },
  ],
};

export default hideResultsNode;
