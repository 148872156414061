import { NodeTypes, ComponentSizes, ContinueType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5016,
    description: 'Built-in page node',
    name: 'Breast Biopsy types',
    type: NodeTypes.Page,
    data: {
      continueType: ContinueType.Fixed,
      subtitle,
      pageTitleKey: 'pageTitles.breastBiopsyHistoryTypesNode',
    },
    components: [
      {
        type: 'OPTION_SELECT',
        id: -17,
        name: 'Breast biopsy Types',
        description: 'Built in question',
        data: {
          answerKey: `${baseKey}.breastBiopsyType`,
          questionKey: 'breastCancerRisk.breastBiopsyType',
          labelKey: '',
          placeholderKey: '',
          subtitleKey: 'breastCancerRisk.breastBiopsySubtitle',
          required: true,
          isSingleSelect: false,
          optionSize: ComponentSizes.Medium,
          selectionOptions: [
            {
              optionKey: 6,
              optionValue: 'breastCancerRisk.breastBiopsyTypes.benign',
            },
            {
              optionKey: 1,
              optionValue: 'breastCancerRisk.breastBiopsyTypes.hyperplasia',
            },
            {
              optionKey: 2,
              optionValue: 'breastCancerRisk.breastBiopsyTypes.atypicalHyperplasia',
            },
            {
              optionKey: 3,
              optionValue: 'breastCancerRisk.breastBiopsyTypes.lcis',
            },
            {
              optionKey: 5,
              optionValue: 'breastCancerRisk.breastBiopsyTypes.dontKnow',
            },
            {
              optionKey: 4,
              optionValue: 'breastCancerRisk.breastBiopsyTypes.unknown',
            },
          ],
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.definitions',
            definitionKey: '',
          },
        },
      },
      {
        id: -3,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.breastBiopsyBenign',
            definitionKey: 'helpHints.breastBiopsyBenignHint',
          },
        },
      },
      {
        id: -4,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.breastBiopsyHyperplasia',
            definitionKey: 'helpHints.breastBiopsyHyperplasiaHint',
          },
        },
      },
      {
        id: -5,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.breastBiopsyAtypicalHyperplasia',
            definitionKey: 'helpHints.breastBiopsyAtypicalHyperplasiaHint',
          },
        },
      },
      {
        id: -5,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.breastBiopsyLcis',
            definitionKey: 'helpHints.breastBiopsyLcisHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -16,
        type: 'UNCONDITIONAL',
        name: 'Breast Biopsy type flow',
        description: 'Built-in flow',
        data: {
          nextPageId: -5017,
        },
      },
    ],
  };
};
