import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, ComponentSizes } from '@myriadgenetics/mgh-types';
import { LIFETIME_TC_THRESHOLD, NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const meetsCriteriaGeneticCounselorContactNode: ChatbotNode = {
  id: NODE_IDS.MEETS_CRITERIA_GENETIC_COUNSELOR_CONTACT_NODE,
  name: 'Meets Criteria Genetic Counselor Contact Node',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.meetCriteriaResultsNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Genetic Counselor Warning',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.priorToYourAppointment',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
      },
    },
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Genetic Counselor Contact',
      data: {
        contentKey: 'chatbot.meetsCriteriaResults.callWhenConvenient',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 3500,
        avatarSvg: 'chatbot.hcpAvatar',
        components: [
          {
            id: getNextComponentId(),
            type: 'TEXT_CONTENT',
            name: 'GC Contact Number',
            data: {
              contentKey: 'chatbot.meetsCriteriaResults.gcPhoneStyled',
              type: TextContentType.Text1,
              highlightModifier: TextContentHighlightMod.Callout,
              textLinks: [
                {
                  urlKey: 'chatbot.meetsCriteriaResults.gcPhoneLink',
                },
              ],
            },
          },
          {
            id: getNextComponentId(),
            type: 'TEXT_CONTENT',
            name: 'GC Contact Number',
            data: {
              contentKey: 'chatbot.meetsCriteriaResults.callWhenConvenientHours.monFri',
              type: TextContentType.Text1,
              highlightModifier: TextContentHighlightMod.Centered,
            },
          },
          {
            id: getNextComponentId(),
            type: 'TEXT_CONTENT',
            name: 'GC Contact Number',
            data: {
              contentKey: 'chatbot.meetsCriteriaResults.callFollowUp',
              type: TextContentType.Text1,
            },
          },
        ],
      },
    },
    {
      id: getNextComponentId(),
      description: 'Call now or later',
      name: 'Call now or later',
      type: 'OPTION_SELECT',
      data: {
        answerKey: 'callWhen',
        questionKey: '',
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
        required: true,
        isSingleSelect: true,
        optionSize: ComponentSizes.Medium,
        delay: 1200,
        position: 'right',
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'callNow',
            optionDisplayKey: 'chatbot.meetsCriteriaResults.callNow',
            isPrimary: true,
            openLink: 'chatbot.meetsCriteriaResults.gcPhoneLink',
          },
          {
            optionKey: 2,
            optionValue: 'callLater',
            optionDisplayKey: 'chatbot.meetsCriteriaResults.callLater',
          },
        ],
      },
    },
  ],
  flows: [
    {
      id: 2,
      type: 'CONDITIONAL',
      name: 'Go To Meets Criteria TC Over Next Steps',
      data: {
        nextPageId: NODE_IDS.MEETS_CRITERIA_TC_OVER_NEXT_STEPS_NODE,
        conditions: [
          {
            answerKey: 'surveyEntrySave.responses.tcResult.remaining_lifetime_risk',
            comparator: '<=',
            value: LIFETIME_TC_THRESHOLD,
          },
        ],
      },
    },
    {
      id: 4,
      type: 'UNCONDITIONAL',
      name: 'Go To Meets Criteria TC Under Next Steps',
      data: {
        nextPageId: NODE_IDS.MEETS_CRITERIA_TC_UNDER_NEXT_STEPS_NODE,
      },
    },
  ],
};

export default meetsCriteriaGeneticCounselorContactNode;
