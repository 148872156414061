import React from 'react';
import { TextQuestionType } from '@myriadgenetics/mgh-types';
import { isNumber } from 'lodash';
import { getYearsAgo, getSimpleFormat } from '../../helpers/date';

import './index.scss';

type Props = {
  className?: string;
  error: boolean;
  onBlur?: () => void;
  onChange?: (newValue: string) => void;
  onFocus?: () => void;
  placeholder: string;
  required: boolean;
  type: TextQuestionType;
  value: string;
  min?: number;
  max?: number;
};

type HtmlInputType = 'text' | 'number' | 'tel' | 'email' | 'date';

type TextInputTypeMap = {
  [TextQuestionType: string]: {
    htmlType: HtmlInputType;
    inputMapper?: (string: string) => string;
    pasteMapper?: (string: string) => string;
  };
};

const transformDateFromISO = (dateString: string) => {
  if (dateString) {
    const splitDateString = dateString.split('-');
    return splitDateString.length === 3 ? `${splitDateString[1]}/${splitDateString[2]}/${splitDateString[0]}` : dateString;
  }
  return dateString;
};

const MAX_MRN_LENGTH = 13;

function TextInput({ className, error, onBlur, onChange, onFocus, placeholder, required, type, value, min, max }: Props) {
  const telFormat = (val: string): string => {
    const phoneDigits = val.replace(/[^\d]/g, '');
    if (phoneDigits.match(/^\d+$/)) {
      let phone = phoneDigits.substr(0, 3);
      if (phoneDigits.length >= 3) phone += '-' + phoneDigits.substr(3, 3);
      if (phoneDigits.length >= 6) phone += '-' + phoneDigits.substr(6, 4);
      return phone;
    }
    return '';
  };

  const telMapper = (val: string): string => {
    let mappedVal = val;
    if (!val.slice(-1).match(/[0-9]/)) {
      mappedVal = mappedVal.substr(0, mappedVal.length - 1);
    } else if (val.length === 8 && !val.endsWith('-')) {
      mappedVal = val.substr(0, 7) + '-' + val.substr(7);
    } else if (val.length === 7 && value.length === 6) {
      mappedVal = val + '-';
    } else if (val.length === 4 && !val.endsWith('-')) {
      mappedVal = val.substr(0, 3) + '-' + val.substr(3);
    } else if (val.length === 3 && value.length === 2) {
      mappedVal = val + '-';
    }
    if (mappedVal.length > 10) {
      mappedVal = mappedVal.substr(0, 12);
    }
    return mappedVal;
  };

  const dobMapper = (val: string): string => {
    let mappedVal = val.trim();
    const lastChar = mappedVal.slice(-1);
    if (!lastChar.match(/[0-9]|\//) || mappedVal.length > 10) {
      mappedVal = mappedVal.substr(0, mappedVal.length - 1);
    } else if (val.length === 2 && val.endsWith('/')) {
      mappedVal = '0' + mappedVal;
    } else if (mappedVal.length === 5 && mappedVal.endsWith('/')) {
      const splitVal = val.split('/');
      mappedVal = `${splitVal[0]}/0${splitVal[1]}/`;
    } else if (mappedVal.length === 3 && !mappedVal.endsWith('/')) {
      mappedVal = mappedVal.substr(0, 2) + '/' + mappedVal.substr(2);
    } else if (mappedVal.length === 6 && !mappedVal.endsWith('/')) {
      mappedVal = mappedVal.substr(0, 5) + '/' + mappedVal.substr(5);
    } else if (mappedVal.endsWith('//')) {
      mappedVal = mappedVal.substr(0, mappedVal.length - 1);
    }
    if (mappedVal.length === 10) {
      const splitVal = mappedVal.split('/');
      mappedVal = `${splitVal[2]}-${splitVal[0].padStart(2, '0')}-${splitVal[1].padStart(2, '0')}`;
    }
    return mappedVal;
  };

  const removeLastChar = (val: string, regex: RegExp): string => (!regex.test(val.slice(-1)) ? val.substr(0, val.length - 1) : val);

  const nameMapper = (val: string): string => removeLastChar(val, /[\w\s&/.()\-,']/);

  const addressMapper = (val: string): string => removeLastChar(val, /[\w\s&/.()\-,'#;:°]/);

  const numberMapper = (val: string): string => {
    const mappedVal = removeLastChar(val, /[0-9]/);
    return mappedVal;
  };

  // this is the same behaviour with the numberMapper now; but, this could be improved later.
  const ageMapper = (val: string): string => {
    const mappedVal = removeLastChar(val, /[0-9]/);
    return mappedVal;
  };

  const postalMapper = (val: string): string => (!val.slice(-1).match(/[0-9]/) || val.length > 5 ? val.substr(0, val.length - 1) : val);

  // blocks the user to input mrn longer than MAX_MRN_LENGTH(13)
  const mrnMapper = (val: string): string => val.toUpperCase().slice(0, MAX_MRN_LENGTH);

  const typeMap: TextInputTypeMap = {
    age: {
      htmlType: 'number',
      inputMapper: ageMapper,
    },
    dob: {
      htmlType: 'text',
      inputMapper: dobMapper,
    },
    date: {
      htmlType: 'date',
    },
    email: {
      htmlType: 'email',
    },
    tel: {
      htmlType: 'tel',
      inputMapper: telMapper,
      pasteMapper: telFormat,
    },
    text: {
      htmlType: 'text',
    },
    number: {
      htmlType: 'number',
      inputMapper: numberMapper,
    },
    address: {
      htmlType: 'text',
      inputMapper: addressMapper,
    },
    name: {
      htmlType: 'text',
      inputMapper: nameMapper,
    },
    postal: {
      htmlType: 'text',
      inputMapper: postalMapper,
    },
    mrn: {
      htmlType: 'text',
      inputMapper: mrnMapper,
    },
  };

  let inputPlaceholder = placeholder;
  const filledClass = !error && value ? 'text-input--filled' : '';
  const errorClass = error ? ' text-input--error' : '';
  switch (type) {
    case 'date':
    case 'dob':
      inputPlaceholder = placeholder || 'mm/dd/yyyy';
      break;
    case 'email':
      inputPlaceholder = placeholder || 'email@domain.com';
      break;
    case 'tel':
      inputPlaceholder = placeholder || '555-123-4567';
      break;
    default:
  }

  const mapResponse = (txt: string): string => (type === 'dob' ? transformDateFromISO(txt) : txt);

  const mapInput = (val: string): string => (typeMap[type].inputMapper ? (typeMap as any)[type].inputMapper(val) : val); // using key reference forces us to use `as any`

  const mapPaste = (e: React.ClipboardEvent<HTMLInputElement>): string => {
    const pasteData = e.clipboardData || (window as any).clipboardData;
    const textVal = pasteData.getData('text');
    e.preventDefault();
    return typeMap[type].pasteMapper ? (typeMap as any)[type].pasteMapper(textVal) : textVal;
  };

  const keyDownNumericCheck = (e: React.KeyboardEvent): void => {
    if (e.defaultPrevented) {
      return;
    }
    const allowedForNumberInput = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Delete',
      'Tab',
      'Down',
      'ArrowDown',
      'Up',
      'ArrowUp',
      'Right',
      'ArrowRight',
      'Left',
      'ArrowLeft',
      'Esc',
      'Escape',
    ];
    const allowedForDobInput = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Delete',
      'Tab',
      'Esc',
      'Escape',
      'Meta',
      'v',
      '/',
      'Right',
      'ArrowRight',
      'Left',
      'ArrowLeft',
    ];
    const allowedKeyList = type === 'dob' ? allowedForDobInput : allowedForNumberInput;
    const isNumberOrDob = typeMap[type].htmlType === 'number' || type === 'dob';
    const isNotAllowedKey = !allowedKeyList.includes(e.key);
    if (isNumberOrDob && isNotAllowedKey) {
      e.preventDefault();
    }
  };

  const setMin = () => {
    switch (type) {
      case 'date':
        return getYearsAgo();
      case 'number':
        return isNumber(min) ? min : undefined;
      case 'age':
        return isNumber(min) ? min : undefined;
      default:
        return undefined;
    }
  };

  const setMax = () => {
    switch (type) {
      case 'date':
        return getSimpleFormat();
      case 'number':
        return isNumber(max) ? max : undefined;
      case 'age':
        return isNumber(max) ? max : undefined;
      default:
        return undefined;
    }
  };

  return (
    <input
      required={required}
      className={`text-input ${filledClass} ${errorClass} ${className || ''}`}
      type={typeMap[type].htmlType}
      placeholder={inputPlaceholder}
      value={mapResponse(value)}
      onKeyDown={(e) => keyDownNumericCheck(e)}
      onChange={(e) => {
        onChange && onChange(mapInput(e.target.value));
      }}
      min={setMin()}
      max={setMax()}
      autoComplete="nope"
      onBlur={onBlur}
      onFocus={onFocus}
      onPaste={(e) => onChange && onChange(mapPaste(e))}
    />
  );
}

TextInput.defaultProps = {
  required: true,
  type: 'text',
  placeholder: '',
  value: '',
  error: false,
};

export default TextInput;
