import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';
import { CardItemProps } from '@myriadgenetics/mgh-types';
import clinicLogoPlaceHolder from '../../../../../assets/provider-logo-placeholder.png';

interface Props extends CardItemProps {
  hasTopTitles?: boolean;
  isSimpleView: boolean;
  isAlternativeStyle: boolean;
  isCustomProvider?: boolean;
  onContinue: () => void;
  clinicName?: string;
  clinicLogo?: string;
}

const loadIcon = async (icon: string) => {
  const { default: iconSrc } = await import(`../../../../../assets/icons/${icon}`);
  return iconSrc;
};

const CardItem: React.FC<Props> = ({
  imageSrc,
  titleKey,
  isAlternativeStyle,
  hasTopTitles,
  contentKey,
  clinicName,
  clinicLogo,
  isCustomProvider,
  isSimpleView,
  onContinue,
  icon,
}) => {
  const { t } = useTranslation();
  const [iconSrc, setIconSrc] = useState<string>('');

  useEffect(() => {
    if (icon) {
      loadIcon(icon || '')
        .then((img) => {
          setIconSrc(img);
        })
        .catch(() => {
          setIconSrc('');
        });
    }
  });

  return (
    <div
      className={`card
      ${isAlternativeStyle && !isSimpleView ? 'card--alternative' : ''}
      ${isSimpleView ? 'card--simple' : ''}`}
    >
      {hasTopTitles && titleKey && (
        <h3 className="card__title">
          <Trans i18nKey={titleKey} values={{ clinicName }} components={[<b className="card__provider-name" />]} />
        </h3>
      )}
      {(isCustomProvider || imageSrc) && (
        <div
          className={`card__image-wrapper 
          ${isCustomProvider ? 'card__image-wrapper-provider' : ''} 
          ${isCustomProvider && !clinicLogo ? 'placeholder' : ''}`}
        >
          <img
            className="card__image"
            src={isCustomProvider ? clinicLogo || clinicLogoPlaceHolder : imageSrc}
            alt={isCustomProvider ? clinicName : t(titleKey || '')}
          />
        </div>
      )}
      {!hasTopTitles && titleKey && (
        <h3 className="card__title">
          {icon && <img src={iconSrc} alt={t(titleKey)} className="card__icon" />}
          <span className="card__title-text">
            <Trans i18nKey={titleKey} values={{ clinicName }} components={[<span className="card__provider-name" />]} />
          </span>
        </h3>
      )}
      <p className="card__text">
        <Trans
          i18nKey={contentKey}
          values={{ clinicName }}
          components={[<span className="card__provider-name" />, <span className="card__button-link" onClick={onContinue} />]}
        />
      </p>
    </div>
  );
};

export default CardItem;
