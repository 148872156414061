import { qqSurveyGTMMapping } from './constants';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export interface GTMDataProps {
  event: string;
  action: string;
  value: string;
  id: string;
  label: string;
}

export const pushGTMData = ({ answerKey, answer, data }: { answerKey: string; answer?: any; data?: Partial<GTMDataProps> }) => {
  if (!qqSurveyGTMMapping.action[answerKey] || data?.action) return;

  const answerData = Array.isArray(answer) ? answer[0]?.optionValue : answer;
  const dataObject: GTMDataProps = {
    event: data?.event || qqSurveyGTMMapping.event,
    action: data?.action || qqSurveyGTMMapping.action[answerKey],
    id: data?.id || qqSurveyGTMMapping.id[answerKey]?.[answerData?.toString()] || qqSurveyGTMMapping.id[answerKey]?.['default'] || answerData,
    label: data?.label || qqSurveyGTMMapping.label[answerKey]?.[answerData?.toString()] || qqSurveyGTMMapping.label[answerKey]?.['default'] || answerData,
    value: data?.value || qqSurveyGTMMapping.value[answerKey]?.[answerData?.toString()] || qqSurveyGTMMapping.value[answerKey]?.['default'] || answerData,
  };
  window?.dataLayer?.push(dataObject);
};

export default { pushGTMData };
