import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';
import Button from '../../../elements/button';
import { CardsComponent, CardItemProps } from '@myriadgenetics/mgh-types';
import CardItem from './components/card';

interface Props {
  compDef: any | CardsComponent;
  onContinue: () => void;
  clinicName?: string;
  clinicLogo?: string;
}

const Cards: React.FC<Props> = ({ compDef, onContinue, clinicLogo, clinicName }) => {
  const { t } = useTranslation();

  const { titleKey, subtitleKey, buttonKey, bgImage, backgroundColor, isBannerView, cards, hasTopCardTitles, isAlternativeStyle } = compDef.data;

  return (
    <>
      {buttonKey && !isBannerView && (
        <div className="cards__button-section--sticky">
          <Button className="cards__button button--alternative button--sticky" secondary onClick={onContinue}>
            {t(buttonKey)}
          </Button>
        </div>
      )}
      <div
        className={`cards ${backgroundColor ? 'cards--withBg' : ''} 
      ${isBannerView ? 'cards--bannerView' : ''}
      ${isBannerView && !bgImage ? 'cards--bannerView--columns' : ''}
      `}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="cards__wrapper">
          <div className={`cards__intro ${isAlternativeStyle ? 'cards__intro--alternative' : ''}`}>
            <div className={`cards__titles-wrapper ${isAlternativeStyle ? 'cards__titles-wrapper--alternative' : ''}`}>
              <h2 className="cards__title">{t(titleKey)}</h2>
              {subtitleKey && (
                <p className="cards__subtitle">
                  <Trans i18nKey={subtitleKey} values={{ clinicName }} components={[<span className="cards__provider-name" />]} />
                </p>
              )}
            </div>
            {buttonKey && !isBannerView && <div className="cards__button-section">{bgImage && <img src={bgImage.src} alt={bgImage.altKey} />}</div>}
          </div>
          {cards && cards.length > 0 && (
            <div className={`cards__items ${isAlternativeStyle ? 'cards__items--alternative' : ''}`}>
              {cards.map((itemProps: CardItemProps, index: number) => (
                <CardItem
                  key={index}
                  {...itemProps}
                  clinicName={clinicName}
                  clinicLogo={clinicLogo}
                  hasTopTitles={hasTopCardTitles}
                  isSimpleView={isBannerView}
                  isAlternativeStyle={!!isAlternativeStyle}
                  onContinue={onContinue}
                />
              ))}
            </div>
          )}
        </div>
        {isBannerView && bgImage && (
          <div className="cards__banner-image">
            <img src={bgImage.src} alt={t(bgImage.altKey)} />
          </div>
        )}
      </div>
    </>
  );
};

export default Cards;
