import { ColonPolypNode, PageNode } from '@myriadgenetics/mgh-types';
import NodeWorkflow from '../node-workflow';
import { ColonPolyp } from './nodes';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { findNodeById } from '../helpers';

import './index.scss';

interface Props {
  node: any | ColonPolypNode;
  initNode?: any | PageNode;
  responses: any;
  gotoNextNode: (nodeId: number | null | undefined) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
}

function ColonPolypNodeReact({ node, responses, gotoNextNode, onResponseUpdated, initNode }: Props) {
  const { answerKey, continueType, subtitle } = node.data;
  const nodeList = ColonPolyp.steps(answerKey, continueType, subtitle);
  return (
    <NodeWorkflow
      className="colon-polyp"
      nodes={nodeList}
      initNode={initNode || findNodeById(ColonPolyp.initNodeId, nodeList)}
      gotoNextNode={() => gotoNextNode(getNextNodeId(node.flows, responses))}
      onResponseUpdated={onResponseUpdated}
      responses={responses}
      isChatbot={false}
    />
  );
}

export default ColonPolypNodeReact;
