import get from 'lodash.get';
import { Flow, Condition, ConditionalFlow, BinaryFlow, UnconditionalFlow } from '@myriadgenetics/mgh-types';

const getResponseValue = (answerKey: string, surveyResponses: Record<string, unknown>): any => get(surveyResponses, answerKey);

const getUnconditionalNextPageId = ({ data }: UnconditionalFlow): number | null | undefined => data.nextPageId;

const getBinaryNextPageId = ({ data }: BinaryFlow, surveyResponses: Record<string, unknown>): number | null | undefined =>
  getResponseValue(data.answerKey, surveyResponses) ? data.affirmativePageId : data.negativePageId;

const evaluateCondition = ({ comparator, answerKey, value }: Condition, surveyResponses: Record<string, unknown>): boolean => {
  const responseValue = getResponseValue(answerKey, surveyResponses);
  switch (comparator) {
    case '==':
      return value === responseValue;
    case '!=':
      return value !== responseValue;
    case '<=':
      return value <= responseValue;
    case '>=':
      return value >= responseValue;
    case '>':
      return value > responseValue;
    case '<':
      return value < responseValue;
    case 'isEmpty':
      try {
        return responseValue.length === 0;
      } catch (ex) {
        return true;
      }
    case 'isExists':
      try {
        return responseValue !== undefined;
      } catch (ex) {
        return false;
      }

    default:
      return false;
  }
};

const getConditionalNextPageId = ({ data }: ConditionalFlow, surveyResponses: Record<string, unknown>): number | null | undefined => {
  for (let i = 0; i < data.conditions.length; i++) {
    if (!evaluateCondition(data.conditions[i], surveyResponses)) {
      return undefined;
    }
  }
  return data.nextPageId;
};

const getFlowNextPageId = (flow: Flow, surveyResponses: Record<string, unknown>): number | null | undefined => {
  switch (flow.type) {
    case 'BINARY':
      return getBinaryNextPageId(flow, surveyResponses);
    case 'CONDITIONAL':
      return getConditionalNextPageId(flow, surveyResponses);
    case 'UNCONDITIONAL':
      return getUnconditionalNextPageId(flow);
    default:
      return undefined;
  }
};

const getNextPageId = (flowList: Flow[] | undefined, surveyResponses: Record<string, unknown>): number | null | undefined => {
  if (flowList && flowList.length) {
    for (let i = 0; i < flowList.length; i++) {
      const pageId = getFlowNextPageId(flowList[i], surveyResponses);
      if (pageId) {
        return pageId;
      }
    }
  }
  return undefined;
};

export default getNextPageId;
