import React, { useState } from 'react';
import Loading from '../loading';
import closeIcon from '../../assets/close.svg';

import './index.scss';
import { Component, SurveyAccount } from '@myriadgenetics/mgh-types';
import { typeToComponent } from '../../helpers/component-map';

export enum DialogType {
  TermsOfUse = 'TERMS_OF_USE',
  PrivacyPolicy = 'PRIVACY_POLICY',
}

interface DialogMapType {
  [DialogType.TermsOfUse]?: string;
  [DialogType.PrivacyPolicy]?: string;
}

const TERMS_OF_USE_URL = 'https://myriad.com/terms-of-use';
const PRIVACY_POLICY_URL = 'https://myriad.com/privacy-policy';

const dialogMap: DialogMapType = {
  PRIVACY_POLICY: PRIVACY_POLICY_URL,
  TERMS_OF_USE: TERMS_OF_USE_URL,
};

type DialogProps = {
  type?: DialogType;
  url?: string;
  components?: Component[];
  surveyAccount?: SurveyAccount;
  onClose: () => void;
};

function DialogFrame({ type, url, components, surveyAccount, onClose }: DialogProps) {
  const [isLoading, setIsLoading] = useState<boolean>(url || type ? true : false);

  const getSrc = (type?: DialogType, url?: string) => {
    if (type) {
      return dialogMap[type];
    } else if (url) {
      return url;
    }
    return '';
  };

  return (
    <React.Fragment>
      <div className="dialog-frame__backdrop"></div>
      <div className="dialog-frame__container">
        <div className="dialog-frame__close">
          <img
            className="dialog-frame__close-button"
            alt="close modal"
            src={closeIcon}
            onClick={() => {
              setIsLoading(true);
              onClose();
            }}
          />
        </div>
        <div className="dialog-frame__content">
          {isLoading && <Loading />}
          {(url || type) && <iframe className="dialog-frame__frame" src={getSrc(type, url)} title="dialog-iframe" onLoad={() => setIsLoading(false)} />}
          {components && (
            <div className="dialog-frame__frame dialog-frame__components">
              {components.map((component: Component, index: number) => {
                const Tag = typeToComponent(component);
                return (
                  <Tag
                    key={component.id}
                    compDef={component}
                    responses={{}}
                    onResponseUpdated={() => {}}
                    onContinue={() => {}}
                    clinicLogo={null}
                    clinicName={surveyAccount?.clinicName}
                    surveyAccount={surveyAccount}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default DialogFrame;
