import { NodeTypes, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const colonPolypYesNo = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.COLON_POLYP_YES_NO_NODE,
  type: NodeTypes.Page,
  name: 'Colon Polyp Node',
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.colonPolypNode',
  },
  components: [
    {
      id: -1,
      type: 'YES_NO',
      data: {
        answerKey: `${baseKey}.hasHistory`,
        questionKey: 'colonPolyp.history',
        required: true,
      },
    },
    {
      id: -2,
      type: 'HELP_HINT',
      data: {
        helpHint: {
          termKey: 'colonPolyp.helpHintTerm',
          definitionKey: 'colonPolyp.helpHint',
        },
      },
    },
  ],
  flows: [
    {
      id: -1,
      type: 'BINARY',
      data: {
        answerKey: `${baseKey}.hasHistory`,
        affirmativePageId: NODE_IDS.COLON_POLYP_AGE_NODE,
        negativePageId: NODE_IDS.CRITERIA_ENDPOINT_NODE,
      },
    },
  ],
});
