import { useTranslation } from 'react-i18next';
import { OtherDiagnosesComponent } from '@myriadgenetics/mgh-types';
import { Text1 } from '../../../elements/typography';

import './index.scss';

interface Props {
  compDef: any | OtherDiagnosesComponent;
}

function OtherDiagnoses({ compDef }: Props) {
  const { t } = useTranslation();

  return (
    <div className="other-diagnoses">
      <Text1 bold="true">{t('otherDiagnoses.ovarian')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.brca')}</Text1>
      <br />
      <Text1 bold="true">{t('otherDiagnoses.lynch')}</Text1>
      <br />
      <Text1 bold="true">{t('otherDiagnoses.colorectalTumor')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.msi')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.mucinous')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.signet')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.tumor')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.crohns')}</Text1>
      <Text1 className="other-diagnoses__tabbed">{t('otherDiagnoses.medullary')}</Text1>
      <br />
      <Text1 bold="true">{t('otherDiagnoses.previousLynch')}</Text1>
    </div>
  );
}

export default OtherDiagnoses;
