import get from 'lodash.get';
import { validateOptionSelect, validatePersonDemographic, validateYesNo, validateInsurance, validateRequired, validateRadioGroup } from './question-component';
import { validateTextQuestion } from './text-question-component';

import { ButtonComponent, Component, ValidationResult } from '@myriadgenetics/mgh-types';

const validResult = { isValid: true, errorMessage: undefined };

export default class Validation {
  static getKey = (c: Component, i: number): string => `${c.id}-${i}`;

  // TODO: remove the anys
  static createValidationMap = (components: Component[], responses: any): any => {
    const vMap: { [key: string]: ValidationResult } = {};
    components.forEach((c: any, i: number) => (vMap[Validation.getKey(c, i)] = Validation.validate(c, get(responses, (c.data as any).answerKey))));
    return vMap;
  };

  static validate = (component: Component | ButtonComponent, val: any): ValidationResult => {
    const isValid = false;
    const errorMessage = 'unhandled component';
    const required: boolean = (component.data as any)?.required ?? false;
    switch (component.type) {
      // informational
      case 'HELP_HINT':
      case 'HELP_HINTS':
      case 'INTRODUCTORY':
      case 'OTHER_DIAGNOSES':
      case 'TEXT_CONTENT':
      case 'TEXT_LIST':
      case 'GRID':
      case 'VIDEO':
      case 'CRITERIA_DETAILS':
      case 'CONTINUE':
      case 'IMAGE':
      case 'BUTTON':
        return validResult;
      // questions
      case 'OPTION_SELECT':
        return validateOptionSelect(component, val, required);
      case 'PERSON_DEMOGRAPHIC':
        return validatePersonDemographic(component, val);
      case 'PERSON_INSURANCE':
        return validateInsurance(component, val);
      case 'TEXT_QUESTION':
        return validateTextQuestion(component, val, required);
      case 'YES_NO':
        return validateYesNo(component, val, required);
      case 'IMAGE_UPLOAD':
      case 'CHECKBOX':
        return validateRequired(component, val, required);
      case 'RADIO_GROUP':
        return validateRadioGroup(component, val, required);
      default:
        const { type } = component;
        console.error(`${type} is not handled in validation.`); // TODO: figure out why this type is never
        return { isValid, errorMessage };
    }
  };
}
