export const USA_STATES = [
  'alabama',
  'alaska',
  'arizona',
  'arkansas',
  'california',
  'colorado',
  'connecticut',
  'delaware',
  'florida',
  'georgia',
  'hawaii',
  'idaho',
  'illinois',
  'indiana',
  'iowa',
  'kansas',
  'kentucky',
  'louisiana',
  'maine',
  'maryland',
  'massachusetts',
  'michigan',
  'minnesota',
  'mississippi',
  'missouri',
  'montana',
  'nebraska',
  'nevada',
  'newHampshire',
  'newJersey',
  'newMexico',
  'newYork',
  'northCarolina',
  'northDakota',
  'ohio',
  'oklahoma',
  'oregon',
  'pennsylvania',
  'rhodeIsland',
  'southCarolina',
  'southDakota',
  'tennessee',
  'texas',
  'utah',
  'vermont',
  'virginia',
  'washington',
  'washingtonDC',
  'westVirginia',
  'wisconsin',
  'wyoming',
];

export const ANCESTRY_LIST = ['ashkenazi', 'white', 'middleEastern', 'hispanic', 'black', 'pacificIslander', 'asian', 'nativeAmerican', 'other'];
