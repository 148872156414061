import { ChatModalNodeOptions, ComponentSizes, ContinueType, NodeTypes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getAncestryOptions } from '../../../../helpers/components';

const ancestryList = getAncestryOptions();

export const ancestryStep = ({ subtitle }: ChatModalNodeOptions) => ({
  id: NODE_IDS.ANCESTRY_NODE,
  name: 'Chatbot Ancestry Select',
  description: 'Select ancestries',
  type: NodeTypes.Page,
  data: {
    continueType: ContinueType.Fixed,
    subtitle,
    continueKey: 'chatbot.modal.done',
    pageTitleKey: 'pageTitles.ancestryNode',
  },
  components: [
    {
      id: -13,
      type: 'OPTION_SELECT',
      name: 'Ancestry Selection',
      description: 'Ancestry selection',
      data: {
        questionKey: 'chatbot.personalInformation.ancestryLabel',
        answerKey: 'ancestry',
        isSingleSelect: false,
        optionSize: ComponentSizes.XSmall,
        isShowOptionAsCheckbox: true,
        required: true,
        selectionOptions: ancestryList,
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
      },
    },
  ],
  flows: [],
});
