export enum M_DATA_KEYS {
  CRITERIA_CHECK = 'criteriaCheck',
  SURVEY_ENTRY_SAVE = 'surveyEntrySave',
}

export enum KEYS_TO_MEDALLIA {
  MET_CRITERIA = 'metCriteria',
  SURVEY_NAME = 'surveyName',
  SURVEY_ID = 'surveyId',
  SHOULD_DISPLAY_MEDALLIA_SURVEY = 'shouldDisplayMedalliaSurvey',
}

export default {
  M_DATA_KEYS,
  KEYS_TO_MEDALLIA,
};
