import get from 'lodash.get';
import { RelativeName, PathologyHistories, PathologyHistory, Relative } from '@myriadgenetics/mgh-types';
import { unflatten } from '../../../helpers/relative';

const responseBaseKey = 'relatives';
export type MappedRelative = {
  relative: Relative;
  relativeKey: string;
  pathologies: PathologyHistory[];
  num: number | null | undefined;
};

const promoteSelf = (keys: RelativeName[]): RelativeName[] => {
  const selfFound = keys.indexOf('self');
  if (selfFound !== -1) {
    const promotedKeys = [...keys];
    promotedKeys.splice(selfFound, 1);
    promotedKeys.unshift('self');

    return promotedKeys;
  } else {
    return keys;
  }
};

const mapRelative = (key: string, relativePathologies: PathologyHistories[]): MappedRelative[] => {
  const count = relativePathologies.length;

  return relativePathologies.map((r, i) => ({
    relative: unflatten(key) as Relative,
    relativeKey: key,
    pathologies: r.pathology,
    num: count === 1 ? null : i + 1,
  }));
};

/**
 * Will always return with self as the first relative if present.
 *
 * @param {*} historyResponse
 */
export const mapHistory = (historyResponse: Record<string, any>): MappedRelative[] => {
  const allRelativesPathologies: MappedRelative[] = [];
  const relativesObj: Record<RelativeName, PathologyHistories[]> = get(historyResponse, responseBaseKey, {}) as Record<RelativeName, PathologyHistories[]>;
  const relativeKeys: RelativeName[] = promoteSelf(Object.keys(relativesObj) as RelativeName[]);

  relativeKeys.forEach((relKey: RelativeName) => {
    const relativePathologies: PathologyHistories[] = relativesObj[relKey];
    if (Array.isArray(relativePathologies)) {
      Array.prototype.push.apply(allRelativesPathologies, mapRelative(`${responseBaseKey}.${relKey}`, relativePathologies));
    }
  });

  return allRelativesPathologies;
};
