import { ComponentSizes, OptionSelectComponent, Group, SubGroup } from '@myriadgenetics/mgh-types';

export const relativeGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -1,
  name: 'Relative Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'group',
    questionKey: 'relatives.chooseRelative',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.Medium,
    selectionOptions: [
      { optionKey: 7, optionValue: 'relatives.self' },
      { optionKey: 1, optionValue: 'relatives.great' },
      { optionKey: 2, optionValue: 'relatives.grand' },
      { optionKey: 3, optionValue: 'relatives.parent' },
      { optionKey: 4, optionValue: 'relatives.sibling' },
      { optionKey: 5, optionValue: 'relatives.child' },
      { optionKey: 6, optionValue: 'relatives.grandChild' },
      { optionKey: 8, optionValue: 'relatives.other' },
    ],
  },
};

export const greatGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -2,
  name: 'Great Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.chooseRelative',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: 'relatives.greatGrandmother.unselected',
      },
      {
        optionKey: 2,
        optionValue: 'relatives.greatGrandfather.unselected',
      },
    ],
  },
};

export const grandGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -3,
  name: 'Grand Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.chooseRelative',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: 'relatives.grandmother.unselected',
      },
      {
        optionKey: 2,
        optionValue: 'relatives.grandfather.unselected',
      },
      {
        optionKey: 3,
        optionValue: 'relatives.greatAunt.unselected',
      },
      {
        optionKey: 4,
        optionValue: 'relatives.greatUncle.unselected',
      },
    ],
  },
};

export const parentGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -4,
  name: 'Parent Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.chooseRelative',
    subtitleKey: 'relatives.chooseRelativeHint',
    labelKey: '',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: 'relatives.mother',
      },
      {
        optionKey: 2,
        optionValue: 'relatives.father',
      },
      {
        optionKey: 3,
        optionValue: 'relatives.aunt.unselected',
      },
      {
        optionKey: 4,
        optionValue: 'relatives.uncle.unselected',
      },
    ],
  },
};

export const siblingGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -5,
  name: 'Sibling Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.chooseRelative',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 2,
        optionValue: 'relatives.sister',
      },
      {
        optionKey: 1,
        optionValue: 'relatives.brother',
      },
      {
        optionKey: 3,
        optionValue: 'relatives.halfSister.unselected',
      },
      {
        optionKey: 4,
        optionValue: 'relatives.halfBrother.unselected',
      },
      {
        optionKey: 5,
        optionValue: 'relatives.femaleCousin.unselected',
      },
      {
        optionKey: 6,
        optionValue: 'relatives.maleCousin.unselected',
      },
    ],
  },
};

export const childGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -6,
  name: 'Child Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.chooseRelative',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 2,
        optionValue: 'relatives.daughter',
      },
      {
        optionKey: 1,
        optionValue: 'relatives.son',
      },
      {
        optionKey: 3,
        optionValue: 'relatives.niece',
      },
      {
        optionKey: 4,
        optionValue: 'relatives.nephew',
      },
    ],
  },
};

export const grandchildGroupSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -7,
  name: 'Grandchild Group Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.chooseRelative',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 2,
        optionValue: 'relatives.granddaughter',
      },
      {
        optionKey: 1,
        optionValue: 'relatives.grandson',
      },
    ],
  },
};

export const sideOfFamilySelect = (relative: SubGroup): OptionSelectComponent => ({
  type: 'OPTION_SELECT',
  id: -8,
  name: 'Side of Family Select',
  description: 'Built in question.',
  data: {
    answerKey: 'subGroup',
    questionKey: 'relatives.sideOfFamilyQuestion',
    questionKeyParams: { relative },
    labelKey: '',
    subtitleKey: '',
    placeholderKey: '',
    required: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.XSmall,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: 'relatives.sideOfFamily.maternal',
      },
      {
        optionKey: 2,
        optionValue: 'relatives.sideOfFamily.paternal',
      },
    ],
  },
});

export const relativeGroupMap: Readonly<Record<Exclude<Group, 'relatives.self' | 'relatives.other'>, OptionSelectComponent>> = {
  'relatives.great': greatGroupSelect,
  'relatives.grand': grandGroupSelect,
  'relatives.parent': parentGroupSelect,
  'relatives.sibling': siblingGroupSelect,
  'relatives.child': childGroupSelect,
  'relatives.grandChild': grandchildGroupSelect,
};

export const otherSelect: OptionSelectComponent = {
  type: 'OPTION_SELECT',
  id: -9,
  name: 'Other Relative',
  description: 'Built in question.',
  data: {
    questionKey: 'relatives.chooseRelative',
    answerKey: 'subGroup',
    labelKey: '',
    subtitleKey: 'relatives.chooseRelativeHint',
    placeholderKey: '',
    required: true,
    isNative: true,
    isSingleSelect: true,
    optionSize: ComponentSizes.Large,
    selectionOptions: [
      {
        optionKey: 1,
        optionValue: 'relatives.halfSister.maternal',
      },
      {
        optionKey: 2,
        optionValue: 'relatives.halfSister.paternal',
      },
      {
        optionKey: 3,
        optionValue: 'relatives.halfBrother.maternal',
      },
      {
        optionKey: 4,
        optionValue: 'relatives.halfBrother.paternal',
      },
    ],
  },
};

export default {
  relativeGroupSelect,
  greatGroupSelect,
  grandGroupSelect,
  parentGroupSelect,
  siblingGroupSelect,
  childGroupSelect,
  grandchildGroupSelect,
  sideOfFamilySelect,
  relativeGroupMap,
  otherSelect,
};
