import { useTranslation } from 'react-i18next';
import { IntroductoryComponent } from '@myriadgenetics/mgh-types';
import { H1, Text1 } from '../../../elements/typography';
import LanguageSelect from '../../../components/language-select';

import './index.scss';

interface Props {
  compDef: any | IntroductoryComponent;
}

// TODO rethink this component, possibly remove it
function Introductory(props: Props) {
  const { t } = useTranslation();
  const { compDef } = props;
  const { titleKey, descriptionKey, showLanguageSelect } = (compDef as IntroductoryComponent).data;

  return (
    <div className="introductory">
      <H1 className="introductory__title">{t(titleKey)}</H1>
      <Text1 className="introductory__description">{t(descriptionKey)}</Text1>
      {showLanguageSelect && <LanguageSelect />}
    </div>
  );
}

export default Introductory;
