import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Component, SurveyAccount, TextContentComponent, TextContentInteractionScheme, TextLink } from '@myriadgenetics/mgh-types';
import { ImageData } from '@myriadgenetics/mgh-types/dist/types/components/data';
import { Text1, Text2, Text3, H1, H2, H3, H4 } from '../../../elements/typography';
import Link from '../../../elements/link';
import DialogFrame from '../../dialog-frame';

import './index.scss';

export enum TextContentType {
  Text1 = 'Text1',
  Text2 = 'Text2',
  Text3 = 'Text3',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
}

interface Props {
  compDef: any | TextContentComponent;
  responses?: Record<string, any>;
  waitBeforeShow?: number;
  surveyAccount?: SurveyAccount;
}

const getTagForType = (type: TextContentType): any | JSX.Element => {
  switch (type) {
    case TextContentType.Text1:
      return Text1;
    case TextContentType.Text2:
      return Text2;
    case TextContentType.Text3:
      return Text3;
    case TextContentType.H1:
      return H1;
    case TextContentType.H2:
      return H2;
    case TextContentType.H3:
      return H3;
    case TextContentType.H4:
      return H4;
    default:
      return Text1;
  }
};

function InlineImage({ src, placeholderSrc, altKey = '', urlKey, targetIsBlank }: TextLink & ImageData) {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(placeholderSrc ? false : true);

  const imageWithPlaceholder = (
    <>
      <img className="inline-image" src={t(src)} alt={t(altKey)} onLoad={() => setIsLoaded(true)} style={{ display: isLoaded ? 'initial' : 'none' }} />
      {!isLoaded && placeholderSrc && <img className="inline-image placeholder" src={t(placeholderSrc)} alt={t(altKey)} />}
    </>
  );

  if (!urlKey) return imageWithPlaceholder;

  return (
    <a className="text-link" href={t(urlKey)} target={targetIsBlank ? '_blank' : '_self'} rel="noreferrer">
      {imageWithPlaceholder}
    </a>
  );
}

function TextContent({ compDef, responses, waitBeforeShow, surveyAccount }: Props) {
  const { t, i18n } = useTranslation();
  const {
    contentKey,
    type,
    highlightModifier,
    interactionScheme,
    interactionContent,
    textLinks = [],
    components = [],
    showComponentsInDialog = false,
    inlineImages = [],
  } = compDef.data;
  const [dialogUrl, setDialogUrl] = useState<string | null>(null);
  const [childComponents, setChildComponents] = useState<Component[] | null>(null);

  const Tag = getTagForType(type);
  const isInteractive = (): boolean => interactionContent;
  const hrefScheme = (): string => {
    switch (interactionScheme) {
      case TextContentInteractionScheme.Telephone:
        return 'tel:';
      case TextContentInteractionScheme.Email:
        return 'mailto:';
      default:
        return '';
    }
  };

  const getLinksComponents = (highlightModifier?: string): JSX.Element[] => {
    const className = highlightModifier ? `text-link text-link--${highlightModifier}` : 'text-link';
    if (textLinks.length)
      return textLinks.map(({ urlKey, targetIsBlank, openInModal }: TextLink, index: number) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className={className}
            key={`${urlKey}-${index}`}
            href={t(urlKey)}
            target={targetIsBlank ? '_blank' : '_self'}
            rel="noreferrer"
            onClick={
              openInModal
                ? (e) => {
                    e.preventDefault();
                    setDialogUrl(t(urlKey));
                  }
                : () => {}
            }
          />
        );
      });
    if (showComponentsInDialog)
      return [
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          className={className}
          target={'_blank'}
          href="/"
          rel="noreferrer"
          onClick={(e) => {
            e.preventDefault();
            setChildComponents(components);
          }}
        />,
      ];
    return [];
  };

  const getImageComponents = (): JSX.Element[] =>
    inlineImages.map(({ src, placeholderSrc, altKey = '', urlKey, targetIsBlank }: ImageData & TextLink, index: number) => (
      <InlineImage key={`${src}-${index}`} src={src} placeholderSrc={placeholderSrc} altKey={altKey} urlKey={urlKey} targetIsBlank={targetIsBlank} />
    ));

  const interactiveComponent = (): JSX.Element => {
    return (
      <Link className="text-content text-content--link" visited={false} error={false}>
        <Tag>
          <a href={`${hrefScheme()}${interactionContent}`}>{t(contentKey)}</a>
        </Tag>
      </Link>
    );
  };
  const hasTranslation = (): boolean => i18n.exists(contentKey);
  const translationComponent = (): JSX.Element => {
    const spanClassName = `text-content__highlight ${highlightModifier ? `text-content__highlight--${highlightModifier}` : ''}`;
    return hasTranslation() ? (
      <>
        {dialogUrl && <DialogFrame url={dialogUrl} onClose={() => setDialogUrl(null)} />}
        {childComponents && <DialogFrame components={childComponents} surveyAccount={surveyAccount} onClose={() => setChildComponents(null)} />}
        <Trans
          key={contentKey}
          i18nKey={contentKey}
          values={{ ...responses, clinicName: surveyAccount?.clinicName }}
          components={[
            <span className={spanClassName} key={`span-trans-element-${contentKey}`} />,
            ...getLinksComponents(highlightModifier),
            <b />,
            ...getImageComponents(),
          ]}
        />
      </>
    ) : (
      t(contentKey)
    );
  };

  const staticComponent = (): JSX.Element => (
    <Tag key={contentKey} className="text-content">
      {translationComponent()}
    </Tag>
  );

  return isInteractive() ? interactiveComponent() : staticComponent();
}

export default TextContent;
