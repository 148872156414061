import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../elements/modal';
import { H4 } from '../../../elements/typography';
import { VideoComponent, VideoDisplayContext, SurveyEvents, VideoPlayerType } from '@myriadgenetics/mgh-types';
import { WISTIA_SCRIPT_URL } from './constants';
import { Helmet } from 'react-helmet';

import './index.scss';

interface Props {
  compDef: any | VideoComponent;
  onContinue?: () => void;
}

enum WistiaPlayerEventType {
  PAUSE = 'pause',
  END = 'end',
}

interface WistiaPlayerEvents {
  event: WistiaPlayerEventType;
  callback: () => void;
}

function Video({ compDef, onContinue }: Props) {
  const { t, i18n } = useTranslation();
  const {
    src,
    displayContext,
    displayContextContent,
    titleKey,
    usePlayer,
    autoPlay = false,
    startMuted = false,
    isContinueControlledByPlayerEvent = false,
  } = compDef.data;
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const isWistiaPlayer = usePlayer === VideoPlayerType.Wistia;
  window._wq = window._wq || [];

  const pauseVideoOnContinue = () => {
    switch (usePlayer) {
      case VideoPlayerType.Wistia:
        const video = window.Wistia?.api(t(src));
        video?.pause();
        break;
      default:
        break;
    }
  };

  const classNameList = (elementName: string): string => `${elementName} ${displayContext ? `${elementName}--${displayContext}` : ''}`;
  const videoTitle = () => titleKey && <H4>{t(titleKey)}</H4>;
  const videoFrame = () => (
    <iframe className={classNameList('video__iframe')} allowFullScreen frameBorder="0" src={t(src)} title="video" allowTransparency={true} scrolling="no" />
  );
  const videoPlayer = () => {
    switch (usePlayer) {
      case VideoPlayerType.Wistia:
        // ! [BUG] Wistia "playSuspendedOffScreen" option is not working!
        // ! It means that when changing the video pauses and fires the binded event.
        return (
          <>
            <div className={`wistia_embed wistia_async_${t(src)} video__player wmode=transparent resumable=false playButton=${!autoPlay}`} />
            <Helmet data-testid="helmet" script={[{ type: 'text/javascript', src: WISTIA_SCRIPT_URL }]} />
          </>
        );
      default:
        return videoFrame();
    }
  };
  const videoElement = () => (
    <div className={classNameList('video')}>
      {videoTitle()}
      {usePlayer ? videoPlayer() : videoFrame()}
    </div>
  );
  const videoContext = () => {
    switch (displayContext) {
      case VideoDisplayContext.Modal:
        return (
          <Modal triggerDisplay="button" triggerText={t(displayContextContent)}>
            {videoElement()}
          </Modal>
        );
      default:
        return videoElement();
    }
  };

  const callOnContinueFnOnPauseOrEndEvent = () => {
    const isLanguageUpdated = i18n.language === currentLanguage;
    if (isLanguageUpdated && onContinue && isContinueControlledByPlayerEvent) onContinue();

    return unbindEvents(wistiaPlayerEvents);
  };

  const wistiaPlayerEvents: WistiaPlayerEvents[] = [
    {
      event: WistiaPlayerEventType.PAUSE,
      callback: callOnContinueFnOnPauseOrEndEvent,
    },
    {
      event: WistiaPlayerEventType.END,
      callback: callOnContinueFnOnPauseOrEndEvent,
    },
  ];

  const bindEvents = (events: WistiaPlayerEvents[]) => {
    window._wq.push({
      id: t(src),
      onReady: (video: any) => {
        // ! [BUG] Wistia's "autoPlay" and "resumable" options are conflicting.
        // ! To maintain both options active, I fire the play event manually and keep the resume option.
        // ! A small video UI flickering occur between the initial pause and play state switch.
        if (startMuted) video.volume(0);
        if (autoPlay) video.play();
        events.forEach((event) => {
          video.bind(event.event, event.callback);
        });
      },
    });
  };

  const unbindEvents = (events: WistiaPlayerEvents[]) => {
    window._wq.push({
      id: t(src),
      onReady: (video: any) => {
        events.forEach((event) => {
          video.unbind(event.event, event.callback);
        });
      },
    });
  };

  useEffect(() => {
    if (!isWistiaPlayer) return;

    if (currentLanguage === i18n.language) bindEvents(wistiaPlayerEvents);
    else setCurrentLanguage(i18n.language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWistiaPlayer, currentLanguage, i18n.language]);

  useEffect(() => {
    if (usePlayer) {
      document.addEventListener(SurveyEvents.onContinue, pauseVideoOnContinue);
      return () => {
        document.removeEventListener(SurveyEvents.onContinue, pauseVideoOnContinue);
        unbindEvents(wistiaPlayerEvents);
      };
    }
  });

  return <React.Fragment key={currentLanguage}>{videoContext()}</React.Fragment>;
}

export default Video;
