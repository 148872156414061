import { SurveyAccountConfigurations } from '@myriadgenetics/mgh-types/src/types/surveyAccount';
import { SurveyAccount } from '@myriadgenetics/mgh-types';
import { addCustomStylesToPage } from '../styles';

export const ACCOUNT_THEME_STYLES_ELEMENT_ID = 'surveyAccountThemeStyles';
export const ACCOUNT_THEME_CONFIG_KEY = 'assessmentTheme';
export const SURVEY_ACCOUNT_ID_SEARCH_PARAM = 'surveyAccountId';
export const SURVEY_ENTRY_ACCOUNTS_ANSWER_KEY = 'surveyEntryAccounts';
export const surveyAccountTypes = {
  receiving: 'RECEIVING',
};

export const getStylesFromAccountConfiguration = (configurations?: SurveyAccountConfigurations[]): string | null => {
  if (!configurations) {
    return null;
  }
  const themeConfiguration = configurations.filter((config) => config.configurationKey === ACCOUNT_THEME_CONFIG_KEY);
  return themeConfiguration[0]?.configurationValue ?? null;
};

export const setSurveyAccountCustomStyles = (surveyAccount?: SurveyAccount) => {
  const customStyles = getStylesFromAccountConfiguration(surveyAccount?.configurations);
  customStyles && addCustomStylesToPage(customStyles, ACCOUNT_THEME_STYLES_ELEMENT_ID);
};

export const CLINIC_LOGO_CONFIG_NAME = 'clinicLogo';

export const getClinicLogo = (surveyAccount: SurveyAccount | undefined): string | undefined =>
  surveyAccount?.configurations?.find((config) => config.configurationKey === CLINIC_LOGO_CONFIG_NAME)?.configurationValue;
