import { useState, useEffect } from 'react';

const SEC = 1000;

type Props = {
  lastInteractionTime: number;
  timeoutSeconds: number;
  timedOut: (arg0: number) => void;
};

function SessionTimer({ lastInteractionTime, timeoutSeconds, timedOut }: Props) {
  const [secondsLeft, setSecondsLeft] = useState<number>(timeoutSeconds);
  useEffect(() => {
    const timer = setTimeout(() => {
      const now = new Date().getTime();
      const secondsSinceLastInteraction = (now - lastInteractionTime) / 1000;
      setSecondsLeft(timeoutSeconds - secondsSinceLastInteraction);
    }, SEC);
    if (secondsLeft <= 0) {
      timedOut(secondsLeft);
    }
    return () => clearTimeout(timer);
  });

  // we do not want to render anything
  return null;
}

export default SessionTimer;
