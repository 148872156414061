import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const patientLocationNode: ChatbotNode = {
  id: NODE_IDS.PATIENT_LOCATION_NODE,
  name: 'Chatbot Patient Location Selection',
  description: 'Chatbot Patient Location Selection',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.patientLocationNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'At home or in office question text',
      description: 'Example of chat',
      data: {
        contentKey: 'chatbot.patientLocation.question',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
        position: 'left',
      },
    },
    {
      id: getNextComponentId(),
      description: 'At Home Or In Office',
      name: 'At Home or In Office Option Select',
      type: 'OPTION_SELECT',
      data: {
        answerKey: 'inOfficeOptionSelect',
        questionKey: '',
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
        required: true,
        isSingleSelect: true,
        optionSize: ComponentSizes.Medium,
        delay: 2000,
        position: 'right',
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'chatbot.patientLocation.healthcareFacility',
          },
          {
            optionKey: 2,
            optionValue: 'chatbot.patientLocation.home',
          },
        ],
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Previously Tested',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.PREVIOUSLY_TESTED_NODE,
      },
    },
  ],
};

export default patientLocationNode;
