import { useTranslation } from 'react-i18next';
import Button from '../../../../elements/button';
import { Text1 } from '../../../../elements/typography';

interface Props {
  onAction: () => void;
}

function NotSure({ onAction }: Props) {
  const { t } = useTranslation();
  return (
    <Button className="option-select__not-sure-button" onClick={onAction} secondary={true}>
      <Text1>{t('notSure')}</Text1>
    </Button>
  );
}

export default NotSure;
