import { ContinueType, NodeTypes, ChatModalNodeType, ChatModalNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const mrnNode: ChatModalNode = {
  id: NODE_IDS.MRN_NODE,
  type: NodeTypes.ChatModalNode,
  name: 'Chatbot MRN Node',
  description: 'Chatbot MRN Node',
  data: {
    answerKey: 'chatbot',
    continueType: ContinueType.AutoFixed,
    subtitle: 'chatbot.cancerHistory.subtitle',
    flowType: ChatModalNodeType.MrnInfo,
    pageTitleKey: 'pageTitles.chatbot.personalAndFamilyCancerNode',
  },
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Some  example node',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.START_NODE,
      },
    },
  ],
};

export default mrnNode;
