import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Relative, CancerHistoryNode, ReviewPathology, ContinueType, Pathology } from '@myriadgenetics/mgh-types';
import { CancerHistoryLocationState } from '../../survey-node/cancer-history-node';
import { useHistory, useLocation } from '../../../helpers/routing';
import CancerHistoryCards from '../cancer-history-cards';
import Continue from '../../../elements/continue';
import ConfirmationModal from '../../../elements/confirmation-modal';
import Link from '../../../elements/link';
import { Text1, H2, H4 } from '../../../elements/typography';
import { mapHistory } from './helper';

import './index.scss';
import { getNextPathologyEntry } from '../../survey-node/cancer-history-node/helper';

interface Props {
  baseKey: string;
  baseUrl?: string;
  responses: any;
  onResponseUpdated: (answerKey: string, val: any) => void;
  cancerHistoryNode?: CancerHistoryNode | null;
  onContinue: () => void;
  containerClass?: string;
}

interface DeleteConfirmation {
  translatedMessage: string;
  onConfirmation: () => void;
}

const REQUIRED_PATHOLOGY_KEYS = ['age', 'type'];

function CancerHistoryReview({ baseKey, baseUrl, responses, onResponseUpdated, cancerHistoryNode, containerClass, onContinue }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location: CancerHistoryLocationState = useLocation() as CancerHistoryLocationState;

  const baseObj = get(responses, baseKey);
  const allRelativesPathologies = mapHistory(baseObj);
  const hasSelf = allRelativesPathologies.some((rp) => rp.relative === 'relatives.self' && rp.pathologies.length > 0);
  const hasFamily = allRelativesPathologies.some((rp) => rp.relative !== 'relatives.self' && rp.pathologies.length > 0);

  const arrivedFromBackAction = history.action === 'POP';

  const [confirmDelete, setConfirmDelete] = useState<DeleteConfirmation | null | undefined>();

  const clearPathologyStateFromCancer = (pathologyEntry: string, pathology: ReviewPathology) => {
    const minimumPathologyObj = {};
    REQUIRED_PATHOLOGY_KEYS.forEach((pathologyKey) => {
      if ((pathology as any)[pathologyKey] !== undefined) {
        (minimumPathologyObj as any)[pathologyKey] = (pathology as any)[pathologyKey];
      }
    });
    // set the location state for a backup...
    location.state.chnState.backupPathology = cloneDeep(pathology);
    location.state.chnState.backupPathologyEntry = pathologyEntry;
    onResponseUpdated(pathologyEntry, minimumPathologyObj);
  };

  const clearBackupPathology = () => {
    location.state.chnState.backupPathology = null;
    location.state.chnState.backupPathologyEntry = null;
  };

  const restoreCancerFromState = (pathologyEntry: string, backupPathology: Pathology) => {
    onResponseUpdated(pathologyEntry, backupPathology);
    clearBackupPathology();
  };

  // on mount we want to check and load previous cancer state if we came from back button
  useEffect(() => {
    if (arrivedFromBackAction && location.state?.chnState?.backupPathology && location.state?.chnState?.backupPathologyEntry) {
      restoreCancerFromState(location.state.chnState.backupPathologyEntry, location.state.chnState.backupPathology);
    }
  });

  return (
    <div className="cancer-history-review">
      {confirmDelete && (
        <ConfirmationModal
          translatedMessage={confirmDelete.translatedMessage}
          confirmKey="Delete"
          containerClass={containerClass}
          onCancel={() => setConfirmDelete(undefined)}
          onConfirm={() => {
            confirmDelete.onConfirmation();
            setConfirmDelete(undefined);
          }}
        />
      )}
      <div className="cancer-history-review__content">
        <H2>{t('review.title')}</H2>
        <Text1 className="cancer-history-review__subtitle">{t('review.subtitle')}</Text1>
        <H4 className="cancer-history-review__history-title">{t('review.historyTitle')}</H4>
        <hr className="cancer-history-review__hr" />
        <React.Fragment>
          {!hasSelf && (
            <React.Fragment>
              <Text1 bold="true">{t('self.history')}</Text1>
              <div className="cancer-history-review__add cancer-history-review__add--self">
                <Text1>{t('self.noHistory')}</Text1>
                <Link
                  onClick={() => {
                    const relativeEntry = 'cancerHistory.relatives.self.0';
                    history.push(`${baseUrl}/add`, {
                      ...location.state,
                      chnState: {
                        baseUrl,
                        curStep: 'choose-pathology',
                        relativeEntry,
                        pathologyEntry: getNextPathologyEntry(responses, relativeEntry),
                        pathology: null,
                        fromReview: true,
                      },
                    });
                  }}
                >
                  {t('pathology.add')}
                </Link>
              </div>
            </React.Fragment>
          )}

          {allRelativesPathologies.map((rp, i) => {
            const relEntryNum = (rp.num || 1) - 1;
            const relativeEntry = `${baseKey}.${rp.relativeKey}.${relEntryNum}`;

            return rp.pathologies.length === 0 ? (
              <React.Fragment key={i}></React.Fragment>
            ) : (
              <div className="cancer-history-review__list" key={i}>
                <CancerHistoryCards
                  relative={rp.relative as any | Relative}
                  num={rp.num || undefined}
                  pathologies={rp.pathologies as ReviewPathology[]}
                  onAddClicked={() => {
                    history.push(`${baseUrl}/add`, {
                      ...location.state,
                      chnState: {
                        baseUrl,
                        curStep: 'choose-pathology',
                        relativeEntry,
                        pathologyEntry: getNextPathologyEntry(responses, relativeEntry),
                        pathology: null,
                        fromReview: true,
                      },
                    });
                  }}
                  onEditClicked={(pathology, pI, isDel) => {
                    const pathologyBase = `${relativeEntry}.pathology`;
                    const pathologyEntry = `${pathologyBase}.${pI}`;
                    if (isDel) {
                      const pathologies = get(responses, pathologyBase, []);
                      setConfirmDelete({
                        translatedMessage: t('pathology.confirmDelete', pathologies[pI]),
                        onConfirmation: () => {
                          // add jump step to do undelete on back
                          history.push(`${baseUrl}/del`, {
                            ...location.state,
                          });

                          const updatedPathologies = pathologies.filter((p: ReviewPathology, pfI: number) => pI !== pfI);
                          onResponseUpdated(pathologyBase, updatedPathologies);
                        },
                      });
                    } else {
                      //  TODO: implement better state management
                      // This is a hacky way to remove all the previously entered cancer data
                      // navigation was wonky in the app from the review page so this was necessary
                      clearPathologyStateFromCancer(pathologyEntry, pathology);
                      history.push(`${baseUrl}/edit`, {
                        ...location.state,
                        chnState: {
                          baseUrl,
                          relativeEntry,
                          pathologyEntry,
                          pathology: pathology.type,
                          curStep: 'pathology',
                          fromReview: true,
                        },
                      });
                    }
                  }}
                  onDeleteClicked={() => {
                    setConfirmDelete({
                      translatedMessage: t('relatives.confirmDelete', rp),
                      onConfirmation: () => {
                        // add jump step to do undelete on back
                        history.push(`${baseUrl}/del`, {
                          ...location.state,
                        });

                        const pathologyBase = `${relativeEntry}.pathology`;
                        onResponseUpdated(pathologyBase, []);
                      },
                    });
                  }}
                />
              </div>
            );
          })}

          {!hasFamily && <Text1 bold="true">{t('relatives.history')}</Text1>}
          <div className="cancer-history-review__add cancer-history-review__add--family">
            {!hasFamily && <Text1>{t('relatives.noHistory')}</Text1>}
            <Link
              onClick={() => {
                history.push(`${baseUrl}/add`, {
                  ...location.state,
                  chnState: {
                    baseUrl,
                    curStep: 'choose-relative',
                    fromReview: true,
                  },
                });
              }}
            >
              {t('relatives.add')}
            </Link>
          </div>
        </React.Fragment>
      </div>
      <div className="cancer-history-review__continue">
        <Continue buttonKey={'looksGood'} type={ContinueType.End} onContinue={onContinue} />
      </div>
    </div>
  );
}

export default CancerHistoryReview;
