import { NODE_IDS as CHATBOT_NODE_IDS } from '../chatbot-container/constants';
export const modalContainerClassName = 'survey-container__node';
export const closeButtonText = 'chatbot.modal.close';

export const onResponseUpdatedModalReactionMapping: { [key: string]: string } = {
  // TODO: fill with keys ( [answerKey]: answer)  on which modal should be opened when flow with this modal options will be developed
};

export const NODE_IDS = {
  ...CHATBOT_NODE_IDS,
  MRN_INFO_NODE: -7001,
  PERSONAL_INFO_INIT_NODE: -4001,
  PERSONAL_INFO_END_NODE: -4004,
  PROVIDER_SELECTION_NODE: -3000,
  INFO_NODE: -3001,
  ADDRESS_NODE: -3002,
  SEX_NODE: -3003,
  ANCESTRY_NODE: -3004,
  CANCER_HISTORY_NODE: 3782,
  TC_NODE: 1078,
  COLON_POLYP_YES_NO_NODE: -6000,
  COLON_POLYP_AGE_NODE: -6001,
  COLON_POLYP_COUNT_NODE: -6002,
  BLOOD_TRANSFUSION_YES_NO_NODE: -6003,
  BLOOD_TRANSFUSION_RECENT_NODE: -6004,
  BLOOD_TRANSFUSION_TYPE_NODE: -6005,
  BONE_MARROW_TRANSPLANT_YES_NO_NODE: -6006,
  TC_INIT_NODE: -5001,
  HIDE_RESULTS_NODE: 1079,
};

export default {
  onResponseUpdatedModalReactionMapping,
  NODE_IDS,
};
