import { useTranslation } from 'react-i18next';
import { ComponentSizes } from '@myriadgenetics/mgh-types';
import { H3, Text1, Text2 } from '../typography';

import './index.scss';

interface Props {
  size: ComponentSizes;
  htmlFor: string;
  className?: string;
  questionKey: string;
  questionKeyParams?: Record<string, unknown>;
}

function QuestionText({ size, htmlFor, className, questionKey, questionKeyParams }: Props) {
  const { t } = useTranslation();
  const Tag = typographyForSize(size);

  return (
    <Tag tag="label" htmlFor={htmlFor} className={`question-text ${className || ''} question-text--${size}`}>
      {t(questionKey, questionKeyParams)}
    </Tag>
  );
}

QuestionText.defaultProps = { size: ComponentSizes.Large };

export const typographyForSize = (size: ComponentSizes) => {
  switch (size) {
    case ComponentSizes.Large:
      return H3;
    case ComponentSizes.Medium:
      return Text1;
    case ComponentSizes.Small:
      return Text2;
    default:
      return H3;
  }
};

export default QuestionText;
