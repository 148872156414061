export const addCustomStylesToPage = (stylesContent: string, id: string) => {
  let styleElement = document.getElementById(id);

  if (styleElement) {
    styleElement.textContent = stylesContent;
    return;
  }
  styleElement = document.createElement('style');
  styleElement.textContent = stylesContent;
  styleElement.id = id;
  document.head.appendChild(styleElement);
};

export const MEDIA_SIZE = {
  xl: '1440px',
  lg: '1200px',
  md: '845px',
  sm: '300px',
};
