import React from 'react';
import { ValidationResult } from '@myriadgenetics/mgh-types';
import './index.scss';
import Checkbox from '../../../elements/checkbox';

interface CheckboxComponentProps {
  compDef: any;
  className?: string;
  onResponseUpdated: (answerKey: string, val: boolean) => void;
  validationResult?: ValidationResult;
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({ compDef, onResponseUpdated, className }) => {
  const componentData = compDef as any;
  const { answerKey, labelKey } = componentData?.data;

  return (
    <div className={`checkbox-wrapper ${className ?? ''}`}>
      <Checkbox labelKey={labelKey} onResponseUpdated={(val) => onResponseUpdated(answerKey, val)} />
    </div>
  );
};

export default CheckboxComponent;
