import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RedirectNode } from '@myriadgenetics/mgh-types';
import { H2, Text2 } from '../../../elements/typography';
import Logger from '../../../helpers/logger';
import { getRedirectUrl } from '../../../helpers/url';
import Config from '../../../helpers/config';
import './index.scss';
import { GTMDataProps } from '../../../helpers/googleTagManager';

interface Props {
  node: any | RedirectNode;
  gtmDataTracking?: ({ answerKey }: { answerKey: string; data: Partial<GTMDataProps> }) => void;
  responses: any;
}

function RedirectNodeReact({ node, responses, gtmDataTracking }: Props) {
  const { t } = useTranslation();
  const { url, titleKey, messageKey, queryParamKeys } = node.data;
  const urlWithParams = getRedirectUrl(t(url), queryParamKeys, responses);

  useEffect(() => {
    gtmDataTracking &&
      gtmDataTracking({
        answerKey: node.type.toLowerCase(),
        data: { id: node.name, value: node.data.url },
      });
    // clear onbeforeunload so redirect will not result in a prompt
    const redirectTimeout = setTimeout(
      () => {
        // clear onbeforeunload so redirect will not result in a prompt
        window.onbeforeunload = null;
        Logger.logEvent('Redirecting', { to: urlWithParams });
        window.location.href = urlWithParams;
      },
      Config.isDev() ? 1000 : 0,
    );
    return () => clearTimeout(redirectTimeout);
  });

  return (
    <div className="redirect-node">
      <H2 className="redirect-node__title">{t(titleKey)}</H2>
      <Text2 className="redirect-node__message">
        {messageKey && t(messageKey)}
        {!messageKey && `${t('redirecting.to')} ${t(url)}.`}
      </Text2>
      <div className="redirect-node__manual">
        <Text2>{t('redirecting.manual')}</Text2>
        <a className="redirect-node__link" href={urlWithParams}>
          {t(url)}
        </a>
      </div>
    </div>
  );
}

export default RedirectNodeReact;
