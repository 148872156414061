import './index.scss';

type TextTag = 'div' | 'span' | 'p' | 'label' | 'legend' | 'dfn' | 'dd';

interface BaseProps {
  htmlFor?: string;
  className?: string;
  children: string;
  tag: TextTag;
  bold?: boolean | string;
  size: 1 | 2 | 3;
}

function Base(props: BaseProps) {
  const { htmlFor, size, className, bold, children } = props;
  const Tag = props.tag;

  const isBold = (bold?: string | boolean): boolean => {
    return bold !== undefined || (typeof bold === 'string' && bold !== 'false') || (typeof bold === 'boolean' && bold);
  };

  return (
    <Tag
      {...props}
      className={`text text--${size}
      ${isBold(bold) ? 'text--bold' : ''}
      ${className || ''}`}
      htmlFor={htmlFor}
    >
      {children}
    </Tag>
  );
}

Base.defaultProps = {
  tag: 'div',
};

interface Props {
  tag?: TextTag;
  htmlFor?: string;
  className?: string;
  children: string | any; // any because sometimes we run it through a Trans function which can return any
  bold?: boolean | string;
}

export function Text1(props: Props) {
  return <Base {...props} size={1} />;
}

export function Text2(props: Props) {
  return <Base {...props} size={2} />;
}

export function Text3(props: Props) {
  return <Base {...props} size={3} />;
}
