import { NodeTypes, ChatModalNodeOptions, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const bloodTransfusionTypeNode = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.BLOOD_TRANSFUSION_TYPE_NODE,
  description: 'Built-in page node',
  name: 'Blood Transfusion types',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.bloodTransfusionTypesNode',
  },
  components: [
    {
      type: 'OPTION_SELECT',
      id: -3,
      name: 'Blood Transfusion Types',
      description: 'Built in question',
      data: {
        answerKey: `${baseKey}.bloodTransfusionType`,
        questionKey: 'bloodTransfusion.bloodTransfusionType',
        labelKey: '',
        placeholderKey: '',
        subtitleKey: '',
        required: true,
        isSingleSelect: true,
        optionSize: ComponentSizes.XSmall,
        selectionOptions: [
          {
            optionKey: 1,
            optionValue: 'bloodTransfusion.bloodTransfusionTypes.redBloodCells',
          },
          {
            optionKey: 5,
            optionValue: 'bloodTransfusion.bloodTransfusionTypes.wholeBlood',
          },
          {
            optionKey: 7,
            optionValue: 'bloodTransfusion.bloodTransfusionTypes.dontKnow',
          },
        ],
      },
    },
    {
      id: -3,
      type: 'HELP_HINT',
      data: {
        helpHint: {
          termKey: 'helpHints.bloodTransfusionTypes',
          definitionKey: 'helpHints.bloodTransfusionTypesHint',
        },
      },
    },
  ],
  flows: [
    {
      id: -3,
      type: 'UNCONDITIONAL',
      name: 'Blood Transfusion types flow',
      description: 'Built-in flow',
      data: {
        nextPageId: NODE_IDS.BONE_MARROW_TRANSPLANT_YES_NO_NODE,
      },
    },
  ],
});
