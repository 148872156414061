import React from 'react';
import './index.scss';

type LinkSizes = 'small' | 'large';

interface Props {
  className?: string;
  children?: any;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => any | null | undefined;
  size?: LinkSizes;
  visited: boolean;
  error: boolean;
}

const baseClass = 'link';

function sizeClass(size: LinkSizes) {
  return `${baseClass}--${size}`;
}

function Link({ className, children, error, onClick, size, visited }: Props) {
  return (
    <button
      onClick={onClick}
      className={`
      ${className || ''}
      ${baseClass} ${size ? sizeClass(size) : ''}
      ${visited ? baseClass + '--visited' : ''}
      ${error ? baseClass + '--error' : ''}
    `}
      type="button"
    >
      {children}
    </button>
  );
}

Link.defaultProps = {
  size: 'large',
  visited: false,
  error: false,
};

export default Link;
