import React from 'react';
import './index.scss';
import { Text1 } from '../typography';

interface Props {
  size: 'small' | 'large';
  name?: string;
}

function TextLogo({ size, name }: Props) {
  return (
    <div className={`text-logo text-logo--${size}`}>
      <Text1>
        {name && name}
        {!name && (
          <React.Fragment>
            my<span className="text-logo__mid">Gene</span>History &trade;
          </React.Fragment>
        )}
      </Text1>
    </div>
  );
}

TextLogo.defaultProps = { size: 'small' };

export default TextLogo;
