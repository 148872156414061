import { NodeTypes, ComponentSizes, TextQuestionType, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { getStateOptions } from '../../../../helpers/components';
import { NODE_IDS } from '../constants';

const stateOptions = getStateOptions('personDemographic.states');

export const addressStep = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.ADDRESS_NODE,
  name: 'Address Node',
  description: 'Fill personal address data',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    continueKey: 'chatbot.modal.next',
    pageTitleKey: 'pageTitles.addressNode',
  },
  components: [
    {
      id: -1,
      description: 'Built in component',
      name: 'Address 1 Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.address`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Address,
        labelKey: 'chatbot.personalInformation.addressLabel',
        placeholderKey: 'chatbot.personalInformation.addressLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
    {
      id: -2,
      description: 'Built in component',
      name: 'Address 2 Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.address2`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Address,
        labelKey: 'chatbot.personalInformation.addressLabel2',
        placeholderKey: 'chatbot.personalInformation.addressLabel2',
        questionKey: '',
        required: false,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
    {
      id: -3,
      description: 'Built in component',
      name: 'City Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.city`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Address,
        labelKey: 'chatbot.personalInformation.cityLabel',
        placeholderKey: 'chatbot.personalInformation.cityLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
    {
      id: -4,
      type: 'OPTION_SELECT',
      name: 'State selection',
      description: 'Built in component',
      data: {
        questionKey: '',
        answerKey: `${baseKey}.state`,
        isSingleSelect: true,
        isNative: true,
        optionSize: ComponentSizes.Medium,
        selectionOptions: stateOptions,
        labelKey: 'chatbot.personalInformation.stateLabel',
        required: true,
        placeholderKey: 'chatbot.personalInformation.stateLabel',
        subtitleKey: '',
      },
    },
    {
      id: -5,
      description: 'Built in component',
      name: 'Zip code Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.postalCode`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Postal,
        labelKey: 'chatbot.personalInformation.zipLabel',
        placeholderKey: 'chatbot.personalInformation.zipLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
      },
    },
  ],
  flows: [
    {
      id: 4,
      type: 'UNCONDITIONAL',
      name: 'Ancestry to cancer landing',
      description: 'Ancestry page to cancer landing page',
      data: {
        nextPageId: NODE_IDS.SEX_NODE,
      },
    },
  ],
});
