import { BreastCancerRiskType, NodeTypes, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5011,
    description: 'Built-in page node',
    name: 'Current Hormone Replacement Therapy',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.currentHormoneReplacementTherapyNode',
    },
    components: [
      {
        id: -12,
        description: 'Built in component',
        name: 'Current Hormone Replace Therapy history',
        type: 'YES_NO',
        data: {
          answerKey: `${baseKey}.currentHormoneTherapyHistory`,
          questionKey: 'breastCancerRisk.currentHormoneTherapyHistory',
          required: type === BreastCancerRiskType.TcScore,
        },
      },
    ],
    flows: [
      {
        id: -11,
        type: 'BINARY',
        name: 'Current Hormone Replacement Therapy history flow',
        description: 'Built-in flow',
        data: {
          answerKey: `${baseKey}.currentHormoneTherapyHistory`,
          affirmativePageId: -5012,
          negativePageId: -5014,
        },
      },
    ],
  };
};
