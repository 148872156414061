import { PageNode, ContinueType, SubtitleType, BreastCancerRiskType } from '@myriadgenetics/mgh-types';
import BreastCancerRiskSteps from './steps';

interface BreastCancerRiskStep extends PageNode {
  includesBreastDensity?: boolean;
}
export class BreastCancerRisk {
  static initNodeId = -5001;
  static steps = (
    baseKey: string,
    continueType: ContinueType,
    subtitle?: SubtitleType,
    type: BreastCancerRiskType = BreastCancerRiskType.Provider,
    includesBreastDensity = false,
  ): Array<BreastCancerRiskStep> => {
    const stepParams = { continueType, subtitle, baseKey, type, includesBreastDensity };
    const bcSteps = BreastCancerRiskSteps(stepParams);
    return [
      bcSteps.heightStep,
      bcSteps.weightStep,
      bcSteps.firstMenstruationStep,
      bcSteps.currentOrPastMenopauseStep,
      bcSteps.currentMenopauseStep,
      bcSteps.menopauseAgeStep,
      bcSteps.liveBirthHistoryStep,
      bcSteps.liveBirthStep,
      bcSteps.firstLiveBirthAgeStep,
      bcSteps.hrtHistoryStep,
      bcSteps.hrtIntakeStep,
      bcSteps.hrtTypesStep,
      bcSteps.hrtCurrentStep,
      bcSteps.hrtStartedStep,
      bcSteps.hrtExpectedStep,
      bcSteps.hrtStoppedStep,
      bcSteps.breastDensityStep,
      bcSteps.breastBiopsyHistoryStep,
      bcSteps.breastBiopsyTypesStep,
      bcSteps.femaleRelativesStep,
    ];
  };
}
