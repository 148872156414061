import React, { useState } from 'react';
import { ValidationResult, RadioGroupOptions, RadioOptionsByName, RadioGroupData } from '@myriadgenetics/mgh-types';
import './index.scss';
import Radio from '../../../elements/radio';
import { OPTION_VALUES_MAPPING } from './constants';
import { Text2 } from '../../../elements/typography';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

interface RadioGroupProps {
  compDef: any;
  className?: string;
  responses: Record<string, any>;
  onResponseUpdated: (answerKey: string, val: boolean | string) => void;
  validationResult?: ValidationResult;
}

const groupRadioOptionsByName = (options: RadioGroupOptions[]) => {
  const optionKeys = [...new Set(options.map((option) => option.optionKey))];
  const radioOptionsByName: RadioOptionsByName = {};

  optionKeys.forEach((optionKey) => {
    const optionsByKey = options.filter((option) => option.optionKey === optionKey);
    radioOptionsByName[optionKey] = optionsByKey;
  });

  return radioOptionsByName;
};

const RadioGroup: React.FC<RadioGroupProps> = ({ compDef, onResponseUpdated, className, responses }) => {
  const componentData = compDef as any;
  const { t } = useTranslation();
  const { answerKey, radioOptions, labelKey }: RadioGroupData = componentData?.data;
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const selectedOptionValue: string[] | string = get(responses, answerKey, []);

  const radioOptionsByName = groupRadioOptionsByName(radioOptions);

  const onChange = (value: string) => {
    const answerValue = OPTION_VALUES_MAPPING[value] !== undefined ? OPTION_VALUES_MAPPING[value] : value;
    setSelectedOption(value);
    onResponseUpdated(answerKey, answerValue);
  };

  return (
    <div className={`radio-wrapper ${className ?? ''}`}>
      {Boolean(labelKey) && (
        <Text2 className="radio-question__label" tag="label">
          {t(labelKey || '')}
        </Text2>
      )}
      {Object.keys(radioOptionsByName).map((radioOptionName) => (
        <fieldset key={radioOptionName}>
          {radioOptionsByName[radioOptionName].map((radioOption) => (
            <Radio
              key={radioOption.optionValue}
              optionKey={radioOption.optionKey}
              optionLabel={radioOption.optionLabel}
              optionValue={radioOption.optionValue}
              onClick={onChange}
              isSelected={selectedOption === radioOption.optionValue || selectedOptionValue === radioOption.optionValue}
            />
          ))}
        </fieldset>
      ))}
    </div>
  );
};

export default RadioGroup;
