import { NodeTypes, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const bloodTransfusionYesNoNode = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.BLOOD_TRANSFUSION_YES_NO_NODE,
  type: NodeTypes.Page,
  name: 'Blood Transfusion History',
  description: 'Built-in page node',
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.bloodTransfusionHistoryNode',
  },
  components: [
    {
      id: -1,
      description: 'Built in component',
      name: 'Blood Transfusion past year',
      type: 'YES_NO',
      data: {
        answerKey: `${baseKey}.bloodTransfusionPastYear`,
        questionKey: 'bloodTransfusion.bloodTransfusionPastYear',
        required: true,
      },
    },
    {
      id: -2,
      type: 'HELP_HINT',
      data: {
        helpHint: {
          termKey: 'helpHints.bloodTransfusion',
          definitionKey: 'helpHints.bloodTransfusionHint',
        },
      },
    },
  ],
  flows: [
    {
      id: -1,
      type: 'BINARY',
      name: 'Blood Transfusion Past Year',
      description: 'Built-in flow',
      data: {
        answerKey: `${baseKey}.bloodTransfusionPastYear`,
        affirmativePageId: NODE_IDS.BLOOD_TRANSFUSION_RECENT_NODE,
        negativePageId: NODE_IDS.BONE_MARROW_TRANSPLANT_YES_NO_NODE,
      },
    },
  ],
});
