import { NodeTypes, BreastCancerRiskType, PageNode, ComponentSizes, TextQuestionType } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5006,
    description: 'Built-in page node',
    name: 'Menopause age input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.menopauseAgeInputNode',
    },
    components: [
      {
        id: -7,
        description: 'Built in component',
        name: 'Menopause Age Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.menopauseAge`,
          questionKey: 'breastCancerRisk.menopauseAge',
          labelKey: 'breastCancerRisk.menopauseAgeLabel',
          placeholderKey: 'breastCancerRisk.agePlaceHolder',
          inputType: TextQuestionType.Age,
          min: 0,
          max: 115,
          threshold: 100,
          size: ComponentSizes.Large,
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -302,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.menopause',
            definitionKey: 'helpHints.menopauseHint',
          },
        },
      },
    ],
    flows: [
      {
        id: -6,
        name: 'HRT Flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: -5009,
        },
      },
    ],
  };
};
