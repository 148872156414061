import { NodeTypes, ComponentSizes, TextQuestionType, TextContentType, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5001,
    description: 'Built-in page node',
    name: 'Height input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.heightInputNode',
    },
    components: [
      {
        id: -22,
        description: 'Built in component',
        type: 'TEXT_CONTENT',
        name: 'Height question',
        data: {
          type: TextContentType.H3,
          contentKey: 'breastCancerRisk.height',
        },
      },
      {
        id: -1,
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.height.feet`,
          inputSize: ComponentSizes.Small,
          inputType: TextQuestionType.Number,
          labelKey: 'breastCancerRisk.heightFeet',
          placeholderKey: '',
          questionKey: '',
          required: type === BreastCancerRiskType.TcScore,
          size: ComponentSizes.Small,
          min: 0,
          max: 8,
        },
      },
      {
        id: -2,
        description: 'Built in component',
        name: 'Height (inches) Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.height.inches`,
          inputSize: ComponentSizes.Small,
          inputType: TextQuestionType.Number,
          labelKey: 'breastCancerRisk.heightInches',
          placeholderKey: '',
          questionKey: '',
          required: type === BreastCancerRiskType.TcScore,
          size: ComponentSizes.Small,
          min: 0,
          max: 11,
        },
      },
    ],
    flows: [
      {
        id: -1,
        name: 'Height flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: -5002,
        },
      },
    ],
  };
};
