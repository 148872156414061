import { Trans, useTranslation } from 'react-i18next';
import { TextListComponent } from '@myriadgenetics/mgh-types';
import { H3, Text1 } from '../../../elements/typography';

import './index.scss';

interface Props {
  compDef: any | TextListComponent;
}

const columnedClass = (columned = false): string => (columned ? 'text-list__list--columned' : '');

function TextList({ compDef }: Props) {
  const { t } = useTranslation();
  const comp: TextListComponent = compDef as TextListComponent;
  const { columned, contentKey, listKeys, gridView } = comp.data;

  return (
    <div className="text-list">
      {contentKey && <H3 className="text-list__text">{t(contentKey)}</H3>}
      <ul className={`text-list__list ${columnedClass(columned)} ${gridView ? 'text-list__list--grid' : ''}`}>
        {listKeys.map((listKey, i) => (
          <li className="text-list__item" key={i}>
            <Text1>{<Trans i18nKey={listKey} components={[<b />]} />}</Text1>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TextList;
