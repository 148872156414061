import { ContinueType, NodeTypes, TextContentHighlightMod, TextContentType, ChatbotNode } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';
import { getNextComponentId } from '../../../../helpers/components';

export const patientLocationNode: ChatbotNode = {
  id: NODE_IDS.CANCER_HISTORY_INTRO_NODE,
  name: 'Chatbot Cancer History Intro',
  description: 'Chatbot Cancer History Intro',
  type: NodeTypes.Chat,
  data: {
    continueType: ContinueType.AutoEnd,
    pageTitleKey: 'pageTitles.chatbot.cancerHistoryIntroNode',
  },
  components: [
    {
      id: getNextComponentId(),
      type: 'TEXT_CONTENT',
      name: 'Go Over Details',
      description: 'Example of chat',
      data: {
        contentKey: 'chatbot.cancerHistory.reviewDetails',
        type: TextContentType.Text1,
        highlightModifier: TextContentHighlightMod.Primary,
        delay: 1000,
        avatarSvg: 'chatbot.hcpAvatar',
        position: 'left',
      },
    },
    {
      id: getNextComponentId(),
      type: 'CONTINUE',
      data: {
        modalTriggerKey: 'cancerHistory',
        buttonKey: 'chatbot.cancerHistory.personal.enterHistory',
      },
    },
  ],
  flows: [
    {
      id: -1,
      name: 'Unconditional',
      description: 'To Personal History',
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.PERSONAL_CANCER_NODE,
      },
    },
  ],
};

export default patientLocationNode;
