import React, { useEffect } from 'react';
import { isIE } from 'react-device-detect';
import { useLocation } from '../../helpers/routing';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../../lang/i18n';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import SurveyNodeContainer from '../survey-container';
import Logger from '../../helpers/logger';
import AuxiliaryPage from '../auxiliary-page';
import TextLogo from '../../elements/text-logo';

import './index.scss';

const DEFAULT_SESSION = '';

export const SessionContext = React.createContext<string>(DEFAULT_SESSION);

interface AppProps extends WithTranslation {
  session?: string;
  children?: any;
}

function App({ session }: AppProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    i18n: { language },
  } = useTranslation();
  if (isIE) {
    return <BrowserRouter>{withRouteLog(OldBrowser)}</BrowserRouter>;
  }

  return (
    <div className="app">
      <SessionContext.Provider value={session || DEFAULT_SESSION}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/auxiliary">
              {withRouteLog(AuxiliaryPage)}
            </Route>
            <Route>{withRouteLog(SurveyNodeContainer)}</Route>
          </Switch>
        </BrowserRouter>
      </SessionContext.Provider>
    </div>
  );
}

// TODO move this out or clean it up if it is not needed.
const withRouteLog = (Tag: any) => (
  <RouteLog>
    <Tag />
  </RouteLog>
);

function RouteLog(props: any) {
  const location = useLocation();

  useEffect(() => {
    Logger.logPageView();
  }, [location.pathname]);

  return <React.Fragment>{props.children}</React.Fragment>;
}

export function OldBrowser() {
  const { t } = useTranslation();

  return (
    <div className="oldbrowser">
      <TextLogo size="large" />
      <br />
      {t('oldBrowser')}
    </div>
  );
}

export default withTranslation()(App);
