import React from 'react';
import './index.scss';

type Props = {
  round: boolean;
  checked: boolean;
  size: 'small' | 'medium';
  onClick?: () => void;
};

function Toggle({ round, checked, size, onClick }: Props) {
  const trigger = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClick && onClick();
  };

  return (
    <div className="toggle" onClick={trigger} onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => (e.key === ' ' || e.key === 'Enter') && trigger(e)}>
      <label
        className={`toggle__switch
          ${size === 'small' ? 'toggle__switch--small' : ''}`}
      >
        <input checked={checked} className="toggle__input" type="checkbox" onChange={() => undefined} />
        <span
          className={`toggle__slider
          ${round ? 'toggle__slider--round' : ''}
          ${checked ? 'toggle__slider--checked' : ''}
          ${size === 'small' ? 'toggle__slider--small' : ''}`}
        ></span>
      </label>
    </div>
  );
}

Toggle.defaultProps = {
  checked: false,
  round: true,
  size: 'medium',
};

export default Toggle;
