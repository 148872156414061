import { NodeTypes, BreastCancerRiskType, PageNode, ContinueType } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type, includesBreastDensity }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5009,
    description: 'Built-in page node',
    name: 'Hormone Replacement Therapy history',
    type: NodeTypes.Page,
    data: {
      continueType: ContinueType.AutoFixed,
      subtitle,
      pageTitleKey: 'pageTitles.hormoneReplacementTherapyHistoryNode',
    },
    components: [
      {
        id: -10,
        description: 'Built in component',
        name: 'Hormone Replace Therapy history',
        type: 'YES_NO',
        data: {
          answerKey: `${baseKey}.hormoneTherapyHistory`,
          questionKey: 'breastCancerRisk.hormoneTherapyHistory',
          required: type === BreastCancerRiskType.TcScore,
        },
      },
      {
        id: -2,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrt',
            definitionKey: 'helpHints.hrtHint',
          },
        },
      },
      {
        id: -303,
        type: 'HELP_HINT',
        data: {
          helpHint: {
            termKey: 'helpHints.hrtNoteKey',
            definitionKey: 'helpHints.hrtNote',
          },
        },
      },
    ],
    flows: [
      {
        id: -9,
        type: 'BINARY',
        name: 'Hormone Replacement Therapy history flow',
        description: 'Built-in flow',
        data: {
          answerKey: `${baseKey}.hormoneTherapyHistory`,
          affirmativePageId: -5018,
          negativePageId: includesBreastDensity ? -5019 : -5015,
        },
      },
    ],
  };
};
