import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContinueType } from '@myriadgenetics/mgh-types';
import Button from '../button';
import { ReactComponent as PatientAvatarIcon } from '../../assets/icons/patient-avatar.svg';

import './index.scss';

type Props = {
  buttonKey?: string;
  type: ContinueType;
  disabled: boolean;
  onContinue: () => void;
  isChatbotStyle?: boolean;
  isPrimary?: boolean;
};

function Continue({ buttonKey, type, disabled, onContinue, isChatbotStyle, isPrimary }: Props) {
  const { t } = useTranslation();

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const onContinueHandler = () => {
    setIsSelected(!isSelected);
    onContinue();
  };

  return (
    <div
      className={`continue continue--${type === ContinueType.Fixed || type === ContinueType.AutoFixed ? 'fixed' : 'end'} ${
        isPrimary ? 'continue--primary' : ''
      }`}
    >
      <Button className="continue__button" onClick={onContinueHandler} disabled={disabled} selected={isSelected}>
        {t(buttonKey || 'continue')}
      </Button>
      {isChatbotStyle && isSelected && (
        <div className="avatar-container">
          <PatientAvatarIcon />
        </div>
      )}
    </div>
  );
}

Continue.defaultProps = { type: ContinueType.Fixed, disabled: false };

export default Continue;
