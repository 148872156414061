import { NodeTypes, ComponentSizes, TextQuestionType, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const infoStep = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.INFO_NODE,
  name: 'Ancestry Select',
  description: 'Select ancestries',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    continueKey: 'chatbot.modal.next',
    pageTitleKey: 'pageTitles.personalInfoNode',
  },
  components: [
    {
      id: -6,
      description: 'Built in component',
      name: 'Firstname Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.firstName`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Text,
        labelKey: 'chatbot.personalInformation.firstNameLabel',
        placeholderKey: 'chatbot.personalInformation.firstNameLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
    {
      id: -7,
      description: 'Built in component',
      name: 'Lastname Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.lastName`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Text,
        labelKey: 'chatbot.personalInformation.lastNameLabel',
        placeholderKey: 'chatbot.personalInformation.lastNameLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
    {
      id: -10,
      description: 'Built in component',
      name: 'Email Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.email`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Email,
        labelKey: 'chatbot.personalInformation.emailLabel',
        placeholderKey: 'chatbot.personalInformation.emailLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
      },
    },
    {
      id: -8,
      description: 'Built in component',
      name: 'Phone Input',
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.phone`,
        inputSize: ComponentSizes.Small,
        inputType: TextQuestionType.Tel,
        labelKey: 'chatbot.personalInformation.phoneLabel',
        placeholderKey: 'chatbot.personalInformation.phoneLabel',
        questionKey: '',
        required: true,
        size: ComponentSizes.Small,
        min: 0,
        max: 11,
      },
    },
    {
      id: -9,
      type: 'OPTION_SELECT',
      name: 'Phone Type Radio Group',
      description: 'Built in component',
      data: {
        answerKey: `${baseKey}.phoneTypeOptionSelect`,
        isSingleSelect: true,
        optionSize: 'x-small',
        selectionOptions: [
          {
            optionKey: 'Mobile',
            optionValue: 'chatbot.personalInformation.phoneTypeMobileLabel',
          },
          {
            optionKey: 'Home',
            optionValue: 'chatbot.personalInformation.phoneTypeHomeLabel',
          },
        ],
        labelKey: '',
        required: true,
        placeholderKey: '',
        subtitleKey: '',
      },
    },
  ],
  flows: [
    {
      id: 4,
      type: 'UNCONDITIONAL',
      name: 'To address node flow',
      description: 'Personal information flow',
      data: {
        nextPageId: NODE_IDS.ADDRESS_NODE,
      },
    },
  ],
});
