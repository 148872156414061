import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageString } from '@myriadgenetics/mgh-types';
import { Button as RACButton, ListBox, ListBoxItem, Popover, Select, SelectValue } from 'react-aria-components';
import arrowDown from '../../assets/arrow-down.svg';
import Button from '../../elements/button';
import Toggle from '../../elements/toggle';
import { Text2 } from '../../elements/typography';
import Logger from '../../helpers/logger';
import { languageOptions } from './constants';

import './index.scss';
import { LSDropdownProps } from './types';

export enum SelectionType {
  Buttons = 'buttons',
  Toggle = 'toggle',
  Dropdown = 'dropdown',
}

interface Props {
  type: SelectionType;
}

function LanguageSelect({ type }: Props) {
  const { i18n } = useTranslation();
  const isSpanish: boolean = React.useMemo(() => i18n.language === 'es', [i18n.language]);

  const RenderComponent = React.useMemo(() => {
    const changeLanguage = (lng: LanguageString) => {
      i18n.changeLanguage(lng);
    };

    switch (type) {
      case SelectionType.Buttons:
        return <LanguageSelectButtons changeLanguage={changeLanguage} />;
      case SelectionType.Dropdown:
        return <LanguageSelectDropDown changeLanguage={changeLanguage} />;
      default:
        return <LanguageSelectToggle changeLanguage={changeLanguage} isSpanish={isSpanish} />;
    }
  }, [type, i18n, isSpanish]);

  return <div className="language-select">{RenderComponent}</div>;
}

LanguageSelect.defaultProps = { type: SelectionType.Buttons };

interface LSButtonProps {
  changeLanguage: (lang: LanguageString) => void;
}

function LanguageSelectButtons({ changeLanguage }: LSButtonProps) {
  const { i18n } = useTranslation();
  return (
    <div className="language-select-buttons">
      <div className="language-select-buttons__option">
        <Button
          secondary
          onClick={() => {
            Logger.logEvent('Selected Language', {
              language: LanguageString.English,
            });
            changeLanguage(LanguageString.English);
          }}
          selected={i18n.language === LanguageString.English}
        >
          English
        </Button>
      </div>
      <div className="language-select-buttons__option">
        <Button
          secondary
          onClick={() => {
            Logger.logEvent('Selected Language', {
              language: LanguageString.Spanish,
            });
            changeLanguage(LanguageString.Spanish);
          }}
          selected={i18n.language === LanguageString.Spanish}
        >
          Español
        </Button>
      </div>
    </div>
  );
}

interface LSToggleProps {
  changeLanguage: (lang: LanguageString) => void;
  isSpanish: boolean;
}

function LanguageSelectToggle({ changeLanguage, isSpanish }: LSToggleProps) {
  const toggleTo = React.useMemo(() => (isSpanish ? LanguageString.English : LanguageString.Spanish), [isSpanish]);

  return (
    <div className="language-select-toggle">
      <Text2 tag="span">English</Text2>{' '}
      <div className="language-select-toggle__toggle">
        <Toggle
          round={false}
          checked={isSpanish}
          size="small"
          onClick={() => {
            Logger.logEvent('Toggled Language', { toggleTo });
            changeLanguage(toggleTo);
          }}
        />
      </div>{' '}
      <Text2 tag="span">Español</Text2>
    </div>
  );
}

function LanguageSelectDropDown({ changeLanguage }: LSDropdownProps) {
  const { i18n } = useTranslation();
  const toggleTo = (language: string) => (language === 'en' ? LanguageString.English : LanguageString.Spanish);

  const handleChangeLanguage = React.useCallback(
    (key: any) => {
      Logger.logEvent('Selected Language', {
        language: key,
      });
      changeLanguage(toggleTo(key));
    },
    [changeLanguage],
  );

  return (
    <Select selectedKey={toggleTo(i18n.language)} data-testid="language-select" onSelectionChange={(e: any) => handleChangeLanguage(e)}>
      <RACButton>
        <SelectValue />
        <span aria-label="selected language" aria-hidden="true">
          <img className="language-select__icon" src={arrowDown} alt="arrow down" />
        </span>
      </RACButton>
      <Popover>
        <ListBox>
          {languageOptions.map((language, i) => (
            <ListBoxItem key={`${language.value}`} id={`${language.value}`} textValue={language.label}>
              {language.label}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
}

export default LanguageSelect;
