import React, { SyntheticEvent, UIEvent, useRef, useState } from 'react';
import { ReactComponent as ImageUploadIcon } from './images/upload.svg';
import { ReactComponent as RemoveIcon } from './images/remove.svg';
import { ImageUploadComponent, ValidationResult } from '@myriadgenetics/mgh-types';
import { acceptableUploadImageTypes, MAX_FILE_SIZE, BYTES_IN_MB } from './constants';
import { useTranslation, Trans } from 'react-i18next';
import './index.scss';
import { Text3 } from '../../../elements/typography';
import { optimizeImage } from './helper';

interface UploadAndDisplayImageProps {
  compDef: any | ImageUploadComponent;
  className?: string;
  onResponseUpdated: (answerKey: string, val: any) => void;
  validationResult?: ValidationResult;
}

const UploadImage: React.FC<UploadAndDisplayImageProps> = ({ compDef, onResponseUpdated, className, validationResult }) => {
  const { t } = useTranslation();
  const componentData: ImageUploadComponent = compDef as ImageUploadComponent;
  const { answerKey, placeholderKey, labelKey, required } = componentData?.data;
  const [selectedImage, setSelectedImage] = useState<Blob | File | null>(null);
  const [selectedImageName, setSelectedImageName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const placeHolderRef = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const encodeImageFileAsURL = (file: Blob) => {
    const reader = new FileReader();
    reader.onload = () => onResponseUpdated(answerKey, reader.result);
    reader.readAsDataURL(file);
    setError(null);
  };

  const preventEvent = (event: UIEvent | SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const optimizeImageCallBack = (blob: Blob | null) => {
    setSelectedImage(blob);
    blob && encodeImageFileAsURL(blob);
  };
  const setImage = (file: File) => {
    if (!file || !(acceptableUploadImageTypes as string[]).includes(file.type)) {
      setError('imageUpload.formatError');
      return;
    }
    if (Math.round(Number(file.size) / BYTES_IN_MB) > MAX_FILE_SIZE) {
      setError('imageUpload.sizeError');
      return;
    }
    setSelectedImageName(file.name);
    optimizeImage(file, optimizeImageCallBack, clearImageData);
  };
  const handleImageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    preventEvent(event);
    setImage(((event.target as HTMLInputElement)?.files as FileList)[0]);
  };
  const handlePlaceholderKeyDown = (event: React.KeyboardEvent) => event.key === 'Enter' && (inputFileRef.current as HTMLInputElement).click();
  const clearImageData = () => {
    setSelectedImage(null);
    setSelectedImageName(null);
    onResponseUpdated(answerKey, null);
    setError(null);
  };
  const handleRemoveImage = (event: React.KeyboardEvent | React.MouseEvent) => {
    if ((event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Enter') || event.type === 'click') {
      preventEvent(event);
      clearImageData();
    }
  };
  const handleImageDrop = (event: React.DragEvent) => {
    preventEvent(event);
    setImage(event.dataTransfer.files[0]);
  };

  return (
    <div className={`image-upload ${className ?? ''}`}>
      <div className="image-wrapper">
        <label className="image-upload__label">
          <span className="image-upload__label-text">{t(labelKey)}</span>
          {((required && validationResult && !validationResult.isValid) || error) && (
            <Text3
              className={`image-upload__error 
              ${error ? 'image-upload__validation-error' : 'image-upload__required-label'}`}
              tag="span"
              bold="true"
            >
              {error ? t(error) : t('validation.required')}
            </Text3>
          )}
          <div
            className="image-upload__placeholder"
            onDragOver={preventEvent}
            onDrop={handleImageDrop}
            ref={placeHolderRef}
            tabIndex={0}
            onKeyPress={handlePlaceholderKeyDown}
            aria-labelledby={t(labelKey)}
          >
            {selectedImage && (
              <>
                <img
                  className="image-upload__image"
                  alt={t(labelKey)}
                  src={URL.createObjectURL(selectedImage)}
                  data-size={selectedImage.size}
                  data-src={selectedImageName}
                />
                <span
                  className="image-upload__remove"
                  onClick={handleRemoveImage}
                  onKeyDown={handleRemoveImage}
                  tabIndex={0}
                  aria-label={t('imageUpload.remove')}
                >
                  <RemoveIcon className="image-upload__remove-svg" />
                </span>
              </>
            )}
            {!selectedImage && (
              <div className="image-upload__placeholder-icon">
                <ImageUploadIcon className="image-upload__placeholder-svg" />
                <p className="image-upload__placeholder-text">
                  <Trans i18nKey={placeholderKey ?? 'imageUpload.placeholder'} components={[<br />]} />
                </p>
              </div>
            )}
          </div>
          <input type="file" onChange={handleImageChanged} ref={inputFileRef} />
        </label>
      </div>
    </div>
  );
};

export default UploadImage;
