import { useTranslation } from 'react-i18next';
import Toggle from '../../elements/toggle';
import { Text1 } from '../../elements/typography';

import './index.scss';

interface Props {
  isHome: boolean;
  onClick?: () => void;
}

function PhoneTypeSelect({ isHome, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <div className="phone-type-select">
      <Text1 tag="span">{t('personDemographic.phoneTypes.mobile')}</Text1>{' '}
      <div className="phone-type-select__toggle">
        <Toggle round={false} checked={isHome} onClick={onClick} />
      </div>{' '}
      <Text1 tag="span">{t('personDemographic.phoneTypes.home')}</Text1>
    </div>
  );
}

PhoneTypeSelect.defaultProps = {
  isHome: false,
};

export default PhoneTypeSelect;
