import { NodeTypes, ChatModalNodeOptions } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const bloodTransfusionRecentNode = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.BLOOD_TRANSFUSION_RECENT_NODE,
  description: 'Built-in page node',
  name: 'Recent Blood Transfusion History',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.recentBloodTransfusionHistoryNode',
  },
  components: [
    {
      id: -2,
      description: 'Built in component',
      name: 'Recent Blood Transfusion',
      type: 'YES_NO',
      data: {
        answerKey: `${baseKey}.bloodTransfusionRecent`,
        questionKey: 'bloodTransfusion.bloodTransfusionRecent',
        required: true,
      },
    },
  ],
  flows: [
    {
      id: -2,
      type: 'BINARY',
      name: 'Blood Transfusion Recent flow',
      description: 'Built-in flow',
      data: {
        answerKey: `${baseKey}.bloodTransfusionRecent`,
        affirmativePageId: NODE_IDS.BLOOD_TRANSFUSION_TYPE_NODE,
        negativePageId: NODE_IDS.BONE_MARROW_TRANSPLANT_YES_NO_NODE,
      },
    },
  ],
});
