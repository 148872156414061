import { NODE_IDS } from '../constants';

export const getTcFlow = () => ({
  nextPageId: NODE_IDS.TC_INIT_NODE,
  conditions: [
    {
      comparator: '==',
      answerKey: 'patient.sex.0.optionValue',
      value: 'personDemographic.female',
    },
    {
      comparator: '==',
      answerKey: 'personalBreastCancerHistory',
      value: false,
    },
    {
      comparator: '>',
      answerKey: 'patient.age',
      value: 84,
    },
  ],
});
