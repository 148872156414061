import { NodeTypes, BreastCancerRiskType, PageNode, TextQuestionType, ComponentSizes } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5008,
    description: 'Built-in page node',
    name: 'First live birth age input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.firstLiveBirthAgeInputNode',
    },
    components: [
      {
        id: -9,
        description: 'Built in component',
        name: 'Live Birth Age Input',
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.liveBirthAge`,
          questionKey: 'breastCancerRisk.liveBirthInput',
          labelKey: 'breastCancerRisk.ageLabel',
          placeholderKey: 'breastCancerRisk.agePlaceHolder',
          inputType: TextQuestionType.Age,
          min: 0,
          max: 115,
          threshold: 100,
          size: ComponentSizes.Large,
          required: type === BreastCancerRiskType.TcScore,
        },
      },
    ],
    flows: [
      {
        id: -8,
        name: 'Live Birth age flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: -5004,
        },
      },
    ],
  };
};
