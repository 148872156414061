import React, { useEffect } from 'react';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { CancerHistoryNode, PathologyHistory, PathologyName } from '@myriadgenetics/mgh-types';
import { useHistory, useLocation } from '../../../helpers/routing';
import RelativeSelector from '../../../components/relative-selector';
import Steps from './selections';
import getNextNodeId from '../../../helpers/flow/route-flow';
import { YesNo } from '../../question';
import Pathology from '../../survey-node/pathology';
import CancerHistoryReview from '../../review/cancer-history-review';
import { Text2 } from '../../../elements/typography';
import PathologySelectNode from '../pathology-select';
import { isSelfFemale, isSelfMale, getNextRelativeEntry, getRelativeFromRelativeEntry, getNextPathologyEntry } from './helper';
import { getUniqueDataId } from '../helpers';

import './index.scss';

export enum CancerHistoryStep {
  ChooseRelative = 'choose-relative',
  ChoosePathology = 'choose-pathology',
  Pathology = 'pathology',
  AnyOtherCancer = 'any-other-cancer',
  AnyoneElse = 'anyone-else',
  Review = 'review',
}

export interface CancerHistoryState {
  baseUrl?: string;
  curStep?: CancerHistoryStep;
  relativeEntry?: any;
  pathologyEntry?: any;
  pathology?: PathologyName;
  fromReview?: boolean;
  backupPathology?: any;
  backupPathologyEntry?: string | undefined | null;
}

export interface CancerHistoryLocationState {
  state: {
    chnState: CancerHistoryState;
  };
  pathname: string;
}

interface Props {
  node: any | CancerHistoryNode;
  responses: any;
  gotoNextNode: (nodeId: number | null | undefined) => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
  isSubWorkflow?: boolean;
  useReviewAsDefaultPage?: boolean;
  className?: string;
  hasBackButton?: boolean;
  containerClass?: string;
}

function CancerHistoryNodeReact({
  node,
  responses,
  gotoNextNode,
  onResponseUpdated,
  isSubWorkflow,
  className,
  hasBackButton,
  containerClass,
  useReviewAsDefaultPage,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const location: CancerHistoryLocationState = useLocation() as CancerHistoryLocationState;
  const { chnState }: any = location.state || {};

  const { answerKey, selectionOptions, selfSexKey, subtitle } = node.data;

  const hasPersonalBreastCancer = () => {
    const selfPathologies = get(responses, `${answerKey}.relatives.self[0].pathology`, []) as PathologyHistory[];
    return selfPathologies.some((p) => p.type === 'cancerHistory.cancers.breastFemale' || p.type === 'cancerHistory.cancers.dcis');
  };

  useEffect(() => {
    if (!chnState) {
      history.replace(`${location.pathname}/relative`, {
        ...location.state,
        chnState: {
          baseUrl: location.pathname,
          curStep: CancerHistoryStep.ChooseRelative,
        },
      });
    }
  });

  const { baseUrl, relativeEntry, pathologyEntry, pathology, curStep, fromReview }: CancerHistoryState = chnState || {};
  const relative = relativeEntry && getRelativeFromRelativeEntry(answerKey, relativeEntry);

  // TODO handle node.data.detailsType

  const triggerGotoNextNode = () => {
    location.state.chnState = {}; // clear out state
    gotoNextNode(getNextNodeId(node.flows, responses));
  };

  let internal = null;

  const step = useReviewAsDefaultPage && !curStep ? CancerHistoryStep.Review : curStep;

  switch (step) {
    case CancerHistoryStep.ChooseRelative:
      internal = (
        <RelativeSelector
          onRelativeSelected={(selectedRelative) => {
            const relativeEntry = getNextRelativeEntry(responses, answerKey, selectedRelative);

            if (selectedRelative !== 'relatives.other') {
              history.push(`${baseUrl}/pathology`, {
                ...location.state,
                title: t('pageTitles.pathologySelect'),
                chnState: {
                  ...chnState,
                  relativeEntry,
                  pathologyEntry: getNextPathologyEntry(responses, relativeEntry || 'orphaned'),
                  pathology: null,
                  curStep: CancerHistoryStep.ChoosePathology,
                },
              });
            } else {
              history.push(`${baseUrl}/others`, {
                ...location.state,
                title: t('pageTitles.pathologySelect'),
                chnState: {
                  ...chnState,
                  pathologyEntry: null,
                  pathology: null,
                  curStep: CancerHistoryStep.AnyoneElse,
                  relativeEntry,
                },
              });
            }
          }}
        />
      );
      break;
    case CancerHistoryStep.ChoosePathology:
      internal = (
        <PathologySelectNode
          selectionOptions={selectionOptions}
          isSelfFemale={isSelfFemale(responses, selfSexKey)}
          isSelfMale={isSelfMale(responses, selfSexKey)}
          relative={relative}
          onPathologySelected={(val) => {
            const selectedPathology = val[0].optionValue;
            onResponseUpdated(`${pathologyEntry}.type`, selectedPathology);
            history.push(`${baseUrl}/pathology`, {
              ...location.state,
              title: 'pageTitles.pathologySelect',
              pathologySelectState: undefined, // clear out sub state (this isn't ideal, but because we do type the way we do above)
              chnState: {
                ...chnState,
                pathology: selectedPathology,
                curStep: CancerHistoryStep.Pathology,
              },
            });
          }}
        />
      );
      break;
    case CancerHistoryStep.Pathology:
      internal = pathology && (
        <Pathology
          pathology={pathology}
          baseAnswerKey={pathologyEntry || 'orphaned'}
          relative={relative}
          responses={responses}
          onResponseUpdated={onResponseUpdated}
          onContinue={() => {
            if (fromReview) {
              history.push(`${baseUrl}/review`, {
                ...location.state,
                title: t('pageTitles.pathologyReview'),
                chnState: {
                  ...chnState,
                  curStep: CancerHistoryStep.Review,
                  relativeEntry: undefined,
                  pathologyEntry: undefined,
                  pathology: undefined,
                },
              });
            } else {
              history.push(`${baseUrl}/add-pathology`, {
                ...location.state,
                title: t('pageTitles.pathologyAdd'),
                chnState: {
                  ...chnState,
                  curStep: CancerHistoryStep.AnyOtherCancer,
                },
              });
            }
          }}
          isSubWorkflow={isSubWorkflow}
          hasBackButton={hasBackButton}
        />
      );
      break;
    case CancerHistoryStep.AnyOtherCancer:
      internal = (
        <YesNo
          compDef={Steps.anyOtherCancer(relative)}
          onResponseUpdated={(answerKey, val) => {
            if (val) {
              history.push(`${baseUrl}/pathology`, {
                ...location.state,
                title: t('pageTitles.pathologySelect'),
                chnState: {
                  ...chnState,
                  pathologyEntry: getNextPathologyEntry(responses, relativeEntry || 'orphaned'),
                  curStep: CancerHistoryStep.ChoosePathology,
                },
              });
            } else {
              history.push(`${baseUrl}/others`, {
                ...location.state,
                title: t('pageTitles.pathologySelect'),
                chnState: {
                  ...chnState,
                  curStep: CancerHistoryStep.AnyoneElse,
                },
              });
            }
          }}
          responses={responses}
        />
      );
      break;
    case CancerHistoryStep.AnyoneElse:
      internal = (
        <YesNo
          compDef={Steps.anyoneElse}
          onResponseUpdated={(answerKey, val) => {
            if (val) {
              history.push(`${baseUrl}/relative`, {
                ...location.state,
                title: t('pageTitles.pathologySelect'),
                chnState: {
                  ...chnState,
                  relativeEntry: undefined,
                  pathologyEntry: undefined,
                  pathology: undefined,
                  curStep: CancerHistoryStep.ChooseRelative,
                },
              });
            } else {
              history.push(`${baseUrl}/review`, {
                ...location.state,
                title: t('pageTitles.pathologyReview'),
                chnState: {
                  ...chnState,
                  curStep: CancerHistoryStep.Review,
                },
              });
            }
          }}
          responses={responses}
        />
      );
      break;
    case CancerHistoryStep.Review:
      internal = (
        <CancerHistoryReview
          baseKey={answerKey}
          baseUrl={baseUrl}
          responses={responses}
          onResponseUpdated={onResponseUpdated}
          cancerHistoryNode={node}
          containerClass={containerClass}
          onContinue={() => {
            onResponseUpdated('personalBreastCancerHistory', hasPersonalBreastCancer());
            triggerGotoNextNode();
          }}
        />
      );
      break;
    default:
      internal = <div className="cancer-history-node__initialize">...initializing...</div>;
  }

  const uniqueDataId = `chat-modal-item--${getUniqueDataId(node.id, step)}`;

  return (
    <div className={`cancer-history-node ${className ? className : ''}`} data-testid={uniqueDataId}>
      {subtitle && <Text2 className="cancer-history-node__subtitle subtitle">{t(subtitle)}</Text2>}
      <div className="cancer-history-node__content">{internal}</div>
    </div>
  );
}

export default CancerHistoryNodeReact;
