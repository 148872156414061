import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow-down.svg';
import { AccordionItem as AccordionItemProps } from '@myriadgenetics/mgh-types';
import { H3 } from '../../../../elements/typography';
import '../index.scss';

const AccordionItem: React.FC<AccordionItemProps> = ({ titleKey, contentKey }) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const toggleIsActive = () => setIsActive(!isActive);

  return (
    <li className={`accordion-item ${isActive ? 'accordion-item--active' : ''}`} onClick={toggleIsActive}>
      <H3 className="accordion-item__title">
        {t(titleKey)}
        <span className="accordion-item__title-icon">
          <ArrowIcon />
        </span>
      </H3>
      <div className="accordion-item__content-wrapper">
        <div className="accordion-item__content">{t(contentKey)}</div>
      </div>
    </li>
  );
};

export default AccordionItem;
