import { NodeTypes, ComponentSizes, TextQuestionType, BreastCancerRiskType, PageNode } from '@myriadgenetics/mgh-types';
import { BreastCancerRiskNodeOptions } from './types';

export default ({ continueType, subtitle, baseKey, type }: BreastCancerRiskNodeOptions): PageNode => {
  return {
    id: -5002,
    description: 'Built-in page node',
    name: 'Weight input',
    type: NodeTypes.Page,
    data: {
      continueType,
      subtitle,
      pageTitleKey: 'pageTitles.weightInputNode',
    },
    components: [
      {
        id: -304,
        type: 'TEXT_QUESTION',
        data: {
          answerKey: `${baseKey}.weight`,
          questionKey: 'breastCancerRisk.weight',
          labelKey: 'breastCancerRisk.weightPounds',
          placeholderKey: '',
          inputType: TextQuestionType.Number,
          size: ComponentSizes.Large,
          required: type === BreastCancerRiskType.TcScore,
          min: 0,
        },
      },
    ],
    flows: [
      {
        id: -2,
        name: 'Height flow',
        description: 'Built-in flow',
        type: 'UNCONDITIONAL',
        data: {
          nextPageId: -5003,
        },
      },
    ],
  };
};
