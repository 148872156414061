import { UploadImageType } from '@myriadgenetics/mgh-types';

export const IMAGE_QUALITY = 1;
export const MAX_IMAGE_SIZE = {
  width: 1200,
  height: 900,
};
export const MAX_FILE_SIZE = 20480;
export const BYTES_IN_MB = 1024;
export const acceptableUploadImageTypes = [UploadImageType.JPEG, UploadImageType.JPG, UploadImageType.PNG];
