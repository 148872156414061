import { NodeTypes, ChatModalNodeOptions, TextQuestionType, ComponentSizes } from '@myriadgenetics/mgh-types';
import { NODE_IDS } from '../constants';

export const colonPolypAge = ({ continueType, subtitle, baseKey }: ChatModalNodeOptions) => ({
  id: NODE_IDS.COLON_POLYP_AGE_NODE,
  name: 'Colon Polyp Age Node',
  type: NodeTypes.Page,
  data: {
    continueType,
    subtitle,
    pageTitleKey: 'pageTitles.colonPolypAgeNode',
  },
  components: [
    {
      id: -2,
      type: 'TEXT_QUESTION',
      data: {
        answerKey: `${baseKey}.firstPolypAge`,
        questionKey: 'colonPolyp.firstPolyp',
        labelKey: 'ageLabel',
        placeholderKey: 'agePlaceHolder',
        required: true,
        inputType: TextQuestionType.Age,
        min: 0,
        max: 115,
        threshold: 100,
        size: ComponentSizes.Large,
      },
    },
  ],
  flows: [
    {
      id: -2,
      type: 'UNCONDITIONAL',
      data: {
        nextPageId: NODE_IDS.COLON_POLYP_COUNT_NODE,
      },
    },
  ],
});
