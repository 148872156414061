import React from 'react';
import { Component, ContentNode, SurveyAccount } from '@myriadgenetics/mgh-types';
import Validation from '../../../validation';
import { typeToComponent } from '../../../helpers/component-map';
import { getClinicLogo } from '../../../helpers/surveyAccount';

interface Props {
  node: ContentNode;
  type: 'top' | 'bottom';
  responses: any;
  surveyAccount?: SurveyAccount;
  onContinue?: () => void;
  onResponseUpdated: (answerKey: string, val: any) => void;
}

const ContentNodeReact: React.FC<Props> = ({ node, responses, surveyAccount, onContinue, onResponseUpdated, type }) => {
  return (
    <>
      {node.components.map((component: Component, index: number) => {
        const key = Validation.getKey(component, index);
        const Tag = typeToComponent(component);
        return (
          <Tag
            key={key}
            compDef={component}
            responses={responses}
            onResponseUpdated={onResponseUpdated}
            onContinue={onContinue}
            clinicLogo={getClinicLogo(surveyAccount)}
            clinicName={surveyAccount?.clinicName}
          />
        );
      })}
    </>
  );
};

export default ContentNodeReact;
