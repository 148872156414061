import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';
import Button from '../../../elements/button';
import { MEDIA_SIZE } from '../../../helpers/styles';
import { BannerComponent } from '@myriadgenetics/mgh-types';
import bgPatternLg from '../../../assets/backgrounds/bg_banner_bottom-lg.jpeg';
import bgPatternSm from '../../../assets/backgrounds/bg_banner_bottom-sm.jpeg';

export interface Props {
  compDef: any | BannerComponent;
  onContinue: () => void;
  clinicName?: string;
  clinicLogo?: string;
}

const BannerSection: React.FC<Props> = ({ compDef, onContinue, clinicName, clinicLogo }) => {
  const { imageSrc, backgroundColor, titleKey, contentKey, buttonKey, isCustomProvider, isBottomBorderImage } = compDef.data;
  const { t } = useTranslation();

  return (
    <div className={`banner ${backgroundColor ? 'banner--withBg' : ''}`} style={{ backgroundColor: backgroundColor }}>
      <div className="banner__wrapper">
        {imageSrc && (
          <div className="banner__image">
            <img src={imageSrc} alt={t(titleKey)} />
          </div>
        )}
        <div className="banner__content">
          {isCustomProvider && clinicLogo && (
            <div className="banner__logo">
              <img src={clinicLogo} alt={t(titleKey)} />
            </div>
          )}
          <h1 className="banner__title">{t(titleKey)}</h1>
          <div className="banner__text_column">
            <p className="banner__text">
              <Trans i18nKey={contentKey} values={{ clinicName }} components={[<span className="banner__provider-name" />]} />
            </p>
            <Button className="banner__button button--alternative" onClick={onContinue} secondary>
              {t(buttonKey)}
            </Button>
          </div>
        </div>
      </div>
      {isBottomBorderImage && (
        <picture className="banner__bottom-pattern">
          <source srcSet={bgPatternLg} media={`(min-width: ${MEDIA_SIZE.lg})`} />
          <source srcSet={bgPatternSm} media={`(min-width: ${MEDIA_SIZE.md})`} />
          <img src={bgPatternSm} alt={t(titleKey)} />
        </picture>
      )}
    </div>
  );
};

export default BannerSection;
