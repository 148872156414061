import api from './axios';
import memoize from 'lodash.memoize';
import { getRequestCacheKey } from '../helpers/api';

export const get = async (endpointPath: string): Promise<any> => {
  const res = await api.get(endpointPath);
  return res.data;
};

const sendPost = async (endpointPath: string, requestBody?: Record<string, any>): Promise<any> => {
  const res = await api.post(endpointPath, requestBody);
  return res.data;
};

export const post = memoize(sendPost, getRequestCacheKey);

const apiExport = {
  get,
  post,
};

export default apiExport;
